import styled from '@emotion/styled';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import {
  GeneGrouping,
  GroupingOption,
  defaultGroupingOptions,
  groupingName,
  sixteenS,
  type AssayStr,
  type L2Target,
} from '@resistapp/common/assays';
import { isConsideredAbsolute } from '@resistapp/common/normalisation-mode';
import { useEffect, useRef } from 'react';
import { ResistomapSelect } from '../../forms/resistomap-select';
import { theme } from '../../shared/theme';

interface Props {
  queryFilters: QueryFilters;
}

export function GeneLegendFilter({ queryFilters }: Props) {
  const { plotData } = useResearchContext();
  const absoluteModeOn = isConsideredAbsolute(queryFilters.filters.normalisationMode);
  const geneGroups = absoluteModeOn
    ? [...defaultGroupingOptions, { value: sixteenS as GeneGrouping, label: groupingName[sixteenS] }]
    : defaultGroupingOptions;
  const selectedGeneGroup = geneGroups.find(group => group.value === queryFilters.filters.selectedTargetGrouping);

  // Track when assay grouping is selected to trigger selection of first assay
  const pendingAssaySelection = useRef(false);

  const geneGroup = selectedGeneGroup?.value;
  const { setGroupingStable } = queryFilters;
  useEffect(() => {
    if (!geneGroup) {
      setGroupingStable(defaultGroupingOptions[0].value);
    } // Is normalisationMode dependency needed?
  }, [geneGroup, queryFilters.filters.normalisationMode]);

  // Effect to select the first assay when plotData.geneGroups is updated
  useEffect(() => {
    if (
      pendingAssaySelection.current &&
      plotData?.geneGroups.length &&
      queryFilters.filters.selectedTargetGrouping === 'assay'
    ) {
      const firstAssay = plotData.geneGroups[plotData.geneGroups.length - 1];
      if (firstAssay && typeof firstAssay === 'string' && firstAssay.startsWith('AY')) {
        queryFilters.toggleGeneGroupStable(firstAssay as AssayStr, true, 'assay');
        pendingAssaySelection.current = false;
      }
    }
  }, [plotData?.geneGroups, queryFilters]);

  return (
    <Container>
      <ResistomapSelect<GroupingOption, false>
        options={geneGroups}
        value={selectedGeneGroup}
        onChange={value => {
          if (!value) return;

          if (value.value === 'assay') {
            queryFilters.setGroupingStable(value.value);
            // Mark that we need to select the first assay once plotData.geneGroups is updated
            pendingAssaySelection.current = true;
          } else {
            queryFilters.toggleGeneGroupStable(value.value as L2Target, false);
            queryFilters.setGroupingStable(value.value);
          }
        }}
        size="md"
      />
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  background-color: ${theme.colors.neutral50};
`;
