import { RefObject, useEffect, useState } from 'react';

export function useContainerWidth(containerRef: RefObject<HTMLElement>) {
  const [containerWidth, setContainerWidth] = useState<number | null>(null);

  // Ensure re-render upon landing after getting the final width
  useEffect(() => {
    if (!containerRef.current) return;
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.clientWidth);
      }
    };
    updateWidth();
    const resizeObserver = new ResizeObserver(updateWidth);
    resizeObserver.observe(containerRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return containerWidth;
}
