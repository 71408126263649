import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import type { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { getKeyPressesFromMouseClick } from '@resistapp/client/utils/general';
import { L2Targets } from '@resistapp/common/assays';
import { friendlyL2Target } from '@resistapp/common/friendly';
import { SampleStatus, type Sample } from '@resistapp/common/types';
import { LegendOrdinal } from '@visx/legend';
import { ScaleOrdinal } from 'd3-scale';
import { useEffect, useMemo, useRef, useState } from 'react';
import { noTextSelection } from '../../shared/theme';
import { LegendStatus, type SampleStatusOrEmpty } from './legend-utils';

const gradientPadding = '20px';

interface Props {
  colorScale: ScaleOrdinal<string, string>;
  labelFormat: (s: string | number) => string;
  getOpacity: (s: string) => number;
  getTextStyle?: (s: string) => React.CSSProperties;
  getSamplesByEnvId?: (s: string | number) => Sample[] | undefined;
  onClick?: (id: string | number, keys: KeysPressOptions) => void;
  noColor?: boolean;
  height?: string;
  focusedEnvironmentIds?: number[];
  updateSampleStatuses?: (newStatus: SampleStatus, environmentId?: number) => void;
  filters?: Filters;
}

export function Legend({
  noColor,
  colorScale,
  labelFormat,
  getOpacity,
  getTextStyle,
  onClick,
  height,
  getSamplesByEnvId,
  focusedEnvironmentIds,
  updateSampleStatuses,
  filters,
}: Props) {
  const { isAdmin } = useUser();
  const { allAssays } = useAssayContext();

  const statusByEnvId = useMemo(() => {
    if (!getSamplesByEnvId) return {};

    return focusedEnvironmentIds?.reduce<Record<number, SampleStatusOrEmpty>>((acc, envId) => {
      const samples = getSamplesByEnvId(envId);

      if (!samples) return acc;

      // Get unique statuses for this environment's samples
      const uniqueStatuses = [...new Set(samples.map(s => s.status))];

      // If all samples have the same status, use that status
      // Otherwise mark as empty string (which we use to indicate mixed/unknown status)
      acc[envId] = uniqueStatuses.length === 1 ? uniqueStatuses[0] : ' ';
      return acc;
    }, {});
  }, [getSamplesByEnvId, focusedEnvironmentIds]);

  const legendRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  useEffect(() => {
    const checkScrollable = () => {
      const element = legendRef.current;
      if (element) {
        setIsScrollable(element.scrollHeight > element.clientHeight);
      }
    };

    const handleScroll = () => {
      const element = legendRef.current;
      if (element) {
        setIsScrollable(
          element.scrollHeight > element.clientHeight &&
            element.scrollTop + element.clientHeight < element.scrollHeight,
        );
      }
    };

    const legendElement = legendRef.current;
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    legendElement?.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', checkScrollable);
      legendElement?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const legendGlyphSize = 14;

  const changeStatus = (label: string, newStatus: SampleStatus) => {
    updateSampleStatuses?.(newStatus, Number(label));
  };

  return (
    <>
      <LegendOrdinal scale={colorScale} labelFormat={labelFormat}>
        {labels => (
          <div
            ref={legendRef}
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
              marginLeft: '-30px',
              paddingLeft: '30px',
              height,
            }}
            className="download_legend-labels"
          >
            {labels.map((label, i) => {
              // KEEP COMMENT FOR FUTURE REFERENCE
              // console.log('LABEL', label);
              // {datum: "PHENICOL" index: 3, text: "PHENICOL", value: "#0c461b"}
              // {datum: "82492" index: 3, text: "Sample name", value: "#293651"}
              // {datum: "≥ 0.01" index: 2, text: "≥ 0.01", value: "#cc7878"}
              const opacity = getOpacity(label.datum);
              const textStyle = getTextStyle?.(label.text);

              return (
                <LegendLabel
                  key={`sample-legend-quantile-${i}`}
                  onClick={e => {
                    if (onClick) {
                      onClick(label.datum, getKeyPressesFromMouseClick(e));
                    }
                  }}
                  style={{ opacity, cursor: onClick ? 'pointer' : undefined, ...textStyle }}
                >
                  {isAdmin && label.datum && getSamplesByEnvId && (
                    <LegendStatus
                      status={statusByEnvId?.[Number(label.datum)]}
                      onStatusChange={newStatus => {
                        newStatus !== ' ' && changeStatus(label.datum, newStatus);
                      }}
                      isDisabled={!focusedEnvironmentIds?.includes(Number(label.datum))}
                    />
                  )}
                  {!noColor && (
                    <LegendSvg width={legendGlyphSize} height={legendGlyphSize} style={{ borderRadius: '2px' }}>
                      <rect fill={label.value} width={legendGlyphSize} height={legendGlyphSize} />
                    </LegendSvg>
                  )}
                  <LegendText>
                    {/* TODO FIX ME, THIS GENERIC COMPONENT SHOULD NOT HAVE TARGET SPECIFIC LOGIC (AND onClick SHOULD PROABBLY NOT DEAL WITH label.text SO WE COULD friendlyfy it)*/}
                    {filters?.selectedTargetGrouping === 'assay'
                      ? allAssays.find(a => a.assay === label.text)?.gene
                      : label.text in L2Targets
                        ? friendlyL2Target(label.text as L2Targets)
                        : label.text}
                  </LegendText>
                </LegendLabel>
              );
            })}
          </div>
        )}
      </LegendOrdinal>
      {isScrollable && <BottomGradientContainer className="download_legend-labels-bottom-gradient" />}
    </>
  );
}

const BottomGradientContainer = styled.div`
  position: relative;
  height: ${gradientPadding};
  top: -${gradientPadding};
  background-image: linear-gradient(180deg, rgba(100, 100, 100, 0), white);
  pointer-events: none;
`;

export const LegendSvg = styled.svg`
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: ${oldTheme.spacing.xxs};
`;

export const LegendLabel = styled.div`
  height: 18px;
  width: 100%;
  white-space: nowrap;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
`;

export const LegendText = styled.span`
  ${noTextSelection}
  line-height: 18px;
  width: 100%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export type KeysPressOptions = {
  ctrl: boolean;
  shift: boolean;
};
