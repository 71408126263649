import { AssayInfo, GetGroup, L1Target, L2Target } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { friendlyCopyNumber, friendlyFoldChange } from '@resistapp/common/friendly';
import { getResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { ChartUnit, MetricMode, NormalisationMode } from '@resistapp/common/types';
import { get, isNil, max } from 'lodash';
import { AntibioticStats } from '../components/selected-map-environment/site-antibiotic-detail-box';
import {
  getFriendlyGenesAndCopyNumbers,
  getFriendlyGenesAndReductionNumbers,
} from '../components/selected-map-environment/site-details/site-details-utils';
import { OverviewDatum } from '../data-utils/plot-data/build-overview-line-data';
import { GeneAndCopyNumber } from '../data-utils/plot-data/process-overview-line-datum';
import { resistanceLevelMetadata } from '../data-utils/resistance-level';
import { getReductionMetricTextColor } from './metric-utils';

export const metricScales: Record<MetricMode, [number, number]> = {
  [MetricMode.ARGI]: [0, 5],
  [MetricMode.RISK]: [0, 100],
  [MetricMode.REDUCTION]: [-5, 1],
};

export type OverviewChartConfiguration = {
  id: 'argi' | 'argi-volume' | 'risk' | 'risk-volume' | 'reduction-relative' | 'reduction-volume';
  trendChartBottomLegendName: string;
  tooltipTitle: string;
  secondaryTooltipTitle: string | null;
  getTooltipValue: (geneData: NonNullable<AntibioticStats | undefined>['analysedGenes'][number]) => string | null;
  getSecondaryTooltipValue: (
    geneData: NonNullable<AntibioticStats | undefined>['analysedGenes'][number],
  ) => string | null | undefined;
  detailBarGraphMin: number;
  detailBarGraphMax: (arr?: GeneAndCopyNumber[]) => number;
  referenceLines: number[];
  scaleProperty: keyof GeneAndCopyNumber;
  dataProperty: 'metric' | 'reduction' | 'log10'; // from AntibioticStats;
  is2Sided: boolean;
  mapLegendTicks?: string[];
  formatNumber?: (n: number | null | undefined) => string;
  xScaleTicks?: number[];
  getFriendlyNumber: (
    genesAndCopyNumbers: GeneAndCopyNumber[],
    selectedSiteDatum: OverviewDatum,
    target: MinorTarget | L1Target | L2Target,
    allAssays: AssayInfo[],
    getGroup: GetGroup,
  ) => GeneAndCopyNumber[];
  getFriendlyMetricValue: (value: number | null) => string;
  getFriendlyTextColor: (value: number | null) => string;
  colorScale?: number[];
};

export const overviewChartConfigurations: Record<MetricMode, Partial<Record<ChartUnit, OverviewChartConfiguration>>> = {
  [MetricMode.ARGI]: {
    [ChartUnit.COPYNUMBER]: {
      id: 'argi',
      trendChartBottomLegendName: 'ARGI',
      tooltipTitle: 'Copy nr',
      secondaryTooltipTitle: null,
      getTooltipValue: geneData => friendlyCopyNumber(geneData.copyNumber),
      getSecondaryTooltipValue: () => null,
      is2Sided: false,
      detailBarGraphMin: metricScales[MetricMode.ARGI][0],
      detailBarGraphMax: (arr?: GeneAndCopyNumber[]) =>
        max(arr?.map(d => d.copyNumber)) || metricScales[MetricMode.ARGI][1],
      referenceLines: [2, 4],
      scaleProperty: 'copyNumber',
      dataProperty: 'metric',
      formatNumber: friendlyCopyNumber,
      mapLegendTicks: ['0.0', '1.0', '2.0', '3.0', '4.0', '5.0'],
      getFriendlyNumber: getFriendlyGenesAndCopyNumbers,
      getFriendlyMetricValue: (value: number | null) => value?.toFixed(1) || '-',
      getFriendlyTextColor: (value: number | null) =>
        !isNil(value) ? resistanceLevelMetadata[getResistanceLevel(value)].textColor : 'black',
    } satisfies OverviewChartConfiguration,
    [ChartUnit.COPIES_PER_L]: {
      id: 'argi-volume',
      trendChartBottomLegendName: 'ARGI',
      tooltipTitle: 'Copies/L',
      secondaryTooltipTitle: null,
      getTooltipValue: geneData => friendlyCopyNumber(geneData.copiesPerL),
      getSecondaryTooltipValue: () => null,
      is2Sided: false,
      detailBarGraphMin: metricScales[MetricMode.ARGI][0],
      detailBarGraphMax: (arr?: GeneAndCopyNumber[]) =>
        max(arr?.map(d => d.copiesPerL)) || metricScales[MetricMode.ARGI][1],
      referenceLines: [2, 4],
      scaleProperty: 'copiesPerL',
      dataProperty: 'metric',
      formatNumber: friendlyCopyNumber,
      mapLegendTicks: ['0.0', '1.0', '2.0', '3.0', '4.0', '5.0'],
      getFriendlyNumber: getFriendlyGenesAndCopyNumbers,
      getFriendlyMetricValue: (value: number | null) => value?.toFixed(1) || '-',
      getFriendlyTextColor: (value: number | null) =>
        !isNil(value) ? resistanceLevelMetadata[getResistanceLevel(value)].textColor : 'black',
    } satisfies OverviewChartConfiguration,
  },
  [MetricMode.RISK]: {
    [ChartUnit.COPYNUMBER]: {
      id: 'risk',
      trendChartBottomLegendName: 'Health Risk Score',
      tooltipTitle: 'Copy nr',
      secondaryTooltipTitle: 'Class',
      getTooltipValue: geneData => friendlyCopyNumber(geneData.copyNumber),
      getSecondaryTooltipValue: geneData => geneData.riskClass,
      is2Sided: false,
      detailBarGraphMin: metricScales[MetricMode.RISK][0],
      detailBarGraphMax: (arr?: GeneAndCopyNumber[]) =>
        max(arr?.map(d => d.copyNumber)) || metricScales[MetricMode.RISK][1],
      referenceLines: [20, 40, 60, 80],
      scaleProperty: 'copyNumber',
      dataProperty: 'metric',
      formatNumber: friendlyCopyNumber,
      mapLegendTicks: ['0', '20', '40', '60', '80', '100'],
      getFriendlyNumber: getFriendlyGenesAndCopyNumbers,
      getFriendlyMetricValue: (metric: number | null) => metric?.toFixed(0) || '-',
      getFriendlyTextColor: (metric: number | null) => (metric && metric > 100 / 2 ? 'white' : 'black'),
      colorScale: [0, 100],
    } satisfies OverviewChartConfiguration,
    [ChartUnit.COPIES_PER_L]: {
      id: 'risk-volume',
      trendChartBottomLegendName: 'Health Risk Score',
      tooltipTitle: 'Copies/L',
      secondaryTooltipTitle: 'Class',
      getTooltipValue: geneData => friendlyCopyNumber(geneData.copiesPerL),
      getSecondaryTooltipValue: geneData => geneData.riskClass,
      is2Sided: false,
      detailBarGraphMin: metricScales[MetricMode.RISK][0],
      detailBarGraphMax: (arr?: GeneAndCopyNumber[]) =>
        max(arr?.map(d => d.copiesPerL)) || metricScales[MetricMode.RISK][1],
      referenceLines: [20, 40, 60, 80],
      scaleProperty: 'copiesPerL',
      dataProperty: 'metric',
      formatNumber: friendlyCopyNumber,
      mapLegendTicks: ['0', '20', '40', '60', '80', '100'],
      getFriendlyNumber: getFriendlyGenesAndCopyNumbers,
      getFriendlyMetricValue: (metric: number | null) => metric?.toFixed(0) || '-',
      getFriendlyTextColor: (metric: number | null) => (metric && metric > 100 / 2 ? 'white' : 'black'),
      colorScale: [0, 100],
    } satisfies OverviewChartConfiguration,
  },
  [MetricMode.REDUCTION]: {
    [ChartUnit.COPIES_PER_L]: {
      id: 'reduction-volume',
      trendChartBottomLegendName: 'Fold Change',
      tooltipTitle: '10-fold change',
      secondaryTooltipTitle: 'Raw copies',
      getTooltipValue: geneData => friendlyFoldChange(geneData.log10, 1, true),
      getSecondaryTooltipValue: geneData => friendlyCopyNumber(geneData.copyNumber),
      is2Sided: true,
      detailBarGraphMin: metricScales[MetricMode.REDUCTION][0],
      detailBarGraphMax: () => metricScales[MetricMode.REDUCTION][1],
      referenceLines: [-4, -3, -2, -1, 0],
      scaleProperty: 'log10',
      dataProperty: 'log10',
      mapLegendTicks: ['-5', '-4', '-3', '-2', '-1', '0', '1'],
      formatNumber: value => friendlyFoldChange(value, 1, true),
      getFriendlyNumber: getFriendlyGenesAndReductionNumbers,
      getFriendlyMetricValue: (value: number | null) => friendlyFoldChange(value, 1, true),
      getFriendlyTextColor: value => getReductionMetricTextColor(value, ChartUnit.COPYNUMBER),
      colorScale: [0, 83, 100],
    } satisfies OverviewChartConfiguration,
    [ChartUnit.COPYNUMBER]: {
      id: 'reduction-relative',
      trendChartBottomLegendName: 'Relative 10-Fold',
      tooltipTitle: 'Relative chng.',
      secondaryTooltipTitle: 'Raw copies',
      getTooltipValue: geneData => friendlyFoldChange(geneData.log10, 1, true),
      getSecondaryTooltipValue: geneData => friendlyCopyNumber(geneData.copyNumber),
      is2Sided: true,
      detailBarGraphMin: metricScales[MetricMode.REDUCTION][0],
      detailBarGraphMax: () => metricScales[MetricMode.REDUCTION][1],
      referenceLines: [-4, -3, -2, -1, 0],
      scaleProperty: 'reduction',
      dataProperty: 'reduction',
      mapLegendTicks: ['-5', '-4', '-3', '-2', '-1', '0', '1'],
      formatNumber: value => friendlyFoldChange(value, 1, true),
      getFriendlyNumber: getFriendlyGenesAndReductionNumbers,
      getFriendlyMetricValue: (value: number | null) => friendlyFoldChange(value, 1, true),
      getFriendlyTextColor: value => getReductionMetricTextColor(value, ChartUnit.COPIES_PER_L),
      colorScale: [0, 83, 100],
    } satisfies OverviewChartConfiguration,
  },
};

export function getActiveChartUnit(availableNormalisationModes: NormalisationMode[]): ChartUnit {
  return availableNormalisationModes.includes(NormalisationMode.LITRE) ? ChartUnit.COPIES_PER_L : ChartUnit.COPYNUMBER;
}

export function getOverviewConfiguration(
  metricMode: MetricMode,
  activeChartUnit: ChartUnit,
): OverviewChartConfiguration {
  return get(overviewChartConfigurations, [metricMode, activeChartUnit], defaultOverviewConfigurations[metricMode]);
}

export const defaultOverviewConfigurations: Record<MetricMode, OverviewChartConfiguration> = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  [MetricMode.ARGI]: overviewChartConfigurations[MetricMode.ARGI][ChartUnit.COPYNUMBER]!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  [MetricMode.REDUCTION]: overviewChartConfigurations[MetricMode.REDUCTION][ChartUnit.COPYNUMBER]!,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  [MetricMode.RISK]: overviewChartConfigurations[MetricMode.RISK][ChartUnit.COPYNUMBER]!,
};
