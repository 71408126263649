import { Global } from '@emotion/react';
import { theme } from './components/shared/theme';

const HEADER_HEIGHT = '2.5rem';

export function SetGlobalStyles() {
  return (
    <Global
      styles={{
        ':root': {
          fontFamily: theme.fontFamily.base,
          // These came recommended by the inter font-family
          fontFeatureSettings: "'liga' 1, 'calt' 1",
        },

        '@supports (font-variation-settings: normal)': {
          ':root': {
            fontFamily: theme.fontFamily.baseVariable,
          },
        },

        html: {
          margin: 0,
          padding: 0,
          height: '100%',
          fontSize: '15px',
          boxSizing: 'border-box',
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
        },

        body: {
          margin: 0,
          padding: 0,
          height: '100%',
          fontSize: '15px',
          backgroundColor: theme.colors.neutral50,
        },

        '#root': {
          height: '100%',
        },

        /* https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
        '*': {
          boxSizing: 'inherit',
        },
        '*:before': {
          boxSizing: 'inherit',
        },
        '*:after': {
          boxSizing: 'inherit',
        },

        /* Mapbox has it's own font-family baked in which we override */
        '.mapboxgl-map > div': {
          fontFamily: theme.fontFamily.base,
        },

        /* This styles the trendcharts left and bottom legend labels */
        '.trendchart-legend-label > g > svg > text': {
          fill: theme.colors.neutral800,
          textAlign: 'center',
          fontFamily: theme.fontFamily.base,
          fontSize: theme.fontSize.medium,
          fontWeight: `inherit !important`,
        },

        /* This styles the trendcharts left side legends text position */
        '.trendchart-legend-label-left > g > svg > text': {
          transform: 'translate(-4px)',
        },

        // The marker tooltip on the trenchards onhover effect shows properly with this
        '.hide-vx-background': {
          backgroundColor: 'transparent !important',
          boxShadow: 'none !important',
        },

        // These resistomap-select styles are for customizing "chakra-react-select" styles
        'div > .resistomap-select__control': {
          border: `1px solid ${theme.colors.neutral300}`,
        },
        // This is newer stripped version of the select. Which doesn't show the borders, but shows the selection as normal text
        '.stripped > .resistomap-select__control': {
          border: 'none',
          borderBottom: `1px dashed ${theme.colors.neutral300}`,
          boxShadow: 'none',
        },
        '.resistomap-select__control .resistomap-select__indicator': {
          backgroundColor: 'inherit',
        },
        '.resistomap-select__control .resistomap-select__indicator-separator': {
          border: 0,
        },
        '.resistomap-select__indicator > .chakra-icon > path': {
          fill: theme.colors.neutral400,
        },

        // ===== React datepicker styles =====
        // This is the container for the opened datepicker
        '.react-datepicker': {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          // border 0, because we handle border in the month-container
          border: '0px',
        },

        // This is for the always visible selected date input
        '.react-datepicker-wrapper input': {
          border: `1px solid ${theme.colors.neutral300}`,
          borderRadius: theme.borders.radius.small,
          backgroundColor: theme.colors.neutral50,
          paddingLeft: '28px',
          width: '100%',
        },

        '.react-datepicker__header': {
          backgroundColor: theme.colors.neutral50,
        },

        '.react-datepicker__month-container': {
          border: `1px solid ${theme.colors.neutral300}`,
          borderRadius: theme.borders.radius.small,
        },

        // This fixes the background color of the arrow in the datepicker
        'svg.react-datepicker__triangle': {
          stroke: `${theme.colors.neutral300} !important`,
          fill: '#F8FAFC !important',
        },

        'svg.react-datepicker__calendar-icon': {
          fill: theme.colors.neutral600,
          height: '100%',
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        },

        '.react-datepicker__month-text': {
          color: theme.colors.neutral900,
          width: '5rem !important',
          height: '2rem !important',
          alignContent: 'center',

          '&:hover': {
            backgroundColor: `${theme.colors.neutral400} !important`,
          },

          '&--disabled': {
            color: `${theme.colors.neutral400} !important`,

            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          },
        },

        // The navigation buttons for years in the header section
        '.react-datepicker__navigation': {
          height: HEADER_HEIGHT,
        },

        // Header with text
        '.react-datepicker__header.react-datepicker-year-header': {
          height: HEADER_HEIGHT,
          alignContent: 'center',
        },

        // The first selected month
        '.react-datepicker__month-text--selecting-range-start': {
          backgroundColor: theme.colors.blue400,
        },

        // The months between first and last selected month
        '.react-datepicker__month-text--in-selecting-range': {
          backgroundColor: `${theme.colors.neutral200} !important`,
          color: theme.colors.neutral900,
        },

        // The last selected month
        '.react-datepicker__month-text--selecting-range-end': {
          backgroundColor: `${theme.colors.neutral400} !important`,
        },

        // The first active month
        '.react-datepicker__month-text--keyboard-selected': {
          backgroundColor: theme.colors.neutral200,
        },
        // The months between first and last active month
        '.react-datepicker__month-text--in-range': {
          backgroundColor: theme.colors.neutral200,
          color: theme.colors.neutral900,
        },
        // The last active month
        '.react-datepicker__month-text--range-end': {
          backgroundColor: theme.colors.neutral200,
        },
      }}
    />
  );
}
