import { EnvironmentSubType } from '@resistapp/common/environment-types';
import { AdminArea, FullSamplesByUID, RawEnvironment, Sample, SampleColumns } from '@resistapp/common/types';
import _, { isNil, uniq } from 'lodash';
import { flattenSamplesByUID } from './utils';

export function getGeneralEnvironmentSubtype(subtype: EnvironmentSubType | undefined) {
  if (isNil(subtype)) {
    return undefined;
  }
  switch (true) {
    case subtype.includes('HOSPITAL_'):
      return 'hospital';
    case subtype.includes('URBAN_RUNOFF'):
      return 'runoff';
    case subtype.includes('URBAN_'):
      return 'urban';
    case subtype.includes('SEPTIC_'):
      return 'septic';
    default:
      return undefined;
  }
}

export function getAdminAreaKey(area: AdminArea) {
  const prefix = `${area.level}---${area.name}---`;
  return area.boundaries ? prefix + JSON.stringify(area.boundaries) : prefix;
}

export function getEnvironmentPoolingKey(sample: Sample) {
  return `${sample.environment.name}---${sample.environment.type}---${sample.environment.subtype}`;
}

export function getGloballyUniqueEnvironmentKey(env: RawEnvironment): string {
  // NOTE: this follows the logic on Customer template - Sample Specification sample key column
  // except for the addition of lat and lon, which are omitted there
  // (eg. in order to allow bio replicates that have slight geographical separation, such as on a field)
  return `${env.name} --XYZ-- ${env.type} --ZYX-- ${env.subtype} --ABC-- ${env.country} --PQR-- ${env.region} --LMN-- ${env.city} --STU-- ${env.lat} --VWX-- ${env.lon}`;
}

export function getBioRepGroupKey(sample: Pick<Sample, 'environmentId' | 'number' | 'time'>) {
  // Time is used instead of the sample number as the sample number is no longer quaranteed to be unique across projects
  // Note: Either all project samples are required to have time, or non of them can have time (and sample number is guaranteed to be unique)
  // Environment id here is essentially the same as getGloballyUniqueEnvironmentKey
  return `${sample.environmentId}-${isNil(sample.time) ? `${sample.number}` : sample.time.toISOString()}`; // UTC ok
}

export function hasDuplicateSampleNumbers(samplesByBioKey: FullSamplesByUID): boolean {
  const flatSamples = flattenSamplesByUID(samplesByBioKey);
  return !!_.chain(flatSamples)
    .groupBy(s => getBioRepGroupKey(s))
    .values()
    .map(samples => samples.map(s => s.number))
    .find(sampleNums => uniq(sampleNums).length > 1)
    .value();
}

export function getSampleNumberOrLabel(sample: Pick<Sample, 'number' | 'id'>, hasDuplicateNums: boolean): string {
  return hasDuplicateNums ? `${sample.id}-${sample.number}` : `${sample.number}`;
}

export function getSimpleBioNumber(sample: Pick<SampleColumns, 'number' | 'bioRep'>) {
  // "Simple" because sample number and bioRep combo may have duplicates within projects
  // (and because 1A 1B numbering may not represent the whole bioRep group, see above getBioRepGroupKey)
  return `${sample.number}${sample.bioRep}`;
}
