import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { GeneAndCopyNumber } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { L2Targets } from '@resistapp/common/assays';
import { chain, isNil, values } from 'lodash';
import { SingleBarDatum, TargetType } from './types';

export function getBarDatum(
  groupingTarget: TargetType,
  barIdentifier: L2Targets | string,
  beforeGenesAndCopyNumbers: GeneAndCopyNumber[],
  afterGenesAndCopyNumbers: GeneAndCopyNumber[],
  lodCopies: number,
  selectedSiteDatum: OverviewDatum,
): SingleBarDatum {
  // For L1Targets.ARG, we group by l2Target, for others we group by assay
  const isL2Target = (values(L2Targets) as string[]).includes(barIdentifier);
  const groupingKey = isL2Target ? ('l2Target' as const) : ('assay' as const);

  const filterByTarget = (gene: GeneAndCopyNumber) => gene[groupingKey] === barIdentifier;

  const beforeData = beforeGenesAndCopyNumbers.filter(filterByTarget);

  const detectedInBefore = beforeData.some(gene => !!gene.copiesPerL);
  const beforeReplacement = detectedInBefore ? lodCopies : 0;
  const beforeTotal =
    chain(beforeData)
      .meanBy(gene => gene.copiesPerL || beforeReplacement)
      .value() || 0;

  const afterData = afterGenesAndCopyNumbers.filter(filterByTarget);

  const detectedInAfter = afterData.some(gene => !!gene.copiesPerL);
  const afterReplacement = detectedInAfter ? lodCopies : 0;
  const afterTotal =
    chain(afterData)
      .meanBy(gene => gene.copiesPerL || afterReplacement)
      .value() || 0;

  const log10Reduction =
    !beforeTotal && !afterTotal
      ? null
      : beforeTotal && afterTotal
        ? Math.log10(afterTotal / beforeTotal)
        : beforeTotal
          ? Math.log10(lodCopies / beforeTotal)
          : Math.log10(afterTotal / lodCopies);

  // Get individual gene data
  const analysedGenes = beforeGenesAndCopyNumbers.filter(filterByTarget).map(gene => {
    const afterGene = afterGenesAndCopyNumbers.find(g => g.assay === gene.assay);
    if (isNil(afterGene)) {
      throw new Error(`Missing treated values for gene ${gene.gene} on ${selectedSiteDatum.environmentAfter?.name}`);
    }
    const beforeCopyNumber = gene.copiesPerL || null;
    const afterCopyNumber = afterGene.copiesPerL || null;

    const reduction = calculateReduction(beforeCopyNumber, afterCopyNumber, lodCopies);

    return {
      gene: gene.gene,
      assay: gene.assay,
      beforeCopyNumber,
      afterCopyNumber,
      reduction,
    };
  });

  const isDecrease = afterTotal <= beforeTotal;

  return {
    groupingTarget,
    barIdentifier,
    beforeTotal: beforeTotal <= lodCopies ? 0 : beforeTotal,
    afterTotal: afterTotal <= lodCopies ? 0 : afterTotal,
    log10Reduction,
    analysedGenes,
    isDecrease,
  };
}

// Calculate reduction using the same logic as the overall bar to maintain consistency
function calculateReduction(before: number | null, after: number | null, lodCopies: number): number | null {
  if (!isNil(before) && !isNil(after)) {
    return Math.log10(after / before);
  }
  if (!isNil(before)) {
    return Math.log10(lodCopies / before);
  }
  if (!isNil(after)) {
    return Math.log10(after / lodCopies);
  }
  return null;
}
