import { Box, Flex, Heading } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { TestSelectors } from '@resistapp/common/testing/visual-test-selectors';
import type { SampleStatus } from '@resistapp/common/types';
import { ResistomapSelect } from '../../forms/resistomap-select';
import { DownloadIcon } from '../../icons/download-icon';
import { InfoTooltipIcon } from '../../icons/info-tooltip-icon';
import { oldTheme } from '../../shared/old-styles';
import { LegendStatus } from './legend-utils';

interface GroupingOptions {
  label: string;
  value: string | undefined;
}

interface Props {
  children: React.ReactNode;
  options?: GroupingOptions[];
  onChange?: (value: GroupingOptions['value']) => void;
  defaultValue?: GroupingOptions['value'];
  TooltipContent?: React.ReactNode;
  optionMinimum?: number;
  header?: string;
  description?: string;
  style?: React.CSSProperties;
  height?: string;
  id?: string;
  canUpdateSampleStatuses?: boolean;
  sampleStatuses?: {
    update: (newStatus: SampleStatus, environmentId?: number) => void;
    status: SampleStatus | ' ';
  };
  onDownload?: () => void;
}

export function BaseLegend(props: Props) {
  const defaultValue = props.options?.find(option => option.value === props.defaultValue);

  return (
    <Box id={props.id}>
      <BaseLegendContainer
        style={{ position: 'relative', whiteSpace: 'nowrap', height: props.height, ...props.style }}
        height={props.height}
        $hasSelection={props.options ? 'true' : 'false'}
      >
        {props.sampleStatuses && (
          <Box style={{ position: 'absolute', top: 0, left: 0 }}>
            <LegendStatus
              status={props.sampleStatuses.status}
              onStatusChange={newStatus => {
                newStatus !== ' ' && props.sampleStatuses?.update(newStatus);
              }}
              isDisabled={false}
            />
          </Box>
        )}
        <Flex alignItems="center" gap={2}>
          <Heading as="h3" variant="h3" style={{ paddingLeft: 3 }}>
            {props.header}
          </Heading>
          {props.onDownload && (
            <DownloadIconWrapper onClick={props.onDownload} className={TestSelectors.TEST_DOWNLOAD_CHART_ICON}>
              <DownloadIcon size="md" style={{ marginBottom: '-4px' }} />
            </DownloadIconWrapper>
          )}
        </Flex>
        {props.options ? (
          <>
            <Flex style={{ paddingLeft: 3, paddingBottom: 10 }}>
              <Box>{props.description}</Box>
            </Flex>
            <div style={{ flexGrow: 1, paddingLeft: 1 }} className={`download_base-legend-select-container`}>
              {props.options.length > (props.optionMinimum ?? 1) && (
                <ResistomapSelect<(typeof props.options)[number], false>
                  options={props.options}
                  onChange={groupingOption => {
                    props.onChange?.(groupingOption?.value);
                  }}
                  defaultValue={defaultValue}
                  chakraStyles={{
                    container: base => ({
                      ...base,
                      paddingBottom: '10px',
                    }),
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <Box style={{ paddingLeft: 3, display: 'flex', justifyContent: 'left', position: 'relative' }}>
            {props.description}
            <InfoTooltipIcon
              iconSize="lg"
              style={{
                // Hacky: absolute position to let this go over the container edge
                position: 'absolute',
                right: props.description === 'Relative abundance (log)' ? '8px' : '0px',
                fontSize: '14px',
                fontWeight: 'normal',
                backgroundColor: 'white',
                padding: 0,
              }}
            >
              {props.TooltipContent}
            </InfoTooltipIcon>
          </Box>
        )}
      </BaseLegendContainer>
      <Box id="base-legend-content">{props.children}</Box>
    </Box>
  );
}

const BaseLegendContainer = styled.div<{ height?: string; $hasSelection: string }>`
  display: 'flex';
  flex-direction: 'column';
  align-items: 'flex-start';
  height: ${props => props.height || (props.$hasSelection === 'true' ? 'auto' : '54px')};
  padding-bottom: '10px';
`;

const DownloadIconWrapper = styled.div`
  cursor: pointer;
  transition: opacity 0.2s;
  display: inline-flex;
  align-items: center;
  margin-top: 1px;
  color: ${oldTheme.slightGray};
  &:hover {
    opacity: 1;
    color: black;
  }
`;
