import { Box, Flex } from '@chakra-ui/react';
import { friendlySampleStatus } from '@resistapp/common/friendly';
import { SampleStatus } from '@resistapp/common/types';
import type { OptionProps } from 'chakra-react-select';
import type { CSSProperties } from 'react';
import { IntegratedTextSelect } from '../../selects/integrated-text-select';
import { getStatusStyles } from '../../shared/general-components';
import { theme } from '../../shared/theme';

export type SampleStatusOrEmpty = SampleStatus | ' ';

interface LegendStatusProps {
  status: SampleStatusOrEmpty | undefined;
  onStatusChange: (status: SampleStatusOrEmpty) => void;
  isDisabled: boolean;
}

export function LegendStatus({ status, onStatusChange, isDisabled }: LegendStatusProps) {
  const statusStyles = getStatusStyles(status || ' ');
  const options = Object.values(SampleStatus).map(s => ({
    value: s,
    label: s,
    disabled: s === SampleStatus.POOLED,
  }));

  return (
    <Flex
      className="export_hide-legend-status"
      marginLeft="-26px"
      paddingRight="5px"
      alignItems="center"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <IntegratedTextSelect
        options={options}
        value={options.find(o => o.value === status)}
        onChange={option => {
          if (option) {
            onStatusChange(option.value);
          }
        }}
        styles={{
          menuList: base => ({
            ...base,
            width: 'min-content',
            minWidth: '150px',
          }),
        }}
        isDisabled={status === SampleStatus.POOLED || isDisabled}
        Option={Option}
        showArrow={false}
        border={`1px solid ${theme.colors.neutral300}`}
        captureMenuScroll={false}
      >
        <span
          style={{
            textAlign: 'center',
            width: '26px',
            height: '20px',
            color: statusStyles.text,
            backgroundColor: statusStyles.bg,
            borderRadius: statusStyles.borderRadius,
          }}
        >
          {!status || status === ' ' ? '?' : friendlySampleStatus(status)}
        </span>
      </IntegratedTextSelect>
    </Flex>
  );
}

const Option = (
  props: OptionProps<{ value: SampleStatus; label: string; disabled: boolean }> & { value: SampleStatus },
) => {
  const { children, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps } = props;
  const style = getStyles('option', props) as CSSProperties;
  const value = props.value;
  const statusStyles = getStatusStyles(value);

  return (
    <Box
      ref={innerRef}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className,
      )}
      style={style}
      {...innerProps}
    >
      <Flex>
        <Flex
          style={{
            justifyContent: 'center',
            width: '32px',
            color: statusStyles.text,
            backgroundColor: statusStyles.bg,
            borderRadius: statusStyles.borderRadius,
            marginRight: '5px',
          }}
        >
          {friendlySampleStatus(value)}
        </Flex>
        <Flex>{children}</Flex>
      </Flex>
    </Box>
  );
};
