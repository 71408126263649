import { useSearchParamsContext } from '@resistapp/client/contexts/search-params-context';
import { useUser } from '../contexts/use-user-context';
import { ExperimentalMetrics } from '../data-utils/plot-data/build-experimental-metrics';
import { OtherParams } from '../utils/url-manipulation';

export function useExperimentalMetric() {
  const { getQueryParams } = useSearchParamsContext();
  const { isAdmin } = useUser();

  const experimental = getQueryParams(OtherParams.EXPERIMENTAL);

  return experimental && isAdmin ? (experimental as keyof ExperimentalMetrics) : null;
}
