export enum TestSelectors {
  TEST_DOWNLOAD_CHART_RADIO = 'test-download-chart-radio',
  TEST_DOWNLOAD_LEGEND_RADIO = 'test-download-legend-radio',
  TEST_DOWNLOAD_CONFIRM_BUTTON = 'test-download-confirm-button',
  TEST_ENVIRONMENT_TYPE_SELECTOR = 'test-environment-type-selector',
  TEST_SAMPLE_SEARCH = 'test-sample-search',
  TEST_CHART_DISPLAY_TOGGLE = 'test-chart-display-toggle',
  TEST_SITE_DETAIL_BOX_PATHOGEN_MARKERS = 'test-site-detail-box-pathogen-markers',
  TEST_INTEGRATED_TEXT_SELECT = 'test-integrated-text-select',
  RESEARCH_CHARTS = 'test-research-charts',
  TEST_DOWNLOAD_CHART_ICON = 'test-export-hide-download-icon',
  DETECTED_GENES_LEGEND = 'test-research-view-detected-genes-legend',
  SAMPLES_LEGEND = 'test-research-view-samples-legend',
  HEATMAP_LEGEND = 'test-research-view-gene-details-legend',

  TEST_RESEARCH_CHARTS = 'test-bar-box-map',
  TEST_OVERVIEW_CONTAINER = 'test-overview-container',
  TEST_OVERVIEW_FOCUS_HEADER = 'test-overview-focus-header-container',
  TEST_SITE_DETAIL_BOXES = 'test-site-detail-boxes',
  TEST_CHART_CONTAINER = 'test-chart-container',
  TEST_FILTER_BAR = 'test-filter-bar',
  TEST_MAP_CONTAINER = 'test-map-container',
}

// Helper function to prepend '.' to test selectors for CSS class selection
export const getCssClass = (selector: string) => `.${selector}`;
