import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { friendlyCopyNumber, friendlyFoldChange } from '@resistapp/common/friendly';
import { TickRendererProps } from '@visx/axis';
import { Text } from '@visx/text';
import { format } from 'date-fns';
import { positioning } from './chart-styles';

export function prepareBottomAxisTick(firstDate: Date) {
  return function BottomAxisText(data: TickRendererProps) {
    const tickDate = new Date(Number(data.formattedValue));
    const formattedValue = format(tickDate, 'LLL');
    const year = format(tickDate, 'yyyy');
    const isFirstMonthOfYear = format(tickDate, 'M') === '1';
    const isFirstDate = format(firstDate, 'yyyy-MMM') === format(tickDate, 'yyyy-MMM');

    return (
      <Text
        y={data.y + positioning.bottomLegend.textY}
        x={data.x + positioning.bottomLegend.textX}
        textAnchor="middle"
        style={{ fontWeight: '500' }}
      >
        {`${formattedValue}${isFirstMonthOfYear || isFirstDate ? ` ${year}` : ''}`}
      </Text>
    );
  };
}

export function perpareLeftAxisTick(
  activeOverviewConfiguration: OverviewChartConfiguration | undefined,
  useExponentFormat: boolean = false,
) {
  return function LeftAxisText(data: TickRendererProps) {
    // The data.formattedValue is a string with a unicode minus sign, which causes the value to be NaN when converted to a number
    const normalizedValue = data.formattedValue?.replace('−', '-');
    // We don't show the uneven numbers on the left legends, but show the lines
    if (!normalizedValue || Math.abs(Number(normalizedValue)) % 1 !== 0) {
      return null;
    }

    const formattedValue = !useExponentFormat
      ? data.formattedValue
      : +normalizedValue && !isNaN(+normalizedValue)
        ? friendlyCopyNumber(Math.pow(10, +normalizedValue))
        : normalizedValue;

    // Just use the default text position
    const xPosition = data.x + positioning.leftLegend.textX;

    return (
      <Text y={data.y + positioning.leftLegend.textY} x={xPosition} textAnchor="end">
        {activeOverviewConfiguration?.id === 'reduction-relative' ||
        activeOverviewConfiguration?.id === 'reduction-volume'
          ? friendlyFoldChange(Number(normalizedValue), 1, true).replace('-', '−')
          : formattedValue}
      </Text>
    );
  };
}
