import { Box } from '@chakra-ui/react';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useCallback, useState } from 'react';
import { TimeSpanOption, useMonthSelection } from '../../hooks/use-month-selection';
import { IntegratedTextSelect } from '../selects/integrated-text-select';

export function MonthSelector() {
  const { selectedSiteDatum } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();

  const [selectActive, setSelectActive] = useState(false);
  const { options, selectedOption } = useMonthSelection({
    isDisabled: (month: Date, months: Date[] | undefined) => Boolean(selectedSiteDatum && !months?.includes(month)),
  });

  const { setMonthStable } = queryFilters;
  const handleChange = useCallback(
    (value: TimeSpanOption | null) => {
      if (!value) return;
      if (value.value === 'latest') {
        setMonthStable(null, null);
      } else {
        setMonthStable(new Date(value.value), null);
      }
    },
    [setMonthStable],
  );

  return (
    <Box
      onClick={() => {
        !selectActive && setSelectActive(true);
      }}
    >
      <IntegratedTextSelect<TimeSpanOption>
        options={options}
        value={selectedOption}
        size="sm"
        menuIsOpenByDefault={selectActive}
        onChange={handleChange}
        onMenuClose={() => {
          setSelectActive(false);
        }}
      >
        <span style={{ wordWrap: 'normal', whiteSpace: 'nowrap' }}>{selectedOption.label}</span>
      </IntegratedTextSelect>
    </Box>
  );
}
