import { Button, Flex, useBreakpointValue } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { FilterBar } from '@resistapp/client/components/bars/filter-bar/filter-bar';
import { TitleBar } from '@resistapp/client/components/bars/title-bar';
import { BarBoxMap } from '@resistapp/client/components/plots/bar-box-map/bar-box-map';
import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { theme } from '@resistapp/client/components/shared/theme';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { ChartDownloadProvider } from '@resistapp/client/contexts/chart-download-context';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { isExtraWideProject } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useDeleteProject } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { useToastWithDebounce } from '@resistapp/client/hooks/use-resistomap-toast';
import { isNonDemo } from '@resistapp/common/features';
import { keys } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import Sticky from 'react-stickynode';
import useLocalStorageState from 'use-local-storage-state';
import { AdminSection } from './admin-section';
import { MethodAndDownloadSections } from './method-section';

const FilterBarMargin = {
  sticky: '0px 25px',
  nonSticky: '0px 35px',
};

export function ResearchView() {
  const { project, queryFilters, loading, error: sampleDataError, adminFeatures } = useResearchContext();
  const { assaysLoaded } = useAssayContext();
  const navigate = useNavigateWithQuery();
  const { isAdmin, user } = useUser();
  const deleteProject = useDeleteProject(project?.id || 0);
  const { data: deleted, error: deleteError } = deleteProject;
  const showToast = useToastWithDebounce();
  const stickyChildRef = useRef<HTMLDivElement>(null);
  // Only activate sticky if the display screen is desktop
  const isSticky = useBreakpointValue({ base: false, lg: true });
  console.log('ResearchView rendering');

  const [notifiedAssayVersion, setNotifiedAssayVersion] = useLocalStorageState<string>(
    LocalStorageId.notifiedAssayVersion,
    {
      defaultValue: 'v3.0.1', // Previous version at time of notification feature launch
    },
  );

  useEffect(() => {
    if (
      isNonDemo(user) &&
      project?.assayVersion &&
      project.assayChangeNotification &&
      project.assayVersion !== notifiedAssayVersion
    ) {
      showToast({
        title: 'New Results Available',
        description: project.assayChangeNotification,
        status: 'info',
        duration: null,
        isClosable: true,
      });
      setNotifiedAssayVersion(project.assayVersion);
    }
  }, [
    project?.assayVersion,
    project?.assayChangeNotification,
    showToast,
    user,
    notifiedAssayVersion,
    setNotifiedAssayVersion,
  ]);

  const triggerDelete = useCallback(
    async (deep?: boolean) => {
      const response = await (deep ? deleteProject.deep() : deleteProject.shallow());
      if (response.status === 200 || response.status === 204) {
        navigate('/', false);
      }
    },
    [deleteProject, navigate],
  );

  useEffect(() => {
    if (deleted) {
      navigate(`/admin`, false);
    }
  }, [deleted, navigate]);

  const error = sampleDataError || deleteError;
  if (error) {
    throw error;
  } else if (loading || !assaysLoaded) {
    return <LoadingView />;
  } else if (keys(project.samplesByUID).length === 0) {
    return (
      <div style={{ marginBottom: oldTheme.spacing.xl }} id="research-view">
        <Root>
          <TitleBar title={<span>{project.name} </span>} />
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>This project has no samples</div>
        </Root>
      </div>
    );
  }

  // TODO fix legacy projects that do not have heatmaps
  const extraWide = isExtraWideProject(project.samplesByUID);

  return (
    <ChartDownloadProvider>
      <div style={{ marginBottom: oldTheme.spacing.xl }} id="research-view">
        <Root>
          <TitleBar $extraWide={extraWide} title={<span>{project.name} </span>} />
          <Sticky
            enabled={isSticky}
            onStateChange={newStatus => {
              // This was fixed with manual styles, since Sticky was lagging, when using react state changes
              if (stickyChildRef.current) {
                stickyChildRef.current.style.borderBottom =
                  newStatus.status !== Sticky.STATUS_ORIGINAL ? `1px solid ${theme.colors.neutral300}` : '';
                stickyChildRef.current.style.margin =
                  newStatus.status !== Sticky.STATUS_ORIGINAL ? FilterBarMargin.sticky : FilterBarMargin.nonSticky;
                stickyChildRef.current.style.boxShadow =
                  newStatus.status !== Sticky.STATUS_ORIGINAL ? '0px 2px 20px 0px rgba(41, 54, 81, 0.15)' : 'none';
              }
            }}
          >
            <div style={{ height: '25px' }} />
            <FilterBar
              innerRef={stickyChildRef}
              style={{
                transition: 'margin 1s 0s',
                boxShadow: 'none',
                margin: FilterBarMargin.nonSticky,
              }}
              project={project}
            />
          </Sticky>
        </Root>
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          <BarBoxMap
            setGrouping={queryFilters.setGroupingStable}
            filters={queryFilters.filters}
            toggleGeneGroup={queryFilters.toggleGeneGroupStable}
            setAbundanceMode={queryFilters.setAbundanceModeStable}
            toggleEnvironment={queryFilters.toggleEnvironmentStable}
          />

          {project.id && <MethodAndDownloadSections project={project} />}
          {project.id && isAdmin && (
            <>
              <Flex justifyContent="center" paddingTop="20px">
                <Button
                  onClick={() => {
                    adminFeatures.setIsModifyAsAdminActive(!adminFeatures.isModifyAsAdminActive);
                  }}
                  variant={adminFeatures.isModifyAsAdminActive ? 'green' : 'red'}
                >
                  {adminFeatures.isModifyAsAdminActive ? 'Switch to normal mode' : 'Switch to admin mode'}
                </Button>
              </Flex>
              {adminFeatures.isModifyAsAdminActive && <AdminSection project={project} triggerDelete={triggerDelete} />}
            </>
          )}
        </div>
      </div>
    </ChartDownloadProvider>
  );
}

const Root = styled.div`
  position: relative;
  z-index: ${theme.zIndexes.header};
`;
