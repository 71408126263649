import { theme } from '@resistapp/client/components/shared/theme';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useTrendChartContext } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import type { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getMetricAndLevel, getMetricColor } from '@resistapp/client/utils/metric-utils';
import { MetricMode } from '@resistapp/common/types';
import { LinearGradient } from '@visx/gradient';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { positioning } from '../chart-styles';
import { getGradientScale } from '../scales';
import { BaseChart } from './base-chart';

export function RiskScoreChart() {
  const { queryFilters } = useSampleDataContext();
  const { getGroup, allAssays } = useAssayContext();
  const {
    graphHeight,
    trenchartTooltip: { trendChartSize, TooltipComponentForRiskScore },
  } = useTrendChartContext();
  const { activeOverviewConfiguration, activeChartUnit, effectiveSiteDetailsProcessMode } = useOverviewContext();

  const indexScale = useMemo(
    () =>
      getGradientScale(graphHeight, [
        activeOverviewConfiguration.detailBarGraphMin,
        activeOverviewConfiguration.detailBarGraphMax(),
      ]),
    [graphHeight, activeOverviewConfiguration],
  );

  const getValue = useCallback(
    (datum: OverviewDatum) => {
      const [metric] = getMetricAndLevel(
        datum,
        queryFilters.filters.selectedTargets,
        MetricMode.RISK,
        effectiveSiteDetailsProcessMode,
        activeChartUnit,
        getGroup,
        allAssays,
      );
      return isNil(metric) ? undefined : indexScale(metric);
    },
    [
      queryFilters.filters.selectedTargets,
      getGroup,
      indexScale,
      activeChartUnit,
      effectiveSiteDetailsProcessMode,
      allAssays,
    ],
  );

  return (
    <BaseChart
      width={trendChartSize.width}
      height={trendChartSize.height}
      indexScale={indexScale}
      labelScale={indexScale}
      Legend={GradientLegend}
      getValue={getValue}
      TooltipComponent={TooltipComponentForRiskScore}
      showData={{ quartileRange: false }}
    />
  );
}

interface GradientLegendProps {
  left: number;
  height: number;
  width: number;
}

function GradientLegend({ left, height, width }: GradientLegendProps) {
  const cornerRadius = theme.borders.radius.small;
  const { activeChartUnit } = useOverviewContext();

  return (
    <svg width={width} height={height} style={{ position: 'absolute', left }}>
      <defs>
        <clipPath id="rounded-corner">
          <rect
            x={0}
            y={positioning.margin.top / 2}
            width={width}
            height={height - positioning.margin.top - positioning.margin.bottom}
            rx={cornerRadius}
          />
        </clipPath>
        <LinearGradient
          id="risk-score-gradient"
          from={theme.colors.red200}
          to={theme.colors.red500}
          x1="0%"
          y1="100%"
          x2="0%"
          y2="0%"
        >
          <stop offset="0%" stopColor={getMetricColor(0, MetricMode.RISK, activeChartUnit)} />
          <stop offset="100%" stopColor={getMetricColor(100, MetricMode.RISK, activeChartUnit)} />
        </LinearGradient>
      </defs>
      <rect
        x={0}
        y={positioning.margin.top / 2}
        width={width}
        height={height - positioning.margin.top - positioning.margin.bottom}
        fill="url(#risk-score-gradient)"
        clipPath="url(#rounded-corner)"
      />
    </svg>
  );
}
