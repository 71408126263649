import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import type { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function RiskScoreMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  const { activeOverviewConfiguration } = useOverviewContext();
  const colorScale = activeOverviewConfiguration.colorScale;

  if (!colorScale) {
    return null;
  }

  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={<ColorScale activeOverviewConfiguration={activeOverviewConfiguration} />}
      indexRange={activeOverviewConfiguration.mapLegendTicks?.map(e => <div key={e}>{e}</div>)}
    />
  );
}

const ColorScale = styled.div<{ activeOverviewConfiguration: OverviewChartConfiguration }>`
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    ${props =>
      getMetricColor(props.activeOverviewConfiguration.colorScale?.[0] ?? null, MetricMode.RISK, ChartUnit.COPYNUMBER)},
    ${props =>
      getMetricColor(props.activeOverviewConfiguration.colorScale?.[1] ?? null, MetricMode.RISK, ChartUnit.COPYNUMBER)}
  );
  border-radius: ${theme.borders.radius.small};
`;
