import { DeadCenter } from '../components/shared/layout';
import { MaybeApiError } from '../components/states/error';

export function PageNotFoundView() {
  return (
    <DeadCenter>
      <MaybeApiError error={new Error('Page not found. Please check the URL and try again.')} />
    </DeadCenter>
  );
}
