import { Box, Popover, PopoverBody, PopoverContent, PopoverTrigger } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { L2Target, L2Targets } from '@resistapp/common/assays';
import { friendlyL2Target } from '@resistapp/common/friendly';
import { MetricMode } from '@resistapp/common/types';
import { friendlyGeneName } from '../selected-map-environment/site-details/site-details-utils';
import { getGroupColor, getGroupTextColor } from './palettes';
import { theme } from './theme';

// Define a constant for non-breaking space
const NBSP = String.fromCharCode(160);

interface Props {
  noBold?: boolean;
  noColor?: boolean;
  noAntibiotic?: boolean;
  style?: React.CSSProperties;
  plural?: boolean;
  antibioticOverride?: L2Target | null;
  metricNameOverride?: string;
}

// TODO refactor this if monster

export function MetricTitleWithDisclaimer(props: Props) {
  const { metricGeneCnt, isOneHealthProject } = useOverviewContext();
  if (isOneHealthProject || props.metricNameOverride) {
    return <MetricTitle {...props} />;
  }
  return (
    <Popover trigger="hover" placement="top">
      <PopoverTrigger>
        <Box as="span">
          <MetricTitle {...props} />
          <b>*</b>
        </Box>
      </PopoverTrigger>
      <PopoverContent width="200px">
        <PopoverBody>
          This metric is based on a custom set of {metricGeneCnt} genes, instead of Resistomap&apos;s standard 96 Gene
          Panel for One Health Framework.
          <br />
          <br />
          Data is comparable within this project, but might not be globally comparable to other sampling projects.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export function MetricTitle(props: Props) {
  const { isOneHealthProject, metricMode, selectedAntibiotic, activeOverviewConfiguration } = useOverviewContext();
  const { queryFilters } = useSampleDataContext();
  const { getAssayInfo } = useAssayContext();
  const antibiotic = props.antibioticOverride === null ? undefined : props.antibioticOverride || selectedAntibiotic;

  const sMaybe = !props.plural ? '' : metricMode === MetricMode.ARGI ? 'es' : 's';

  // Helper to get gene name for display
  const getGeneName = () => {
    if (queryFilters.filters.selectedTargetGrouping === 'assay' && queryFilters.filters.selectedTargets.length > 0) {
      return queryFilters.filters.selectedTargets
        .map(ay => friendlyGeneName(ay, getAssayInfo(ay)?.gene || ay))
        .join(', ');
    }
    return '';
  };

  if (queryFilters.filters.selectedTargetGrouping === 'assay' && props.antibioticOverride === undefined) {
    const name = getGeneName();
    switch (metricMode) {
      case MetricMode.ARGI:
        return (
          <span>
            {name} {props.metricNameOverride || `Index${sMaybe}`}
          </span>
        );
      case MetricMode.RISK:
        return (
          <span>
            {name} {props.metricNameOverride || `Score${sMaybe}`}
          </span>
        );
      case MetricMode.REDUCTION: {
        return props.metricNameOverride ? (
          <span>
            {name}{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {props.metricNameOverride}
              {NBSP}
              {activeOverviewConfiguration.id === 'reduction-relative' ? '(relative)' : '(per L)'}
            </span>
          </span>
        ) : (
          <span>
            {name} 10-fold{NBSP}Change{sMaybe}
            {NBSP}
            {activeOverviewConfiguration.id === 'reduction-relative' ? '(relative)' : '(per L)'}
          </span>
        );
      }
    }
  }

  switch (metricMode) {
    case MetricMode.ARGI: {
      if (props.metricNameOverride === 'gene') {
        const name = getGeneName();
        if (name) {
          return (
            <span>
              {name} Gene{sMaybe}
            </span>
          );
        }
        return <span>Gene{sMaybe}</span>;
      }

      const metricText = props.metricNameOverride || `Resistance${NBSP}Index${sMaybe}`;
      if (antibiotic) {
        return (
          <span>
            <AntibioticText {...props} />
            {NBSP}
            {metricText}
          </span>
        );
      } else {
        const idxName = isOneHealthProject ? 'Global' : 'Antibiotic';
        return (
          <span>
            {props.metricNameOverride ? '' : idxName}
            {props.metricNameOverride ? null : NBSP}
            {props.metricNameOverride ? props.metricNameOverride : `Resistance${NBSP}Gene${NBSP}Index${sMaybe}`}
          </span>
        );
      }
    }
    case MetricMode.RISK: {
      if (props.metricNameOverride === 'gene') {
        const name = getGeneName();
        if (name) {
          return (
            <span>
              {name} Gene{sMaybe}
            </span>
          );
        }
        return <span>Gene{sMaybe}</span>;
      }

      return (
        <span>
          {antibiotic ? (
            <>
              <AntibioticText {...props} />
              {NBSP}
            </>
          ) : props.metricNameOverride ? (
            ''
          ) : (
            <>
              {`Comparative${NBSP}Health`}
              {NBSP}
            </>
          )}
          {props.metricNameOverride || `Risk${NBSP}Score${sMaybe}`}
        </span>
      );
    }
    case MetricMode.REDUCTION: {
      // Unlike other metrics, the reduction figure supports single gene display
      const name = getGeneName();
      if (props.metricNameOverride === 'gene') {
        if (name) {
          return (
            <span>
              {name} Gene{sMaybe}
            </span>
          );
        }
        return <span>Gene{sMaybe}</span>;
      }

      const metric = props.metricNameOverride || `10-fold${NBSP}Change${sMaybe}`;
      return (
        <span>
          <span style={{ whiteSpace: 'nowrap' }}>
            {antibiotic ? (
              <>
                <AntibioticText {...props} />
                {NBSP}
              </>
            ) : name ? (
              name + NBSP
            ) : (
              ''
            )}
            {metric}
            {NBSP}
            {activeOverviewConfiguration.id === 'reduction-relative' ? '(relative)' : '(per L)'}
          </span>
        </span>
      );
    }
  }
}

function AntibioticText(props: Props) {
  const { selectedAntibiotic } = useOverviewContext();
  const { allGeneGroups } = useAssayContext();
  const antibiotic: L2Target | undefined = props.antibioticOverride || selectedAntibiotic;
  if (!antibiotic) {
    return null;
  }

  const groupColor = getGroupColor(antibiotic, 'antibiotic', allGeneGroups);
  const groupTextColor = getGroupTextColor(antibiotic, 'antibiotic', allGeneGroups);

  return (
    <span
      style={
        props.noColor || props.noAntibiotic
          ? { fontWeight: props.noBold ? 'inherit' : 'bold' }
          : {
              fontWeight: props.noBold ? 'inherit' : 'bold',
              backgroundColor: groupColor,
              color: groupTextColor,
              padding: theme.spacing[0.5],
              paddingLeft: theme.spacing[1],
              paddingRight: theme.spacing[1],
              borderRadius: theme.borders.radius.small,
              ...props.style,
            }
      }
    >
      {props.noAntibiotic ? (
        ''
      ) : (
        <span style={{ whiteSpace: 'nowrap' }}>{friendlyL2Target(antibiotic as L2Targets)}</span>
      )}
    </span>
  );
}
