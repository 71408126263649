import { getCssClass, TestSelectors } from '@resistapp/common/testing/visual-test-selectors';
import { toPng } from 'html-to-image';

// These two need to be modified if the axis at the top of the chart is not correctly aligned vertically. So the axis
// is placed from the top as X_AXIS_TOP_OFFSET orders and chart is placed further down so that it doesn't overlap with axis.
const X_AXIS_TOP_OFFSET = -50;
const CHART_TOP_OFFSET = 45;

// This is specifically tailored to the research views charts and will probably need to be modified, when others are
// exported in the future (like overview charts).
export async function getImageDataUrlFromSVG(
  originalElement: SVGElement,
  plotType: 'heat' | 'box' | 'bar',
  options: {
    padding?: { left: number; bottom: number; right: number };
    pixelRatio?: number;
    keepElement?: boolean;
  } = {},
): Promise<string | undefined> {
  const { padding = { left: 0, bottom: 0, right: 0 }, pixelRatio = 8, keepElement = false } = options;

  try {
    if (!(originalElement instanceof SVGElement)) {
      throw new Error('Original element is not an SVGElement');
    }
    const clonedElement = originalElement.cloneNode(true) as SVGElement;
    const { parentContainer } = containAndHideInParent(clonedElement);
    const { originalSize, scaledSize } = getSize(
      originalElement,
      { width: padding.left + padding.right, height: padding.bottom },
      pixelRatio,
    );
    const { canvas, ctx } = createCanvas(scaledSize.width, scaledSize.height);
    setSVGElementSize(clonedElement, originalSize, scaledSize, padding);

    // Remove download icons from the exported image
    const downloadIcons = clonedElement.querySelectorAll(getCssClass(TestSelectors.TEST_DOWNLOAD_CHART_ICON));
    downloadIcons.forEach(icon => {
      icon.remove();
    });

    const rotationElements = clonedElement.getElementsByClassName('download_heatmap-genes-axis-text-rotation');
    if (rotationElements.length > 0) {
      Array.from(rotationElements).forEach(element => {
        removeRotationFromTransform(element);
      });
    }

    // This positions the element correctly. Since they are SVG, you can not just position them using padding or
    // other HTML ways. This clones the download_x-axis element and positions it on top of the chart and TOP_OFFSETs
    // determine the relationship between the chart and the axis and the padding on top of the y-axis..
    if (plotType === 'heat') {
      const xAxisElement = document.getElementsByClassName('download_x-axis')[1];
      if (xAxisElement instanceof SVGElement) {
        const clonedXAxis = xAxisElement.cloneNode(true) as SVGElement;
        setVGElementPosition(clonedXAxis, {
          x: 0,
          y: X_AXIS_TOP_OFFSET,
        });
        // We need to also move the original element down to make room for the new xAxisElement
        setVGElementPosition(clonedElement.firstElementChild as SVGElement, {
          x: 0,
          y: CHART_TOP_OFFSET,
        });

        clonedElement.appendChild(clonedXAxis);
      }
    }

    // This is for testing and developing purposes. It keeps the element on the page and makes it visible, not aiming
    // to export it or download it.
    if (keepElement) {
      parentContainer.style.left = '0px';
      parentContainer.style.top = '0px';
      const rootElement = document.getElementById('root') as HTMLElement;
      document.body.removeChild(rootElement);
      moveElementBackToViewport(parentContainer);
      return;
    }

    const objectUrl = getObjectUrlFromElement(clonedElement);

    const img = new Image();
    parentContainer.appendChild(img);
    await loadImage(img, objectUrl);

    ctx && ctx.drawImage(img, 0, 0);
    const pngUrl = canvas.toDataURL('image/png');
    cleanUp({ container: parentContainer, objectUrl });
    return pngUrl;
  } catch (error) {
    throw error instanceof Error ? error : new Error(String(error));
  }
}

export async function getImageDataUrlFromHtml(
  element: HTMLElement,
  minContent: boolean = false,
  padding: { width: number; height: number } = { width: 0, height: 0 },
  pixelRatio = 8,
) {
  if (!(element instanceof HTMLElement)) {
    throw new Error('Original element is not an HTMLElement');
  }
  const size = minContent ? element.getBoundingClientRect() : { width: 1000, height: 1000 };
  const clonedElement = element.cloneNode(true) as HTMLElement;
  document.body.appendChild(clonedElement);

  removeTooltip(clonedElement);
  setLegendLabelsCorrectStyles(clonedElement);
  removeElement(clonedElement, '.download_base-legend-select-container');
  removeElement(clonedElement, '.download_legend-labels-bottom-gradient');
  removeElement(clonedElement, '.export_hide-legend-status');
  removeElement(clonedElement, getCssClass(TestSelectors.TEST_DOWNLOAD_CHART_ICON));

  if (minContent) {
    clonedElement.style.width = 'min-content';
    clonedElement.style.height = 'min-content';
    const boundRect = clonedElement.getBoundingClientRect();
    size.width = boundRect.width;
    size.height = boundRect.height;
  }

  size.width += padding.width;
  size.height += padding.height;

  let pngData: string | undefined;
  try {
    pngData = await toPng(clonedElement, { pixelRatio, width: size.width, height: size.height });

    if (!pngData) {
      console.error('Failed to generate PNG data', clonedElement);
      return;
    }
    return pngData;
  } catch (error) {
    console.error('Error exporting element as PNG:', error);
  } finally {
    cleanUp({ container: clonedElement, objectUrl: pngData });
  }

  return undefined;
}

function containAndHideInParent(element: Element) {
  const parentContainer = document.createElement('div');

  document.body.appendChild(parentContainer);
  moveElementOutsideViewport(parentContainer);
  parentContainer.appendChild(element);

  return {
    parentContainer,
  };
}

function moveElementOutsideViewport(element: HTMLElement) {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  element.style.position = 'fixed';
  element.style.left = `${viewportWidth}px`;
  element.style.top = `${viewportHeight}px`;
}

function moveElementBackToViewport(element: HTMLElement) {
  element.style.position = 'static';
}

function getSize(element: Element, padding: { width: number; height: number }, pixelRatio: number) {
  const size = element.getBoundingClientRect();
  const originalSize = {
    width: size.width + padding.width,
    height: size.height + padding.height,
  };
  const scaledSize = {
    width: originalSize.width * pixelRatio,
    height: originalSize.height * pixelRatio,
  };

  return {
    originalSize,
    scaledSize,
  };
}

function cleanUp({ container, objectUrl }: { container?: Element; objectUrl?: string }) {
  container && container.remove();
  objectUrl && URL.revokeObjectURL(objectUrl);
}

function createCanvas(width: number, height: number) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');

  return { canvas, ctx };
}

function setSVGElementSize(
  element: Element,
  originalSize: { width: number; height: number },
  scaledSize: { width: number; height: number },
  padding: { left: number; bottom: number; right: number },
) {
  element.setAttribute('width', `${scaledSize.width}px`);
  element.setAttribute('height', `${scaledSize.height}px`);
  element.setAttribute('viewBox', `-${padding.left} 0 ${originalSize.width} ${originalSize.height}`);
}

export function downloadDataUrl(dataUrl: string, filename: string) {
  const downloadLink = document.createElement('a');
  downloadLink.href = dataUrl;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

function removeTooltip(element: Element) {
  const infoTooltipIcons = element.getElementsByClassName('download_info-tooltip-icon');

  Array.from(infoTooltipIcons).forEach(icon => {
    if (icon instanceof HTMLElement) {
      icon.style.display = 'none';
    }
  });
}

function setLegendLabelsCorrectStyles(element: Element) {
  const legendLabels = element.getElementsByClassName('download_legend-labels');
  const legendLabelsElement = legendLabels[0] as HTMLElement | undefined;

  legendLabelsElement && (legendLabelsElement.style.overflow = 'visible');
  legendLabelsElement && (legendLabelsElement.style.height = 'min-content');
}

function removeElement(element: Element | Element[], queryString: string) {
  const elementsToRemove = Array.isArray(element) ? element : element.querySelectorAll(queryString);
  elementsToRemove.forEach(e => {
    if (e instanceof HTMLElement) {
      e.remove();
    }
  });
}

function removeRotationFromTransform(element: Element) {
  const transform = element.getAttribute('transform');

  if (transform) {
    const translateMatch = transform.match(/translate\(([^)]+)\)/);
    if (translateMatch) {
      const newTransform = `translate(${translateMatch[1]})`;
      element.setAttribute('transform', newTransform);
    }
  }
}

function setVGElementPosition(element: Element, position: { x: number; y: number }) {
  element.setAttribute('transform', `translate(${position.x}, ${position.y})`);
}

function getObjectUrlFromElement(element: Element) {
  const serializer = new XMLSerializer();
  const svgString = serializer.serializeToString(element);
  const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });

  return URL.createObjectURL(svgBlob);
}

async function loadImage(img: HTMLImageElement, src: string): Promise<void> {
  await new Promise((resolve, reject) => {
    img.onload = () => {
      resolve(void 0);
    };
    img.onerror = reject;
    img.src = src;
  });
}
