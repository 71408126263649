import { Flex, FormControl, Switch, Text } from '@chakra-ui/react';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import {
  ChartDisplayMode,
  useChartDisplayMode,
} from '@resistapp/client/contexts/use-overview-context/chart-display-mode-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { TestSelectors } from '@resistapp/common/testing/visual-test-selectors';
import { MetricMode } from '@resistapp/common/types';
import { useCallback, useEffect, useMemo, useRef } from 'react';

export function ChartDisplayToggle() {
  const { chartDisplayMode, setChartDisplayMode, previousMode, setPreviousMode, setForcedMode } = useChartDisplayMode();
  const { queryFilters } = useSampleDataContext();
  const { metricMode } = useOverviewContext();

  // Track if we've previously forced GENES mode due to assay selection
  const wasAssaySelectedRef = useRef(false);
  // Track the previous metric mode to detect changes
  const previousMetricModeRef = useRef(metricMode);

  // Check if assay is selected
  const isAssaySelected = queryFilters.filters.selectedTargetGrouping === 'assay';

  // Automatically set to GENES mode when assay is selected in RISK or ARGI mode
  // And revert when either assay is deselected OR metric mode changes to something else
  useEffect(() => {
    // Force mode when assay is selected in non-REDUCTION mode
    if (isAssaySelected && metricMode !== MetricMode.REDUCTION) {
      if (chartDisplayMode !== ChartDisplayMode.GENES) {
        // Force into GENES mode when assay is selected
        setForcedMode(ChartDisplayMode.GENES);
        wasAssaySelectedRef.current = true;
      }
    }
    // Revert when conditions change
    else if (wasAssaySelectedRef.current && previousMode) {
      // Revert to previous mode when either:
      // 1. Assay is deselected, OR
      // 2. Metric mode changed from REDUCTION to something else
      const metricModeCausedChange =
        previousMetricModeRef.current !== MetricMode.REDUCTION && metricMode === MetricMode.REDUCTION;

      if (!isAssaySelected || metricModeCausedChange) {
        setChartDisplayMode(previousMode);
        setPreviousMode(null);
        wasAssaySelectedRef.current = false;
      }
    }

    // Update the previous metric mode ref
    previousMetricModeRef.current = metricMode;
  }, [
    isAssaySelected,
    metricMode,
    chartDisplayMode,
    setChartDisplayMode,
    setForcedMode,
    previousMode,
    setPreviousMode,
  ]);

  const handleToggleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newMode = e.target.checked ? ChartDisplayMode.GENES : ChartDisplayMode.METRIC;
      setChartDisplayMode(newMode);
      // Reset previous mode when user manually changes
      setPreviousMode(null);
    },
    [setChartDisplayMode, setPreviousMode],
  );

  // Determine if toggle should be disabled
  const isDisabled = isAssaySelected && metricMode !== MetricMode.REDUCTION;

  // Determine the metric label based on the current metric mode
  const metricLabel = useMemo(() => {
    switch (metricMode) {
      case MetricMode.ARGI:
        return 'Index';
      case MetricMode.RISK:
        return 'Risk';
      case MetricMode.REDUCTION:
        return '10-fold change';
      default:
        return 'Index';
    }
  }, [metricMode]);

  return (
    <FormControl
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
      width="auto"
      className={TestSelectors.TEST_CHART_DISPLAY_TOGGLE}
    >
      <Flex gap={2} alignItems="center">
        <Text fontSize="sm" fontWeight="medium" opacity={isDisabled ? 0.5 : 1}>
          {metricLabel}
        </Text>
        <Switch
          size="md"
          colorScheme="blue"
          isChecked={chartDisplayMode === ChartDisplayMode.GENES}
          onChange={handleToggleChange}
          data-testid="chart-display-toggle"
          isDisabled={isDisabled}
        />
        <Text fontSize="sm" fontWeight="medium" opacity={isDisabled ? 0.5 : 1}>
          Genes
        </Text>
      </Flex>
    </FormControl>
  );
}
