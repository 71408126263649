import { useMapContext } from '@resistapp/client/contexts/map-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { isEqual } from 'lodash';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { MapboxMap, Marker as ReactMarker, useMap } from 'react-map-gl';
import { MapRef } from 'react-map-gl/dist/esm/mapbox/create-ref';
import { theme } from '../../shared/theme';
import { getPositionedMarkers, MarkerOverviewDatum } from './marker-utils';
import { onMarkerClick } from './on-marker-click';
import { getMarkerSize, ProcessMarker } from './process-marker';

export const MapMarkers = () => {
  const {
    mapData,
    selectedOrHoveredAreaOrSiteEnvId,
    setHoveredAreaOrSiteEnvIdStable,
    selectedSiteDatum: pinnedMapData,
    previousAdminAreasLifo,
  } = useOverviewContext();
  const { mapInstance, changeZoomedAdminAreaStable, activeMapSource } = useMapContext();
  const { data, queryFilters } = useSampleDataContext();
  const { current: map } = useMap();
  const navigate = useNavigateWithQuery();

  const oldMapDataRef = useRef<OverviewDatum[]>();
  const [positionedMarkers, setPositionedMarkers] = useState<MarkerOverviewDatum[]>([]);

  // We show map markers at the exact location when regions are used
  const adminLevelsActive = useMemo(() => Boolean(activeMapSource), [activeMapSource]);
  const oldAdminLevelsActiveRef = useRef(adminLevelsActive);
  const adminLevelsActiveRef = useRef(adminLevelsActive);
  useEffect(() => {
    adminLevelsActiveRef.current = adminLevelsActive;
  }, [adminLevelsActive]);
  const onMouseEnter = (marker: MarkerOverviewDatum) => {
    // React does not want state changes when in "render-phase", so we circumvent it with setTimeout
    !adminLevelsActive &&
      !pinnedMapData &&
      data &&
      setTimeout(() => {
        setHoveredAreaOrSiteEnvIdStable(marker.environment.id);
      });

    return '';
  };

  useEffect(() => {
    if (map && mapData?.length) {
      oldMapDataRef.current = mapData;

      // If zoom changed aggregation level, the labels have to be redrawn here, because the previous redraw happened with old data
      updatePositionedMarkers(mapData, map, adminLevelsActiveRef.current, setPositionedMarkers);

      const onLoad = () => {
        updatePositionedMarkers(mapData, map, adminLevelsActiveRef.current, setPositionedMarkers);
      };

      const onZoomEnd = () => {
        // Only update positions on zoom when data or admin areas have changed or we are showing markers
        if (
          !adminLevelsActiveRef.current ||
          oldAdminLevelsActiveRef.current !== adminLevelsActiveRef.current ||
          !isEqual(oldMapDataRef.current, mapData)
        ) {
          oldAdminLevelsActiveRef.current = adminLevelsActiveRef.current;
          updatePositionedMarkers(mapData, map, adminLevelsActiveRef.current, setPositionedMarkers);
        }
      };

      map.once('load', onLoad);
      map.on('zoomend', onZoomEnd);

      return () => {
        map.off('load', onLoad);
        map.off('zoomend', onZoomEnd);
      };
    }

    return undefined;
  }, [mapData, map]);

  if (!mapData || !map) {
    return null;
  }

  return (
    <Fragment>
      {positionedMarkers.map(
        (marker, index) =>
          // Show all markers if regions are not shown, if there are regions, show the marker only for that
          // specific region, which is being hovered
          (!adminLevelsActive || isSelected(marker, selectedOrHoveredAreaOrSiteEnvId)) && (
            <ReactMarker
              key={index}
              latitude={getCorrectedLatitudeForAdminArea(marker, adminLevelsActive)}
              longitude={getCorrectedLongitudeForAdminArea(marker, map, adminLevelsActive)}
              anchor="bottom"
              style={{
                zIndex:
                  isSelected(marker, pinnedMapData?.environment.id) ||
                  isSelected(marker, selectedOrHoveredAreaOrSiteEnvId)
                    ? theme.zIndexes.markers.selected
                    : theme.zIndexes.markers.default,
              }}
            >
              {/* onMouseEnter is always active when there is a region */}
              {/* {selectedEnvironmentId === marker.environment.id && onMouseEnter(marker)} */}
              <ProcessMarker
                position={marker.position}
                overviewDatum={marker}
                // selected={isSelected(marker, selectedEnvironmentId)}
                pinned={isSelected(marker, pinnedMapData?.environment.id)}
                onClick={close => {
                  onMarkerClick(
                    !!close,
                    marker,
                    navigate,
                    queryFilters.toggleEnvironmentStable,
                    adminLevelsActive,
                    changeZoomedAdminAreaStable,
                    previousAdminAreasLifo[previousAdminAreasLifo.length - 1],
                    mapInstance,
                  );
                }}
                onMouseEnter={() => onMouseEnter(marker)}
              />
            </ReactMarker>
          ),
      )}
    </Fragment>
  );
};

function updatePositionedMarkers(
  mapData: OverviewDatum[],
  map: MapRef<MapboxMap>,
  regionsActive: boolean,
  setPositionedMarkers: React.Dispatch<React.SetStateAction<MarkerOverviewDatum[]>>,
) {
  const freshLabels = getPositionedMarkers(mapData, map, getMarkerSize, regionsActive ? 'center' : undefined);
  setPositionedMarkers(freshLabels);
}

function isSelected(marker: OverviewDatum, selectedOrHoveredAreaOrSiteEnvId?: number) {
  return selectedOrHoveredAreaOrSiteEnvId === marker.environment.id;
}

// We calculate the center for lat as we want to center the marker vertically in admin areas
function getCorrectedLatitudeForAdminArea(marker: MarkerOverviewDatum, regionsActive?: boolean) {
  if (!marker.adminLevel?.boundaries?.length || !regionsActive) {
    return marker.environment.inferredLat ?? 0;
  }

  const lats = marker.adminLevel.boundaries.map(b => b.lat);
  // Exception for France, because France has islands all over the world
  if (marker.adminLevel.name === 'France') {
    lats[0] = 42.015727;
    lats[1] = 47.4569668;
  }

  // Lon doesn't really matter, we are calculating lat
  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const centerLat = (minLat + maxLat) / 2;

  return centerLat;
}

const MARKER_X_OFFSET_FROM_REGION_EDGE = 15;
// We calculate the rightmost position for the Lon to position it outside of the region
function getCorrectedLongitudeForAdminArea(
  marker: MarkerOverviewDatum,
  map: MapRef<MapboxMap>,
  regionsActive?: boolean,
) {
  if (!marker.adminLevel?.boundaries?.length || !regionsActive) {
    return marker.environment.inferredLon ?? 0; // We should always have inferredLon
  }

  const boundaries =
    marker.adminLevel.name === 'France' && marker.adminLevel.level === 2
      ? { ...marker.adminLevel.boundaries[1], lon: 1.740729 }
      : { ...marker.adminLevel.boundaries[1] };

  // Lat doesn't really matter, we are calculating lon
  const asPixels = map.project([boundaries.lon, boundaries.lat]);
  const correctGeoCoord = map.unproject([asPixels.x + marker.width / 2 + MARKER_X_OFFSET_FROM_REGION_EDGE, asPixels.y]);

  return correctGeoCoord.lng;
}
