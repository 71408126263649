import {
  EnvSeriesOfOverviewDatums,
  OverviewDatum,
  OverviewLineData,
} from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { useStateWithRef } from '@resistapp/client/hooks/use-state-with-ref';
import { ApiError } from '@resistapp/client/utils/error';
import {
  OverviewChartConfiguration,
  getActiveChartUnit,
  getOverviewConfiguration,
} from '@resistapp/client/utils/overview-chart-configurations';
import type { ReferenceByMAEC } from '@resistapp/common/api-types';
import { type L2Target } from '@resistapp/common/assays';
import { EnvGroup, EnvironmentTypeGroup } from '@resistapp/common/comparable-env-groups';
import type { PossibleZoomableLevel } from '@resistapp/common/types';
import { AdminArea, AdminLevelKey, ChartUnit, FullSample, MetricMode, ProcessMode } from '@resistapp/common/types';
import { Dictionary, keys } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useAssayContext } from '../assay-context';
import { useSampleDataContext } from '../sample-data-context';
import { useOverviewData } from './hooks/use-overview-data';
import { useOverviewQueryParams } from './hooks/use-overview-query-params';
import { useSelectedSiteDatum } from './hooks/use-single-site-selected';
import { useSupportedSamples } from './hooks/use-supported-samples';
import { getAnalysedGroupsAndAll, getEffectiveProcessMode } from './overview-context-utils';

export interface OverviewContextData {
  hasPreparedData: boolean;
  loading: boolean;
  error: Error | ApiError | null;

  // Each value in trendDataByLevel is an array of time serieS, one time series for each siteOrArea in the project (or for individual sites)
  // The special key 'null' has the trend data for individual sites, key '2' has country level data for admin areas, and so on.
  // Accessing a datum: trendDataByLevel[level][iSiteOrArea][time]
  // Literal structure: {lvl: [[env1_time1, env1_time2, ...], [env2_time1, env2_time3, ...], ...] }
  trendDataByLevel: Dictionary<OverviewLineData> | undefined;

  // Each value in mapDataByLevel is an array of siteOrArea datums for a selected timepoint or latest available timepoint for each siteOrArea.
  // The special key 'null' has the trend data for individual sites, key '2' has country level data for admin areas, and so on.
  // Accessing a datum: mapDataByLevel[level][iSiteOrArea]
  // Literal structure: {lvl: [latest_datum_for_env_1, latest_datum_for_env_2...] }
  mapDataByLevel: Dictionary<EnvSeriesOfOverviewDatums> | undefined;

  // trendData: an array of siteOrArea time series, shortcut for trendDataByLevel[adminLevel]
  // Accessing a datum: trendData[iSiteOrArea][time]
  // Literal structure: [[env1_time1, env1_time2, ...], [env2_time1, env2_time3, ...], ...]
  trendData: OverviewLineData | undefined;

  // mapData: selected from mapDataByLevel with shownAdminLevel and filtered by selectedCountry
  // mapData has one data point for each siteOrArea picked 'vertically' from each siteTimeSeriesArrray in the outer trendData array
  // (eg. based on selected time, or latest timepoint for each siteOrArea)
  // Accessing a datum: mapData[iSiteOrArea]
  // Literal structure: [latest_datum_for_env_1, latest_datum_for_env_2...]
  mapData: EnvSeriesOfOverviewDatums | undefined;

  selectedAntibiotic: L2Target | undefined;
  selectedSiteDatum: OverviewDatum | undefined; // A single site selected for site view
  selectedMonth: Date | null;
  selectedEnvironmentTypeGroup: EnvironmentTypeGroup;
  hoveredAreaOrSiteEnvId: number | undefined;
  selectedOrHoveredAreaOrSiteEnvId: number | undefined; // Environment id (either area or site) that is currently hovered over, or selected in site view
  setHoveredAreaOrSiteEnvIdStable: React.Dispatch<React.SetStateAction<number | undefined>>;
  selectedCountry: string | undefined;
  levelsWithZoomableAreas: number[] | undefined;
  // NOTE => Used only in month-selector
  availableMonths: string[];
  setMonthStable: (date: Date | null, endDate: Date | null) => void;
  processMode: ProcessMode;
  effectiveSiteDetailsProcessMode: ProcessMode; // When all envs are selected, default to BEFORE when AFTER is not available, and vice versa
  metricMode: MetricMode;
  activeOverviewConfiguration: OverviewChartConfiguration;
  availableEnvGroups: EnvGroup[] | undefined;
  activeChartUnit: ChartUnit;
  isOneHealthProject: boolean | undefined;
  shownAdminLevel: number | null;
  previousAdminAreasLifo: AdminArea[];
  zoomedMapData: OverviewDatum | undefined;
  metricGeneCnt: number | undefined;
  supportedSamples: FullSample[] | undefined;
  zoomedAdminAreaRef: React.MutableRefObject<AdminArea | null>;
  notAvailableReason: string | null; // TODO this is used at map container level, not only mapboxmap
  analyzedAntibioticGroups: Array<L2Target | 'All antibiotics'> | undefined;
  setZoomedAdminAreaStable: React.Dispatch<React.SetStateAction<AdminArea | null>>;
  setPreviousAdminAreasLifoStable: React.Dispatch<React.SetStateAction<AdminArea[]>>;
  setSelectedCountryStable: (country: string | undefined) => void;
  setShownAdminLevelStable: (level: number | null) => void;
  referenceLineData: ReferenceByMAEC | undefined;
}

interface ProviderProps {
  children: React.ReactNode;
  metricMode: MetricMode;
}

const OverviewContext = createContext<OverviewContextData | undefined>(undefined);

export function OverviewContextProvider({ children, metricMode }: ProviderProps) {
  // 0. LOAD INPUT DATA
  const { assaysLoaded, getGroup } = useAssayContext();
  const { queryFilters, loading, error, data } = useSampleDataContext();
  const referenceLineData = data?.referencesByMAEC;

  // INTERNAL STATE - Admin level and site selection
  const [zoomedAdminArea, setZoomedAdminAreaStable, zoomedAdminAreaRef] = useStateWithRef<AdminArea | null>(null);
  const [shownAdminLevel, setShownAdminLevelStable, shownAdminLevelRef] = useStateWithRef<number | null>(null);
  const [previousAdminAreasLifo, setPreviousAdminAreasLifoStable] = useState<AdminArea[]>([]);

  const [trendData, setTrendData] = useState<OverviewDatum[][] | undefined>();
  const [mapData, setMapData] = useState<OverviewDatum[] | undefined>();

  // 1. ENFORCE SUPPORTED QUERY STATES AND TRANSITIONS (availableEnvGroups)
  const {
    queryParamsInitialised,
    availableEnvGroups,
    selectedAntibiotic,
    activeEnvGroup,
    setSelectedCountryStable,
    countryParam,
  } = useOverviewQueryParams({
    metricMode,
  });

  // 2. KEEP ONLY SUPPORTED SAMPLES AND ADMIN LEVELS THAT ARE PRESENT IN ALL OF THEM
  const { supportedSamples, isOneHealthProject, metricGeneCnt, selectedCountry } = useSupportedSamples({
    availableEnvGroups,
    metricMode,
    selectedAntibiotic,
    countryParam,
  });

  // 3. PREPARE OVERVIEW DATA
  const {
    trendDataByLevel,
    mapDataByLevel,
    availableMonths,
    notAvailableReason,
    availableNormalisationModes,
    processMode,
  } = useOverviewData({
    supportedSamples,
    queryParamsInitialised,
    activeEnvGroup,
    metricMode,
  });

  // Helpers
  // TODO WE WANNA SHOW PER LITTER AND RISK AS WELL !?!
  const activeChartUnit = getActiveChartUnit(availableNormalisationModes);
  const activeOverviewConfiguration = getOverviewConfiguration(metricMode, activeChartUnit);
  const zoomedMapData = useMemo(() => {
    if (!zoomedAdminArea || !mapDataByLevel) {
      return undefined;
    }
    return mapDataByLevel[zoomedAdminArea.level].find(area => area.environment.name === zoomedAdminArea.name);
  }, [zoomedAdminArea, mapDataByLevel]);

  // SITE SELECTION STATE
  const [hoveredAreaOrSiteEnvId, setHoveredAreaOrSiteEnvIdStable] = useState<number | undefined>(undefined);
  const selectedSiteDatum = useSelectedSiteDatum({
    supportedSamples,
    mapData,
  });

  // 4.1 SELECT TREND DATA BASED ON SHOWN LEVEL
  const selectedSiteEnvId = selectedSiteDatum?.environment.id;
  useEffect(() => {
    if (trendDataByLevel) {
      const adminLevelKey = shownAdminLevel || 'null';
      const newTrendData = trendDataByLevel[adminLevelKey];
      // When one specific site is selected (transient aggregated environments have negative ids)
      if (shownAdminLevel === null && selectedSiteEnvId && selectedSiteEnvId > 0) {
        const selectedTrendData = newTrendData.flat().find(dataLocal => dataLocal.environment.id === selectedSiteEnvId);
        setTrendData([[selectedTrendData]] as OverviewDatum[][]);
      } else {
        setTrendData(newTrendData);
      }
    } else {
      setTrendData([]);
    }
  }, [trendDataByLevel, shownAdminLevel, selectedSiteEnvId]);

  // 4.2 SELECT MAP DATA BASED ON SHOWN LEVEL
  useEffect(() => {
    if (!mapDataByLevel) {
      setMapData([]);
      return;
    }
    const adminLevelKey = shownAdminLevel || 'null';
    const mapDataForLevel = mapDataByLevel[adminLevelKey];
    const newMapData = mapDataForLevel.filter(d => d.environment.country === selectedCountry || !selectedCountry);
    setMapData(newMapData);
  }, [mapDataByLevel, selectedCountry, shownAdminLevel]);

  // 5. DETERMINE SHOWN ADMIN LEVEL WHEN
  // - MAP DATA CHANGES
  // - COUNTRY CHANGES, OR
  // -SITE VIEW GETS ENABLED/DISABLED
  const prevLevelsWithZoomableAreasRef = useRef<PossibleZoomableLevel[] | undefined>(undefined);
  const levelsWithZoomableAreas: PossibleZoomableLevel[] | undefined = useMemo(() => {
    return data?.levelsWithZoomableAreas && Object.keys(data.levelsWithZoomableAreas).length > 1
      ? [1, 2]
      : selectedCountry && data?.levelsWithZoomableAreas
        ? data.levelsWithZoomableAreas[selectedCountry]
        : undefined;
  }, [data, selectedCountry]);

  const isSiteSelected = !!selectedSiteDatum;
  const previousAdminAreasLifoRef = useRef<AdminArea[]>([]);

  previousAdminAreasLifoRef.current = previousAdminAreasLifo;

  useEffect(() => {
    if (!mapDataByLevel) {
      return;
    }

    // Update shownAdminLevel when country has changed
    let nextShownAdminLevel = shownAdminLevelRef.current;
    const previousLifo = previousAdminAreasLifoRef.current;
    // LevelsWithZoomableAreas changes when selectedCountry changes or when mapDataByLevel is set or changed
    if (levelsWithZoomableAreas !== prevLevelsWithZoomableAreasRef.current) {
      prevLevelsWithZoomableAreasRef.current = levelsWithZoomableAreas;
      // There are no zoomable areas to show, so we show the markers on the map
      if (!levelsWithZoomableAreas?.length) {
        nextShownAdminLevel = null;
        // The country was selected, prepare the new admin levels
      } else if (levelsWithZoomableAreas[0] === 2 && previousLifo.length === 1) {
        nextShownAdminLevel = levelsWithZoomableAreas.find(level => level > 2) || null;
        const nextArea = nextShownAdminLevel
          ? mapDataByLevel[nextShownAdminLevel][0]?.environment.adminLevels?.[
              `${nextShownAdminLevel}` as AdminLevelKey
            ] || null
          : null;
        setZoomedAdminAreaStable(nextArea);
      }
    }

    if (isSiteSelected) {
      // Set shownAdminLevel to null for site is selected
      nextShownAdminLevel = null;
    } else if (keys(mapDataByLevel).length && levelsWithZoomableAreas?.length && zoomedAdminAreaRef.current === null) {
      // Set shownAdminLevel upon landing on non-site overview with data
      const highestLevel = levelsWithZoomableAreas[0];
      const levelMapData = mapDataByLevel[highestLevel];
      const firstArea = levelMapData[0]?.environment.adminLevels?.[`${highestLevel}` as AdminLevelKey] || null;
      nextShownAdminLevel = levelsWithZoomableAreas.find(level => firstArea && level > firstArea.level) || null;
      setZoomedAdminAreaStable(firstArea);
    }

    if (nextShownAdminLevel !== shownAdminLevelRef.current) {
      setShownAdminLevelStable(nextShownAdminLevel);
    }
  }, [mapDataByLevel, levelsWithZoomableAreas, isSiteSelected]);

  const siteTrendData = trendDataByLevel?.['null'];
  const analyzedAntibioticGroups = useMemo(
    () => getAnalysedGroupsAndAll(siteTrendData, processMode, getGroup),
    [siteTrendData, processMode, getGroup],
  );

  const effectiveSiteDetailsProcessMode = selectedSiteDatum
    ? getEffectiveProcessMode(selectedSiteDatum, processMode, queryFilters.filters.selectedEnvironmentTypeGroup)
    : processMode;

  // CONTEXT DATA ASSEMBLY
  const contextData: OverviewContextData = {
    hasPreparedData: !!trendData && !!mapData,
    mapData,
    mapDataByLevel,
    levelsWithZoomableAreas,
    selectedSiteDatum,
    trendData,
    selectedAntibiotic,
    previousAdminAreasLifo,
    loading: loading || !queryParamsInitialised || !assaysLoaded,
    error,
    setHoveredAreaOrSiteEnvIdStable,
    selectedOrHoveredAreaOrSiteEnvId: hoveredAreaOrSiteEnvId || selectedSiteEnvId,
    hoveredAreaOrSiteEnvId,
    processMode,
    effectiveSiteDetailsProcessMode,
    shownAdminLevel,
    isOneHealthProject,
    metricGeneCnt,
    availableEnvGroups,
    zoomedAdminAreaRef,
    zoomedMapData,
    metricMode,
    notAvailableReason,
    availableMonths,
    setMonthStable: queryFilters.setMonthStable,
    // Currently the interval.start can present the 1900 year, which is less than 0
    selectedMonth: queryFilters.filters.interval.start.valueOf() > 0 ? queryFilters.filters.interval.start : null,
    selectedEnvironmentTypeGroup: queryFilters.filters.selectedEnvironmentTypeGroup,
    activeOverviewConfiguration,
    activeChartUnit,
    selectedCountry,
    supportedSamples,
    trendDataByLevel,
    setPreviousAdminAreasLifoStable,
    setZoomedAdminAreaStable,
    setSelectedCountryStable,
    setShownAdminLevelStable,
    analyzedAntibioticGroups,
    referenceLineData,
  };

  return <OverviewContext.Provider value={contextData}>{children}</OverviewContext.Provider>;
}

export function useOverviewContext() {
  const context = useContext(OverviewContext);
  if (!context) {
    throw new Error('useOverviewContext must be used within a OverviewContextProvider');
  }
  return context;
}
