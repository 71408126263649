import { GetGroup } from '@resistapp/common/assays';
import { getSampleNumberOrLabel, hasDuplicateSampleNumbers } from '@resistapp/common/environments';
import { FullSamplesByUID } from '@resistapp/common/types';
import { countDetectedGenesPerSampleUID, countDetectedGenesPerTarget } from '@resistapp/common/utils';
import { csvFormat } from 'd3-dsv';
import { Dictionary, keys, mapValues } from 'lodash';

// const targetColumn = 'Target';
const sampleNumberColumn = 'Sample number';
const numDetectedColumn = 'Total number of detected genes';
const descriptionColumn = 'Description';

export function getNumberOfDetectedGenesBySampleNumCsv(samplesByBioKey: FullSamplesByUID, getGroup: GetGroup): string {
  const countByBioKey = countDetectedGenesPerSampleUID(samplesByBioKey);
  const hasDuplicateNums = hasDuplicateSampleNumbers(samplesByBioKey);
  const nameByUID = mapValues(samplesByBioKey, samples => samples[0].environment.name);
  const rows = keys(countByBioKey).map(bioGroupKey => {
    const perTargetCounts = countDetectedGenesPerTarget(samplesByBioKey[bioGroupKey], getGroup);
    const sampleNum = getSampleNumberOrLabel(samplesByBioKey[bioGroupKey][0], hasDuplicateNums);
    return Object.keys(perTargetCounts).reduce<Dictionary<string | number>>(
      (acc, target) => {
        acc[target] = perTargetCounts[target];
        return acc;
      },
      {
        [sampleNumberColumn]: sampleNum,
        [descriptionColumn]: nameByUID[bioGroupKey],
        [numDetectedColumn]: countByBioKey[bioGroupKey],
      },
    );
  });
  return csvFormat(rows);
}
