import {
  Box,
  Link as ChakraLink,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useBreakpointValue,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import LogoSource from '@resistapp/client/assets/Resistomap-new@3x.png';
import { useUrlContext } from '@resistapp/client/contexts/url-provider';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { getUserProjectIds } from '@resistapp/common/access';
import { Feature, hasFeature, isAdmin } from '@resistapp/common/features';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import { useUser } from '../../../contexts/use-user-context';
import { IconBase } from '../../icons/icon-base';
import { ProjectSelector } from '../../project-selector/project-selector'; // Import ProjectSelector
import { oldTheme } from '../../shared/old-styles';
import { theme } from '../../shared/theme';

type LinkType = {
  key: string;
  label: string;
  shortLabel?: string;
  to: string;
  disabled: boolean;
  separator?: boolean;
};
type LinkArrays = LinkType[][];

export const headerHeight = 60;

interface Props {}
export function HeaderBar(_props: Props) {
  const navigate = useNavigateWithQuery();
  const { page, query, projectId } = useUrlContext();
  const { user, logout } = useUser();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const isMobileLg = useBreakpointValue({ base: true, '1700px': false });

  const activeStyle = ({ isActive }: { isActive: boolean }) =>
    isActive ? { color: theme.colors.blue900, backgroundColor: theme.colors.blue100 } : {};
  const logoPath = !user ? '/world' : isAdmin(user) ? '/admin' : `/${page.viable}/${projectId.viable}`;

  const link = {
    map: {
      key: 'map-link',
      label: 'Map',
      to: '/world',
      disabled: false,
    },
    organisations: {
      key: 'organisations-link',
      label: 'Admin',
      to: '/admin',
      disabled: false,
    },
    resistanceIndex: {
      key: 'resistance-index-link',
      label: 'Resistance\u00A0Index',
      shortLabel: 'Index',
      to: `/index/${projectId.viable}${query}`,
      disabled: !projectId.viable || (!isAdmin(user) && !hasFeature(user, Feature.ARGI)),
    },
    healthRisk: {
      key: 'health-risk-link',
      label: 'Comparative\u00A0Risk',
      shortLabel: 'Risk',
      to: `/risk/${projectId.viable}${query}`,
      disabled: !projectId.viable || (!isAdmin(user) && !hasFeature(user, Feature.RISK)),
    },
    geneReduction: {
      key: 'gene-reduction-link',
      label: 'Gene\u00A0Reduction',
      shortLabel: 'Reduction',
      to: `/reduction/${projectId.viable}${query}`,
      disabled: !projectId.viable || (!isAdmin(user) && !hasFeature(user, Feature.REDUCTION)),
    },
    analyse: {
      key: 'analyse-link',
      label: 'Gene\u00A0Details',
      shortLabel: 'Details',
      to: `/research/${projectId.viable}${query}`,
      disabled: !projectId.viable,
      separator: true,
    },
  } as const satisfies Record<string, LinkType>;

  const adminMobileLinks: LinkArrays = [[link.resistanceIndex], [link.organisations]];
  // const researcherLinks: LinkArrays = [[link.map]];
  const subscriberLinks: LinkArrays = [[link.resistanceIndex, link.healthRisk, link.geneReduction, link.analyse], []];
  const adminLinks: LinkArrays = [[...subscriberLinks[0]], [link.organisations]];
  // We don't show anything if user is not logged in or user uses small display
  const shownLinks: LinkArrays =
    isAdmin(user) && isMobile ? adminMobileLinks : isAdmin(user) ? adminLinks : !isMobile ? subscriberLinks : [[], []];
  const projectIds = getUserProjectIds(user);

  return (
    <Root id="header-bar" py={{ base: '20px', lg: '12px' }} px={6}>
      <Main>
        <Flex minWidth={{ base: 'none', md: '100px', lg: '155px' }} width="155px">
          <ChakraLink as={RouterLink} to={logoPath} display={{ base: 'none', md: 'block' }}>
            <Logo
              src={LogoSource as string}
              objectFit="contain"
              height={{ base: '0px', md: '60px' }}
              alt="Resistomap"
            />
          </ChakraLink>
        </Flex>
        {page.current !== 'world' && user && projectIds.length > 1 && (
          <ProjectSelectorContainer>
            <ProjectSelector showStatus={isAdmin(user)} menuHeight="640px" />
          </ProjectSelectorContainer>
        )}
        <LinksContainer
          gap={{ base: 0, lg: theme.spacing[6], xl: theme.spacing[12] }}
          pl={{ base: theme.spacing[2], md: theme.spacing[12] }}
          pr={theme.spacing[4]}
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
        >
          <Flex flexGrow={1} justifyContent="center">
            {shownLinks[0].map(linkLocal => (
              <LinkContainer key={linkLocal.key}>
                <Popover trigger={linkLocal.disabled ? 'hover' : undefined} placement="bottom" key={linkLocal.key}>
                  <PopoverTrigger>
                    <Box>
                      <HeaderLink
                        key={linkLocal.key}
                        to={linkLocal.to}
                        style={activeStyle}
                        disabled={linkLocal.disabled ? true : false}
                        separator={linkLocal.separator}
                      >
                        {isMobileLg && linkLocal.shortLabel ? linkLocal.shortLabel : linkLocal.label}
                      </HeaderLink>
                    </Box>
                  </PopoverTrigger>
                  <PopoverContent width="100%">
                    <PopoverBody>Subscription required</PopoverBody>
                  </PopoverContent>
                </Popover>
              </LinkContainer>
            ))}
          </Flex>
          <Flex flexGrow={0}>
            {shownLinks[1].map(linkLocal => (
              <LinkContainer key={linkLocal.key} style={{ cursor: linkLocal.disabled ? 'not-allowed' : 'pointer' }}>
                <HeaderLink
                  key={linkLocal.key}
                  to={linkLocal.disabled ? '#' : linkLocal.to}
                  style={activeStyle}
                  disabled={linkLocal.disabled ? true : false}
                >
                  {isMobileLg && linkLocal.shortLabel ? linkLocal.shortLabel : linkLocal.label}
                </HeaderLink>
              </LinkContainer>
            ))}
          </Flex>
        </LinksContainer>
        <AccountContainer pr={theme.spacing[1]} justifyContent={{ base: 'center', lg: 'flex-end' }}>
          <HeaderLink
            to={user ? '/' : '/login'}
            style={activeStyle}
            onClick={() => {
              // when login happens, we only direct the user to the login-page, on logout we act on it.
              if (user) {
                logout();
                navigate('/login', false);
              }
            }}
          >
            {user ? 'Log out' : 'Login'}
          </HeaderLink>
        </AccountContainer>
      </Main>
    </Root>
  );
}

const Root = styled(Box)`
  /* For some reason this does not work I expected on firefox, if we have border-box */
  box-sizing: content-box;
  z-index: ${theme.zIndexes.header};
  position: relative;
  background-color: ${theme.colors.neutral50};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%; /* 28px */
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${theme.colors.neutral50};
`;

export const ProjectSelectorContainer = styled(Flex)`
  position: relative;
  width: 100%;
`;

const LinksContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const AccountContainer = styled(Flex)`
  white-space: nowrap;
  align-items: center;
  padding-top: ${oldTheme.spacing.xxs};
  padding-bottom: ${oldTheme.spacing.xxs};
`;

const Logo = styled(Image)`
  box-sizing: border-box;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 13.5px;
  padding-bottom: 13.5px;
  margin: 0;
  background-color: ${theme.colors.neutral50};
`;

const iconStyle = `
   margin-left: 6px;
   margin-bottom: -0.3px;
`;

export const HomeIcon = styled(IconBase)`
  ${iconStyle}
`;

const LinkContainer = styled(Flex)`
  position: relative;
  display: flex;
  align-items: center;
`;

const headerLinkStyle = {
  '&:hover': {
    textDecoration: 'none',
    color: theme.colors.neutral700,
  },
  display: 'flex',
  color: theme.colors.neutral700,
  fontSize: theme.oldFontSize.xs,
  fontStyle: 'normal',
  fontWeight: 501,
  lineHeight: '28px',
  padding: '12px 18px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  borderRadius: '9999px',
};

function HeaderLink({
  disabled = false,
  separator = false,
  style,
  children,
  to,
  onClick,
}: {
  disabled?: boolean;
  separator?: boolean;
  style?: ({ isActive }: { isActive: boolean }) => React.CSSProperties;
  children: React.ReactNode;
  to: string;
  onClick?: () => void;
}) {
  return (
    <Flex alignItems="center">
      {separator && (
        <div
          style={{
            width: '1px',
            height: '24px',
            backgroundColor: theme.colors.neutral300,
            marginLeft: theme.spacing[2],
            marginRight: theme.spacing[2],
          }}
        />
      )}
      <div>
        <NavLink
          style={({ isActive }) => ({
            ...style,
            ...headerLinkStyle,
            opacity: disabled ? 0.5 : 1,
            cursor: disabled ? 'not-allowed' : 'pointer',
            backgroundColor: isActive ? theme.colors.blue100 : 'transparent',
          })}
          to={to}
          onClick={e => {
            if (disabled) {
              e.preventDefault();
            } else {
              onClick?.();
            }
          }}
        >
          {children}
        </NavLink>
      </div>
    </Flex>
  );
}
