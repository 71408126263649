import { handleFiltersSelectionWithKeys } from '@resistapp/client/data-utils/filter-data/filter';
import { EnvironmentTypeGroup, filterGroupEnvironments } from '@resistapp/common/comparable-env-groups';
import { DEFAULT_END_INTERVAL, DEFAULT_START_INTERVAL, type StandardDateFormat } from '@resistapp/common/friendly';
import { Environment, FullProject, FullSamplesByUID, SampleStatus } from '@resistapp/common/types';
import { sortUniqEnvironmentsAndAddSampleStatus } from '@resistapp/common/utils';
import { ascending } from 'd3-array';
import { ScaleOrdinal } from 'd3-scale';
import { differenceInSeconds } from 'date-fns';
import { difference, uniqBy } from 'lodash';
import { KeysPressOptions } from '../legends/legend';

export const chartLayoutValues = {
  plotLeftOffset: 71, // For the y-axis ticks and markers
  xAxisBottomMargin: 12,
  xAxisLeftMargin: 1,
  yAxisLeftMargin: 1,
  heatmapYAxisTopMargin: 10,
  heatMapRightMargin: 5,
  heatmapYAxisTopMargin2: 15,
  yAxisMarginLeft: 6,
  // TODO: I think with proper design there should be no need for this extra margin.
  // Now the bar and box plots work with above "yAxisMarginLeft", but heatmap needs this 6
  heatmapYAxisMarginLeftExtraMargin: 6,
  xAxisHeight: 40,
  xAxisTopMargin: 5,
};

export function getShortestStepInSeconds(times: Date[]) {
  const sortedTimes = (() => {
    const uniqTimes = uniqBy(times, time => time.toISOString());
    uniqTimes.sort(ascending);
    return uniqTimes;
  })();
  return sortedTimes.reduce<number>((minSoFar, current, i) => {
    if (i === 0) {
      return minSoFar;
    }
    const previous = sortedTimes[i - 1];
    return Math.min(minSoFar, differenceInSeconds(current, previous));
  }, Number.MAX_SAFE_INTEGER);
}

export function exponentToSuperScript(exponent: number) {
  switch (exponent) {
    case 0:
      return '⁰';
    case -1:
      return '⁻¹';
    case -2:
      return '⁻²';
    case -3:
      return '⁻³';
    case -4:
      return '⁻⁴';
    case -5:
      return '⁻⁵';
    case -6:
      return '⁻⁶';
    case -7:
      return '⁻⁷';
    case -8:
      return '⁻⁸';
    case -9:
      return '⁻⁹';
    default:
      throw Error(`Unsupported exponent: ${exponent}`);
  }
}

export function getHeatmapChartOneGeneHeight(numKeys: number) {
  return numKeys > 100 ? 5 : 10;
}

// We need to unregister the previous event listener before registering a new one, to avoid bugs and memory leaks
let activehandleKeyUpEventListener: (e: KeyboardEvent) => void = () => {};
// We track what id was previously selected, for using the shift key functionality when selecting samples.
let previouslySelectedId: number | undefined = undefined;
export function getSampleSelector(
  selectedEnvironmentIdsOrdered: number[],
  allEnvironmentIds: number[],
  samplesBeingSelected: { ids: number[]; removeOldSelections: boolean },
  setSamplesBeingSelected: (value: { ids: number[]; removeOldSelections: boolean }) => void,
  toggleEnvironment: (id: number | number[], removeOldSelections: boolean) => void,
  commitMode: boolean,
  disableShiftSelection = false,
  filters?: { interval: { start: Date; end: Date } },
  setIntervalStable?: (start: Date | StandardDateFormat, end: Date | StandardDateFormat) => void,
  project?: FullProject,
) {
  return (_newSelectedId: number | string, keys: KeysPressOptions) => {
    const newSelectedId = +_newSelectedId;
    if (disableShiftSelection) {
      return;
    }

    // Use the last *clicked* item as the anchor for shift, regardless of pending/committed state
    const baseIdForShift = previouslySelectedId;

    // --- Commit Mode Logic ---
    if (commitMode && (keys.shift || keys.ctrl)) {
      // Track keys pressed *during this specific click*
      const capturedExpectedKeys = { shift: keys.shift, ctrl: keys.ctrl };

      let nextPendingIds: number[] = [];
      let nextRemoveOldSelections = true; // Default assumption, adjusted below

      // --- Shift Selection ---
      if (capturedExpectedKeys.shift) {
        // Shift + Ctrl behaves like Shift only for selection calculation
        const [shiftSelectionStrings] = handleFiltersSelectionWithKeys(
          [], // Base selection doesn't matter for shift range calculation
          allEnvironmentIds.map(String), // Needs full list to calculate range
          String(newSelectedId),
          baseIdForShift !== undefined ? String(baseIdForShift) : undefined,
          { shift: true, ctrl: false }, // Force shift logic
        );
        nextPendingIds = (Array.isArray(shiftSelectionStrings) ? shiftSelectionStrings : [shiftSelectionStrings]).map(
          Number,
        );
        nextRemoveOldSelections = true; // Shift always replaces the selection
      }
      // --- Ctrl/Cmd Selection (Order matters!) ---
      else if (capturedExpectedKeys.ctrl) {
        const currentPendingState = samplesBeingSelected;
        const isFirstCtrlClickInSequence = currentPendingState.ids.length === 0;

        // Determine the effective base selection (all if empty)
        const isBaseAllSelected = selectedEnvironmentIdsOrdered.length === 0;
        const baseSelectionIds = isFirstCtrlClickInSequence ? selectedEnvironmentIdsOrdered : currentPendingState.ids;

        // Check if the clicked item was selected *before* this click sequence started.
        // const wasInitiallySelected = isBaseAllSelected || baseSelectionIds.includes(newSelectedId);
        const wasInitiallySelected = isFirstCtrlClickInSequence
          ? baseSelectionIds.includes(newSelectedId) // On first click, only check the explicit list
          : isBaseAllSelected || baseSelectionIds.includes(newSelectedId); // On subsequent clicks, allow implicit 'all'

        if (isFirstCtrlClickInSequence) {
          // Starting a new Ctrl sequence. Mode depends on whether the first item was initially selected.
          nextRemoveOldSelections = wasInitiallySelected; // True if starting by removing an existing item.
          if (wasInitiallySelected) {
            // Start sequence by removing the clicked item from the full set.
            // If base was 'all selected', filter from allEnvironmentIds.
            const sourceList = isBaseAllSelected ? allEnvironmentIds : baseSelectionIds;
            nextPendingIds = sourceList.filter(id => id !== newSelectedId);
          } else {
            // Start sequence by adding the clicked item to the committed list.
            nextPendingIds = [...selectedEnvironmentIdsOrdered, newSelectedId];
          }
        } else {
          // Continuing a Ctrl sequence: add/remove from the *current pending* selection
          nextRemoveOldSelections = currentPendingState.removeOldSelections; // Preserve mode from sequence start

          const currentlyInPendingSet = baseSelectionIds.includes(newSelectedId);

          if (currentlyInPendingSet) {
            // Remove existing item from pending list, maintaining order of others
            nextPendingIds = baseSelectionIds.filter(id => id !== newSelectedId);
          } else {
            // Add new item to the end of the pending list
            nextPendingIds = [...baseSelectionIds, newSelectedId];
          }
        }
      }

      // --- Setup Key Up Listener ---
      // Capture the state intended for this specific key release
      const capturedPendingIds = [...nextPendingIds];
      const capturedRemoveOldSelections = nextRemoveOldSelections;

      // Update the shared pending state for UI feedback / next click
      setSamplesBeingSelected({ ids: nextPendingIds, removeOldSelections: nextRemoveOldSelections });

      const handleKeyUp = (e: KeyboardEvent) => {
        const shiftReleased = capturedExpectedKeys.shift && e.key === 'Shift';
        const ctrlReleased = capturedExpectedKeys.ctrl && (e.key === 'Control' || e.key === 'Meta'); // Meta for Cmd

        if (shiftReleased || ctrlReleased) {
          // --- IMPORTANT ---
          // Only commit if THIS listener is the currently active one.
          // Prevents race conditions from rapid clicks where an older listener might fire after a newer one was set.
          if (activehandleKeyUpEventListener !== handleKeyUp) {
            window.removeEventListener('keyup', handleKeyUp); // Clean up this outdated listener
            return;
          }

          // Check for data in time range using CAPTURED state
          const hasDataInTimeRange = capturedPendingIds.every(envId => {
            if (!project) return false;
            // Simplified sample finding assuming samplesByUID structure is reliable
            const samples =
              project.samplesByUID[
                Object.keys(project.samplesByUID).find(uid => project.samplesByUID[uid][0]?.environment.id === envId) ||
                  ''
              ];

            if (!samples.length) return false;

            return samples.some(sample => {
              if (!sample.time || !filters?.interval) return false;
              const sampleDate = new Date(sample.time);
              return sampleDate >= filters.interval.start && sampleDate <= filters.interval.end;
            });
          });

          if (!hasDataInTimeRange && setIntervalStable && capturedPendingIds.length > 0) {
            setIntervalStable(DEFAULT_START_INTERVAL, DEFAULT_END_INTERVAL);
          }

          // Determine the final list to commit based on the mode
          let idsToCommit: number[];
          if (capturedRemoveOldSelections) {
            // Replace mode: commit the full calculated list
            idsToCommit = capturedPendingIds;
          } else {
            // Add/Toggle mode: commit only the effective changes relative to the original committed state
            // selectedEnvironmentIdsOrdered holds the state *before* this Ctrl/Cmd sequence started
            const itemsToAdd = difference(capturedPendingIds, selectedEnvironmentIdsOrdered);
            const itemsToRemove = difference(selectedEnvironmentIdsOrdered, capturedPendingIds);
            // Combine added and removed items to represent the total toggle operations needed
            idsToCommit = [...itemsToAdd, ...itemsToRemove];
          }

          // Commit the determined selection changes
          toggleEnvironment(idsToCommit, capturedRemoveOldSelections);
          setSamplesBeingSelected({ ids: [], removeOldSelections: true }); // Reset pending state

          // Clean up this listener
          window.removeEventListener('keyup', handleKeyUp);
          activehandleKeyUpEventListener = () => {}; // Clear the stored listener reference
        }
      };

      // Remove any *previous* listener first to avoid duplicates
      window.removeEventListener('keyup', activehandleKeyUpEventListener);
      // Add the new listener
      window.addEventListener('keyup', handleKeyUp);
      // Store it as the currently active one
      activehandleKeyUpEventListener = handleKeyUp;

      // Update previouslySelectedId *after* all calculations for this click are done
      previouslySelectedId = newSelectedId;

      return handleKeyUp; // For tests
    }

    // --- Non-Commit Mode or Single Click (No modifiers pressed) ---
    // Handles clicks when commitMode is false OR when commitMode is true but no modifiers were pressed
    if (!commitMode || (!keys.shift && !keys.ctrl)) {
      // Determine previousId for potential shift click (only relevant if shift *was* pressed, though handled by keys)
      const previousIdForShift = keys.shift
        ? previouslySelectedId ||
          (selectedEnvironmentIdsOrdered.length === 1 ? selectedEnvironmentIdsOrdered[0] : undefined)
        : undefined;

      const [selectionStrings, removeOldSelections] = handleFiltersSelectionWithKeys(
        selectedEnvironmentIdsOrdered.map(String),
        allEnvironmentIds.map(String),
        String(newSelectedId),
        previousIdForShift !== undefined ? String(previousIdForShift) : undefined, // Use calculated previousId if shift was pressed
        keys, // Pass the *actual* keys pressed
      );

      const numericSelections = (Array.isArray(selectionStrings) ? selectionStrings : [selectionStrings]).map(Number);

      toggleEnvironment(numericSelections, removeOldSelections);

      // Reset any potentially lingering pending state
      setSamplesBeingSelected({ ids: [], removeOldSelections: true });
      // Update previouslySelectedId for potential subsequent shift click
      previouslySelectedId = newSelectedId;
      return; // Explicit return undefined for non-test scenarios
    }

    // If we reach here, it means commitMode is true AND (keys.shift || keys.ctrl) is true,
    // so the commit mode logic above should have handled it. This path should not be reached.
    // However, to satisfy TypeScript and prevent accidental fallthrough, we can add an explicit return or error.
    console.error('getSampleSelector reached an unexpected state.');
    return; // Or throw new Error('Unexpected state');
  };
}

export enum VisualSelectionStatus {
  SELECTED = 'SELECTED', // Committed and not part of a pending removal
  PENDING_ADD = 'PENDING_ADD', // Not committed but in pending list (add mode)
  PENDING_REMOVE = 'PENDING_REMOVE', // Committed or not, but in pending list (remove mode)
  DESELECTED = 'DESELECTED', // Neither committed nor pending
}

export function getVisualSelectionState(
  environmentId: number,
  selectedIds: number[], // The *committed* selection (empty means all)
  pendingState: { ids: number[]; removeOldSelections: boolean },
  allRelevantIds: number[], // All IDs currently considered relevant (e.g., in the legend/chart)
): VisualSelectionStatus {
  // Determine initial selection state considering the 'all selected' case relative to relevant IDs
  const wasInitiallySelected =
    selectedIds.length === 0 ? allRelevantIds.includes(environmentId) : selectedIds.includes(environmentId);

  const isInPendingList = pendingState.ids.includes(environmentId);
  const isRemoveMode = pendingState.removeOldSelections;
  const isPendingListActive = pendingState.ids.length > 0;

  if (!isPendingListActive) {
    // No pending operation
    return wasInitiallySelected ? VisualSelectionStatus.SELECTED : VisualSelectionStatus.DESELECTED;
  }

  // --- Pending operation is active --- //

  if (isRemoveMode) {
    // Remove Mode: Pending list = items to keep.
    if (isInPendingList) {
      // Item is in the list to keep -> SELECTED
      return VisualSelectionStatus.SELECTED;
    } else {
      // Item is NOT in the list to keep.
      if (wasInitiallySelected) {
        // It was selected initially, so it's PENDING_REMOVE.
        return VisualSelectionStatus.PENDING_REMOVE;
      } else {
        // It was not selected initially, remains DESELECTED.
        return VisualSelectionStatus.DESELECTED;
      }
    }
  } else {
    // Add/Toggle Mode: Pending list = items that will be selected.
    if (isInPendingList) {
      // Item IS in the pending list.
      if (wasInitiallySelected) {
        // Was initially selected -> remains SELECTED.
        return VisualSelectionStatus.SELECTED;
      } else {
        // Was not initially selected -> PENDING_ADD.
        return VisualSelectionStatus.PENDING_ADD;
      }
    } else {
      // Item IS NOT in the pending list.
      if (wasInitiallySelected) {
        // Was initially selected -> PENDING_REMOVE (toggle off).
        return VisualSelectionStatus.PENDING_REMOVE;
      } else {
        // Was not initially selected -> remains DESELECTED.
        return VisualSelectionStatus.DESELECTED;
      }
    }
  }
}

interface EnvironmentWithSampleStatus extends Environment {
  sampleStatus: SampleStatus;
}
export function getEnvironmentsWithStatusForSelectedEnvTypeGroup(
  samplesByUID: FullSamplesByUID,
  selectedEnvTypeGroup: EnvironmentTypeGroup,
  sortByProjectId: number | undefined,
): EnvironmentWithSampleStatus[] {
  const allEnvironments = sortUniqEnvironmentsAndAddSampleStatus(samplesByUID, sortByProjectId);
  return filterGroupEnvironments(allEnvironments, selectedEnvTypeGroup);
}

export function getSemiTransparentColorFromScale(scale: ScaleOrdinal<string, string>) {
  return (d: string) => {
    const hex = scale(d);
    return getSemiTransparentColor(hex);
  };
}
export function getSemiTransparentColor(hex: string) {
  return hex + '80'; // 80 in hex = 50% opacity
}
