import { useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useToastWithDebounce } from '../../hooks/use-resistomap-toast';
import { LocalStorageId } from '../shared/local-storage';

export function MobileSupportNotice() {
  const [isMobile] = useMediaQuery('(max-width: 550px)');
  const hasActivated = localStorage.getItem(LocalStorageId.mobileNoticeShown);
  const showToast = useToastWithDebounce();

  useEffect(() => {
    if (isMobile && !hasActivated) {
      showToast({
        title: 'Mobile support',
        description:
          'The platform has limited support for narrow screens. For the best experience, please use a laptop or a tablet.',
        status: 'warning',
        duration: 13000,
      });
      localStorage.setItem(LocalStorageId.mobileNoticeShown, 'true');
    }

    return () => {
      showToast.cancel();
    };
  }, [isMobile, hasActivated, showToast]);

  return null;
}
