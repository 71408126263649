import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// The smallest limitation (~2000 chars) are Internet explorer and possibly SEO.
// So I think we should be safe to use 4000 chars as a warning threshold. This is ok for other servers and browsers.
const URL_WARNING_THRESHOLD = 4000;
const URL_LENGTH_WARNING_KEY = 'url_length_warning_shown';

export function useUrlLengthWarning() {
  const location = useLocation();

  useEffect(() => {
    const fullUrl = window.location.origin + location.pathname + location.search;

    if (fullUrl.length > URL_WARNING_THRESHOLD) {
      // Only show warning once per session to avoid spamming
      const warningShown = sessionStorage.getItem(URL_LENGTH_WARNING_KEY);
      if (!warningShown) {
        // We want this to be an error, so that it is sent to sentry and noted. Warning are not sent anywhere.
        console.error(
          `URL length (${fullUrl.length} chars) is approaching browser/server limits. This may cause issues with 
          server processing or data loss. Consider reducing the number of selected filters or using shorter parameter 
          values.`,
        );
        sessionStorage.setItem(URL_LENGTH_WARNING_KEY, 'true');
      }
    }
  }, [location.pathname, location.search]);
}
