import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CSSProperties } from 'react';
import { useToastWithoutDebounce } from '../../hooks/use-resistomap-toast';
import { TrashIcon } from './trash-icon';

interface DeleteIconProps {
  name?: string;
  message?: string;
  triggerDelete: (deep?: boolean) => Promise<any>;
  color?: string[];
  style?: CSSProperties;
}

export function DeleteIcon({ name, triggerDelete, style, message }: DeleteIconProps) {
  const showToast = useToastWithoutDebounce();
  const confirmText = message || `${name || 'this item'} will be permanently deleted. Are you sure?`;

  return (
    <TrashIcon
      style={style}
      icon={faTrashAlt}
      onClick={() => {
        const shouldDeepDelete = confirm(confirmText);
        void (async () => {
          try {
            await triggerDelete(shouldDeepDelete);
            setTimeout(() => {
              // TODO no needed for project deletion but might be for other entities?
              window.location.reload();
            }, 500);
          } catch (error) {
            showToast({
              title: `Failed to delete ${name || 'item'}`,
              description: error instanceof Error ? error.message : 'An unexpected error occurred',
              status: 'error',
            });
          }
        })();
      }}
    />
  );
}
