import { Box } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  AssayInfo,
  GeneGroupsByGrouping,
  L2Target,
  L2TargetOrAssay,
  nonSixteenSL2Targets,
} from '@resistapp/common/assays';
import { MetricMode } from '@resistapp/common/types';
import { ReactNode } from 'react';
import { getGroupColor, getGroupTextColor } from '../shared/palettes';
import { theme } from '../shared/theme';
import { AntibioticSelector } from './antibiotic-selector';
import { EnvironmentTypeSelector } from './environment-type-selector';
import { MonthSelector } from './month-selector';
import { allAntibiotics, OptionBase, OptionForTargets } from './types';

type Props = {
  onChangeAntibiotic: (value: OptionBase | OptionForTargets | null) => void;
  onChangeEnvTypeGroup: (value: OptionBase['value'] | undefined) => void;
  selectedTargets: L2Target[];
  children?: ReactNode;
  envTypeSelectorOpen?: boolean;
  setEnvTypeSelectorOpen: (value: boolean) => void;
};

export function ScopeSelectors({
  onChangeAntibiotic,
  onChangeEnvTypeGroup,
  selectedTargets,
  children = null,
  envTypeSelectorOpen = false,
  setEnvTypeSelectorOpen,
}: Props) {
  const { metricMode, analyzedAntibioticGroups } = useOverviewContext();
  const { allGeneGroups, getAssayInfo } = useAssayContext();
  const selectedAntibioticOption = getSelectedAntibioticOption(
    selectedTargets,
    allGeneGroups,
    getAssayInfo,
    metricMode,
  );
  // console.log('selectedAntibioticOption', selectedAntibioticOption);

  return (
    <Box fontSize={theme.fontSize.medium} fontWeight={theme.fontWeight.bold} position={'relative'}>
      {children}
      <AntibioticSelector
        metricMode={metricMode}
        onChange={onChangeAntibiotic}
        selectedAntibiotic={selectedAntibioticOption as OptionForTargets}
        analyzedAntibioticGroups={analyzedAntibioticGroups || []}
      />
      <EnvironmentTypeSelector
        onChange={onChangeEnvTypeGroup}
        active={envTypeSelectorOpen}
        setActive={setEnvTypeSelectorOpen}
      />
      <MonthSelector />
    </Box>
  );
}

// HACK! adding a second arg with default to the util func in common breaks everything ??
export function shouldInterpretAsAllAntibiotics2(
  targets: L2TargetOrAssay[] | undefined,
  showAllAntibsInSingleAssayMode: boolean,
): boolean {
  return (
    !targets ||
    (targets.length === 1 && targets[0].startsWith('AY') && showAllAntibsInSingleAssayMode) ||
    (targets.length === nonSixteenSL2Targets.length && !targets[0].startsWith('AY'))
  );
}
function getSelectedAntibioticOption(
  selectedGroups: L2Target[],
  allGeneGroups: GeneGroupsByGrouping,
  getAssayInfo: (assay: string) => AssayInfo | undefined,
  metricMode: MetricMode,
): OptionBase {
  const isAllAntibiotics = shouldInterpretAsAllAntibiotics2(selectedGroups, metricMode !== MetricMode.REDUCTION);
  const selectedValue = isAllAntibiotics ? allAntibiotics : selectedGroups[0];

  // Get display value - if it's an assay ID and not in assay grouping mode, get the gene name
  let displayValue: string = selectedValue;
  if (!isAllAntibiotics && typeof selectedValue === 'string' && selectedValue.startsWith('AY')) {
    const assayInfo = getAssayInfo(selectedValue);
    if (assayInfo?.gene) {
      displayValue = assayInfo.gene;
    }
  }

  const groupColor = isAllAntibiotics
    ? 'white'
    : selectedGroups[0]
      ? getGroupColor(selectedGroups[0], 'antibiotic', allGeneGroups)
      : 'white';
  const groupTextColor = isAllAntibiotics
    ? theme.colors.neutral800
    : selectedGroups[0]
      ? getGroupTextColor(selectedGroups[0], 'antibiotic', allGeneGroups)
      : theme.colors.neutral800;

  return {
    value: selectedValue,
    label: displayValue,
    textColor: groupTextColor,
    bgColor: groupColor,
  };
}
