import styled from '@emotion/styled';
import React from 'react';

interface Props {
  style?: React.CSSProperties;
  title?: string;
  size?: 'sm' | 'md' | 'lg' | '1x' | '2x';
}

export const DownloadIcon = ({ style, title, size = 'md' }: Props) => {
  const sizeValue =
    size === 'sm' ? '14px' : size === 'md' ? '18px' : size === 'lg' ? '20px' : size === '1x' ? '24px' : '32px';

  return (
    <SVGWrapper style={style} size={sizeValue} title={title}>
      <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 3v12m0 0l5-5m-5 5L7 10"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="4" y="19" width="16" height="2" rx="1" fill="currentColor" />
      </svg>
    </SVGWrapper>
  );
};

const SVGWrapper = styled.div<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
  position: relative;
  top: -1px;
`;
