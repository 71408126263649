import { Button, FormControl, FormLabel, Input, Stack } from '@chakra-ui/react';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { FormView } from '@resistapp/client/components/shared/layout';
import { MaybeApiError } from '@resistapp/client/components/states/error';
import { Loading } from '@resistapp/client/components/states/loading';
import { usePostProject } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { PostProjectRequest } from '@resistapp/common/api-types';
import { useEffect, useState } from 'react';

export function CreateEmptyProjectView() {
  const navigateStable = useNavigateWithQuery();
  const { loading, error, data, send } = usePostProject();
  const [name, setName] = useState('');

  useEffect(() => {
    if (data) {
      navigateStable(`/research/${data.id}`, false);
    }
  }, [data, navigateStable]);

  const handleSubmit = () => {
    const payload: PostProjectRequest = {
      name,
      // No sheetLink provided for empty project
    };
    send(payload);
  };

  return (
    <FormView>
      <RSecondTitle>Create Empty Project</RSecondTitle>
      <Stack spacing={5} width="full">
        <FormControl isRequired isDisabled={loading}>
          <FormLabel htmlFor="name">Project Name</FormLabel>
          <Input
            id="name"
            name="name"
            value={name}
            onChange={event => {
              setName(event.target.value);
            }}
            autoFocus
            width="full"
            placeholder="Enter project name"
          />
        </FormControl>

        <Button onClick={handleSubmit} isDisabled={loading || !name.trim()} mt={4} colorScheme="blue">
          {loading ? 'Creating...' : 'Create Empty Project'}
        </Button>

        <MaybeApiError error={error} />
        {loading && <Loading />}
      </Stack>
    </FormView>
  );
}
