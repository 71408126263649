import { Flex } from '@chakra-ui/react';
import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { GeneGroupsByGrouping, L2Targets } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { friendlyCopyNumber, friendlyFoldChange, friendlyL2Target } from '@resistapp/common/friendly';
import { chain, values } from 'lodash';
import { getGroupColor, getGroupTextColor } from '../../../shared/palettes';
import { theme } from '../../../shared/theme';
import { friendlyGeneName } from '../site-details-utils';
import { SingleBarDatum } from './types';

// Updated row widths to accommodate the split middle column
const rowWidths = [140, 95, 60, 80, 100];

interface DataRowProps {
  label: string;
  labelBgColor?: string;
  labelTextColor?: string;
  afterCopyNumber: number | null | undefined;
  beforeCopyNumber: number | null | undefined;
  reduction: number | null | undefined;
  isHeader?: boolean;
  fontStyle?: React.CSSProperties['fontStyle'];
  marginBottom?: string;
  paddingBottom?: string;
  fontWeight?: number;
}

// Reusable component for both summary and gene rows
function DataRow({
  label,
  labelBgColor,
  labelTextColor,
  afterCopyNumber,
  beforeCopyNumber,
  reduction,
  fontStyle = 'normal',
  marginBottom = '0',
  paddingBottom = '0',
  isHeader = false,
}: DataRowProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom,
        paddingBottom,
      }}
    >
      <div
        style={{
          textAlign: 'right',
          width: rowWidths[0],
          fontStyle,
        }}
      >
        {labelBgColor && labelTextColor ? (
          <span
            style={{
              padding: theme.spacing[1],
              borderRadius: theme.borders.radius.small,
              backgroundColor: labelBgColor,
              color: labelTextColor,
            }}
          >
            {label}
          </span>
        ) : (
          label
        )}
      </div>
      <div style={{ width: rowWidths[1], textAlign: 'right', paddingRight: '8px' }}>
        {isHeader ? 'avg. ' : ''}
        {friendlyCopyNumber(afterCopyNumber)}
      </div>

      {/* Fold change column with left arrow */}
      <div style={{ width: rowWidths[2], display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '10px' }}>⭠</div>
        <div style={{ textAlign: 'right', fontWeight: theme.fontWeight.extraHeavy, marginRight: '4px' }}>
          {beforeCopyNumber || afterCopyNumber ? friendlyFoldChange(reduction, 1, true) : '-'}
        </div>
      </div>

      {/* Percentage column with right arrow */}
      <div style={{ width: rowWidths[3], display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ textAlign: 'left', paddingLeft: '4px' }}>
          {beforeCopyNumber || afterCopyNumber ? foldChangeToPercentage(reduction) : ''}
        </div>
        <div style={{ width: '10px' }}>⭠</div>
      </div>

      <div style={{ width: rowWidths[4], textAlign: 'left', paddingLeft: '8px' }}>
        {friendlyCopyNumber(beforeCopyNumber)}
      </div>
    </div>
  );
}

interface L2TargetTooltipContentProps {
  tooltipData: SingleBarDatum;
  activeOverviewConfiguration: OverviewChartConfiguration;
  allGeneGroups: GeneGroupsByGrouping;
}

export function L2TargetTooltipContent({
  tooltipData,
  activeOverviewConfiguration,
  allGeneGroups,
}: L2TargetTooltipContentProps) {
  const isL2Target = (values(L2Targets) as string[]).includes(tooltipData.barIdentifier);
  const sortedGenes = [...tooltipData.analysedGenes].sort(
    (a, b) => (b.beforeCopyNumber || 0) - (a.beforeCopyNumber || 0),
  );

  // Calculate average values for the overall row using the same approach as the bar chart
  const avgBeforeCopies = chain(sortedGenes)
    .meanBy(gene => gene.beforeCopyNumber || 0)
    .value();

  const avgAfterCopies = chain(sortedGenes)
    .meanBy(gene => gene.afterCopyNumber || 0)
    .value();

  const overallReduction = tooltipData.log10Reduction;

  // Only get color information if it's an L2Target to prevent "Invalid hex color" warnings
  // Use string hex values directly rather than theme colors to avoid type issues
  const groupColor = isL2Target ? getGroupColor(tooltipData.barIdentifier, 'antibiotic', allGeneGroups) : '';
  const groupTextColor = isL2Target ? getGroupTextColor(tooltipData.barIdentifier, 'antibiotic', allGeneGroups) : '';

  return (
    <div style={{ padding: theme.spacing[2] }}>
      <Flex pb={2} pt={2} mb={4} borderBottom={`1px solid ${theme.colors.neutral300}`}>
        <div style={{ textAlign: 'right', width: rowWidths[0] }} />
        <div style={{ width: rowWidths[1], textAlign: 'right', paddingRight: '8px', whiteSpace: 'nowrap' }}>
          Treated copies
        </div>
        {/* Split the middle column header into two parts */}
        <div
          style={{
            width: rowWidths[2],
            textAlign: 'right',
            fontWeight: theme.fontWeight.extraHeavy,
            whiteSpace: 'nowrap',
          }}
        >
          {/* Use the first part of the tooltip title if available */}
          {activeOverviewConfiguration.tooltipTitle.split(' ')[0] || 'Fold'}
        </div>
        <div
          style={{
            width: rowWidths[3],
            textAlign: 'left',
            fontWeight: theme.fontWeight.extraHeavy,
            whiteSpace: 'nowrap',
            paddingLeft: '4px',
          }}
        >
          {activeOverviewConfiguration.tooltipTitle.split(' ')[1] || 'Change'}
        </div>
        <div style={{ width: rowWidths[4], textAlign: 'left', paddingLeft: '8px' }}>Raw copies</div>
      </Flex>

      {/* Overall summary row - only show for antibiotics with multiple genes */}
      {isL2Target && sortedGenes.length > 1 && (
        <DataRow
          label={friendlyL2Target(tooltipData.barIdentifier as L2Targets)}
          labelBgColor={groupColor}
          labelTextColor={groupTextColor}
          afterCopyNumber={avgAfterCopies}
          beforeCopyNumber={avgBeforeCopies}
          reduction={overallReduction}
          isHeader={true}
          marginBottom={theme.spacing[3]}
          paddingBottom={theme.spacing[2]}
        />
      )}

      {/* Individual gene rows */}
      {sortedGenes.map(gene => (
        <DataRow
          key={gene.gene}
          label={friendlyGeneName(gene.assay, gene.gene)}
          afterCopyNumber={gene.afterCopyNumber}
          beforeCopyNumber={gene.beforeCopyNumber}
          reduction={gene.reduction}
          fontStyle={tooltipData.groupingTarget === MinorTarget.PATHOGEN ? 'italic' : 'normal'}
        />
      ))}
    </div>
  );
}

export function foldChangeToPercentage(foldChange: number | null | undefined): string {
  if (foldChange === null || foldChange === undefined) {
    return '';
  }

  // For extreme negative values (typically when treated genes are not detected)
  if (foldChange <= -9) {
    return '(-100%)';
  }

  let percentage: number;
  if (foldChange < 0) {
    // For reductions (negative values)
    percentage = 100 * (1 - Math.pow(10, foldChange));

    // Ensure percentage doesn't exceed -100%
    percentage = Math.max(percentage, -100);

    // Check if this would round to -100%
    if (Math.round(Math.abs(percentage)) >= 100 && foldChange > -9) {
      return '(-99.9%)';
    }
  } else {
    // For increases (positive values)
    percentage = 100 * (Math.pow(10, foldChange) - 1);
  }

  // Format based on magnitude
  let formattedPercentage: string;
  const sign = foldChange < 0 ? '-' : '+';
  const absPercentage = Math.abs(percentage);

  if (absPercentage >= 999 && percentage > 0) {
    formattedPercentage = '999%';
  } else if (absPercentage >= 99.9 && percentage < 0) {
    // For negative percentages close to -100%, always show as -99.9%
    formattedPercentage = '99.9%';
  } else if (absPercentage >= 10) {
    // Check again if rounding would result in -100%
    if (Math.round(absPercentage) >= 100 && percentage < 0 && foldChange > -9) {
      formattedPercentage = '99.9%';
    } else {
      formattedPercentage = `${Math.round(absPercentage)}%`;
    }
  } else {
    formattedPercentage = `${absPercentage.toFixed(1)}%`;
  }

  const result = formattedPercentage === '999%' ? `(>${formattedPercentage})` : `(${sign}${formattedPercentage})`;
  return result;
}
