import { getComparableEnvGroupsForOverview } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { useStateWithRef } from '@resistapp/client/hooks/use-state-with-ref';
import { getCountryParam, OtherParams } from '@resistapp/client/utils/url-manipulation';
import { antibioticL2Targets, type L2Target } from '@resistapp/common/assays';
import {
  AllProjectEnvironmentTypesGroup,
  ComparableEnvGroupType,
  EnvGroup,
} from '@resistapp/common/comparable-env-groups';
import { EnvironmentType } from '@resistapp/common/environment-types';
import { MetricMode } from '@resistapp/common/types';
import { values } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { useAssayContext } from '../../assay-context';
import { useSampleDataContext } from '../../sample-data-context';
import { useSearchParamsContext } from '../../search-params-context';

interface UseQueryParamInitProps {
  metricMode: MetricMode;
}

interface UseQueryParamInitResult {
  queryParamsInitialised: boolean;
  availableEnvGroups: EnvGroup[] | undefined;
  selectedAntibiotic: L2Target | undefined;
  activeEnvGroup: EnvGroup | undefined;
  setSelectedCountryStable: (country: string | undefined) => void;
  countryParam: string | null;
}

export function useOverviewQueryParams({ metricMode }: UseQueryParamInitProps): UseQueryParamInitResult {
  const { data, queryFilters } = useSampleDataContext();
  const { assaysLoaded, getGroup, allAssays } = useAssayContext();
  const { searchParams, searchParamsRef, setSearchParamsStable } = useSearchParamsContext();

  const countryParam = getCountryParam(searchParams);

  const setSelectedCountryStable = useCallback((country: string | undefined) => {
    if (country) {
      searchParamsRef.current.set(OtherParams.COUNTRY, country);
    } else {
      searchParamsRef.current.delete(OtherParams.COUNTRY);
    }
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, []);

  const [availableEnvGroups, setAvailableEnvGroupsStable, availableEnvGroupsRef] = useStateWithRef<EnvGroup[]>();
  const {
    filters: { selectedTargets, selectedEnvironmentTypeGroup, selectedTargetGrouping },
    setGroupingStable,
    setEnvironmentTypeGroupStable,
  } = queryFilters;

  const selectedAntibiotic =
    queryFilters.filters.selectedTargets.length === 1 &&
    antibioticL2Targets.includes(queryFilters.filters.selectedTargets[0])
      ? queryFilters.filters.selectedTargets[0]
      : undefined;

  const activeEnvGroup =
    availableEnvGroups?.find(group => group.type === queryFilters.filters.selectedEnvironmentTypeGroup) ||
    availableEnvGroups?.[0];

  // 1. UPDATE SELECTABLE ENV GROUPS & QUERY FILTERS ON PROJECT AND METRIC MODE CHANGE
  //    *AND* KEEP QUERY FILTERS IN SUPPORTED STATE FOR OVERVIEW (ON ALL CHANGES)
  const projectIdRef = useRef(data?.id);
  const metricModeRef = useRef<MetricMode | null>(null);
  const queryParamsInitialisedRef = useRef(false);
  useEffect(() => {
    if (!data || !assaysLoaded) {
      return;
    }

    // Detect project and metric mode changes
    const projectChanged = data.id !== projectIdRef.current; // NOTE: focusedByUID data filtering is not noticed by this check
    const metricModeChanged = metricMode !== metricModeRef.current;
    projectIdRef.current = data.id;
    metricModeRef.current = metricMode;

    // Update available env groups on project or metric mode change
    const freshSelectableEnvGroups =
      projectChanged || metricModeChanged
        ? getComparableEnvGroupsForOverview(data.samplesByUID, metricMode)
        : availableEnvGroupsRef.current || [];
    if (projectChanged || metricModeChanged) {
      setAvailableEnvGroupsStable(freshSelectableEnvGroups);
    }

    // Update environment type group and gene groups to supported value when needed
    let paramsUpdated = false;
    if (
      metricModeChanged &&
      metricMode !== MetricMode.REDUCTION &&
      selectedEnvironmentTypeGroup === ComparableEnvGroupType.WATER_TREATMENT &&
      freshSelectableEnvGroups.find(group => group.type === ComparableEnvGroupType.TREATED_WASTEWATER)
    ) {
      // Auto-select TREATED_WASTEWATER
      setEnvironmentTypeGroupStable(ComparableEnvGroupType.TREATED_WASTEWATER, false);
      paramsUpdated = true;
    } else if (
      metricModeChanged &&
      metricMode === MetricMode.REDUCTION &&
      (selectedEnvironmentTypeGroup === EnvironmentType.WASTEWATER ||
        values(ComparableEnvGroupType).includes(selectedEnvironmentTypeGroup as ComparableEnvGroupType)) &&
      selectedEnvironmentTypeGroup !== ComparableEnvGroupType.WATER_TREATMENT &&
      freshSelectableEnvGroups.find(group => group.type === ComparableEnvGroupType.WATER_TREATMENT)
    ) {
      // Auto-select WATER_TREATMENT
      setEnvironmentTypeGroupStable(ComparableEnvGroupType.WATER_TREATMENT, false);
      paramsUpdated = true;
    } else if (
      // Default, empty etype param is treated as automatic switching based on metric mode
      // TODO: changes this behaviour and allow showing data across env types when explicitly requested
      selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS &&
      !freshSelectableEnvGroups.find(group => group.type === selectedEnvironmentTypeGroup)
    ) {
      setEnvironmentTypeGroupStable(AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS, true);
      paramsUpdated = true;
    }

    // We only support assay with selected assay and antibiotic grouping in overview, others can cause errors
    // If grouping is set to 'assay' but no assay or all assays are selected, remove the grouping
    else if (
      (selectedTargetGrouping !== 'assay' && selectedTargetGrouping !== 'antibiotic') ||
      (selectedTargetGrouping === 'assay' &&
        (!selectedTargets.length ||
          !selectedTargets.some(g => typeof g === 'string' && g.startsWith('AY')) ||
          selectedTargets.length === allAssays.length)) ||
      (selectedTargetGrouping === 'antibiotic' &&
        selectedTargets.length !== 1 &&
        antibioticL2Targets.includes(selectedTargets[0] as L2Target))
    ) {
      setGroupingStable(null);
    }

    // // Force selectedTargetGrouping to antibiotic and allow only antibiotic in selected targets
    // if (
    //   selectedTargetGrouping !== 'antibiotic' ||
    //   selectedTargets.find(target => getGroup(target, 'l1Target') !== L1Targets.ARG) ||
    //   !selectedTargets.length
    // ) {
    //   setGroupingStable('antibiotic');
    //   toggleGeneGroupStable(antibioticL2Targets, true, 'antibiotic');
    //   paramsUpdated = true;
    // }

    // If params were updated, wait for the next render cycle before setting queryParamsReady
    if (paramsUpdated) {
      setTimeout(() => {
        queryParamsInitialisedRef.current = true;
      }, 0);
    } else if (!queryParamsInitialisedRef.current) {
      queryParamsInitialisedRef.current = true;
    }
  }, [
    data,
    metricMode,
    selectedTargets,
    selectedEnvironmentTypeGroup,
    selectedTargetGrouping,
    assaysLoaded,
    getGroup,
    allAssays.length,
  ]);

  return {
    availableEnvGroups,
    selectedAntibiotic,
    activeEnvGroup,
    setSelectedCountryStable,
    countryParam,
    queryParamsInitialised: queryParamsInitialisedRef.current,
  };
}
