import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import { type OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { theme } from '../../shared/theme';
import { BaseMapLegend } from './base-map-legend';

export function ReductionMapLegend({ timeLegend }: { timeLegend: React.ReactNode | string }) {
  const { activeChartUnit, activeOverviewConfiguration } = useOverviewContext();
  const colorScale = activeOverviewConfiguration.colorScale;

  if (!colorScale) {
    return null;
  }

  return (
    <BaseMapLegend
      timeLegend={timeLegend}
      indicators={
        <ColorScale activeChartUnit={activeChartUnit} activeOverviewConfiguration={activeOverviewConfiguration} />
      }
      indexRange={activeOverviewConfiguration.mapLegendTicks?.map(e => (
        // The marginLeft takes into account that some of the numbers have minuses in front of them and take up
        // space. Without the margin the scale is not inline with the colored bar over it.
        <div key={e}>{e}</div>
      ))}
    />
  );
}

const ColorScale = styled.div<{ activeChartUnit: ChartUnit; activeOverviewConfiguration: OverviewChartConfiguration }>`
  width: 100%;
  height: 20px;
  background: linear-gradient(
    to right,
    ${props =>
        getMetricColor(
          props.activeOverviewConfiguration.detailBarGraphMin,
          MetricMode.REDUCTION,
          props.activeChartUnit,
        )}
      ${props => props.activeOverviewConfiguration.colorScale?.[0]}%,
    /* We add +1 to position the 0 in the correct spot */ #ffffff
      ${props => (props.activeOverviewConfiguration.colorScale?.[1] || 2) + 1}%,
    ${props =>
        getMetricColor(
          props.activeOverviewConfiguration.detailBarGraphMax(),
          MetricMode.REDUCTION,
          props.activeChartUnit,
        )}
      ${props => props.activeOverviewConfiguration.colorScale?.[2]}%
  );
  border-radius: ${theme.borders.radius.small};
`;
