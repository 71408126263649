import {
  antibioticL2Targets,
  GetGroup,
  L1Targets,
  L2TargetOrAssay,
  nonSixteenSL2Targets,
} from '@resistapp/common/assays';
import { getAbundanceLog10Stats } from '@resistapp/common/statistics/abundance-stats';
import { AssayResult, FullAbundance, NormalisationMode, StrippedFullAbundance } from '@resistapp/common/types';
import { isNil, uniq } from 'lodash';
import { LOD } from '../utils';

export type PartialAbundance = Pick<
  FullAbundance,
  'assay' | 'gene' | 'absolute' | 'relative' | 'copiesPerL' | 'copiesPerHour' | 'copiesPerMgSS' | 'copiesPerMgBod'
>;
export type WithAbundances = {
  beforeAbundances?: PartialAbundance[] | undefined;
  afterAbundances?: PartialAbundance[] | undefined;
};

export function getResistanceIndexData(
  abundances: StrippedFullAbundance[] | AssayResult[],
  _targets: L2TargetOrAssay[] | undefined, // undefined antibiotic means all antibiotics
  getGroup: GetGroup,
) {
  const targets = interpretAllL2TargetsAsAntibiotics(_targets);
  const l1Targets = targets.map(l2 => getGroup(l2, 'l1Target'));
  if (uniq(l1Targets).length !== 1 || l1Targets[0] !== L1Targets.ARG) {
    throw Error(`Unable to calculate index for non-ARGs; ${uniq(l1Targets).join(', ')}`);
  }
  const data = getAbundanceLog10Stats(
    abundances,
    {
      scope: 'analysed',
      mode: NormalisationMode.SIXTEEN_S,
      targets,
    },
    getGroup,
  );
  return data;
}

export function interpretAllL2TargetsAsAntibiotics(targets: L2TargetOrAssay[] | undefined): L2TargetOrAssay[] {
  return !targets || shouldInterpretAsAllAntibiotics(targets) ? antibioticL2Targets : targets;
}

export function shouldInterpretAsAllAntibiotics(targets: L2TargetOrAssay[] | undefined): boolean {
  return !targets || (targets.length === nonSixteenSL2Targets.length && !targets[0].startsWith('AY'));
}

export function getResistanceGeneIndex(
  abundances: StrippedFullAbundance[] | AssayResult[],
  targets: L2TargetOrAssay[] | undefined, // undefined means all antibiotics
  getGroup: GetGroup,
) {
  const antibiotics = shouldInterpretAsAllAntibiotics(targets) ? antibioticL2Targets : targets;
  const l1Targets = antibiotics ? antibiotics.map(l2 => getGroup(l2, 'l1Target')) : [L1Targets.ARG];
  if (uniq(l1Targets).length !== 1 || l1Targets[0] !== L1Targets.ARG) {
    return null; // Not defined for assays or non-antibiotics
  }
  const data = getResistanceIndexData(abundances, antibiotics, getGroup);
  return abundances.length ? calcResistanceGeneIndex(data.mean ?? Math.log10(LOD)) : null;
}

export function calcResistanceGeneIndex(relativeAbundanceExponent: number) {
  return Math.min(5, Math.max(0, 5 + relativeAbundanceExponent));
}

export enum ResistanceLevel {
  low = 'low',
  moderate = 'moderate',
  high = 'high',
}

export function getResistanceLevel(value: number): ResistanceLevel {
  return value >= 4 ? ResistanceLevel.high : value >= 2 ? ResistanceLevel.moderate : ResistanceLevel.low;
}

export function getResistanceGeneIndexAndLevel(
  abundances: StrippedFullAbundance[] | undefined,
  antibiotic: L2TargetOrAssay[] | undefined, // undefined antibiotic means all antibiotics
  getGroup: GetGroup,
) {
  // TODO PERF
  // This  is currently called separately from overview focus header, base chart (2x), Process markers and colored region activation,
  // Activations take 30ms a pop and add up to a similar delay as overview data building (and these are likely called more often)
  if (!abundances) {
    return { resistanceIndex: null, resistanceLevel: null };
  }
  const resistanceIndex = getResistanceGeneIndex(abundances, antibiotic, getGroup);
  const resistanceLevel = isNil(resistanceIndex) ? null : getResistanceLevel(resistanceIndex);
  return { resistanceIndex, resistanceLevel };
}
