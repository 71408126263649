import { Box, Flex } from '@chakra-ui/react';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import {
  faCity,
  faDroplet,
  faFillDrip,
  faQuestion,
  faToilet,
  faTrash,
  faWater,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EnvironmentSubType, EnvironmentType, getParentType } from '@resistapp/common/environment-types';
import { CSSProperties } from 'react';

export interface EnvTypeIconProps {
  style?: CSSProperties;
  envSubtype: EnvironmentSubType | 'pooled' | undefined;
  color?: string;
  isHeader?: boolean;
}

export function EnvTypeIcon({ envSubtype, style, color, isHeader }: EnvTypeIconProps) {
  const parentType = envSubtype && getParentType(envSubtype as EnvironmentSubType);

  if (parentType === EnvironmentType.OTHER_WATER) {
    return <FontAwesomeIcon style={style} icon={faDroplet} />;
  } else if (parentType === EnvironmentType.SURFACE_WATER) {
    return <FontAwesomeIcon style={style} icon={faWater} />;
  } else if (parentType === EnvironmentType.WASTEWATER) {
    switch (envSubtype) {
      case EnvironmentSubType.URBAN_RUNOFF:
        return <FontAwesomeIcon style={style} icon={faFillDrip} />;
      case EnvironmentSubType.HOSPITAL_WASTEWATER_RAW:
      case EnvironmentSubType.HOSPITAL_WASTEWATER_TREATED:
      case EnvironmentSubType.PRIMARY_HEALTH_CARE_RAW:
      case EnvironmentSubType.PRIMARY_HEALTH_CARE_TREATED:
        return <HospitalIcon style={style} color={color} />;
      case EnvironmentSubType.URBAN_WASTEWATER_RAW:
      case EnvironmentSubType.URBAN_WASTEWATER_TREATED:
        return <FontAwesomeIcon style={style} icon={faCity} />;
      case EnvironmentSubType.SEPTIC_TANK_RAW:
      case EnvironmentSubType.SEPTIC_TANK_TREATED:
        return <FontAwesomeIcon style={style} icon={faToilet} />;
      case EnvironmentSubType.OTHER_WASTEWATER:
        return <WastewaterIcon style={style} color={color} isBig={isHeader} />;
      case 'pooled':
        return <FontAwesomeIcon style={style} icon={faMap} />;
      default:
        return <FontAwesomeIcon style={style} icon={faQuestion} />;
    }
  }

  return null;
}

export function HospitalIcon({ color, style }: { color?: string; style?: CSSProperties }) {
  const svgStyle: CSSProperties = {
    display: 'inline-block',
    verticalAlign: 'middle',
    paddingBottom: '1px',
    marginLeft: '-8px',
    marginRight: '-6px',
    ...(style || {}),
  };

  return (
    <svg style={svgStyle} width="44" height="44" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="cutout-mask">
        <rect width="22" height="22" fill="white" />
        <path d="M9.5 5.5H12.5V9H16V12H12.5V15.5H9.5V12H6V9H9.5V5.5Z" fill="black" />
      </mask>
      <circle cx="11" cy="11" r="9" fill={color || '#000000'} mask="url(#cutout-mask)" />
    </svg>
  );
}

export function WastewaterIcon({ style, isBig }: { color?: string; style?: CSSProperties; isBig?: boolean }) {
  return (
    <Flex height="30px" fontSize="10px" alignItems="center">
      <FontAwesomeIcon style={{ ...style, height: '25px', paddingRight: '0px' }} icon={faDroplet} />
      <Box ml="-3px" mt="-2px" pl={isBig ? 1.5 : 0} height="100%">
        <FontAwesomeIcon style={{ ...style, height: '12px', width: '12px' }} icon={faTrash} />
      </Box>
    </Flex>
  );
}
