import styled from '@emotion/styled';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSelectedSiteDatumOrThrow } from '@resistapp/client/contexts/use-overview-context/overview-context-utils';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getGenesAndCopyNumbers,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { L1Target, L2Target } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { friendlyCopyNumber } from '@resistapp/common/friendly';
import { theme } from '../shared/theme';
import { SiteDetailTitle } from './site-detail-title';
import { formatGeneMutationSuffixes } from './site-details/general-site-details';

interface Props {
  title?: string;
  explanation?: string;
  target: L1Target | L2Target | MinorTarget;
  children?: React.ReactNode;
}

export function SiteGeneDetailBox({ title, explanation, target, children }: Props) {
  const { metricMode, effectiveSiteDetailsProcessMode } = useOverviewContext();
  const { getGroup } = useAssayContext();
  const selectedSiteDatum = useSelectedSiteDatumOrThrow();

  const counts = getNumDetectedAndAnalysedAssays(selectedSiteDatum, target, effectiveSiteDetailsProcessMode, getGroup);
  const genesAndCopyNumbers = getGenesAndCopyNumbers(
    selectedSiteDatum,
    target,
    metricMode,
    effectiveSiteDetailsProcessMode,
    getGroup,
  );

  return (
    <>
      {title && <SiteDetailTitle title={title} explanation={explanation} />}
      <DetailContainer>
        <DetectedBox>
          <DetectedNumbers>
            <DetectedDetected>{counts.detectedCount}</DetectedDetected>
            <DetectedSeparator>/</DetectedSeparator>
            <DetectedAnalysed>{counts.analysedCount}</DetectedAnalysed>
          </DetectedNumbers>
          <DetectedText>detected</DetectedText>
        </DetectedBox>
        {genesAndCopyNumbers.length > 0 && (
          <GeneList>
            {genesAndCopyNumbers.map((gene, index) => (
              <Gene key={index}>
                <GeneCopyNumber>{friendlyCopyNumber(gene.copyNumber)}</GeneCopyNumber>
                <GeneName>{formatGeneMutationSuffixes(gene.gene)}</GeneName>
              </Gene>
            ))}
          </GeneList>
        )}
        {children}
      </DetailContainer>
    </>
  );
}

const DetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${theme.spacing[2]};
  width: 100%;
  height: 100%;
`;

const DetectedBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.blue50};
  border-radius: ${theme.borders.radius.large};
  padding: ${theme.spacing[4]};
  width: 110px;
  min-width: 110px;
  height: 110px;
`;

const DetectedNumbers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 50px;
  padding-top: 25px;
`;

const DetectedText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeight.heavy};
  color: ${theme.colors.neutral600};
  text-transform: uppercase;
  opacity: 0.7;
  padding-top: ${theme.spacing[2]};
`;

const DetectedDetected = styled.div`
  font-size: ${theme.fontSize.h0};
  font-weight: ${theme.fontWeight.light};
  line-height: 0;
`;

const DetectedSeparator = styled.div`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};
  opacity: 0.7;
  line-height: 1.7;
`;

const DetectedAnalysed = styled.div`
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};
  opacity: 0.7;
  line-height: 1.9;
`;

const GeneList = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  gap: ${theme.spacing[2]};
  flex-wrap: wrap;
  align-content: flex-start;
`;

const Gene = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 33px;
  width: max-content;
  ${theme.addBorder(200, 'full')};
  padding: 5px;
  padding-right: 13px;
`;

const GeneName = styled.div`
  font-size: ${theme.fontSize.medium};
  padding-left: ${theme.spacing[2]};
  word-break: break-all;
`;

const GeneCopyNumber = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.heavy};
  padding: ${theme.spacing[2]};
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${theme.colors.neutral100};
  border-radius: ${theme.borders.radius.full};
`;
