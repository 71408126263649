import {
  getProjectOrganisationIds,
  getUserProjectIds,
  getUserProjectIdsWithDirectAccess,
} from '@resistapp/common/access';
import { isAdmin } from '@resistapp/common/features';
import { FullProject, Organisation, SampleStatus, User } from '@resistapp/common/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LocalStorageId } from '../components/shared/local-storage';
import { useSampleDataContext } from '../contexts/sample-data-context';
import { useUser } from '../contexts/use-user-context';
import { useOrganisations, usePatchProjectSamples, usePatchSampleStatuses, useUsers } from './api';

export interface ResearchAdminFeatures {
  updateSampleStatuses: (newStatus: SampleStatus, environmentId?: number) => void;
  isModifyAsAdminActive: boolean;
  setIsModifyAsAdminActive: (value: boolean) => void;
  project: FullProject | null;
  patchSamplesToProject: ReturnType<typeof usePatchProjectSamples>;
  usersWithAccess: User[];
  usersWithDirectAccess: User[];
  usersWithoutAccess: User[];
  organisationsWithAccess: Organisation[];
  organisationsWithoutAccess: Organisation[];
  getAccessDifferences: (targetProjectId: number) => {
    usersOnlyInTarget: User[];
    usersOnlyInCurrent: User[];
    orgsOnlyInTarget: Organisation[];
    orgsOnlyInCurrent: Organisation[];
  };
}

export function useResearchAdmin(): ResearchAdminFeatures {
  const { data: project, refetchData } = useSampleDataContext();
  const { data: allUsers, send: loadUsers } = useUsers();
  const { data: allOrganisations } = useOrganisations();
  const { user } = useUser();
  const patchSamplesToProject = usePatchProjectSamples();

  const [isModifyAsAdminActive, setIsModifyAsAdminActiveInternal] = useState(
    localStorage.getItem(LocalStorageId.adminMode) === 'true',
  );

  const { mutate: patchSampleStatuses } = usePatchSampleStatuses(
    () => {
      refetchData();
    },
    (err: Error) => {
      console.error('Failed to update sample statuses:', err);
    },
  );

  const updateSampleStatuses = useCallback(
    (newStatus: SampleStatus, environmentId?: number) => {
      if (!project?.focusedByUID) return;
      const focusedSamples = Object.values(project.focusedByUID).flat();
      const newStatuses = focusedSamples
        .filter(data => (environmentId ? data.environment.id === environmentId : true))
        .map(sample => {
          return { id: sample.id, status: newStatus };
        });
      patchSampleStatuses(newStatuses);
    },
    [project?.focusedByUID, patchSampleStatuses],
  );

  const setIsModifyAsAdminActive = useCallback((value: boolean) => {
    localStorage.setItem(LocalStorageId.adminMode, value.toString());
    setIsModifyAsAdminActiveInternal(value);
  }, []);

  const usersWithAccess = useMemo(
    () => allUsers?.filter(u => !isAdmin(u) && getUserProjectIds(u).includes(project?.id ?? -1)) || [],
    [allUsers, project?.id],
  );

  const usersWithDirectAccess = useMemo(
    () => allUsers?.filter(u => !isAdmin(u) && getUserProjectIdsWithDirectAccess(u).includes(project?.id ?? -1)) || [],
    [allUsers, project?.id],
  );

  const usersWithoutAccess = useMemo(
    () => allUsers?.filter(u => !isAdmin(u) && !getUserProjectIds(u).includes(project?.id ?? -1)) || [],
    [allUsers, project?.id],
  );

  const organisationsWithAccess = useMemo(
    () => allOrganisations?.filter(org => getProjectOrganisationIds(org).includes(project?.id ?? -1)) || [],
    [allOrganisations, project?.id],
  );

  const organisationsWithoutAccess = useMemo(
    () => allOrganisations?.filter(org => !getProjectOrganisationIds(org).includes(project?.id ?? -1)) || [],
    [allOrganisations, project?.id],
  );

  const getAccessDifferences = useCallback(
    (targetProjectId: number) => {
      // Get access rights for the target project
      const targetProjectUsers = usersWithAccess.filter(u => getUserProjectIds(u).includes(targetProjectId));
      const targetProjectOrgs = organisationsWithAccess.filter(org =>
        getProjectOrganisationIds(org).includes(targetProjectId),
      );

      // Compare access rights between current and target project
      const usersOnlyInTarget = targetProjectUsers.filter(
        targetUser => !usersWithAccess.some(currentUser => currentUser.id === targetUser.id),
      );
      const usersOnlyInCurrent = usersWithAccess.filter(
        currentUser => !targetProjectUsers.some(targetUser => targetUser.id === currentUser.id),
      );

      // Compare organisation access rights
      const orgsOnlyInTarget = targetProjectOrgs.filter(
        targetOrg => !organisationsWithAccess.some(currentOrg => currentOrg.id === targetOrg.id),
      );
      const orgsOnlyInCurrent = organisationsWithAccess.filter(
        currentOrg => !targetProjectOrgs.some(targetOrg => targetOrg.id === currentOrg.id),
      );

      return {
        usersOnlyInTarget,
        usersOnlyInCurrent,
        orgsOnlyInTarget,
        orgsOnlyInCurrent,
      };
    },
    [allUsers, allOrganisations, usersWithAccess, organisationsWithAccess],
  );

  useEffect(() => {
    if (isAdmin(user)) {
      void loadUsers();
    }
  }, [user, loadUsers]);

  return {
    updateSampleStatuses,
    isModifyAsAdminActive,
    setIsModifyAsAdminActive,
    project: project || null,
    patchSamplesToProject,
    usersWithAccess,
    usersWithDirectAccess,
    usersWithoutAccess,
    organisationsWithAccess,
    organisationsWithoutAccess,
    getAccessDifferences,
  };
}
