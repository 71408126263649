import { Box, Flex, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { useContainerWidth } from '@resistapp/client/hooks/use-container-width';
import { getMetricColor } from '@resistapp/client/utils/metric-utils';
import { L1Targets } from '@resistapp/common/assays';
import { ChartUnit, MetricMode } from '@resistapp/common/types';
import { RefObject } from 'react';
import { theme } from '../../../shared/theme';
import { SiteDetailTitle } from '../../site-detail-title';
import { graphMargins } from '../consts';
import { SiteDetailContainer } from '../general-site-details';
import { detailGraphHeight } from '../site-details-utils';
import { TreatedAndReducedBarGraph } from './treated-and-reduced-bar-graph';
import { TargetType } from './types';

interface Props {
  containerRef: RefObject<HTMLDivElement>;
  title: string;
  explanation: string;
  groupingTarget: TargetType;
  height?: number;
  id?: string;
}

export function SiteDetailTreatedAndReducedBox({
  containerRef,
  title,
  explanation,
  groupingTarget,
  height = detailGraphHeight,
  id,
}: Props) {
  const { selectedSiteDatum, activeChartUnit } = useOverviewContext();
  const containerWidth = useContainerWidth(containerRef);

  if (!selectedSiteDatum) {
    return null;
  }
  const treatedBarColor = getMetricColor(-3.5, MetricMode.REDUCTION, activeChartUnit);
  const reducedBarColor = getMetricColor(-1, MetricMode.REDUCTION, activeChartUnit);
  const isPerL = activeChartUnit === ChartUnit.COPIES_PER_L;
  const avgMaybe = (groupingTarget as L1Targets) === L1Targets.ARG ? 'avg.' : '';

  if (!isPerL || !selectedSiteDatum.beforeAbundances?.length || !selectedSiteDatum.afterAbundances?.length) {
    return (
      <SiteDetailContainer ref={containerRef} id={id}>
        <SiteDetailTitle title={title} explanation={explanation} />
        <Flex justifyContent="center" alignItems="center" height={height} width="100%">
          Data not available for this site or sample type
        </Flex>
      </SiteDetailContainer>
    );
  }

  return (
    <SiteDetailContainer ref={containerRef}>
      <SiteDetailTitle title={title} explanation={explanation} />
      <Box px={5} pt={2} width="100%">
        <LegendContainer>
          <LegendItem>
            <LegendText backgroundColor={treatedBarColor} />
            <Text pl={2} alignItems="center">
              {avgMaybe} remaining <NoWrapSpan>copies/L&nbsp;(log)</NoWrapSpan>
            </Text>
          </LegendItem>
          <LegendItem>
            <LegendText backgroundColor={reducedBarColor} opacity={0.7} />
            <Text pl={2} alignItems="center">
              {avgMaybe} removed copies <NoWrapSpan>(10-fold&nbsp;change)</NoWrapSpan>
            </Text>
          </LegendItem>
          {/* NOTE: could be good to add legend items for incease colors as well in the rare case there are any, but we'll only know that inside L2TargetStackedBarGraph */}
        </LegendContainer>
        <Flex minHeight={height} width="100%" alignItems="center" justifyContent="center">
          {containerWidth && (
            <TreatedAndReducedBarGraph
              containerRef={containerRef}
              width={containerWidth - graphMargins}
              height={height}
              selectedSiteDatum={selectedSiteDatum}
              groupingTarget={groupingTarget}
            />
          )}
        </Flex>
      </Box>
    </SiteDetailContainer>
  );
}

const LegendContainer = styled(Flex)`
  padding-bottom: ${theme.spacing[8]};
  flex-direction: row;
  gap: ${theme.spacing[6]};
  color: ${theme.colors.neutral700};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
`;

const LegendItem = styled(Flex)`
  align-items: center;
`;

const LegendText = styled(Box)`
  margin-top: 2px;
  height: 10px;
  width: 10px;
`;

const NoWrapSpan = styled.span`
  white-space: nowrap;
`;
