import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { ComparableEnvGroupType, type EnvironmentTypeGroup } from '@resistapp/common/comparable-env-groups';
import { ResistanceLevel } from '@resistapp/common/statistics/resistance-index';
import { MetricMode, ProcessMode } from '@resistapp/common/types';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import { DownRightIcon, RightIcon, UpRightIcon } from '../icons/diaconal-arrow-icons';
import { OverviewMetricLabel, getChangeIndicatorColor } from '../shared/overview-metric-label';
import { theme } from '../shared/theme';
import { isBeforeOrChangePresent } from './types';

interface Datum {
  level: ResistanceLevel | null | undefined;
  metric: number | null;
  label?: string;
}

interface ResistanceBoxesProps {
  beforeOrChange?: Datum;
  after?: Datum;
  handleEnvTypeGroupChange: (envTypeGroup: EnvironmentTypeGroup) => void;
  groupToSelect?: EnvironmentTypeGroup;
}

export function ResistanceBoxes({
  beforeOrChange,
  after,
  handleEnvTypeGroupChange,
  groupToSelect,
}: ResistanceBoxesProps) {
  const { metricMode, processMode, activeChartUnit } = useOverviewContext();

  const changeDirection = getChangeDirection(beforeOrChange, after, metricMode);
  const renderBeforeAsWhite = processMode === ProcessMode.AFTER && !isNil(after?.metric);
  const renderAfterAsWhite = processMode === ProcessMode.BEFORE && !isNil(beforeOrChange?.metric);
  const showBeforeArrow = renderBeforeAsWhite && changeDirection !== undefined;
  const showAfterArrow = processMode === ProcessMode.BEFORE && after !== undefined && changeDirection !== undefined;
  const beforeLabelShown = isBeforeOrChangePresent(beforeOrChange);
  const afterLabelShown = after && after.metric !== null && metricMode !== MetricMode.REDUCTION;

  const onLabelClick = useCallback(
    (envTypeGroup: EnvironmentTypeGroup) => {
      if (afterLabelShown) {
        handleEnvTypeGroupChange(envTypeGroup);
      } else if (groupToSelect) {
        handleEnvTypeGroupChange(groupToSelect);
      }
    },
    [afterLabelShown, groupToSelect, handleEnvTypeGroupChange],
  );

  return (
    <Flex gap={theme.spacing[4]}>
      {(beforeLabelShown || !afterLabelShown) && (
        <Flex
          flexDirection="column"
          width="100px"
          onClick={() => {
            onLabelClick(ComparableEnvGroupType.RAW_WASTEWATER);
          }}
          style={{ cursor: afterLabelShown || !beforeOrChange?.metric ? 'pointer' : 'default' }}
        >
          <OverviewMetricLabel
            id="influentLevelContainer"
            level={beforeOrChange?.level ?? null}
            metricMode={metricMode}
            metric={beforeOrChange?.metric ?? null}
            renderAsWhite={renderBeforeAsWhite}
            style={{
              position: 'relative',
            }}
            activeChartUnit={activeChartUnit}
            showAlways={!afterLabelShown}
          >
            {showBeforeArrow && (
              <ArrowContainer right>
                {changeDirection < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeDirection > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            )}
          </OverviewMetricLabel>
          {beforeOrChange?.label && <FluentsTextContainer>{beforeOrChange.label}</FluentsTextContainer>}
        </Flex>
      )}
      {afterLabelShown && (
        <Flex
          flexDirection="column"
          width="100px"
          onClick={() => {
            onLabelClick(ComparableEnvGroupType.TREATED_WASTEWATER);
          }}
          style={{ cursor: beforeLabelShown || !after.metric ? 'pointer' : 'default' }}
        >
          <OverviewMetricLabel
            id="effluentLevelContainer"
            level={after.level ?? null}
            metricMode={metricMode}
            metric={after.metric}
            renderAsWhite={renderAfterAsWhite}
            activeChartUnit={activeChartUnit}
            showAlways={!beforeLabelShown}
          >
            {showAfterArrow && (
              <ArrowContainer>
                {changeDirection < 0 ? (
                  <DownRightIcon style={{ color: getChangeIndicatorColor(-1) + 'A0' }} />
                ) : changeDirection > 0 ? (
                  <UpRightIcon style={{ color: getChangeIndicatorColor(1) + 'A0' }} />
                ) : (
                  <RightIcon style={{ color: getChangeIndicatorColor(0) + 'A0' }} />
                )}
              </ArrowContainer>
            )}
          </OverviewMetricLabel>
          {after.label && <FluentsTextContainer>{after.label}</FluentsTextContainer>}
        </Flex>
      )}
    </Flex>
  );
}

function getChangeDirection(
  firstLevel: Datum | undefined,
  secondLevel: Datum | undefined,
  metricMode: MetricMode,
): -1 | 1 | 0 | undefined {
  const roundedBeforeMetric = !isNil(firstLevel?.metric)
    ? Number(firstLevel.metric.toFixed(metricMode === MetricMode.ARGI || metricMode === MetricMode.REDUCTION ? 1 : 0))
    : null;
  const roundedAfterMetric = !isNil(secondLevel?.metric)
    ? Number(secondLevel.metric.toFixed(metricMode === MetricMode.ARGI || metricMode === MetricMode.REDUCTION ? 1 : 0))
    : null;
  return isNil(roundedAfterMetric) || isNil(roundedBeforeMetric) || metricMode === MetricMode.REDUCTION
    ? undefined
    : roundedAfterMetric === roundedBeforeMetric
      ? 0
      : roundedAfterMetric > roundedBeforeMetric
        ? 1
        : -1;
}

const FluentsTextContainer = styled.div`
  color: ${theme.colors.neutral500};
  text-align: center;
  font-size: ${theme.fontSize.extraSmall};
  font-weight: ${theme.fontWeight.heavy};
`;

const ArrowContainer = styled.div<{ right?: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${props => (props.right ? 'right: 10px;' : 'left: 8px;')}
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
`;
