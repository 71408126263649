import { Flex } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  getGenesAndCopyNumbers,
  getNumDetectedAndAnalysedAssays,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { useContainerWidth } from '@resistapp/client/hooks/use-container-width';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { L1Target, L2Target } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { MetricMode } from '@resistapp/common/types';
import { ReactNode } from 'react';
import { MetricTitle } from '../../shared/overview-metric-title';
import { CopyNumberBarGraph } from '../copy-number-bar-graph';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteGeneDetailBox } from '../site-gene-detail-box';
import { graphMargins } from './consts';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';
import { detailGraphHeight } from './site-details-utils';

type Props = SiteDetailsBaseProps & {
  target: MinorTarget | L1Target | L2Target; // TODO support gene group bars in reduction mode
  title: string;
  titleExplanation?: ReactNode;
  noGenesText: string;
  id?: string;
  italic?: boolean;
  customExplanation?: (params: {
    unit: ReactNode;
    detectedCount: number;
    inRawOrEffluentSamples: string | undefined;
  }) => ReactNode;
};

export function SiteDetailBarGraph({
  containerRef,
  target,
  title,
  titleExplanation,
  noGenesText,
  id,
  italic,
  customExplanation,
}: Props) {
  const { metricMode, effectiveSiteDetailsProcessMode, selectedSiteDatum, activeOverviewConfiguration } =
    useOverviewContext();
  const { getGroup, allAssays } = useAssayContext();
  const containerWidth = useContainerWidth(containerRef);

  if (!selectedSiteDatum) {
    return null;
  }

  const { detectedCount, analysedCount } = getNumDetectedAndAnalysedAssays(
    selectedSiteDatum,
    target,
    effectiveSiteDetailsProcessMode,
    getGroup,
  );
  const width = (containerWidth || 130) - graphMargins;

  if (detectedCount === 0) {
    return (
      <SiteDetailContainer ref={containerRef} id={id}>
        <SiteDetailTitle title={title} explanation={noGenesText} counts={{ detectedCount, analysedCount }} />
        <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
          <SiteGeneDetailBox target={target} />
        </Flex>
      </SiteDetailContainer>
    );
  }

  const genesAndCopyNumbers = getGenesAndCopyNumbers(
    selectedSiteDatum,
    target,
    metricMode,
    effectiveSiteDetailsProcessMode,
    getGroup,
  );
  const friendlyGenesAndNumbers = activeOverviewConfiguration.getFriendlyNumber(
    genesAndCopyNumbers,
    selectedSiteDatum,
    target,
    allAssays,
    getGroup,
  );

  const unit =
    metricMode === MetricMode.REDUCTION ? (
      <MetricTitle noAntibiotic={true} plural={detectedCount > 1} />
    ) : (
      'Copy number'
    );

  const _inRawOrEffluentSamples = get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode);

  const explanation = customExplanation
    ? customExplanation({
        unit,
        detectedCount,
        inRawOrEffluentSamples: _inRawOrEffluentSamples,
      })
    : titleExplanation || (
        <span>
          {unit} of independently detected genes{_inRawOrEffluentSamples ? `, ${_inRawOrEffluentSamples}` : ''}
        </span>
      );

  return (
    <SiteDetailContainer ref={containerRef} id={id}>
      <SiteDetailTitle title={title} explanation={explanation} counts={{ detectedCount, analysedCount }} />
      {containerWidth && (
        <CopyNumberBarGraph
          genesAndNumbers={friendlyGenesAndNumbers}
          width={width}
          height={detailGraphHeight}
          events={false}
          metricMode={metricMode}
          activeOverviewConfiguration={activeOverviewConfiguration}
          italic={italic}
        />
      )}
    </SiteDetailContainer>
  );
}
