import { Box } from '@chakra-ui/react';
import { getMarkerSize, ProcessMarker } from '@resistapp/client/components/map/markers/process-marker';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useMapContext } from '@resistapp/client/contexts/map-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { getMetricAndLevel } from '@resistapp/client/utils/metric-utils';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureLocalStartOfMonth,
  ensureLocalStartOfNextMonth,
  type StandardDateFormat,
} from '@resistapp/common/friendly';
import { getAllOriginalEnvironmentIds, MetricMode } from '@resistapp/common/types';
import { useTooltip } from '@visx/tooltip';
import { ScaleTime } from 'd3-scale';
import { get, isNil } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { chartLeftMargin, positioning } from './chart-styles';
import { getGradientScale, getResistanceIndexScale } from './scales';

export function useTrendchartTooltip(
  timeScale: ScaleTime<number | undefined, number | undefined> | undefined,
  shownAdminLevel: number | null | undefined,
  containerRef: React.RefObject<HTMLDivElement>,
) {
  const { queryFilters } = useSampleDataContext();
  const { getGroup, allAssays } = useAssayContext();
  const {
    hoveredAreaOrSiteEnvId,
    setHoveredAreaOrSiteEnvIdStable,
    trendData,
    metricMode,
    processMode,
    selectedSiteDatum,
    activeChartUnit,
    activeOverviewConfiguration,
  } = useOverviewContext();
  const { changeZoomedAdminAreaStable } = useMapContext();
  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<OverviewDatum>();

  const positionTooltip = useCallback(
    (datum: OverviewDatum) => {
      const [metric] = getMetricAndLevel(
        datum,
        queryFilters.filters.selectedTargets,
        metricMode,
        processMode,
        activeChartUnit,
        getGroup,
        allAssays,
      );

      if (isNil(metric)) {
        return;
      }
      if (!timeScale) {
        throw Error('Unexpectedly moved mouse without timescale');
      }

      const containerRect = containerRef.current?.getBoundingClientRect() || { height: 0 };
      const graphHeight = containerRect.height - positioning.margin.top - positioning.margin.bottom;
      const indexScale =
        metricMode === MetricMode.ARGI
          ? getResistanceIndexScale(graphHeight)
          : getGradientScale(graphHeight, [
              activeOverviewConfiguration.detailBarGraphMin,
              activeOverviewConfiguration.detailBarGraphMax(),
            ]);
      const shadowWidth = 2;
      const markerSize = getMarkerSize();
      const leftOffset = markerSize.width / 2 + shadowWidth - markerSize.chartOffset;
      const topOffset = markerSize.height - 2 * shadowWidth;
      const b = indexScale(metric);

      showTooltip({
        tooltipLeft: (timeScale(new Date(datum.date.toString())) ?? 0) + leftOffset,
        tooltipTop: b - topOffset,
        tooltipData: datum,
      });
    },
    [
      timeScale,
      showTooltip,
      activeOverviewConfiguration,
      metricMode,
      processMode,
      activeChartUnit,
      queryFilters.filters.selectedTargets,
      allAssays,
      getGroup,
    ],
  );

  const prevEnvRef = useRef(hoveredAreaOrSiteEnvId);
  useEffect(() => {
    if (trendData && prevEnvRef.current !== hoveredAreaOrSiteEnvId) {
      prevEnvRef.current = hoveredAreaOrSiteEnvId;
      const envData = trendData.find(data => data[0]?.environment.id === hoveredAreaOrSiteEnvId);

      if (!envData?.length) return;

      // Filter to get the hovered site datum or last datum within the selected time range
      const filteredData = envData.filter(datum => {
        const datumDate = new Date(datum.date);
        return datumDate >= queryFilters.filters.interval.start && datumDate <= queryFilters.filters.interval.end;
      });

      const lastDatum = get(filteredData, filteredData.length - 1, undefined);
      if (lastDatum) {
        positionTooltip(lastDatum);
      }
    }
  }, [
    positionTooltip,
    trendData,
    hoveredAreaOrSiteEnvId,
    queryFilters.filters.interval.start,
    queryFilters.filters.interval.end,
  ]);

  const mouseMoveHandler = useCallback(
    (data: OverviewDatum[], event: React.MouseEvent<SVGElement>) => {
      const { offsetX } = event.nativeEvent;

      if (!timeScale) {
        throw Error('Unexpectedly moved mouse without timescale');
      }

      const closestDatum = data.reduce((prev, curr) => {
        const currentTimePoint = timeScale(new Date(curr.date));
        const previousTimePoint = timeScale(new Date(prev.date));
        if (isNil(currentTimePoint) || isNil(previousTimePoint)) {
          console.error(`Unexpectedly nill time`, typeof curr, curr, curr.date, typeof prev, prev, prev.date);
          return prev;
        }
        const currentXPoint = currentTimePoint + chartLeftMargin;
        const previousXPoint = previousTimePoint + chartLeftMargin;

        return Math.abs(currentXPoint - offsetX) < Math.abs(previousXPoint - offsetX) ? curr : prev;
      });

      positionTooltip(closestDatum);
      setHoveredAreaOrSiteEnvIdStable(closestDatum.environment.id);
    },
    [timeScale, positionTooltip, setHoveredAreaOrSiteEnvIdStable],
  );

  const { toggleEnvironmentStable, setIntervalStable, filters } = queryFilters;
  const mouseClickHandler = useCallback(
    (data: OverviewDatum | OverviewDatum[]) => {
      // The handler receives multiple data points when clicking on the quartile range of an area trend line
      const oneDataPoint = Array.isArray(data) ? data[data.length - 1] : data;

      if (selectedSiteDatum) {
        // Find the trend data for this environment
        const envTrendData = trendData?.find(trend => trend[0]?.environment.id === oneDataPoint.environment.id);
        if (!envTrendData?.length) return;

        // Check if this is the last data point
        const isLastDataPoint = envTrendData[envTrendData.length - 1].date === oneDataPoint.date;

        if (isLastDataPoint) {
          // Remove the interval by setting it to default values
          setIntervalStable(DEFAULT_START_INTERVAL, DEFAULT_END_INTERVAL);
        } else {
          const startOfMonth = ensureLocalStartOfMonth(oneDataPoint.date as StandardDateFormat);
          const startOfNextMonth = ensureLocalStartOfNextMonth(startOfMonth);
          setIntervalStable(startOfMonth, startOfNextMonth);
        }
      } else if (shownAdminLevel) {
        const nextAdminLevel = Object.values(oneDataPoint.environment.adminLevels ?? {}).find(
          d => Number(d.level) === shownAdminLevel,
        );
        if (nextAdminLevel) {
          changeZoomedAdminAreaStable(nextAdminLevel);
        }
      } else {
        const envId = oneDataPoint.environment.id;
        !isEnvironmentSelected(envId, filters.selectedEnvironmentIdsOrdered) &&
          toggleEnvironmentStable(
            getAllOriginalEnvironmentIds(oneDataPoint.environment, oneDataPoint.environmentAfter),
            true,
          );
      }
    },
    [filters.selectedEnvironmentIdsOrdered, shownAdminLevel, selectedSiteDatum, trendData],
  );

  const onMouseLeave = useCallback(() => {
    hideTooltip();
    setHoveredAreaOrSiteEnvIdStable(undefined);
  }, [hideTooltip, setHoveredAreaOrSiteEnvIdStable]);

  const TooltipComponentForARGIMarker = useCallback(() => {
    if (!tooltipOpen || !tooltipData) {
      return null;
    }

    return (
      <Box position="absolute" top={tooltipTop} left={(tooltipLeft || 0) + 20} id="tooltip-component">
        <ProcessMarker
          overviewDatum={tooltipData}
          position="chart"
          dateLabel={!!selectedSiteDatum}
          onClick={() => {
            mouseClickHandler(tooltipData);
          }}
          onMouseEnter={() => {
            !selectedSiteDatum && setHoveredAreaOrSiteEnvIdStable(undefined);
          }}
        />
      </Box>
    );
  }, [tooltipOpen, tooltipData, tooltipLeft, tooltipTop, mouseClickHandler, selectedSiteDatum]);
  const TooltipComponentForRiskScore = TooltipComponentForARGIMarker;
  const TooltipComponentForReduction = TooltipComponentForARGIMarker;

  return {
    mouseMoveHandler,
    mouseClickHandler,
    onMouseLeave,
    TooltipComponentForARGIMarker,
    TooltipComponentForRiskScore,
    TooltipComponentForReduction,
    hideTooltip,
    tooltipData,
  };
}

function isEnvironmentSelected(envId: number, selectedEnvironmentIdsOrdered: number[]) {
  return selectedEnvironmentIdsOrdered.length === 1 && envId === selectedEnvironmentIdsOrdered[0];
}
