import { Kbd, ListItem, UnorderedList } from '@chakra-ui/react';
import { getEnvColorById } from '@resistapp/client/components/shared/palettes';
import { useChartDownload } from '@resistapp/client/contexts/chart-download-context';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { useNormalisationMode } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { FullProject, FullSample, NormalisationMode } from '@resistapp/common/types';
import { getSampleSortValue } from '@resistapp/common/utils';
import { scaleOrdinal } from '@visx/scale';
import { isWithinInterval } from 'date-fns';
import { chain, Dictionary, findKey } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { ChartHotkeyInstructions } from '../../tooltips/chart-hotkey-instructions';
import { getSampleSelector, getVisualSelectionState, VisualSelectionStatus } from '../bar-box-map/utils';
import { BaseLegend } from './base-legend';
import { KeysPressOptions, Legend } from './legend';

interface SampleLegendProps {
  toggleEnvironment: (envId: number | number[], removeOldSelections: boolean) => void;
  filters: Filters;
  project: FullProject;
  legendHeight: string;
  id?: string;
  showSampleNumbers?: boolean;
}

export function SampleLegend(props: SampleLegendProps) {
  const { project, filters, toggleEnvironment, showSampleNumbers = false } = props;
  const {
    samplesBeingSelected,
    setSamplesBeingSelected,
    focusedSamplesByEnvId,
    environmentsForSelectedEnvTypeGroup,
    adminFeatures,
    sequentialSampleIndexes,
    areSampleNumbersUnique,
    queryFilters,
  } = useResearchContext();
  const nameById = useMemo(
    () =>
      chain(environmentsForSelectedEnvTypeGroup)
        .keyBy(e => e.id)
        .mapValues(env => env.name)
        .value(),
    [environmentsForSelectedEnvTypeGroup],
  );
  const normalisationMode = useNormalisationMode();
  const { isAdmin } = useUser();
  const { downloadChartAndLegend } = useChartDownload();

  const activeKeys = useRef<KeysPressOptions | undefined>();
  // Create a mapping of environment ID to sample for sorting
  const sampleAndSortIndexByEnvId = useMemo(() => {
    return chain(environmentsForSelectedEnvTypeGroup)
      .keyBy(env => env.id)
      .mapValues(env => {
        const sampleUID = findKey(project.samplesByUID, samples => samples[0].environment.id === env.id);
        if (!sampleUID) {
          return null;
        }
        const sample = project.samplesByUID[sampleUID][0];
        const sortIndex = sequentialSampleIndexes[sample.id];
        return { sample, sortIndex };
      })
      .pickBy() // Remove null values
      .value() as Dictionary<{
      sample: FullSample;
      sortIndex: number;
    }>;
  }, [environmentsForSelectedEnvTypeGroup, project.samplesByUID, sequentialSampleIndexes]);

  // Sort the environment IDs by their samples' sort indexes
  const sortedEnvironmentIds = useMemo(() => {
    // Convert environmentIds to an array of objects with id and sortIndex
    const envWithIndex = environmentsForSelectedEnvTypeGroup.map(env => ({
      id: env.id,
      sortIndex: sampleAndSortIndexByEnvId[env.id].sortIndex || 999999,
    }));

    // Sort by sortIndex and extract just the ids
    return envWithIndex.sort((a, b) => a.sortIndex - b.sortIndex).map(item => item.id);
  }, [environmentsForSelectedEnvTypeGroup, sampleAndSortIndexByEnvId]);

  const sampleLabelByEnvId = useMemo(
    () =>
      chain(environmentsForSelectedEnvTypeGroup)
        .keyBy(env => `${env.id}`)
        .mapValues(env => {
          if (!showSampleNumbers) {
            return env.name;
          } else {
            const sample =
              project.samplesByUID[
                Object.keys(project.samplesByUID).find(uid => project.samplesByUID[uid][0].environment.id === env.id) ||
                  ''
              ][0];
            const rawSortValue = getSampleSortValue(sample, project.id);
            const displayIndex = areSampleNumbersUnique
              ? sample.number
              : sequentialSampleIndexes[sample.id] || rawSortValue;
            return `${displayIndex} - ${env.name}`;
          }
        })
        .value(),
    [
      environmentsForSelectedEnvTypeGroup,
      showSampleNumbers,
      project.samplesByUID,
      project.id,
      sequentialSampleIndexes,
      areSampleNumbersUnique,
    ],
  );
  const envColorById = getEnvColorById(project);

  // Use sortedEnvironmentIds instead of environmentIds for palette
  const palette = sortedEnvironmentIds.map(id => envColorById[Number(id)]);
  const scale = scaleOrdinal<string, string>({
    domain: sortedEnvironmentIds.map(String),
    range: palette,
  });

  const { selectedEnvironmentIdsOrdered } = filters;

  // Determine which environment IDs have data within the current time interval
  const environmentIdsWithDataInTimeRange = useMemo(() => {
    const result = new Set<string>();

    Object.values(project.samplesByUID).forEach(samples => {
      const sample = samples[0];
      if (sample.time && isWithinInterval(new Date(sample.time), filters.interval)) {
        result.add(String(sample.environment.id));
      }
    });

    return result;
  }, [project.samplesByUID, filters.interval]);

  const getOpacity = useCallback(
    (_envId: string | number) => {
      const environmentId = +_envId;
      const visualStatus = getVisualSelectionState(
        environmentId,
        selectedEnvironmentIdsOrdered,
        samplesBeingSelected,
        environmentsForSelectedEnvTypeGroup.map(e => e.id),
      );

      // Also check if the environment has data in the current time range
      const hasDataInTimeRange = environmentIdsWithDataInTimeRange.has(String(environmentId));

      // Legend items are dimmed if deselected OR pending removal OR if they have no data in the current range
      return visualStatus === VisualSelectionStatus.DESELECTED ||
        visualStatus === VisualSelectionStatus.PENDING_REMOVE ||
        !hasDataInTimeRange
        ? 0.4
        : 1;
    },
    [
      samplesBeingSelected,
      selectedEnvironmentIdsOrdered,
      environmentIdsWithDataInTimeRange,
      environmentsForSelectedEnvTypeGroup,
    ],
  );

  const onClick = useCallback(
    (envId: number | string, keys: KeysPressOptions) => {
      const selector = getSampleSelector(
        selectedEnvironmentIdsOrdered,
        environmentsForSelectedEnvTypeGroup.map(e => e.id),
        samplesBeingSelected,
        setSamplesBeingSelected,
        toggleEnvironment,
        true,
        false,
        filters,
        queryFilters.setIntervalStable,
        project,
      );
      selector(envId, keys);
      activeKeys.current = keys;
    },
    [
      filters,
      project,
      environmentsForSelectedEnvTypeGroup,
      toggleEnvironment,
      samplesBeingSelected,
      setSamplesBeingSelected,
      selectedEnvironmentIdsOrdered,
      queryFilters.setIntervalStable,
    ],
  );
  const labelFormat = (envId: string | number) => (showSampleNumbers ? sampleLabelByEnvId[envId] : nameById[envId]);
  const getSamplesByEnvId = (label: string | number) => focusedSamplesByEnvId[Number(label)];
  const { description, valueTip } = getNormalisationDescription(normalisationMode);

  // Update focusedEnvironmentIds to only include environment IDs that have data in the time range
  const focusedEnvironmentIds = useMemo(() => {
    const baseIds = selectedEnvironmentIdsOrdered.length > 0 ? selectedEnvironmentIdsOrdered : sortedEnvironmentIds;

    // Include all IDs, but client code will use the opacity function to gray out those without data
    return baseIds;
  }, [selectedEnvironmentIdsOrdered, sortedEnvironmentIds]);

  // Get all unique statuses from focused samples to determine initial status
  const sampleStatus = useMemo(() => {
    const focusedSamples = Object.values(props.project.focusedByUID || {}).flat();
    const uniqueStatuses = [...new Set(focusedSamples.map(s => s.status))];
    return uniqueStatuses.length === 1 ? uniqueStatuses[0] : ' ';
  }, [props.project.focusedByUID]);

  return (
    <BaseLegend
      id={props.id}
      header="Samples"
      description={description}
      optionMinimum={2}
      TooltipContent={
        <div>
          {valueTip ? <div style={{ marginBottom: '10px' }}>{valueTip}</div> : null}
          <UnorderedList style={{ marginBottom: '20px' }}>
            <ListItem>
              <Kbd>Click</Kbd> a label or chart bar to select a sample
            </ListItem>
            <ChartHotkeyInstructions label="samples" />
          </UnorderedList>
        </div>
      }
      sampleStatuses={
        isAdmin && adminFeatures.isModifyAsAdminActive
          ? {
              update: adminFeatures.updateSampleStatuses,
              status: sampleStatus,
            }
          : undefined
      }
      canUpdateSampleStatuses={adminFeatures.isModifyAsAdminActive && isAdmin}
      onDownload={
        props.id
          ? () => {
              console.log('Sample legend download icon clicked, id:', props.id);
              downloadChartAndLegend({
                legendId: props.id,
                chartId: 'research-view-samples-chart',
                plotType: 'box',
                label: 'Samples',
              });
              console.log('downloadChartAndLegend triggered');
            }
          : undefined
      }
    >
      <Legend
        colorScale={scale}
        labelFormat={labelFormat}
        getOpacity={getOpacity}
        onClick={onClick}
        height={props.legendHeight}
        getSamplesByEnvId={adminFeatures.isModifyAsAdminActive ? getSamplesByEnvId : undefined}
        focusedEnvironmentIds={focusedEnvironmentIds}
        updateSampleStatuses={adminFeatures.updateSampleStatuses}
        filters={filters}
      />
    </BaseLegend>
  );
}

function getNormalisationDescription(mode: NormalisationMode): { description: string; valueTip: string } {
  switch (mode) {
    case NormalisationMode.MG_SS:
      return {
        description: 'Copies / mg of SS (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per mg of suspended solids',
      };
    case NormalisationMode.HOUR:
      return {
        description: 'Copies per hour (log)',
        valueTip: 'The graph presents Log₁₀ of copies per hour (flow normalised)',
      };
    case NormalisationMode.LITRE:
      return {
        description: 'Copies per litre (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per litre',
      };
    case NormalisationMode.MG_BOD:
      return {
        description: 'Copies / mg BOD (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per mg of biochemical oxygen demand',
      };
    case NormalisationMode.TEN_UL_DILUTED_DNA:
      return {
        description: 'Analysed copies (log)',
        valueTip:
          'The graph presents Log₁₀ of the numbers of gene copies in 10 uL of diluted DNA analysed on the SmartChip qPCR run.',
      };
    case NormalisationMode.SIXTEEN_S:
      return {
        description: 'Relative abundance (log)',
        valueTip: 'The graph presents Log₁₀ of abundance, relative to 16S rRNA genes',
      };
  }
}
