export const strokeColor = '#e0e0e0';
export const leftLegendOffset = -56;
export const chartLeftMargin = 106;
// The x-offset to apply to axis text when no color legend is present
export const emptyLegendTextXOffset = 40;

export const positioning = {
  leftLegend: {
    textX: -3,
    textY: 5,
  },
  bottomLegend: {
    positionY: 10,
    textY: 8,
    textX: 0,
  },
  margin: {
    top: 40,
    right: 30,
    bottom: 50,
    left: chartLeftMargin,
    legendLeft: chartLeftMargin + leftLegendOffset,
    legendBottom: 50,
  },
};
