import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { L2TargetOrAssay, L2Targets } from '@resistapp/common/assays';
import { friendlyCopyNumber, friendlyFoldChange } from '@resistapp/common/friendly';
import { ClipPath } from '@visx/clip-path';
import { Group } from '@visx/group';
import { PatternLines } from '@visx/pattern';
import { Bar } from '@visx/shape';
import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { MouseEvent } from 'react';
import { theme } from '../../../shared/theme';
import { PlotTooltip, usePlotTooltip } from '../../../tooltips/plot-tooltip';
import { L2TargetTooltipContent } from './reduction-tooltip';
import { textPadding } from './treated-and-reduced-bar-graph';
import { SingleBarDatum } from './types';

interface L2TargetBarProps {
  datum: SingleBarDatum;
  barY: number;
  barHeight: number;
  beforeWidth: number;
  afterWidth: number;
  baseBarColor: string;
  changeBarColor: string;
  tooltipStuff: UseTooltipInPortal;
  hoveredTarget: L2TargetOrAssay | null;
  setHoveredTarget: (target: L2TargetOrAssay | null) => void;
  xScale: (value: number) => number;
}

export function ReductionBar({
  datum,
  barY,
  barHeight,
  beforeWidth,
  afterWidth,
  baseBarColor,
  changeBarColor,
  tooltipStuff,
  hoveredTarget,
  setHoveredTarget,
  xScale,
}: L2TargetBarProps) {
  const { queryFilters } = useSampleDataContext();
  const { activeOverviewConfiguration } = useOverviewContext();
  const { allGeneGroups } = useAssayContext();
  const { tooltipData, tooltipProps, handleMouseMove, hideTooltip } = usePlotTooltip<SingleBarDatum>(tooltipStuff);

  const hasRealAfterData = !!datum.afterTotal;
  const hasRealBeforeData = !!datum.beforeTotal;
  // Check if we need to show diagonal lines
  const shouldShowDiagonalLines = datum.isDecrease
    ? !hasRealAfterData // For decrease bars: show when no treated (after) data
    : !hasRealBeforeData; // For increase bars: show when no raw (before) data

  const opacityModifier =
    queryFilters.filters.selectedTargets.length === 16 || // Hack: default all targets selected
    queryFilters.filters.selectedTargets.includes(datum.barIdentifier as L2Targets)
      ? 1
      : 0.5;

  const onMouseEnter = (event: MouseEvent<SVGElement>) => {
    setHoveredTarget(datum.barIdentifier as L2Targets);
    handleMouseMove(event, datum, true);
  };
  const onMouseLeave = () => {
    setHoveredTarget(null);
    hideTooltip();
  };
  const onMouseMove = (event: MouseEvent<SVGElement>) => {
    handleMouseMove(event, datum);
  };
  const onClick = () => {
    queryFilters.toggleSingleTarget(datum.barIdentifier as L2Targets);
  };

  // Feedback: Screenshots would benefit from always showing these (but it looks quite crowded and makes the legend ambiguous)
  const isHovered = hoveredTarget === (datum.barIdentifier as L2Targets);

  // Format the reduction label with fold change and percentage
  const foldChangeText = friendlyFoldChange(datum.log10Reduction, 1, true);

  // Determine the color for the highest inlet/outlet wastewater figure
  const barTopFigureColor = datum.isDecrease ? theme.colors.neutral500 : theme.colors.red800;

  // Determine the maximum width for the overlay
  const maxWidth = Math.max(beforeWidth, afterWidth);
  // Add extra space for the outside text label
  const maxTextWidth = friendlyCopyNumber(datum.isDecrease ? datum.beforeTotal : datum.afterTotal).length * 8;
  const overlayWidth = maxWidth + textPadding + maxTextWidth;

  // Generate unique IDs for pattern and clippath
  const patternId = `diagonal-pattern-${datum.barIdentifier}`;
  const clipPathId = `clip-path-${datum.barIdentifier}`;

  // Define the specific positions for the diagonal pattern
  const minValue = 10000; // 10k
  const loqValue = 100000; // 100k (LOQ)
  const minX = xScale(minValue);
  const loqX = xScale(loqValue);
  const diagonalWidth = loqX - minX;

  // IMPORTANT: For the current bar layout, we need to understand that:
  // 1. In TreatedAndReducedBarGraph.tsx, the values are passed like this:
  //    beforeWidth={data.isDecrease ? beforeWidth : afterWidth}
  //    afterWidth={data.isDecrease ? afterWidth : beforeWidth}
  // 2. This means:
  //    - For decrease bars: beforeWidth = raw, afterWidth = treated
  //    - For increase bars: beforeWidth = treated, afterWidth = raw
  //    So the parameters are swapped for increase bars!

  // Calculate label position and visibility
  const { shouldShowLabel, labelX } = calculateLabelPosition({
    hasRealAfterData,
    hasRealBeforeData,
    isDecrease: datum.isDecrease,
    beforeWidth,
    afterWidth,
    loqX,
  });

  return (
    <Group key={datum.barIdentifier}>
      {/* Diagonal line pattern and clip path for bars with no data */}
      {shouldShowDiagonalLines && (
        <>
          <PatternLines
            id={patternId}
            height={8}
            width={8}
            stroke="#FFFFFF" // Pure white
            strokeWidth={1}
            orientation={['diagonal']}
          />
          {/* The diagonal lines should only be shown in the area where we don't have data */}
          <ClipPath id={clipPathId}>
            <rect x={minX} y={barY} width={diagonalWidth} height={barHeight} rx={3} />
          </ClipPath>
        </>
      )}

      {/* Bar for raw samples */}
      <Bar
        x={0}
        y={barY}
        width={beforeWidth}
        height={barHeight}
        fill={changeBarColor}
        opacity={0.7 * opacityModifier}
        rx={3}
        cursor="pointer"
      />

      {/* Bar for treated samples */}
      <Bar
        x={0}
        y={barY}
        width={afterWidth}
        height={barHeight}
        fill={baseBarColor}
        opacity={opacityModifier}
        rx={3}
        cursor="pointer"
      />

      {/* Diagonal line overlay for bars with no data */}
      {shouldShowDiagonalLines && (
        <rect
          x={minX}
          y={barY}
          width={diagonalWidth}
          height={barHeight}
          fill={`url(#${patternId})`}
          clipPath={`url(#${clipPathId})`}
          cursor="pointer"
        />
      )}

      {/* Text label for raw samples - outside the bar, visible only on hover */}
      <text
        x={beforeWidth + textPadding}
        y={barY + barHeight / 2}
        dy=".32em"
        fontSize={12}
        fill={barTopFigureColor}
        fontWeight={theme.fontWeight.bold}
        cursor="pointer"
        style={{
          opacity: isHovered || tooltipData ? 1 : 0,
        }}
      >
        {friendlyCopyNumber(datum.isDecrease ? datum.beforeTotal : datum.afterTotal)}
      </text>

      {/* Text label for treated samples - inside the bar near its right edge */}
      {afterWidth > 50 && hasRealAfterData && (
        <text
          x={afterWidth - textPadding}
          y={barY + barHeight / 2}
          dy=".32em"
          dx="-.32em"
          fontSize={12}
          fill={theme.colors.neutral100}
          fontWeight={theme.fontWeight.bold}
          textAnchor="end"
          cursor="pointer"
        >
          {friendlyCopyNumber(datum.isDecrease ? datum.afterTotal : datum.beforeTotal)}
        </text>
      )}

      {/* Reduction/Increase label - fold change */}
      {shouldShowLabel && (
        <text
          x={labelX}
          y={barY + barHeight / 2}
          dy=".32em"
          fontSize={12}
          fill={activeOverviewConfiguration.getFriendlyTextColor(-1)}
          fontWeight={datum.isDecrease ? theme.fontWeight.bold : theme.fontWeight.extraHeavy}
          textAnchor="middle"
          cursor="pointer"
        >
          {foldChangeText}
        </text>
      )}

      {/* Transparent overlay to handle all mouse events */}
      <rect
        x={0}
        y={barY}
        width={overlayWidth}
        height={barHeight}
        fill="transparent"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onClick={onClick}
        cursor="pointer"
      />

      {tooltipData && (
        <PlotTooltip {...tooltipProps} backgroundColor={theme.colors.blue50}>
          <L2TargetTooltipContent
            tooltipData={tooltipData}
            activeOverviewConfiguration={activeOverviewConfiguration}
            allGeneGroups={allGeneGroups}
          />
        </PlotTooltip>
      )}
    </Group>
  );
}

/**
 * Calculate if a label should be shown and determine its position
 *
 * @param hasRealAfterData Whether there is real data for the after/treated bar
 * @param hasRealBeforeData Whether there is real data for the before/raw bar
 * @param isDecrease Whether this is a decrease bar (true) or increase bar (false)
 * @param beforeWidth Width of the before bar (which is raw for decrease, treated for increase)
 * @param afterWidth Width of the after bar (which is treated for decrease, raw for increase)
 * @param loqX X position of the LOQ (100k) line
 * @returns Object with shouldShowLabel and labelX properties
 */
function calculateLabelPosition({
  hasRealAfterData,
  hasRealBeforeData,
  isDecrease,
  beforeWidth,
  afterWidth,
  loqX,
}: {
  hasRealAfterData: boolean;
  hasRealBeforeData: boolean;
  isDecrease: boolean;
  beforeWidth: number;
  afterWidth: number;
  loqX: number;
}) {
  // Check if we need to show diagonal lines
  const shouldShowDiagonalLines = isDecrease
    ? !hasRealAfterData // For decrease bars: show when no treated (after) data
    : !hasRealBeforeData; // For increase bars: show when no raw (before) data

  // For regular bars (no diagonal lines)
  if (!shouldShowDiagonalLines) {
    // Regular logic for bars where we have both values
    const reductionAreaWidth = Math.abs((beforeWidth || 0) - (afterWidth || 0));
    return {
      shouldShowLabel: reductionAreaWidth > 30,
      labelX: ((beforeWidth || 0) + (afterWidth || 0)) / 2, // Center between bars
    };
  }

  // For bars with diagonal lines
  // For both decrease and increase cases, we position the label
  // between the LOQ line and the visible part of the bar
  const visibleBarWidth = beforeWidth;
  const availableWidth = Math.abs(visibleBarWidth - loqX);

  return {
    shouldShowLabel: availableWidth > 40,
    labelX: availableWidth > 40 ? (loqX + visibleBarWidth) / 2 : 0,
  };
}
