import { getSupportedSamplesWithConsistentAdminLevels } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { L2Target } from '@resistapp/common/assays';
import {
  areSamplesAnalysedWithOneHealthPanel,
  countMetricGenes,
} from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { EnvGroup } from '@resistapp/common/comparable-env-groups';
import { FullSample, MetricMode } from '@resistapp/common/types';
import { getCountriesFromSamples } from '@resistapp/common/utils';
import { values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAssayContext } from '../../assay-context';
import { useSampleDataContext } from '../../sample-data-context';

interface UseSupportedSamplesProps {
  availableEnvGroups: EnvGroup[] | undefined;
  metricMode: MetricMode;
  selectedAntibiotic: L2Target | undefined;
  countryParam: string | null;
}

interface UseSupportedSamplesResult {
  supportedSamples: FullSample[] | undefined;
  isOneHealthProject: boolean | undefined;
  metricGeneCnt: number | undefined;
  countries: string[] | undefined;
  selectedCountry: string | undefined;
}

// Overview does not use focussedByUID, because it needs to look at all the genes.
// However, query parameters are still used to keep track of focused samples between overview, sample view and reseach view
export function useSupportedSamples({
  availableEnvGroups,
  metricMode,
  selectedAntibiotic,
  countryParam,
}: UseSupportedSamplesProps): UseSupportedSamplesResult {
  const { data } = useSampleDataContext();
  const { assaysLoaded, getGroup } = useAssayContext();

  const [supportedSamples, setSupportedSamples] = useState<FullSample[] | undefined>();
  const [isOneHealthProject, setIsOneHealthProject] = useState<boolean | undefined>();
  const [metricGeneCnt, setMetricGeneCnt] = useState<number | undefined>();
  const [countries, setCountries] = useState<string[] | undefined>();

  const samplesByUID = data?.samplesByUID;

  const hasMultipleCountries = useMemo(
    () => supportedSamples && getCountriesFromSamples(supportedSamples).length > 1,
    [supportedSamples],
  );
  const selectedCountry = useMemo(() => {
    const samples = values(data?.samplesByUID);
    const country =
      countryParam ??
      (samples.length ? (!hasMultipleCountries ? samples[0][0].environment.country : undefined) : undefined);
    return country || undefined;
  }, [countryParam, hasMultipleCountries, data?.samplesByUID]);

  useEffect(() => {
    if (samplesByUID && availableEnvGroups && assaysLoaded) {
      const supportedSamplesWithNormalisedLevels = getSupportedSamplesWithConsistentAdminLevels(
        samplesByUID,
        availableEnvGroups,
      );
      setSupportedSamples(supportedSamplesWithNormalisedLevels);
      setIsOneHealthProject(areSamplesAnalysedWithOneHealthPanel(samplesByUID));
      setMetricGeneCnt(countMetricGenes(samplesByUID, metricMode, selectedAntibiotic, getGroup));
      const countriesLocal = getCountriesFromSamples(supportedSamplesWithNormalisedLevels);
      setCountries(countriesLocal);
    }
  }, [samplesByUID, metricMode, selectedAntibiotic, availableEnvGroups, assaysLoaded, getGroup]);

  return {
    supportedSamples,
    isOneHealthProject,
    metricGeneCnt,
    countries,
    selectedCountry,
  };
}
