import { Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import {
  EnvironmentSubType,
  getEnvSubTypeBaseString,
  getEnvSubtypePoolingKey,
  isBeforeSample,
  type SampleWithEnvironment,
} from '@resistapp/common/environment-types';
import { getAdminAreaKey, getEnvironmentPoolingKey } from '@resistapp/common/environments';
import { AdminLevelKey, BioRep, PooledEnvHack, Sample } from '@resistapp/common/types';
import { chain, Dictionary, get, isNil, keys, pickBy } from 'lodash';
import { getCountriesFromSamples } from './utils';

/**
 * Pooling samples environments means rewriting their environments with virtual environments that are identical for the samples that are pooled together.
 *
 * Create new raw samples (with untouched abundances) that share an environment for all input samples that with the same pooling criteria.
 * If pooling is not specified, no pooling is performed, and samples are only mapped to new raw samples (with untouched abundances).
 */
export function poolSampleEnvironments<T extends Sample>(
  samples: T[],
  pooling: Pooling | undefined,
  _countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): T[] {
  let mutatingSampleNumberCounter = 1;
  const hasMultipleCountries = getCountriesFromSamples(samples).length > 1;
  const countryNameByAlpha3IfNeeded = hasMultipleCountries ? _countryNameByAlpha3IfNeeded : undefined;

  const pooledSamples = chain(samples)
    .filter(sample => {
      const hasKeys = hasPoolingKeys(sample, pooling, !!countryNameByAlpha3IfNeeded);
      if (pooling?.mode === PoolingMode.THROW_MISSING && !hasKeys) {
        throw new Error(`Sample ${sample.id} is missing ${pooling.type} (${get(pooling, 'level', '')}) pooling key`);
      }
      return hasKeys;
    })
    .groupBy(sample => getPoolingKey(sample, pooling, countryNameByAlpha3IfNeeded))
    .mapValues((samplesToBePooled, key, collection) => {
      const sampleNum = mutatingSampleNumberCounter++;

      // HACK! Use bogus negative ids to satisfy type system just in case some dumbass goes and upserts these in db
      // NOTE: env id should be unique across sites but identical for site samples (though they have different subtypes for building before/after datums fields)
      const envId = -1 - keys(collection).findIndex(k => k === key);
      const sampleId = -sampleNum;
      const pooledSampless = samplesToBePooled.map(sample =>
        getSampleWithPooledMetadata(
          sample,
          sampleNum,
          BioRep.A,
          sampleId,
          envId,
          samplesToBePooled,
          pooling,
          countryNameByAlpha3IfNeeded,
        ),
      );
      return pooledSampless;
    })
    .values()
    .flatten()
    .value() as T[]; // Typescript somehow things this could be T[][]
  return pooledSamples;
}

function getSampleWithPooledMetadata<T extends Sample>(
  sample: T,
  sampleNum: number,
  bioRep: BioRep,
  sampleId: number,
  environmentId: number,
  samplesToBePooled: T[],
  pooling: Pooling | undefined,
  countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): T {
  const keepLatLon = !pooling || pooling.type === PoolingType.SITE || pooling.type === PoolingType.ENVIRONMENT;
  const keepCity = keepLatLon || pooling.type === PoolingType.CITY;
  const keepRegion = keepCity || pooling.type === PoolingType.REGION;
  const keepCountry =
    keepRegion || pooling.type === PoolingType.COUNTRY || pooling.type === PoolingType.SITE_AND_ADMIN_LEVEL;

  const adminLevels = keepLatLon
    ? sample.environment.adminLevels
    : pooling.type === PoolingType.SITE_AND_ADMIN_LEVEL
      ? pickBy(sample.environment.adminLevels, levelDatum => levelDatum.level >= pooling.level)
      : undefined;

  const inferredLat = chain(samplesToBePooled)
    .map(s => s.environment.inferredLat)
    .mean()
    .value();
  const inferredLon = chain(samplesToBePooled)
    .map(s => s.environment.inferredLon)
    .mean()
    .value();

  const uniqSubtypes = chain(samplesToBePooled)
    .map(s => s.environment.subtype)
    .uniq()
    .value();
  const numUniqSubtypes = uniqSubtypes.length;
  const isSitePooling = pooling?.type === PoolingType.SITE && numUniqSubtypes === 2;
  const keepSubtype = keepSubtypeForProcessPairs(samplesToBePooled, uniqSubtypes, isSitePooling);

  const environmentName = isSitePooling
    ? samplesToBePooled.find(isBeforeSample)?.environment.name || ''
    : getPoolName(sample, pooling, countryNameByAlpha3IfNeeded);

  if (!environmentName) {
    throw new Error(`Unexpectedly falsy env name for ${sample.id}`);
  }

  const pooledSample: T = {
    ...sample,
    id: sampleId,
    projects: sample.projects,
    number: sampleNum,
    bioRep,
    environment: {
      name: environmentName,
      type: samplesToBePooled[0].environment.type,
      subtype: keepSubtype ? sample.environment.subtype : undefined,
      id: environmentId,
      inferredLat,
      inferredLon,
      adminLevels,
      city: keepCity ? sample.environment.city : undefined,
      region: keepRegion ? sample.environment.region : undefined,
      country: keepCountry ? sample.environment.country : undefined,
      lat: keepLatLon ? sample.environment.lat : undefined,
      lon: keepLatLon ? sample.environment.lon : undefined,
    },
    environmentId,
    specificLat: keepLatLon ? sample.specificLat : undefined,
    specificLon: keepLatLon ? sample.specificLon : undefined,
  };

  // HACK, see PooledEnvHack
  const pooledSampleEnv = pooledSample.environment as PooledEnvHack;
  pooledSampleEnv.originalEnvironmentId = get(sample.environment, 'originalEnvironmentId', sample.environment.id);
  pooledSampleEnv.originalEnvironmentNames = chain(samplesToBePooled)
    .map(s => get(s.environment, 'originalEnvironmentNames', [s.environment.name]))
    .flatten()
    .uniq()
    .value();

  return pooledSample;
}

function keepSubtypeForProcessPairs(
  samplesToBePooled: Sample[],
  uniqSubtypes: Array<EnvironmentSubType | null | undefined>,
  isSitePooling: boolean,
) {
  const numUniqSubtypes = uniqSubtypes.length;
  const evenNumberOfSamples = samplesToBePooled.length % 2 === 0;
  const numValidProcessPairs = chain(uniqSubtypes)
    .map(subtype => getEnvSubTypeBaseString(subtype))
    .uniq()
    .value().length;
  const numLocations = chain(samplesToBePooled)
    .map(s => `${s.environment.lat},${s.environment.lon}`)
    .uniq()
    .value().length;
  const numTimePoints = chain(samplesToBePooled)
    .map(s => {
      return typeof s.time === 'string' ? s.time : s.time?.toISOString();
    })
    .uniq()
    .value().length;
  const timePointsMatch = samplesToBePooled.length === numTimePoints * numLocations;

  // Do a rudimentary check for whether or not the area only contains process pairs
  const allowBeforeAndAfterAreaAggregation =
    evenNumberOfSamples && numUniqSubtypes / 2 === numValidProcessPairs && timePointsMatch;

  const allowedUniqueSubtypes = isSitePooling ? 2 : allowBeforeAndAfterAreaAggregation ? numUniqSubtypes : 1;
  const keepSubtype = numUniqSubtypes <= allowedUniqueSubtypes;
  return keepSubtype;
}

function hasPoolingKeys(sample: Sample, pooling: Pooling | undefined, countryNeeded: boolean) {
  const countryOk = !countryNeeded || !!sample.environment.country;
  if (pooling?.type === PoolingType.COUNTRY) {
    return countryOk;
  } else if (pooling?.type === PoolingType.REGION) {
    return countryOk && !!sample.environment.region;
  } else if (pooling?.type === PoolingType.CITY) {
    return countryOk && !!sample.environment.city;
  } else if (pooling?.type === PoolingType.SITE_AND_ADMIN_LEVEL) {
    return !!sample.environment.adminLevels?.[`${pooling.level}` as AdminLevelKey];
  } else if (pooling?.type === PoolingType.SITE) {
    return !isNil(sample.environment.lat) && !isNil(sample.environment.lon);
  } else if (pooling?.type === PoolingType.ENVIRONMENT) {
    return !!sample.environment.subtype;
  } else if (pooling?.type === PoolingType.ENVIRONMENT_TYPE || !pooling) {
    return true;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  throw Error(`Unsupported pooling type: ${pooling.type}`);
}

export function getPoolingKey(
  sample: Sample,
  pooling: Pooling | undefined,
  countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): string {
  switch (pooling?.type) {
    case PoolingType.COUNTRY:
    case PoolingType.REGION:
    case PoolingType.CITY:
      return `${sample.environment.type} - ${getPoolName(sample, pooling, countryNameByAlpha3IfNeeded)}`;
    case PoolingType.ENVIRONMENT_TYPE:
      return sample.environment.type;
    case PoolingType.ENVIRONMENT:
      return getEnvironmentPoolingKey(sample);
    case PoolingType.SITE_AND_ADMIN_LEVEL: {
      const adminArea = sample.environment.adminLevels?.[`${pooling.level}` as AdminLevelKey];
      if (!adminArea) {
        throw new Error(`Sample ${sample.id} missing admin level ${pooling.level}`);
      }
      return getAdminAreaKey(adminArea);
    }
    case PoolingType.SITE:
      if (isNil(sample.environment.lat) || isNil(sample.environment.lon)) {
        throw new Error(`Sample ${sample.id} missing lat/lon`);
      }
      return getEnvSubtypePoolingKey(sample as SampleWithEnvironment);
    case undefined: {
      const environment = sample.environment;
      if (!environment.id) {
        throw new Error(`Sample ${JSON.stringify(sample)} missing environment id in getPoolingKey`);
      }
      return `${environment.id}`;
    }
    default:
      throw Error(`Unexpected pooling type ${JSON.stringify(pooling)}`);
  }
}

function getPoolName(
  sample: Sample,
  pooling: Pooling | undefined,
  countryNameByAlpha3IfNeeded: Dictionary<string> | undefined,
): string {
  const countryStr =
    countryNameByAlpha3IfNeeded && sample.environment.country
      ? get(countryNameByAlpha3IfNeeded, sample.environment.country, sample.environment.country)
      : 'Unknown';
  switch (pooling?.type) {
    case PoolingType.COUNTRY:
      return countryStr;
    case PoolingType.REGION:
      return countryNameByAlpha3IfNeeded
        ? `${countryStr} - ${sample.environment.region}`
        : (sample.environment.region as string);
    case PoolingType.CITY:
      return countryNameByAlpha3IfNeeded ? `${countryStr} - ${sample.environment.city}` : `${sample.environment.city}`;
    case PoolingType.ENVIRONMENT_TYPE:
      return sample.environment.type;
    case PoolingType.ENVIRONMENT:
      return sample.environment.name;
    case PoolingType.SITE_AND_ADMIN_LEVEL: {
      const levelName = sample.environment.adminLevels?.[`${pooling.level}` as AdminLevelKey]?.name;
      if (!levelName) {
        // Could be due to the same commented in maxAffordableLevelByCountry ?
        throw new Error(`Sample ${sample.id} missing admin level ${pooling.level}`);
      }
      return levelName;
    }
    case PoolingType.SITE:
      return sample.environment.name;
    case undefined:
      return `${sample.environment.name} (${sample.projects[0].projectId})`;
  }
}
