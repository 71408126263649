import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { createContext, useCallback, useContext, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';

export enum ChartDisplayMode {
  METRIC = 'METRIC', // ARGI, RISK, OR REDUCTION
  GENES = 'GENES', // GENE COPIES
}

interface ChartDisplayModeContextData {
  chartDisplayMode: ChartDisplayMode;
  setChartDisplayMode: (mode: ChartDisplayMode) => void;
  previousMode: ChartDisplayMode | null;
  setPreviousMode: (mode: ChartDisplayMode | null) => void;
  setForcedMode: (mode: ChartDisplayMode) => void;
}

const ChartDisplayModeContext = createContext<ChartDisplayModeContextData | undefined>(undefined);

interface ChartDisplayModeProviderProps {
  children: React.ReactNode;
}

export function ChartDisplayModeProvider({ children }: ChartDisplayModeProviderProps) {
  // Using only the local storage state cause bugs when multiple tabs are open and some are in a state where the switch is disabled
  const [localStorageChartDisplayMode, setChartDisplayModeInLocalStorage] = useLocalStorageState<ChartDisplayMode>(
    LocalStorageId.chartDisplayMode,
    {
      defaultValue: ChartDisplayMode.METRIC,
    },
  );
  const [chartDisplayMode, setChartDisplayModeInternal] = useState<ChartDisplayMode>(localStorageChartDisplayMode);
  const setChartDisplayMode = useCallback(
    (mode: ChartDisplayMode) => {
      setChartDisplayModeInternal(mode);
      setChartDisplayModeInLocalStorage(mode);
    },
    [setChartDisplayModeInLocalStorage],
  );

  // Track the previous mode when forced to switch
  const [previousMode, setPreviousMode] = useState<ChartDisplayMode | null>(null);

  // Helper function to set a forced mode while preserving the previous mode
  const setForcedMode = (mode: ChartDisplayMode) => {
    if (chartDisplayMode !== mode) {
      setPreviousMode(chartDisplayMode);
      setChartDisplayMode(mode);
    }
  };

  const contextValue: ChartDisplayModeContextData = {
    chartDisplayMode,
    setChartDisplayMode,
    previousMode,
    setPreviousMode,
    setForcedMode,
  };

  return <ChartDisplayModeContext.Provider value={contextValue}>{children}</ChartDisplayModeContext.Provider>;
}

export function useChartDisplayMode() {
  const context = useContext(ChartDisplayModeContext);
  if (!context) {
    throw new Error('useChartDisplayMode must be used within a ChartDisplayModeProvider');
  }
  return context;
}
