import { KeysPressOptions } from '@resistapp/client/components/plots/legends/legend';
import { GeneGrouping, L2Target, sixteenS, type GetGroup } from '@resistapp/common/assays';
import { EnvironmentTypeGroup, sampleBelongsToEnvironmentGroup } from '@resistapp/common/comparable-env-groups';
import { FullSample, FullSamplesByUID, NormalisationMode } from '@resistapp/common/types';
import { flattenRelevantAbundances, flattenSamplesByUID, groupBioSamples } from '@resistapp/common/utils';
import { isWithinInterval } from 'date-fns';
import { Dictionary } from 'lodash';

export enum AbunanceSelection {
  ANALYSED = 'ANALYSED',
  QUANTIFIED_AND_TRACES = 'QUANTIFIED_AND_TRACES',
  QUANTIFIED_ONLY = 'QUANTIFIED_ONLY',
}

export interface FilterInterval {
  start: Date;
  end: Date;
}

export interface Filters {
  // Selected environment type or comparable type (and subtype) group (from query params), or all project env types
  // - In research view, selecting multiple samples manually can lead to a custom group of a few (uncomparable) env types being selected at the same time,
  //   but the filter bar only shows this is the case, and does not allow selecting mulitple from the bar itself
  // - In overview one comparable group (including each env type individually) can be selected at a time, since pooling samples accross environment types does not make sense biologically
  // - Contains all project environment types if no query param is selected
  selectedEnvironmentTypeGroup: EnvironmentTypeGroup;
  selectedEnvironmentIdsOrdered: number[]; // Environment IDs in selection order (from query params), or all IDs if no query param
  selectedTargets: L2Target[]; // Selected targets of the selected grouping (from query params), or all targets if no query param (TODO IMPROVE TYPING, see L2TargetOrAssay)
  selectedTargetGrouping: GeneGrouping;
  abundances: AbunanceSelection;
  normalisationMode: NormalisationMode;
  interval: FilterInterval;
}

export function getNextToggledIdentifiers<T extends string | number>(
  toggled: T | T[],
  current: T[],
  defaults: T[],
  only = false,
) {
  const arrToggled = Array.isArray(toggled) ? toggled : [toggled];

  if (only) {
    // Return defaults if toggling the same single item in current
    if (current.length === 1 && arrToggled.length === 1 && arrToggled[0] === current[0]) {
      return defaults;
    }
    // When only is true, we replace the current selection with the toggled values if multiple, or filter defaults if single
    const next = arrToggled.length > 1 ? arrToggled : defaults.filter(d => arrToggled.includes(d));

    return next;
  } else {
    // Handle selections while preserving order:
    // 1. Start with the current selection
    // 2. Remove any toggled items that are already in the selection (user is deselecting)
    // 3. Add any new items to the end (user is selecting new items)

    // First make a copy and remove any items that are being toggled off
    const removedToggled = current.filter(item => !arrToggled.includes(item));

    // Add any new items that aren't already selected to the end
    const newItems = arrToggled.filter(item => !current.includes(item));

    // Combine: keep existing selection order and add new items to end
    const next = [...removedToggled, ...newItems];

    return next.length ? next : defaults;
  }
}

export function filterSelectedGeneGroups(
  groups: string[],
  geneGroups: Array<string | undefined>,
  selectedGroups: string[],
) {
  const filteredGeneGroups = groups.filter(g => geneGroups.includes(g) && groups.includes(g)).reverse();
  let enabledGeneGroups = filteredGeneGroups.filter(t => groups.includes(t));
  if (selectedGroups.length) {
    enabledGeneGroups = enabledGeneGroups.filter(g => selectedGroups.includes(g));
  }

  return enabledGeneGroups;
}

export function filterSelectedGeneGroupings(
  groups: string[],
  geneGroups: Array<string | undefined>,
  selectedGroups: string[],
) {
  const filteredGeneGroups = groups.filter(g => geneGroups.includes(g) && groups.includes(g)).reverse();
  let enabledGeneGroups = filteredGeneGroups.filter(t => groups.includes(t));
  if (selectedGroups.length) {
    enabledGeneGroups = enabledGeneGroups.filter(g => selectedGroups.includes(g));
  }

  return enabledGeneGroups;
}

export function filterSelectedEnvironments(focusedByUID: FullSamplesByUID, selectedEnvironmentIds: number[]) {
  let focusedSamples = flattenSamplesByUID(focusedByUID);
  if (selectedEnvironmentIds.length) {
    focusedSamples = focusedSamples.filter(s => selectedEnvironmentIds.includes(s.environment.id));
  }

  return focusedSamples;
}

export function filterSelectedEnvironmentTypes(focusedByUID: FullSamplesByUID, selectedEnvironmentIds: number[]) {
  let focusedSamples = flattenSamplesByUID(focusedByUID);
  if (selectedEnvironmentIds.length) {
    focusedSamples = focusedSamples.filter(s => selectedEnvironmentIds.includes(s.environment.id));
  }

  return focusedSamples;
}

export function filterSamplesAndAbundances(samplesByUID: FullSamplesByUID, filters: Filters, getGroup: GetGroup) {
  const samples = flattenSamplesByUID(samplesByUID);
  const focusedSamples = filterSamples(samples, filters);
  const focusedSamplesAndAbundances = filterAbundances(focusedSamples, filters, getGroup);
  const regroupedBioRepsByUID = groupBioSamples(focusedSamplesAndAbundances);
  return regroupedBioRepsByUID;
}

function filterSamples(samples: FullSample[], filters: Filters): FullSample[] {
  return samples
    .filter(sample => sampleBelongsToEnvironmentGroup(sample, filters.selectedEnvironmentTypeGroup))
    .filter(
      sample =>
        !filters.selectedEnvironmentIdsOrdered.length ||
        filters.selectedEnvironmentIdsOrdered.includes(sample.environment.id),
    )
    .filter(sample => !sample.time || isWithinInterval(new Date(sample.time), filters.interval));
}

function filterAbundances(samples: FullSample[], filters: Filters, getGroup: GetGroup): FullSample[] {
  const showTraces = filters.abundances !== AbunanceSelection.QUANTIFIED_ONLY;
  const flatAbundances = flattenRelevantAbundances(samples, filters.selectedTargetGrouping === sixteenS);
  const showByAssay = flatAbundances.reduce<Dictionary<boolean>>((acc, abundance) => {
    acc[abundance.assay] =
      acc[abundance.assay] ||
      filters.abundances === AbunanceSelection.ANALYSED ||
      abundance.relative !== null ||
      (showTraces && abundance.traces);
    return acc;
  }, {});
  return samples.map(sample => {
    const abundances = sample.abundances
      .filter(datum => showByAssay[datum.assay])
      .filter(
        datum =>
          !filters.selectedTargets.length ||
          filters.selectedTargets.includes(getGroup(datum.assay, filters.selectedTargetGrouping) as L2Target), // undefined are ok
      );
    return {
      ...sample,
      abundances,
    };
  });
}

function selectContinuousRangeOfValues<T extends string | number>(allSelections: T[], selectedValues: [T, T]): T[] {
  const selectedIndex1 = allSelections.findIndex(s => s === selectedValues[0]);
  const selectedIndex2 = allSelections.findIndex(s => s === selectedValues[1]);
  const smallerValueIndex = selectedIndex1 < selectedIndex2 ? selectedIndex1 : selectedIndex2;
  const largerValueIndex = selectedIndex1 < selectedIndex2 ? selectedIndex2 : selectedIndex1;
  const newSelection = allSelections.filter((_s, index) => index >= smallerValueIndex && index <= largerValueIndex);

  return newSelection;
}

// This manages the shift and control click selections
export function handleFiltersSelectionWithKeys<T extends string | number>(
  previousGroups: T[],
  allGroups: T[],
  selectedLabel: T,
  previousLabel: T | undefined,
  keys: KeysPressOptions,
  disableKeys?: boolean,
): [T | T[], boolean] {
  if (keys.shift && !disableKeys) {
    const areAllSelected = previousGroups.length === allGroups.length;

    // This is the first click with shift, so we don't select a range yet, only single value
    if (!previousLabel || areAllSelected) {
      previousLabel = previousGroups[0];
      return [selectedLabel, true] as const;
    }

    const newGroups = selectContinuousRangeOfValues(allGroups, [selectedLabel, previousLabel]);
    return [newGroups, true] as const;
  }

  previousLabel = selectedLabel;
  return [selectedLabel, Boolean(disableKeys) || !keys.ctrl] as const;
}
