import { Pooling, PoolingMode, PoolingType } from '@resistapp/common/api-types';
import { GetGroup } from '@resistapp/common/assays';
import { safeAssert } from '@resistapp/common/assert-utils';
import {
  EnvGroup,
  EnvironmentTypeGroup,
  getComparableEnvironmentGroups,
} from '@resistapp/common/comparable-env-groups';
import { EnvironmentType, isAfterSample, isBeforeSample } from '@resistapp/common/environment-types';
import { ensureLocalMidnight, ensureLocalStartOfMonth, friendlyMonth } from '@resistapp/common/friendly';
import { poolSampleEnvironments } from '@resistapp/common/pool-samples';
import { RequiredNonNullableFields } from '@resistapp/common/type-utils';
import { arePropertiesPresentInAllFullSamples } from '@resistapp/common/typeguards';
import {
  Environment,
  FullAbundance,
  FullSample,
  FullSamplesByUID,
  MetricMode,
  PooledEnvHack,
  ProcessMode,
  getOriginalEnvironmentId,
} from '@resistapp/common/types';
import { flattenSamplesByUID } from '@resistapp/common/utils';
import { chain, intersection, isNil, keys, pickBy } from 'lodash';
import { WithAbundances } from '../../../common/statistics/resistance-index';
import { DateDatum } from './research-plot-data';

export type TimeSeriesOfOverviewDatums = OverviewDatum[]; // inner array in trendData, indexed by time (month), ie. singleSiteOrAreaTimeSeries[time]
export type OverviewLineData = TimeSeriesOfOverviewDatums[]; // outer array in trendData, indexed by iSiteOrArea and then time, ie. trendData[iSiteOrArea][time] = trendData[iSiteOrArea][time]
export type EnvSeriesOfOverviewDatums = OverviewDatum[]; // overview datums for each site, vertically picked from a single time or latest timepoints, ie. mapData[iSiteOrArea] = trendData[iSiteOrArea][trendData[iSiteOrArea].length - 1]

export type FullSampleWithTime = RequiredNonNullableFields<FullSample, 'time'>;

export interface OverviewDatum extends DateDatum {
  environment: Environment | PooledEnvHack;
  beforeAbundances: FullAbundance[] | undefined;
  afterAbundances: FullAbundance[] | undefined;
  environmentAfter?: Environment | PooledEnvHack;
  uniqueDates: Date[];
}

export interface OverviewDatumWithQuartiles extends OverviewDatum {
  firstQuartile?: number;
  thirdQuartile?: number;
}

export const unsuportedOverviewEnvTypeGroups: EnvironmentTypeGroup[] = [
  // AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS,
  EnvironmentType.CONTROL,
  EnvironmentType.STOOL, // Used as control in some projects
  EnvironmentType.FOOD,
];

export function getBeforeOrAfterAbundances<T extends WithAbundances>(datum: T, processMode: ProcessMode) {
  const step: ProcessMode.AFTER | ProcessMode.BEFORE =
    processMode === ProcessMode.BEFORE || processMode === ProcessMode.AFTER ? processMode : ProcessMode.BEFORE;
  const key = step === ProcessMode.BEFORE ? ('beforeAbundances' as const) : ('afterAbundances' as const);
  const abundances = datum[key];
  return abundances;
}

export function getComparableEnvGroupsForOverview(samplesByUID: FullSamplesByUID, metricMode: MetricMode): EnvGroup[] {
  const flatSamples = flattenSamplesByUID(samplesByUID);
  const flatEnvs = chain(flatSamples)
    .map(s => s.environment)
    .uniqBy(env => env.id)
    .value();
  const comparableGroups = getComparableEnvironmentGroups(flatEnvs, metricMode);
  return comparableGroups.filter(group => !unsuportedOverviewEnvTypeGroups.includes(group.type));
}

/*
 * Get relevant samples for overview and drop admin levels that are not present in all samples
 *
 * Overview is designed for (and may make biological sense only for) selected environment types
 */
export function getSupportedSamplesWithConsistentAdminLevels(
  samplesByUID: FullSamplesByUID,
  envGroups: EnvGroup[],
): FullSample[] {
  const supportedEnvs = new Set(envGroups.flatMap(envGroup => envGroup.envs).map(env => env.id));
  const flatSamples = flattenSamplesByUID(samplesByUID);
  const flatSupportedSamples = flatSamples.filter(
    sample =>
      !isNil(sample.environment.inferredLat) &&
      !isNil(sample.environment.inferredLon) &&
      !isNil(sample.environment.lat) &&
      !isNil(sample.environment.lon) &&
      !isNil(sample.time) &&
      supportedEnvs.has(sample.environment.id),
  );
  const levelsInAllSamples = new Set(
    chain(flatSupportedSamples)
      .map(s => s.environment.adminLevels || {})
      .map(levels => keys(levels).map(level => +level))
      .reduce((acc, levels) => intersection(acc, levels))
      .value(),
  );
  const supportedSamplesWithConsistentLevels = flatSupportedSamples.map(sample => ({
    ...sample,
    environment: {
      ...sample.environment,
      adminLevels: pickBy(sample.environment.adminLevels, datum => levelsInAllSamples.has(datum.level)),
    },
  }));
  return supportedSamplesWithConsistentLevels;
}

/*
 * Build BoxDatum series (arrays), where each series contains time series BoxDatums for one overview environment / location (line)
 */
export function buildOverviewLineData(
  samples: FullSample[],
  selectedEnvHash: Set<number>,
  processMode: ProcessMode,
  pooling: Pooling | undefined,
  getGroup: GetGroup,
): OverviewLineData {
  if (!samples.length) {
    throw new Error('No supported samples available');
  }
  const samplesHaveTimes = arePropertiesPresentInAllFullSamples(samples, 'time');
  if (!samplesHaveTimes) {
    throw new Error('Supported samples unexpectedly missing time');
  }
  const samplesHaveLocations = samples.every(
    sample => !isNil(sample.environment.inferredLat) && !isNil(sample.environment.inferredLon),
  );
  if (!samplesHaveLocations) {
    throw new Error('Supported samples unexpectedly missing location');
  }

  // After validation, we can safely cast to FullSampleWithTime
  const samplesWithTime = samples as FullSampleWithTime[];

  // Before pooling by admin level, we have to combine site samples
  const maybeSitePooledSamples =
    pooling?.type === PoolingType.SITE_AND_ADMIN_LEVEL
      ? poolSampleEnvironmentsForOverview(samplesWithTime, {
          type: PoolingType.SITE,
          mode: PoolingMode.THROW_MISSING,
        })
      : samplesWithTime;

  // Proceed with primary pooling
  const maybePooledSamples = poolSampleEnvironmentsForOverview(maybeSitePooledSamples, pooling);

  return chain(maybePooledSamples)
    .groupBy(sample => sample.environment.id)
    .mapValues((siteSamples, _, _c) => {
      return buildSingleOverviewLineSeries(siteSamples, selectedEnvHash, processMode, getGroup);
    })
    .values()
    .value();
}

function poolSampleEnvironmentsForOverview(
  samples: FullSampleWithTime[],
  pooling: Pooling | undefined,
): FullSampleWithTime[] {
  // Special case checks
  if (!pooling) {
    return samples;
  } else if (pooling.type !== PoolingType.SITE_AND_ADMIN_LEVEL && pooling.type !== PoolingType.SITE) {
    throw Error('Only site and admin level pooling are supported in the overview');
  }

  // Combine sample environments so that samples with the same pooling criteria share the environment
  const pooledSamples = poolSampleEnvironments(samples, pooling, undefined);

  return pooledSamples;
}

// NOTE! Keep consistent with buildAfterSampleIdMapping
function buildSingleOverviewLineSeries(
  siteOrAreaSamples: FullSampleWithTime[],
  selectedEnvHash: Set<number>,
  processMode: ProcessMode,
  getGroup: GetGroup,
): TimeSeriesOfOverviewDatums {
  return (
    chain(siteOrAreaSamples)
      // Always use monthly grouping
      .groupBy(sample => getSnappedLocalTimeFields(sample).snappedTime)
      .mapValues(siteOrAreaSamplesForTimePoint =>
        buildOverviewDatum(siteOrAreaSamplesForTimePoint, selectedEnvHash, processMode, getGroup),
      )
      .values()
      .sortBy(datum => new Date(datum.date))
      .value()
  );
}

// NOTE! Keep consistent with buildAfterSampleIdMapping
function buildOverviewDatum(
  siteOrAreaSamplesForTimePoint: FullSampleWithTime[],
  selectedEnvHash: Set<number>,
  processMode: ProcessMode,
  _getGroup: GetGroup,
): OverviewDatum {
  // Originally, all sites have a subtype or none have
  // Env type pooling should only ever result in 1 or 2 uniq sub types for pooled samples
  // Area pooling results in undefined or one subtype -> one uniq
  // Site pooling results in 1 for process sites and 2 for pooled sites
  const numSubEnvs = chain(siteOrAreaSamplesForTimePoint)
    .map(s => s.environment.subtype)
    .uniq()
    .value().length;
  const isIndividualSite = numSubEnvs <= 2; // Non-process site, process site, or area containining only similar sites
  // TODO, areas currently aggregate accross before and after samples,
  // even when they could just aggregate over the selected process mode samples
  // We might want to change this, but let's hear some feedback first

  // Allow all process samples for individual process sites so that we can show secondary figures in the UI
  const selectedSamples = siteOrAreaSamplesForTimePoint.filter(
    s => isIndividualSite || keepSelectedEnvs(s, selectedEnvHash),
  );
  const beforeSamples = selectedSamples
    .filter(s => isBeforeSample(s) || (!isBeforeSample(s) && !isAfterSample(s)))
    .filter(_ => isIndividualSite || processMode !== ProcessMode.AFTER)
    .filter(
      s =>
        isIndividualSite ||
        !!selectedEnvHash.has((s.environment as PooledEnvHack).originalEnvironmentId || s.environment.id),
    );
  const afterSamples = selectedSamples
    .filter(s => isAfterSample(s))
    .filter(_ => isIndividualSite || processMode !== ProcessMode.BEFORE)
    .filter(
      s =>
        isIndividualSite ||
        !!selectedEnvHash.has((s.environment as PooledEnvHack).originalEnvironmentId || s.environment.id),
    );

  if (isIndividualSite && beforeSamples.length + afterSamples.length !== selectedSamples.length) {
    throw Error(`Before samples.length ${beforeSamples.length} + ${afterSamples.length} != ${selectedSamples.length}`);
  }

  const isSupportedSample = (s: FullSampleWithTime) =>
    !!selectedEnvHash.has((s.environment as PooledEnvHack).originalEnvironmentId || s.environment.id);
  const hasSupportedEnvType = beforeSamples.find(isSupportedSample) || afterSamples.find(isSupportedSample);

  const beforeAbundances: FullAbundance[] = hasSupportedEnvType
    ? beforeSamples.flatMap(sampleLocal => sampleLocal.abundances)
    : [];
  const afterAbundances: FullAbundance[] = hasSupportedEnvType
    ? afterSamples.flatMap(sampleLocal => sampleLocal.abundances)
    : [];

  const firstSample = siteOrAreaSamplesForTimePoint[0];
  const { snappedTime, formattedTime } = getSnappedLocalTimeFields(firstSample);

  const uniqueDates = chain(siteOrAreaSamplesForTimePoint)
    .map(sample => ensureLocalMidnight(sample.time))
    .uniqBy(date => date.getTime())
    .sortBy(date => date.getTime())
    .value();

  return {
    date: snappedTime,
    beforeAbundances,
    afterAbundances,
    label: formattedTime,
    environment: firstSample.environment,
    environmentAfter: afterSamples[0]?.environment,
    uniqueDates,
  };
}

function keepSelectedEnvs(s: FullSampleWithTime, selectedEnvHash: Set<number>) {
  const originalEnvId = getOriginalEnvironmentId(s.environment);
  safeAssert(!!originalEnvId, `Unexpectedly missing originalEnvironmentId`);
  return selectedEnvHash.has(originalEnvId);
}

function getSnappedLocalTimeFields({ time }: Pick<FullSampleWithTime, 'time'>) {
  const snappedTime = ensureLocalStartOfMonth(time);
  const formattedTime = friendlyMonth(snappedTime, 'postfixAlways');
  return { snappedTime: snappedTime.toISOString(), formattedTime };
}
