import { Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { TitleBar } from '@resistapp/client/components/bars/title-bar';
import { DeleteIcon } from '@resistapp/client/components/icons/delete-icon';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { useDeleteUser, useProjects, useUsers } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { getUserProjectIds } from '@resistapp/common/access';
import { isAdmin } from '@resistapp/common/features';
import { Project, User } from '@resistapp/common/types';
import { Dictionary, keyBy } from 'lodash';
import { useEffect, useState } from 'react';

export function AllUsersSection() {
  const { data: projects } = useProjects();
  const [projectsById, setProjectsById] = useState<Dictionary<Project>>({});
  const { data: allUsers, loading, error, send: load } = useUsers();
  const users = allUsers?.filter(u => !isAdmin(u));

  useEffect(() => {
    void load();
    // eslint-disable-next-line exhaustive-deps-with-refs/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projects) {
      setProjectsById(keyBy(projects, project => project.id));
    }
  }, [projects]);

  if (error) {
    throw error;
  } else if (loading || !users) {
    return <LoadingView />;
  }

  return (
    <Flex direction="column" borderTop="1px solid gray">
      <TitleBar title="All Users" $extraWide={true} />
      <p style={{ paddingLeft: oldTheme.spacing.l, paddingRight: oldTheme.spacing.l }}>
        This section lists all non-admin users, together with their direct and indirect project accesses. Before
        organisations existed, users were given direct access to projects.
      </p>
      <ListView>
        {users.map((user, key) => (
          <UserListItem key={`u-${key}`} user={user} projectsById={projectsById} />
        ))}
      </ListView>
    </Flex>
  );
}

interface UserListItemProps {
  user: User;
  projectsById?: Partial<Dictionary<Project>>;
}

function UserListItem(props: UserListItemProps) {
  const { user, projectsById } = props;
  const navigate = useNavigateWithQuery();
  const { send } = useDeleteUser(user.id);
  return (
    <li>
      <span>
        {user.firstName} {user.lastName}, {user.email}
        <DeleteIcon triggerDelete={async () => await send()} name={user.email} />
      </span>
      <ul>
        {projectsById &&
          !isAdmin(user) &&
          getUserProjectIds(user).map((projectId, usKey) => {
            return (
              <li
                key={`us-${usKey}`}
                style={{ marginLeft: oldTheme.spacing.m, cursor: 'pointer', color: 'gray' }}
                onClick={() => {
                  navigate(`/research/${projectId}`, false);
                }}
              >
                {projectsById[projectId]?.name || projectId}
              </li>
            );
          })}
      </ul>
    </li>
  );
}

const ListView = styled.ul`
  padding-top: ${oldTheme.spacing.m};
  padding-left: ${oldTheme.spacing.xl};
`;
