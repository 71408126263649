import { ChangeZoomedAdminArea } from '@resistapp/client/contexts/map-context';
import { zoomAndCenterMap } from '@resistapp/client/contexts/use-overview-context/overview-context-utils';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { QueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { AdminArea, getOriginalEnvironmentId } from '@resistapp/common/types';
import { isNil } from 'lodash';
import type { MapboxMap } from 'react-map-gl';

function getOriginalEnvironmentIds(
  environment: Pick<OverviewDatum, 'environmentAfter' | 'environment'>['environment'] | undefined,
): number[] {
  const id = getOriginalEnvironmentId(environment);
  return id !== undefined ? [id] : [];
}

export function onMarkerClick(
  closeClick: boolean,
  marker: Pick<OverviewDatum, 'environmentAfter' | 'environment'>,
  _navigate: (path: string, keepQuery: boolean) => void,
  toggleEnvironment: QueryFilters['toggleEnvironmentStable'],
  adminLevelsActive: boolean,
  changeZoomedAdminAreaStable: ChangeZoomedAdminArea,
  previousAdminArea: AdminArea | undefined,
  mapInstance: MapboxMap | null,
) {
  if (closeClick) {
    // If we select a project, the projectId exists, but if we unselect it is undefined
    toggleEnvironment(undefined, true);
    changeZoomedAdminAreaStable(previousAdminArea || undefined, { previous: true });
  } else if (!adminLevelsActive) {
    // Get only the original environment IDs, not the bogus negative IDs
    const envIds = [
      ...getOriginalEnvironmentIds(marker.environment),
      ...getOriginalEnvironmentIds(marker.environmentAfter),
    ];

    toggleEnvironment(envIds, true);
    if (mapInstance && !isNil(marker.environment.inferredLon) && !isNil(marker.environment.inferredLat)) {
      zoomAndCenterMap(mapInstance, {
        zoom: 13,
        center: [marker.environment.inferredLon, marker.environment.inferredLat],
      });
    }
    changeZoomedAdminAreaStable(null);
  }
}
