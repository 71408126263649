import { Dispatch, MutableRefObject, SetStateAction, useRef, useState } from 'react';

export function useStateWithRef<S = undefined>(): [
  S | undefined,
  Dispatch<SetStateAction<S | undefined>>,
  MutableRefObject<S | undefined>,
];

export function useStateWithRef<S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, MutableRefObject<S>];

export function useStateWithRef<S>(initialState?: S | (() => S)) {
  const [state, setState] = useState(initialState);
  const stateRef = useRef(state);
  stateRef.current = state;
  return [state, setState, stateRef];
}
