import { useParams } from 'react-router-dom';

export enum PathParams {
  projectId = 'projectId',
  signupCode = 'signupCode',
}

const PathValidators = {
  projectId: (param: string) => Number(param) as number | undefined,
  signupCode: (param: string) => param as string | undefined,
} as const satisfies Record<PathParams, (param: string) => string | number | undefined>;

export function usePathParam(paramKey: PathParams.projectId): number | undefined;
export function usePathParam(paramKey: PathParams.signupCode): string | undefined;
export function usePathParam(paramKey: PathParams) {
  const params = useParams<PathParams>();
  const param = params[paramKey];

  return param ? PathValidators[paramKey](param) : undefined;
}
