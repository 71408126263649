import { AssayResult, NormalisationMode, type StrippedFullAbundance } from './types';

export const NormalisationModesAndProperties = {
  [NormalisationMode.MG_SS]: 'copiesPerMgSS',
  [NormalisationMode.HOUR]: 'copiesPerHour',
  [NormalisationMode.LITRE]: 'copiesPerL',
  [NormalisationMode.TEN_UL_DILUTED_DNA]: 'absolute',
  [NormalisationMode.MG_BOD]: 'copiesPerMgBod',
  [NormalisationMode.SIXTEEN_S]: 'relative',
} as const; // satisfies Partial<Record<NormalisationMode, keyof FullAbundance>>;

export function isConsideredAbsolute(normalisationMode: NormalisationMode) {
  return normalisationMode !== NormalisationMode.SIXTEEN_S;
}

export function getNormalisedValue(
  abundanceOrResult: StrippedFullAbundance | Pick<AssayResult, 'value' | 'normalisationMode'>,
  normalisationMode: keyof typeof NormalisationModesAndProperties,
) {
  if ('value' in abundanceOrResult && 'normalisationMode' in abundanceOrResult) {
    if (abundanceOrResult.normalisationMode === normalisationMode) {
      return abundanceOrResult.value;
    } else {
      throw Error(`Normalisation mode mismatch: ${abundanceOrResult.normalisationMode} !== ${normalisationMode}`);
    }
  }
  const modeProperty = NormalisationModesAndProperties[normalisationMode];
  return abundanceOrResult[modeProperty];
}

export const NormalisedValueName = {
  [NormalisationMode.SIXTEEN_S]: 'Relative abundance',
  [NormalisationMode.MG_SS]: 'Copy numbers per mg of SS',
  [NormalisationMode.HOUR]: 'Copy numbers per hour',
  [NormalisationMode.LITRE]: 'Copy numbers per L',
  [NormalisationMode.TEN_UL_DILUTED_DNA]: 'Copy numbers',
  [NormalisationMode.MG_BOD]: 'Copy numbers per mg BOD',
} as const;
