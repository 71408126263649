import { getEffectiveProcessMode } from '@resistapp/client/contexts/use-overview-context/overview-context-utils';
import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { MapSource, MapSourceWithLevel } from '@resistapp/client/utils/map-sources';
import { getMetricAndLevel, getMetricColor } from '@resistapp/client/utils/metric-utils';
import { AssayInfo, GetGroup, L2Target } from '@resistapp/common/assays';
import { EnvironmentTypeGroup } from '@resistapp/common/comparable-env-groups';
import {
  AdminArea,
  AdminLevelKey,
  ChartUnit,
  MetricMode,
  ProcessMode,
  type AdminAreaByLevel,
  type PossibleCountryLevel,
} from '@resistapp/common/types';
import { Dictionary } from 'lodash';
import { LngLatBounds } from 'mapbox-gl';
import { MapboxMap, MapMouseEvent } from 'react-map-gl';

export enum ZoomLevels {
  marker = 13,
}

const coloredLayerId = 'colored-layer';
const activeEventListeners: Array<{
  type: MapMouseEvent['type'];
  layerId: string;
  listener: (e: MapMouseEvent & mapboxgl.EventData) => void;
}> = [];

export function activateColoredRegions(
  mapInstance: MapboxMap,
  activeMapStyle: MapSourceWithLevel,
  mapData: OverviewDatum[],
  setHoveredEnvironmentId: (id: number | undefined) => void,
  environmentBoundingBoxes: Dictionary<{ ne: [number, number]; sw: [number, number] }>,
  changeZoomedAdminAreaStable: (nextAdminArea: AdminArea, options?: { countryId?: string }) => void,
  selectedTargets: L2Target[],
  metricMode: MetricMode,
  processMode: ProcessMode,
  activeChartUnit: ChartUnit,
  selectedEnvironmentTypeGroup: EnvironmentTypeGroup,
  getGroup: GetGroup,
  allAssays: AssayInfo[],
) {
  const sourceName = activeMapStyle.tileset.sourceLayer;
  const sourceLayer = activeMapStyle.tileset.sourceLayer;
  const layerId = `${sourceName}-${coloredLayerId}`;

  const isSourceLoaded = Boolean(mapInstance.getSource(sourceName));
  if (!isSourceLoaded) {
    mapInstance.addSource(sourceName, {
      // If you need to test some geojson data, before converting to mbtiles and uploading:
      // type: 'geojson', data: geoBoundariesFINADM1 as string,
      type: 'vector',
      url: activeMapStyle.tileset.url,
      promoteId: activeMapStyle.tileset.propertyName,
    });
  }

  const regionsWithData = new Map<string, number>();
  const matchExpression = ['match', ['get', activeMapStyle.tileset.propertyName]];

  const isCountries = activeMapStyle.tileset.admLevel.includes(2 as PossibleCountryLevel);
  const onlyCorrectCountriesMapData = isCountries
    ? mapData
    : mapData.filter(dataItem => dataItem.environment.country === activeMapStyle.countryAlphaCode3);

  // TIP for Error: layers.osmidnadm3_5-colored-layer.paint.fill-color: Expected at least 4 arguments, but found only 2.
  // This is likely due to undefined adminLevel or areaName, which causes improperly formated match expressions to be passed to addLayer
  onlyCorrectCountriesMapData.forEach(dataItem => {
    // We get first admin area name based on admin level data in database (that is from open street map) or country name
    const adminAreaName = isCountries
      ? getAreaName(activeMapStyle, dataItem.environment.country)
      : getCorrectAdminLevelName(
          dataItem.environment.adminLevels as AdminAreaByLevel,
          String(activeMapStyle.adminLevel) as AdminLevelKey,
        );
    // This is the old way of getting the admin area name, but it is not used anymore. Using the sample.env.region property
    // const area = adminAreaName || getAreaName(activeMapStyle, dataItem.environment[isCountries ? 'country' : 'region']);
    if (!adminAreaName) {
      return;
    }

    const effectiveProcessMode = getEffectiveProcessMode(dataItem, processMode, selectedEnvironmentTypeGroup);

    const color = getMatchExpressionColor(
      dataItem,
      selectedTargets,
      metricMode,
      effectiveProcessMode,
      activeChartUnit,
      getGroup,
      allAssays,
    );
    if (color) {
      setMatchExpressionData(matchExpression, regionsWithData, adminAreaName, color, dataItem.environment.id);
    }
  });

  // Last value is the default, used where there is no data. This basically sets all the non-existant areas invisible.
  matchExpression.push('rgba(255, 0, 0, 0)');

  const isLayerLoaded = Boolean(mapInstance.getLayer(layerId));

  if (!isLayerLoaded) {
    if (mapData.length === 0) {
      // This is kind of silent error. We don't deal with the admin areas, if there are none
      return;
    } else if (matchExpression.length < 5) {
      console.error(
        'Admina areas can not be shown, since fill-color needs at least 4 parameters',
        matchExpression,
        mapData,
      );
      return;
    }

    // This removes duplicate area-color pairs from the matchExpression. Though those shouldn't be there in the first place.
    const seen = new Set<string>();
    for (let i = 2; i < matchExpression.length - 1; i += 2) {
      const area = matchExpression[i];
      const color = matchExpression[i + 1];

      if (typeof area === 'string' && typeof color === 'string') {
        if (seen.has(area)) {
          // Remove both the area and its color
          matchExpression.splice(i, 2);
          i -= 2; // Adjust index since we removed elements
        } else {
          seen.add(area);
        }
      }
    }

    mapInstance.addLayer(
      {
        id: layerId,
        type: 'fill',
        source: sourceName,
        'source-layer': sourceLayer,
        filter: isCountries ? ['all'] : ['==', ['get', 'admin_level'], activeMapStyle.adminLevel],
        paint: {
          // The matchExpression works as string | string[], so we just ignore it here and force a dumb type
          'fill-color': matchExpression as unknown as string,
          // [
          //   'case',
          //   ['has', ['to-string', ['get', activeMapStyle.tileset.propertyName]], ['literal', regionsWithData]],
          //   [
          //     'interpolate',
          //     ['linear'],
          //     ['get', ['to-string', ['get', activeMapStyle.tileset.propertyName]], ['literal', regionsWithData]],
          //     0,
          //     metricMode === MetricType.ARGI
          //       ? resistanceLevelMetadata[ResistanceLevel.low].color
          //       : colorInterpolator(0),
          //     2.5,
          //     metricMode === MetricType.ARGI
          //       ? resistanceLevelMetadata[ResistanceLevel.moderate].color
          //       : colorInterpolator(0.5),
          //     5,
          //     metricMode === MetricType.ARGI
          //       ? resistanceLevelMetadata[ResistanceLevel.high].color
          //       : colorInterpolator(1),
          //   ],
          //   'rgba(0, 0, 0, 0)',
          // ],
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.95, 0.7],
        },
      },
      'waterway-label', // 'building' is another option
    );

    mapInstance.addLayer(
      {
        id: `${layerId}-border`,
        type: 'line',
        source: sourceName,
        'source-layer': sourceLayer,
        filter: ['==', ['get', 'admin_level'], activeMapStyle.adminLevel],
        paint: {
          'line-color': 'rgba(0, 0, 0, 1)',
          'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.2],
        },
      },
      'waterway-label', // 'building' is another option
    );
  }

  const handleRegionHover = createHandleRegionHover(
    mapInstance,
    layerId,
    activeMapStyle,
    regionsWithData,
    setHoveredEnvironmentId,
    sourceName,
    sourceLayer,
  );
  const handleRegionLeave = createHandleRegionLeave(mapInstance, sourceName, sourceLayer, setHoveredEnvironmentId);
  const handleRegionClick = createHandleRegionClick(
    mapInstance,
    layerId,
    activeMapStyle,
    regionsWithData,
    environmentBoundingBoxes,
    mapData,
    changeZoomedAdminAreaStable,
  );

  // We need to remove the old event listeners first, since we recreate them everytime.
  activeEventListeners.forEach(listener => void mapInstance.off(listener.type, listener.layerId, listener.listener));
  activeEventListeners.length = 0;
  activeEventListeners.push(
    { type: 'click', layerId, listener: handleRegionClick },
    { type: 'mousemove', layerId, listener: handleRegionHover },
    { type: 'mouseleave', layerId, listener: handleRegionLeave },
  );
  activeEventListeners.forEach(listener => void mapInstance.on(listener.type, listener.layerId, listener.listener));
}

// This is shared between the mouseOver and mouseLeave handlers
let mutatingHoveredRegionName: string | undefined;
function createHandleRegionHover(
  mapInstance: MapboxMap,
  layerId: string,
  activeMapStyle: MapSource,
  regionsWithData: Map<string, number>,
  setHoveredEnvironmentId: (id: number | undefined) => void,
  sourceName: string,
  sourceLayer: string,
) {
  return (
    e: MapMouseEvent & {
      features?: mapboxgl.MapboxGeoJSONFeature[] | undefined;
    } & mapboxgl.EventData,
  ) => {
    const { region, environmentId } = getLayerDataAtPoint(mapInstance, e, layerId, activeMapStyle, regionsWithData);
    // This is to debug is the area data the same in our database and in mapbox tileset
    // If the area matches the adminArea should show up, if it does not then there is no data in that area or there is a mismatch
    // console.log(
    // `Hovering over area that in mapbox is '${region}' and it ${environmentId ? 'matched' : 'did not match'} our data`,
    // );
    if (region && environmentId) {
      setCanvasCursor(e, 'pointer');
      setHoveredEnvironmentId(environmentId);
    } else {
      setCanvasCursor(e, 'default');
      setHoveredEnvironmentId(undefined);
    }

    const features = e.features;
    const currentHoveredRegionName = features?.[0]?.properties?.[activeMapStyle.tileset.propertyName] as
      | string
      | undefined;

    if (features && features.length > 0) {
      // Remove the hover state from the old area, that is no longer being hovered
      if (currentHoveredRegionName !== mutatingHoveredRegionName && mutatingHoveredRegionName) {
        mapInstance.setFeatureState(
          {
            source: sourceName,
            sourceLayer,
            id: mutatingHoveredRegionName,
          },
          { hover: false },
        );
      }

      // Add hover true to the new hovered area
      if (currentHoveredRegionName) {
        mapInstance.setFeatureState(
          {
            source: sourceName,
            sourceLayer,
            id: currentHoveredRegionName,
          },
          { hover: true },
        );
      }
    }

    mutatingHoveredRegionName = currentHoveredRegionName;
  };
}

// createHandleRegionClick gets coordinates from the mapbox vector tile, that resides in mapbox studio, but since those
// coordinates didn't seem to be enough, we also add all the environment coordinates and calculate the bounding box
// from all of those. Otherwise we might end up zooming to an area of the region, that has no samples.
function createHandleRegionClick(
  mapInstance: MapboxMap,
  layerId: string,
  activeMapStyle: MapSourceWithLevel,
  regionsWithData: Map<string, number>,
  environmentBoundingBoxes: Dictionary<{ ne: [number, number]; sw: [number, number] }>,
  mapData: OverviewDatum[],
  changeZoomedAdminAreaStable: (nextAdminArea: AdminArea, options?: { countryId?: string }) => void,
) {
  return (e: MapMouseEvent & mapboxgl.EventData) => {
    const { environmentId, feature } = getLayerDataAtPoint(mapInstance, e, layerId, activeMapStyle, regionsWithData);
    const region = feature.properties?.[activeMapStyle.tileset.propertyName] as string;

    const correctRegionFeature = mapInstance.queryRenderedFeatures(undefined, {
      layers: [layerId],
      filter: [
        'all',
        ['==', ['get', 'admin_level'], activeMapStyle.adminLevel],
        ['==', ['get', activeMapStyle.tileset.propertyName], region],
      ],
    });

    if (environmentId) {
      const envAdminLevels = mapData.find(d => d.environment.id === environmentId)?.environment.adminLevels;
      const nextAdminLevel = Object.values(envAdminLevels ?? {}).find(
        d => Number(d.level) === activeMapStyle.adminLevel,
      );

      console.log('Region click data:', {
        environmentId,
        envAdminLevels,
        nextAdminLevel,
        activeMapStyle,
        featureId: feature.id,
      });

      if (nextAdminLevel) {
        console.log('Calling changeZoomedAdminAreaStable with:', {
          nextAdminLevel,
          countryId: activeMapStyle.adminLevel === 2 ? String(feature.id) : undefined,
        });
        changeZoomedAdminAreaStable(
          nextAdminLevel,
          activeMapStyle.adminLevel === 2 ? { countryId: String(feature.id) } : undefined,
        );
        return;
      }

      const coordinates = (feature.geometry as { coordinates: number[][] }).coordinates;

      const flattenedCoordinates = correctRegionFeature.map(_feature => coordinates.flat(5)).flat();
      const allCoordinates = [
        ...flattenedCoordinates,
        ...environmentBoundingBoxes[environmentId].ne.flat(),
        ...environmentBoundingBoxes[environmentId].sw.flat(),
      ];
      const { highest, lowest } = getHighestAndLowestCoordinates(allCoordinates);

      const boundsLocal = new LngLatBounds(lowest, highest);

      mapInstance.fitBounds(boundsLocal, { padding: 20 });
    } else {
      console.log('No environmentId found for region click');
    }
  };
}

function createHandleRegionLeave(
  mapInstance: MapboxMap,
  sourceName: string,
  sourceLayer: string,
  setHoveredEnvironmentId: (id: number | undefined) => void,
) {
  return (e: MapMouseEvent & mapboxgl.EventData) => {
    setCanvasCursor(e, 'default');

    if (mutatingHoveredRegionName) {
      mapInstance.setFeatureState(
        {
          source: sourceName,
          sourceLayer,
          id: mutatingHoveredRegionName,
        },
        { hover: false },
      );
    }

    setHoveredEnvironmentId(undefined);
    mutatingHoveredRegionName = undefined;
  };
}

function setCanvasCursor(e: MapMouseEvent & mapboxgl.EventData, cursor: string) {
  e.target.getCanvasContainer().style.cursor = cursor;
}

function getAreaName(activeMapStyle: MapSource, area: string | null) {
  if (!area) return undefined;
  return (activeMapStyle as { mappings?: Record<string, string> }).mappings?.[area] || area;
}

function getLayerDataAtPoint(
  mapInstance: MapboxMap,
  e: MapMouseEvent & mapboxgl.EventData,
  layerId: string,
  activeMapStyle: MapSource,
  regionsWithData: Map<string, number>,
) {
  const features = mapInstance.queryRenderedFeatures(e.point, {
    layers: [layerId],
  });
  const feature = features[0];

  const region = feature.properties?.[activeMapStyle.tileset.propertyName] as string;
  return { region, environmentId: regionsWithData.get(region), feature };
}

function getHighestAndLowestCoordinates(coordinates: number[]): {
  highest: [number, number];
  lowest: [number, number];
} {
  const lons = coordinates.filter((_coord, index) => index % 2 === 0);
  const lats = coordinates.filter((_coord, index) => index % 2 === 1);
  const highest = [Math.max(...lons), Math.max(...lats)] as [number, number];
  const lowest = [Math.min(...lons), Math.min(...lats)] as [number, number];

  return { highest, lowest };
}

export function getBoundingBoxForEnvironment(data: OverviewDatum[]) {
  const highestAndLowestCoordinates = data
    .flat(3)
    .filter(d => d.environment.inferredLon && d.environment.inferredLat)
    .map(d =>
      getHighestAndLowestCoordinates([d.environment.inferredLon as number, d.environment.inferredLat as number]),
    );
  const ne = highestAndLowestCoordinates.map(coords => coords.highest);
  const sw = highestAndLowestCoordinates.map(coords => coords.lowest);

  return { ne, sw };
}

export function deactivateColoredRegions(mapInstance: MapboxMap) {
  const layers = mapInstance.getStyle().layers;

  if (layers.length) {
    layers.forEach(layer => {
      if (layer.id.includes('colored-layer')) {
        mapInstance.removeLayer(layer.id);
      }
    });
  }
}

// If the matchExpressionData does not match the names in the mapbox tilesets area names, the area won't be shown.
// So when you have issues with admin areas not showing up as colored it could be data missmatch
function getMatchExpressionColor(
  dataItem: OverviewDatum,
  targets: L2Target[],
  metricMode: MetricMode,
  effectiveProcessMode: ProcessMode,
  activeChartUnit: ChartUnit,
  getGroup: GetGroup,
  allAssays: AssayInfo[],
) {
  const [metric] = getMetricAndLevel(
    dataItem,
    targets,
    metricMode,
    effectiveProcessMode,
    activeChartUnit,
    getGroup,
    allAssays,
    true, // useArgsInsteadOfSingleGene
  );

  const color = getMetricColor(metric, metricMode, activeChartUnit, true);

  if (metric === null || !color) {
    return 'rgba(100, 100, 100, 0.5)';
  }

  return color;
}

function setMatchExpressionData(
  matchExpression: any[],
  regionsWithData: Map<string, number>,
  area: string,
  color: string | null,
  environmentId: number,
) {
  matchExpression.push(area, color);
  regionsWithData.set(area, environmentId);
}

// Gets the correct admin area name based on the admin level from the adminLevels - which is official open street map data
function getCorrectAdminLevelName(
  adminLevels: AdminAreaByLevel | undefined,
  adminLevel: AdminLevelKey,
): string | undefined {
  return adminLevels?.[adminLevel]?.name;
}
