import { DeleteIcon } from '@resistapp/client/components/icons/delete-icon';
import { Feature } from '@resistapp/common/features';
import { Organisation, User } from '@resistapp/common/types';
import {
  useDeleteOrganisation,
  useDeleteOrganisationAccess,
  useDeleteOrganisationUser,
  usePatchOrganisation,
} from '../../hooks/api';

interface DeleteOrgUserIconProps {
  refreshUsersByOrgId: () => Promise<void>;
  user: User;
  organisation: Organisation;
}

export function DeleteOrgUserIcon(props: DeleteOrgUserIconProps) {
  const { refreshUsersByOrgId, organisation, user } = props;
  const del = useDeleteOrganisationUser(organisation.id, user.id);
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync();
        await refreshUsersByOrgId();
      }}
      message={`${user.email} will be removed from ${organisation.name} organisation.\n\nAre you sure?`}
    />
  );
}

interface DeleteOrgAccessIconProps {
  organisation: Organisation;
  projectId: number;
  projectName: string | undefined;
}

export function DeleteOrgAccessIcon(props: DeleteOrgAccessIconProps) {
  const { organisation, projectId, projectName } = props;
  const del = useDeleteOrganisationAccess(organisation.id, projectId);
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync();
      }}
      message={`${organisation.name} will loose access to project '${projectName || '?'}' (${projectId}).\n\nAre you sure?`}
    />
  );
}

export function DeleteFeatureLink(props: { featureToDelete: Feature; organisation: Organisation }) {
  const { organisation, featureToDelete } = props;
  const del = usePatchOrganisation();
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync({
          id: organisation.id,
          features: organisation.features.filter(f => f !== featureToDelete),
        });
      }}
      message={`Remove ${organisation.name}'s access to ${featureToDelete}?`}
    />
  );
}

export function DeleteOrganisationLink(props: { organisation: Organisation }) {
  const { organisation } = props;
  const del = useDeleteOrganisation(organisation.id);
  return (
    <DeleteIcon
      triggerDelete={async () => {
        await del.mutateAsync();
      }}
      message={`THIS WILL ORPHAN ALL USERS (REVOKING THEIR ACCESSES) AND DELETE ${organisation.name}.\n\nARE YOU SURE?`}
    />
  );
}
