import { CountryNameByAlpha3, type PossibleCountryLevel } from '@resistapp/common/types';
import { filter, isNil, map, sortBy, uniq } from 'lodash';
import { OverviewDatum } from '../data-utils/plot-data/build-overview-line-data';

function itemCount(type: string, trendData?: OverviewDatum[][]) {
  const regions = trendData ? filter(uniq(map(trendData.flat(), type)), Boolean) : [];
  return regions.length;
}
function hasRegions(trendData?: OverviewDatum[][]) {
  return trendData?.every(item => item.every(subItem => subItem.environment.region));
}
function hasCountries(trendData?: OverviewDatum[][]) {
  return trendData?.every(item => item.every(subItem => subItem.environment.country));
}
export function showRegions(trendData?: OverviewDatum[][]) {
  return hasRegions(trendData) && itemCount('region', trendData) > 1 && hasCountries(trendData);
}
export function showCountries(trendData?: OverviewDatum[][]) {
  return hasCountries(trendData) && itemCount('country', trendData) > 1;
}

function getCorrectMapSourceFromAdminLevel(
  country: string | undefined | null,
  adminLevel: number,
): MapSourceWithLevel | undefined {
  const countrySources = mapSources.filter(
    source =>
      country === source.countryAlphaCode3?.toUpperCase() &&
      source.tileset.admLevel.includes(adminLevel as PossibleCountryLevel),
  );
  const sortedSources = sortBy(countrySources, source => source.tileset.admLevel);
  return sortedSources.length ? { ...sortedSources[0], adminLevel } : undefined;
}

export function getMapSourceFromAdminLevel(
  adminLevel: number | undefined,
  country?: string | null,
): MapSourceWithLevel | undefined {
  // TODO should we pick only from the admin levels we have map sources for?
  if (!isNil(adminLevel)) {
    const mapSource = getCorrectMapSourceFromAdminLevel(country, adminLevel);
    return mapSource;
  } else {
    return undefined;
  }
}

export interface MapSourceWithLevel extends MapSource {
  adminLevel: number;
}
export interface MapSource {
  countryAlphaCode3: keyof CountryNameByAlpha3 | undefined;
  tileset: {
    url: string;
    sourceLayer: string;
    propertyName: string;
    admLevel: PossibleCountryLevel[];
  };
  mappings: Record<string, string> | undefined;
}

// The admLevel 2 is special, never add it to any of the country specific admin levels
export const mapSources: MapSource[] = [
  {
    countryAlphaCode3: undefined,
    tileset: {
      url: 'mapbox://mapbox.country-boundaries-v1',
      sourceLayer: 'country_boundaries',
      propertyName: 'iso_3166_1_alpha_3',
      admLevel: [2 as PossibleCountryLevel], // Enforce the type, since this is the only exception
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'FIN',
    tileset: {
      url: 'mapbox://janneresistomap.0q5h4ge9',
      sourceLayer: 'osm_fin_adm2_15',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
    mappings: {
      Pohjanmaa: 'Österbotten',
    },
  },
  {
    countryAlphaCode3: 'GBR',
    tileset: {
      url: 'mapbox://janneresistomap.26b8tw9d',
      sourceLayer: 'osm_gbr_adm2_9',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'IDN',
    tileset: {
      url: 'mapbox://janneresistomap.afyhv174',
      sourceLayer: 'osm_idn_adm2_15',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'THA',
    tileset: {
      url: 'mapbox://janneresistomap.0oa12x9d',
      sourceLayer: 'osm_tha_adm2_15',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'NPL',
    tileset: {
      url: 'mapbox://janneresistomap.3mgqbt6x',
      sourceLayer: 'osm_npl_adm2_15',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'IRL',
    tileset: {
      url: 'mapbox://janneresistomap.7y6xesq7',
      sourceLayer: 'osm_irl_adm2_9',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9],
    },
    mappings: undefined,
  },
  {
    countryAlphaCode3: 'ZAF',
    tileset: {
      url: 'mapbox://janneresistomap.d01ds292',
      sourceLayer: 'osm_zaf_adm2_15',
      propertyName: 'name',
      admLevel: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    },
    mappings: undefined,
  },
];
