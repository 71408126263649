import { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react';
import type { NavigateOptions, URLSearchParamsInit } from 'react-router-dom';
// eslint-disable-next-line no-restricted-imports
import { useSearchParams as useSearchParamsOrig } from 'react-router-dom';
import type { OtherParams, QueryParams } from '../utils/url-manipulation';

type SearchParamsContextType = {
  searchParams: URLSearchParams;
  getQueryParams: (key: QueryParams | OtherParams) => string | null;
  setSearchParamsStable: (
    nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
    navigateOpts?: NavigateOptions,
  ) => void;
  searchParamsRef: { current: URLSearchParams };
};

const SearchParamsContext = createContext<SearchParamsContextType | null>(null);

export function SearchParamsProvider({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParamsOrig();
  const setSearchParamsRef = useRef(setSearchParams);
  setSearchParamsRef.current = setSearchParams;
  const searchParamsRef = useRef(searchParams);
  searchParamsRef.current = searchParams;

  const setSearchParamsStable = useCallback(
    (
      nextInit?: URLSearchParamsInit | ((prev: URLSearchParams) => URLSearchParamsInit),
      navigateOpts?: NavigateOptions,
    ) => {
      setSearchParamsRef.current(nextInit, navigateOpts);
    },
    [],
  );

  const getQueryParams = useCallback((key: QueryParams | OtherParams) => searchParamsRef.current.get(key), []);

  return (
    <SearchParamsContext.Provider
      value={{
        searchParams,
        getQueryParams,
        setSearchParamsStable,
        searchParamsRef,
      }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
}

export function useSearchParamsContext() {
  const context = useContext(SearchParamsContext);
  if (!context) {
    throw new Error('useSearchParamsContext must be used within a SearchParamsProvider');
  }
  return context;
}
