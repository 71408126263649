import { theme } from '@resistapp/client/components/shared/theme';
import { useTrendChartContext } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { Group } from '@visx/group';
import { scaleLinear, scaleLog } from '@visx/scale';
import { useCallback, useMemo } from 'react';
import { GeneTrendLines } from '../../chart-components/gene-trend-lines';
import { emptyLegendTextXOffset, strokeColor } from '../chart-styles';
import { BaseChart } from './base-chart';
// We directly modify the trendchart-bottom-legend.tsx instead of using the context here

const exponentRange = [5, 6, 7, 8, 9, 10, 11];
// Maps exponents to human-readable labels
const exponentLabels: Record<number, string> = {
  5: '(LOQ) 100k',
  6: '1M',
  7: '10M',
  8: '100M',
  9: '1B',
  10: '10B',
  11: '100B',
};

export function GeneTrendChart() {
  const {
    graphHeight,
    graphWidth,
    trenchartTooltip: { trendChartSize },
  } = useTrendChartContext();
  const { trendData, selectedOrHoveredAreaOrSiteEnvId, selectedSiteDatum, effectiveSiteDetailsProcessMode } =
    useOverviewContext();

  // Create logarithmic scale for internal data mapping from 10^4 to 10^10
  const logScale = useMemo(() => {
    return scaleLog({
      domain: [10 ** exponentRange[0], 10 ** exponentRange[exponentRange.length - 1]],
      range: [graphHeight, 0],
      // Ensure exact alignment with chart edges
      clamp: true,
    });
  }, [graphHeight]);

  // Create a LINEAR scale with integer steps (4-10) for the y-axis labels
  // This will map directly to powers of 10 (10^4, 10^5, etc.)
  const labelScale = useMemo(() => {
    return scaleLinear({
      // Use 4-10 as the domain (representing powers of 10^4 to 10^10)
      domain: [exponentRange[0], exponentRange[exponentRange.length - 1]],
      // Map to the same height range
      range: [graphHeight, 0],
    });
  }, [graphHeight]);

  // This chart uses the raw log scale for rendering data points
  // but uses a linear scale (4-10) for the axis ticks
  const getValue = useCallback(() => undefined, []);

  // Empty legend component - we'll use the BaseChart's built-in AxisLeft
  const EmptyLegend = useCallback(({ left, height, width }: { left: number; height: number; width: number }) => {
    return <svg width={width} height={height} style={{ position: 'absolute', left }} />;
  }, []);

  // Get context elements outside of the callback to avoid hooks rules violation
  const context = useTrendChartContext();
  const { mouseMoveHandler, mouseClickHandler } = context.trenchartTooltip;
  const { timeScale } = context;

  // Override the renderTrendLines function in BaseChart
  const renderCustomTrendLines = useCallback(
    (_: any) => {
      const correctTrendData =
        (selectedSiteDatum
          ? trendData?.filter(data => data[0].environment.id === selectedSiteDatum.environment.id)
          : trendData) || [];

      if (!timeScale) return <Group />;

      return (
        <Group>
          {correctTrendData.map((lineData, i) => (
            <GeneTrendLines
              key={i}
              data={lineData.map(d => ({ ...d, precalculatedValue: undefined }))}
              timeScale={timeScale}
              valueScale={v => logScale(v)}
              selected={lineData[0].environment.id === selectedOrHoveredAreaOrSiteEnvId}
              mouseMoveHandler={mouseMoveHandler}
              mouseClickHandler={mouseClickHandler}
              siteSelected={!!selectedSiteDatum}
              processMode={effectiveSiteDetailsProcessMode}
            />
          ))}
        </Group>
      );
    },
    [
      trendData,
      selectedOrHoveredAreaOrSiteEnvId,
      selectedSiteDatum,
      logScale,
      timeScale,
      mouseMoveHandler,
      mouseClickHandler,
      effectiveSiteDetailsProcessMode,
    ],
  );

  // Custom reference lines for each exponent of then but on linear scale
  const LogReferenceLines = useCallback(() => {
    return (
      <>
        {exponentRange.map(value => (
          <line
            key={value}
            x1="0"
            x2={graphWidth}
            y1={labelScale(value)}
            y2={labelScale(value)}
            stroke={strokeColor}
            strokeWidth="1"
          />
        ))}

        {/* Manually add the y-axis labels with our desired format */}
        {exponentRange.map(value => (
          <text
            key={`label-${value}`}
            x={-30}
            y={labelScale(value)}
            fontSize={14}
            fill={theme.colors.neutral700}
            fontWeight={theme.fontWeight.bold}
            textAnchor="end"
            dominantBaseline="middle"
          >
            {exponentLabels[value]}
          </text>
        ))}
      </>
    );
  }, [graphWidth, labelScale]);

  return (
    <BaseChart
      width={trendChartSize.width}
      height={trendChartSize.height}
      // For data, use the log scale
      indexScale={logScale}
      // For the y-axis, use the linear scale with exponents 4-10
      labelScale={labelScale}
      // Empty legend since we're using the built-in axis
      Legend={EmptyLegend}
      getValue={getValue}
      TooltipComponent={() => null}
      showData={{ quartileRange: false }}
      renderCustomTrendLines={data => <Group>{renderCustomTrendLines(data)}</Group>}
      renderCustomReferenceLines={() => <LogReferenceLines />}
      // Generate exactly 7 ticks for exponents (4, 5, 6, 7, 8, 9, 10)
      numTicks={exponentRange.length}
      // Move the text labels to the right to account for missing color bar legend
      textXOffset={emptyLegendTextXOffset}
      // Hide the axis labels since we're rendering our own
      hideAxisLabels={true}
    />
  );
}
