import { zEnvironmentSubType, zEnvironmentType } from '@resistapp/common/environment-types';
import { chain, compact, Dictionary, uniq } from 'lodash';
import { coerce } from 'semver';
import { z } from 'zod';
import { ReferenceByMAEC } from './api-types';
import { zL1Targets, zL2Targets } from './assays';
import { safeAssert } from './assert-utils';
import { Feature } from './features';
import { RequiredFields } from './type-utils';

// Keep consistent with analysis
export enum BioRep {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
  O = 'O',
  P = 'P',
  Q = 'Q',
  R = 'R',
  S = 'S',
  T = 'T',
  U = 'U',
  V = 'V',
  W = 'W',
  X = 'X',
  Y = 'Y',
  Z = 'Z',
}
export const zBioRep = z.nativeEnum(BioRep);

export enum AccessSubjectType {
  ORG = 'ORG',
  USER = 'USER',
}
export const zAccessSubjectType = z.nativeEnum(AccessSubjectType);

export interface UserProject {
  userId: number;
  projectId: number;
}

export interface OrganisationProject {
  organisationId: number;
  projectId: number;
}

export interface Access {
  projectId: number;
  subjectType: AccessSubjectType;
  subjectId: number;
}

export interface OrganisationUser {
  organisationId: number;
  userId: number;
}

export interface RawUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface User extends RawUser {
  id: number;
  token?: string;
  directAccesses: Array<Pick<Access, 'projectId'>>;
  organisations: Organisation[];
}

export interface Organisation {
  id: number;
  name: string;
  isDemo: boolean;
  signupCode: string | null;
  accesses: Array<Pick<Access, 'projectId'>>;
  features: Feature[];
  defaultMetric: MetricMode | null;
}

export const zLevelsWithZoomableAreas = z.record(z.string(), z.array(z.number()));
export type LevelsWithZoomableAreas = z.infer<typeof zLevelsWithZoomableAreas>;

export enum ProjectSampleAction {
  ADD = 'ADD',
  MOVE = 'MOVE',
  REMOVE = 'REMOVE',
}
export const zProjectSamplesUpdate = z.record(
  z.string(),
  z.object({
    action: z.enum([ProjectSampleAction.ADD, ProjectSampleAction.MOVE, ProjectSampleAction.REMOVE]),
    sampleIds: z.array(z.number()),
    sourceProjectId: z.number(),
  }),
);
export type ProjectSamplesUpdate = z.infer<typeof zProjectSamplesUpdate>;

export interface Project {
  id: number;
  name: string;
  type: ProjectType;
  statusUserId?: number;
  statusNote?: string;
  filenames?: string[];
  warnings?: string[];
  driveLink?: string;
  customerSheetLink?: string;
  publicationName?: string;
  publicationUrl?: string;
  createdAt: Date | string;
  levelsWithZoomableAreas: PartialDict<PossibleZoomableLevel[]>; // { countryCode: [level1, level2, ...] }
  originalLevelsWithZoomableAreas: PartialDict<PossibleZoomableLevel[]>; // { countryCode: [level1, level2, ...] }
  userAccessCount?: number;
  disableTimeseries: boolean;
}

export interface ChipInfo {
  runDate: Date;
  dispenser: number;
  cycler: number;
}

export interface ProjectData {
  ctsByChipName: Dictionary<CtCsvRow[]>;
  chipInfoByChipName: Dictionary<ChipInfo>;
  parsedSamples: SampleToBeCreated[];
  customerSheetLink: string | undefined;
  mmLot: string;
  previousLabSheetUrls: string[];
}

export enum ProjectType {
  NORMAL = 'NORMAL',
  POOLED = 'POOLED',
  LEGACY = 'LEGACY',
}

export enum SampleStatus {
  DRAFT = 'DRAFT',
  FAILED = 'FAILED',
  DUPLICATE = 'DUPLICATE',
  POOLED = 'POOLED',
  APPROVED = 'APPROVED',
  APPROVED_WITH_ISSUES = 'APPROVED_WITH_ISSUES',
  APPROVED_SKIP_RESULT_STATISTICS = 'APPROVED_SKIP_RESULT_STATISTICS',
}

export function isExternallyShownSample(sample: Sample): boolean {
  return sample.status !== SampleStatus.DRAFT && sample.status !== SampleStatus.FAILED;
}

export type CopiesByUB = Dictionary<Dictionary<number | undefined>>; // By sample UID, bioRep
export type ReplicatedSamples = FullSample[]; // Array of biologibal replica samples
export type FullSamplesByUID = Dictionary<ReplicatedSamples>; // By getBioRepGroupKey

export interface FullProject extends Project {
  qpcrFiles: Dictionary<string>;
  samplesByUID: FullSamplesByUID;
  chips: Chip[];
  // For supported projects and overview users only
  referencesByMAEC?: ReferenceByMAEC;
  // The focused term in focusedByUID attempts to communicate that
  // - samples filtered based on query parameters
  // - sample abundances are filtered based on query parameters
  //   - 16S rRNA either filtered out, or all other genes are filtered out
  focusedByUID?: FullSamplesByUID;
  assayVersion?: string;
  resultsVersion?: number;
  assayChangeNotification?: string;
}

// Base type for samples (can be generated as an empty placeholder to get the ID for tubes)
export interface SamplePlaceholder {
  id: number;
}

export interface SampleNormalisationMetadata {
  // DNA measurements - diluted
  dilutedDnaVolume: number | null;
  dilutedDnaConcentration: number | null;
  dilutedDnaQuality280: number | null;
  dilutedDnaQuality230: number | null;
  // DNA measurements - eluted
  elutedDnaQuality280: number | null;
  elutedDnaQuality230: number | null;
  elutedDnaConcentration: number | null;
  elutedDnaVolume: number | null;
  // Specific for water samples
  filteredVolume: number | null; // copies/L
  // Wastewater treatment plan specific
  bod: number | null; // mg/L
  suspendedSolids: number | null; // mg/L
  operatedFlowRate: number | null; // L/h
  flowRate: number | null; // L/h
}

export interface SampleDataColumns extends SampleNormalisationMetadata {
  number: number | null;
  bioRep: BioRep | null;
  environmentId: number | null;
  time: Date | null;
  // lat/lon are kept in samples as supporting data for:
  // 1. Moving environments (e.g. ships, mobile sampling units)
  // 2. Large environments where biological samples are taken from significantly different locations
  specificLat: number | null;
  specificLon: number | null;
  status: SampleStatus | null;
}

export interface SampleColumns extends SamplePlaceholder, SampleDataColumns {}

// TODO this started out as a Record type for creating a sample, but it has since been taken to use too braodly
export interface RawSample extends SampleColumns {
  number: number;
  bioRep: BioRep;
  environmentId: number;
  environment: RawEnvironment; // TODO this should be optional as it is not loaded by default
  projects: ProjectSample[];
  status: SampleStatus;
}

export interface ProjectSample {
  projectId: number;
  sampleId: number;
  id: number;
}

export interface Sample extends RawSample {
  environment: Environment | PooledEnvHack; // TODO this should be optional as it is not loaded by default
}

export interface FullSample extends Sample {
  abundances: FullAbundance[]; // To be deprecated. UI still expects the legacy abundances result format, and it is populated by buildSamplesByUID
  cts: CtCsvRow[];
  // Not needed by UI yet
  // assayResults: AssayResult[];
  // meanCts: MeanCt[];
}

// Type for samples during creation phase, before they have IDs assigned
// TODO: Remove 'id' from Omit once sample sheets provide real IDs
export interface SampleToBeCreated extends Omit<SampleColumns, 'projectId' | 'environmentId'> {
  environment: RawEnvironment;
}

export const frozenFieldsForApprovedSamples: Array<keyof SampleToBeCreated> = [
  'bioRep',
  'number',
  'dilutedDnaVolume',
  'dilutedDnaConcentration',
  'dilutedDnaQuality280',
  'dilutedDnaQuality230',
  'elutedDnaQuality280',
  'elutedDnaQuality230',
  'elutedDnaConcentration',
  'elutedDnaVolume',
  'filteredVolume',
  'bod',
  'suspendedSolids',
  'operatedFlowRate',
  'flowRate',
  'time',
];

export type SampleToBeCreatedWithoutId = Omit<SampleToBeCreated, 'id'>;

export function isPopulatedSample(sample: NullablePartial<SampleColumns>) {
  return (
    typeof sample.environmentId === 'number' &&
    typeof sample.number === 'number' &&
    typeof sample.bioRep === 'string' &&
    typeof sample.status === 'string'
  );
}

export function assertPopulatedSample(samples: Array<NullablePartial<SampleColumns>>): asserts samples is RawSample[] {
  samples.forEach((sample: NullablePartial<SampleColumns>) => {
    safeAssert(isPopulatedSample(sample), `Sample ${sample.id} is not populated`);
  });
}

export function isFullSample(sample: NullablePartial<FullSample>): sample is FullSample {
  return isPopulatedSample(sample) && !!sample.projects && !!sample.environment && !!sample.cts;
}

export type PossibleZoomableLevel = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
export type PossibleCountryLevel = 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15;
export type AdminLevelKey =
  | '1' // Resistomap's custom Global level
  | '2' // Finland
  | '3' // Åland
  | '4'
  | '5'
  | '6' // Uusimaa
  | '7' // Helsinki sub-region
  | '8' // Helsinki
  | '9' // Central major district
  | '10' // Vallila
  | '11'
  | '12'
  | '13'
  | '14'
  | '15';

export const zAdminLevelKey = z.enum(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15']);

type MinLonLat = { lon: number; lat: number };
type MaxLonLat = MinLonLat;

export type AdminAreaBoundaries = [MinLonLat, MaxLonLat]; // Accepted by mapbox

const zLonLat = z.object({
  lon: z.number(),
  lat: z.number(),
});
const zMaxLonLat = zLonLat;
const zMinLonLat = zLonLat;
const zSouthWest = zMinLonLat;
const zNorthEast = zMaxLonLat;

// Boundaries are in the strict format of [SouthWest, NorthEast]
const zAdminAreaBoundaries = z.tuple([zSouthWest, zNorthEast]);

export interface AdminAreaMetadata {
  level: number;
  name: string;
}

const zAdminAreaMetadata = z.object({
  level: z.number(),
  name: z.string(),
});

const zAdminArea = zAdminAreaMetadata.extend({
  boundaries: zAdminAreaBoundaries.optional(),
});
export type AdminArea = z.infer<typeof zAdminArea>;

export const zAdminAreaByLevel = z.record(zAdminLevelKey, zAdminArea);
export type AdminAreaByLevel = z.infer<typeof zAdminAreaByLevel>;

export type AdminAreaMetadataByLevel = {
  [key in AdminLevelKey]?: AdminAreaMetadata;
};

export interface AdminLevelWithParents extends AdminArea {
  parent?: AdminArea;
}

export const zRawEnvironmentCoreFields = z.object({
  name: z.string(),
  type: zEnvironmentType,
  subtype: zEnvironmentSubType.nullable(),
  country: z.string().nullable(),
  region: z.string().nullable(),
  city: z.string().nullable(),
  lat: z.number().nullable(),
  lon: z.number().nullable(),
});
export const zRawEnvironment = z.object({
  ...zRawEnvironmentCoreFields.shape,
  inferredLat: z.number().nullable(),
  inferredLon: z.number().nullable(),
  adminLevels: zAdminAreaByLevel.nullable(),
});
export type RawEnvironment = z.infer<typeof zRawEnvironment>;

export const zEnvironment = zRawEnvironment.extend({
  id: z.number(),
});
export type Environment = z.infer<typeof zEnvironment>;

// HACK: originalEnvironmentNames and od that is only populated by the UI pooling code
// it is used for keeping track of the original environment, eg. for keeping environmentNames query param intact
// despite the formation of transient pooled (site or admin level samples in createPooledSample).
// TODO: consider removing when implementing sites
export interface PooledEnvHack extends Environment {
  originalEnvironmentNames?: string[];
  originalEnvironmentId?: number;
}

/**
 * Gets the original environment ID from an environment object.
 * If the environment has an originalEnvironmentId, returns that.
 * Otherwise, returns the environment's own ID only if it's positive.
 * This prevents bogus negative IDs from being used in query parameters.
 */
export function getOriginalEnvironmentId(environment: Environment | PooledEnvHack | undefined): number | undefined {
  if (!environment) {
    return undefined;
  }

  const pooledEnv = environment as PooledEnvHack;
  if (pooledEnv.originalEnvironmentId !== undefined) {
    return pooledEnv.originalEnvironmentId;
  }

  // Only use the environment's ID if it's positive (not a bogus negative ID)
  return environment.id > 0 ? environment.id : undefined;
}

export function getAllOriginalEnvironmentIds(
  env1: Environment | PooledEnvHack,
  env2: Environment | PooledEnvHack | undefined,
): number[] {
  const ids = compact(uniq([getOriginalEnvironmentId(env1), getOriginalEnvironmentId(env2)]));
  return ids;
}

export type VersionedSample = Omit<FullSample, 'abundances'> &
  Required<Pick<FullSample, 'environment'>> & { assayResults: AssayResult[]; meanCts: MeanCt[] };

export interface SampleWithEnvironment extends SampleToBeCreated {
  environmentId: number;
  environment: Environment;
}

export type SampleForCreation = Omit<RawSample, 'id'> & {
  id?: number;
};

export type FullSampleWithTime = RequiredFields<FullSample, 'time'>;

// Keep consistent with rs_to_api
export const zAbundance = z.object({
  gene: z.string(),
  assay: z.string(),
  assayVersion: z.string(),
  serverVersion: z.string(),
  analysisVersion: z.string(),
  relative: z.number().nullable(),
  meanCt: z.number().nullable(),
  traces: z.boolean(),
});
export type Abundance = z.infer<typeof zAbundance>;

export const zFullAbundance = zAbundance.extend({
  absolute: z.number().nullable(),
  copiesPerL: z.number().nullable(),
  copiesPerHour: z.number().nullable(),
  copiesPerMgSS: z.number().nullable(),
  copiesPerMgBod: z.number().nullable(),
});
export type FullAbundance = z.infer<typeof zFullAbundance>;
export type StrippedFullAbundance = Pick<
  FullAbundance,
  'assay' | 'gene' | 'relative' | 'absolute' | 'copiesPerL' | 'copiesPerHour' | 'copiesPerMgSS' | 'copiesPerMgBod'
>;

export interface WithStartAndEndDate {
  startDate: string | Date | undefined;
  endDate: string | Date | undefined;
}

// Keep in sync with api_by_python_column and rs_to_api
export interface AnalysisAbundance extends Abundance {
  sampleNum: number;
  bioRep: BioRep;
  target: string;
  analysisVersion: string;
}

export enum HeatmapType {
  ALL = 'resistomap_all',
  DETECTED = 'resistomap_det',
  QUANTIFIED = 'resistomap_qnt',
}

export interface Correlation {
  // carrierAssay: string;
  // resistanceAssay: string;
  carrierGene: string;
  resistanceGene: string;
  target: string;
  assayVersion: string;
  serverVersion: string;
  analysisVersion: string;
  value: number;
}

type Lat = number;
type Lon = number;
type Coordinate = [Lat, Lon];
export type AdminLevelGeodata = {
  id: number;
  localName: string;
  nameEn: string;
  countryId: string;
  adminLevel: number;
  coordinates: Coordinate[];
  boundaries: AdminAreaBoundaries | undefined; // High admin level boundaries may be missing if we haven't had credits to fetch them
};
export type AdminLevelBoundaryData = Omit<AdminLevelGeodata, 'coordinates'>;

export interface Country {
  alpha3: string;
  alpha2: string;
  name: string;
  continent: Continent;
}

export type CountryNameByAlpha3 = Record<string, string>;
export type CountryByAlpha3 = Record<string, Country>;

export function getCountryNameByAlpha3(countryByAlpha3: CountryByAlpha3): CountryNameByAlpha3 {
  return chain(countryByAlpha3)
    .mapValues(c => c.name)
    .value();
}

export enum Continent {
  AFRICA = 'AFRICA',
  ASIA = 'ASIA',
  EUROPE = 'EUROPE',
  NORTH_AMERICA = 'NORTH_AMERICA',
  OCEANIA = 'OCEANIA',
  SOUTH_AMERICA = 'SOUTH_AMERICA',
  ANTARCTICA = 'ANTARCTICA',
}

export type CsvRows = Array<Dictionary<string | number | undefined>>;

export type AbundanceByBA = Partial<{
  [key in BioRep]: Dictionary<FullAbundance>; // Not full for old projects
}>;

export type PartialDict<T> = Partial<Dictionary<T>>;

type NullablePartial<T> = {
  [P in keyof T]?: T[P] | null;
};

export type AbundancesByUBA = PartialDict<AbundanceByBA>;

export const zCoordinates = z.object({
  lat: z.number(),
  lon: z.number(),
});
export type Coordinates = z.infer<typeof zCoordinates>;

const assaySample = {
  assay: z
    .string()
    .min(3)
    .transform(val => val.trim()),
  sample: z
    .string()
    .min(1)
    .transform(val => val.trim()), // sampleId-numBiorep-rep1
};
const analysisValues = {
  ct: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  tm: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  efficiency: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  flags: z.string().nullable(),
};
export const zAnalysisCtValue = z.object({ ...assaySample, ...analysisValues });
export type AnalysisCtValue = z.infer<typeof zAnalysisCtValue>;

// We cannot extend, because we want to maintain key (column) order lof the origincal cts file
export const zCtRow = z.object({
  row: z.string().transform(val => parseInt(val, 10)),
  column: z.string().transform(val => parseInt(val, 10)),
  ...assaySample,
  conc: z
    .string()
    .nullable()
    .transform(val => (val ? parseFloat(val.replace(',', '.')) : null)),
  ...analysisValues,
});
export type CtCsvRow = z.infer<typeof zCtRow>;
export type CtCsvRowWithSampleId = CtCsvRow & { sampleId: number | null };
export const ctsHeaders = Object.keys(zCtRow.shape) as Array<keyof CtCsvRow>;
export const zCtTableColumns = z.object({
  id: z.number(),
  chipId: z.number(),
  sampleId: z.number().nullable(),
  ...zCtRow.shape,
});
export type CtsColumns = z.infer<typeof zCtTableColumns>;

export const zChip = z.object({
  id: z.number(),
  name: z.string(),
  mmLot: z.string().nullable(),
  runDate: z.date().nullable(),
  labSheetUrl: z.string().nullable(),
  legacyProjectId: z.number().nullable(),
  dispenser: z.number().nullable(),
  cycler: z.number().nullable(),
});
export type Chip = z.infer<typeof zChip>;

// The MetricModes order matters for default metric selection. ARGI the default one and reduction the last choice
export enum MetricMode {
  ARGI = 'ARGI',
  RISK = 'RISK',
  REDUCTION = 'REDUCTION',
}
export const zMetricMode = z.nativeEnum(MetricMode);

export enum ProcessMode {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  DURING = 'DURING',
}
export const zProcessMode = z.nativeEnum(ProcessMode);

// Normalization modes in priority order
export enum NormalisationMode {
  MG_SS = 'MG_SS', // Copier per mg Suspendedd Solids
  HOUR = 'HOUR', // Copier per hour
  LITRE = 'LITRE', // Copier per litre
  TEN_UL_DILUTED_DNA = 'TEN_UL_DILUTED_DNA', // old 'absolute' mode
  MG_BOD = 'MG_BOD', // Copier per mg Biochemical Oxygen Demand
  SIXTEEN_S = 'SIXTEEN_S', // relative
}
export const zNormalisationMode = z.nativeEnum(NormalisationMode);

export enum ChartUnit {
  COPIES_PER_L = 'COPIES_PER_L', // NormalisationMode.LITRE
  COPYNUMBER = 'COPYNUMBER', // NormalisationMode.TEN_UL_DILUTED_DNA
}

// Type for raw database result with snake_case column names
export type DbVersion = {
  id: number;
  assay_version: string;
  server_version: string;
  name: string;
  description: string;
  change_notification: string | null;
  created_at: Date;
  updated_at: Date;
};
export interface Version {
  id: number;
  assayVersion: string;
  serverMinor: string;
  name: string;
  description: string;
  changeNotification?: string | null;
  createdAt: Date;
  updatedAt: Date;
}
export const zPostVersionRequest = z.object({
  id: z.number().refine(n => Number.isInteger(n * 10) && n.toString().match(/^\d{4}\.\d+$/), {
    message: 'Version ID must be in format YYYY.N where N is an integer',
  }),
  assayVersion: z.string().refine(v => !!coerce(v), {
    message: 'Assay version must follow semver',
  }),
  name: z.string(),
  description: z.string(),
  changeNotification: z.string().optional(),
});
export type PostVersionRequest = z.infer<typeof zPostVersionRequest>;

// Base type definitions for Assay
export const zAssayBase = z.object({
  assay: z.string(),
  gene: z.string(),
  deprecated: z.boolean(),
  minMeltingTemperature: z.number().nullable(),
  maxMeltingTemperature: z.number().nullable(),
  targetGroupLvl1: zL1Targets,
  targetGroupLvl2: zL2Targets,
  targetGroupLvl3: z.string().nullable(),
  targetGroupLvl4: z.string().nullable(),
  targetGroupLvl5: z.string().nullable(),
  genePackage1: z.boolean(),
  genePackage2: z.boolean(),
  genePackage21: z.boolean(),
  genePackage3: z.boolean(),
  forwardPrimer: z.string().nullable(),
  reversePrimer: z.string().nullable(),
  primerRef: z.string().nullable(),
  primerRefUrl: z.string().nullable(),
  revPrimerRef: z.string().nullable(),
  revPrimerRefUrl: z.string().nullable(),
});
export type AssayBase = z.infer<typeof zAssayBase>;

export const zAssayRaw = zAssayBase.extend({
  version: z.string(),
});

export type AssayRaw = z.infer<typeof zAssayRaw>;

export interface Assay extends AssayRaw {
  createdAt: Date;
}

export type RawMeanCt = {
  versionId: number;
  sampleId: number;
  assay: string;
  meanCt: number | null;
  traces: boolean;
  serverVersion: string | null; // Populated only for AY 1, for manual investigations only
};

export interface MeanCt extends RawMeanCt {
  gene?: string;
  createdAt: Date;
}

export interface RawAssayResult {
  versionId: number;
  sampleId: number;
  assay: string;
  normalisationMode: NormalisationMode;
  value: number | null;
  traces: boolean;
}

export interface AssayResult extends RawAssayResult {
  gene?: string;
  createdAt: Date;
}

const zSampleStatusUpdate = z.object({
  id: z.number(),
  status: z.nativeEnum(SampleStatus),
});
export const zSampleStatusUpdates = z.array(zSampleStatusUpdate);
export type SampleStatusUpdates = z.infer<typeof zSampleStatusUpdates>;
