import { chain, Dictionary, every, filter, get, values } from 'lodash';
import { GetGroup, L1Targets, L2Target } from './assays';
import { FullSamplesByUID, MetricMode } from './types';

// TODO deprecate
// This  was a transitionary thing before we updated the L2Targets to have these categories
// but the selected POLLUTION_MARKERs and the 4 MST genes still genes still need to be identified eg. as l3Targets before we can remove thiss
export enum MinorTarget {
  MST = 'MST',
  POLLUTION_MARKER = 'POLLUTION_MARKER',
  PATHOGEN = 'PATHOGEN',
  MGE = 'MGE',
}

// TODO move to assays when the new categorization is ready
// TODO also revert hacks in isAntibiotic when implementing new antibiotic grouping
// Source: https://docs.google.com/spreadsheets/d/15kQ2Mr0FVRWfXBd9EaHY7vXP2LMN5pNF9SkaOSJmxgo/edit?gid=0#gid=0
const minorTargetByAssay: Dictionary<MinorTarget> = {
  AY634: MinorTarget.MST,
  AY635: MinorTarget.MST,
  AY636: MinorTarget.MST,
  AY633: MinorTarget.MST,
  // AY641: MinorTarget.MST, Lachnospiraceae, not in 96 genes

  AY473: MinorTarget.PATHOGEN,
  AY475: MinorTarget.PATHOGEN,
  AY476: MinorTarget.PATHOGEN,
  AY478: MinorTarget.PATHOGEN,
  AY479: MinorTarget.PATHOGEN,
  AY480: MinorTarget.PATHOGEN,
  AY640: MinorTarget.PATHOGEN,
  AY629: MinorTarget.PATHOGEN,
  AY637: MinorTarget.PATHOGEN,
  AY638: MinorTarget.PATHOGEN,
  AY631: MinorTarget.PATHOGEN,
  AY639: MinorTarget.PATHOGEN,
  AY632: MinorTarget.PATHOGEN,
  AY628: MinorTarget.PATHOGEN,
  AY626: MinorTarget.PATHOGEN,
  AY625: MinorTarget.PATHOGEN,
  AY627: MinorTarget.PATHOGEN,
  AY630: MinorTarget.PATHOGEN,

  AY465: MinorTarget.POLLUTION_MARKER,
  AY197: MinorTarget.POLLUTION_MARKER,
  AY198: MinorTarget.POLLUTION_MARKER,
  AY466: MinorTarget.POLLUTION_MARKER,
  AY191: MinorTarget.POLLUTION_MARKER,
  AY218: MinorTarget.POLLUTION_MARKER,

  AY293: MinorTarget.MGE,
  AY289: MinorTarget.MGE,
  AY290: MinorTarget.MGE,
  AY296: MinorTarget.MGE,
  AY294: MinorTarget.MGE,
  AY500: MinorTarget.MGE,
  AY292: MinorTarget.MGE,
  AY501: MinorTarget.MGE,
  AY502: MinorTarget.MGE,
  AY503: MinorTarget.MGE,
  AY504: MinorTarget.MGE,
  AY505: MinorTarget.MGE,
  AY317: MinorTarget.MGE,
  AY316: MinorTarget.MGE,
  AY318: MinorTarget.MGE,
  AY319: MinorTarget.MGE,
  AY320: MinorTarget.MGE,
  AY313: MinorTarget.MGE,
  AY314: MinorTarget.MGE,
  AY506: MinorTarget.MGE,
  AY310: MinorTarget.MGE,
  AY508: MinorTarget.MGE,
  AY509: MinorTarget.MGE,
  AY510: MinorTarget.MGE,
  AY511: MinorTarget.MGE,
  AY512: MinorTarget.MGE,
  AY507: MinorTarget.MGE,
  AY513: MinorTarget.MGE,
  AY514: MinorTarget.MGE,
  AY515: MinorTarget.MGE,
  AY516: MinorTarget.MGE,
  AY298: MinorTarget.MGE,
  AY517: MinorTarget.MGE,
  AY518: MinorTarget.MGE,
  AY311: MinorTarget.MGE,
  AY519: MinorTarget.MGE,
  AY520: MinorTarget.MGE,
  AY312: MinorTarget.MGE,
  AY309: MinorTarget.MGE,
  AY521: MinorTarget.MGE,
  AY522: MinorTarget.MGE,
  AY307: MinorTarget.MGE,
  AY324: MinorTarget.MGE,
  AY321: MinorTarget.MGE,
  AY323: MinorTarget.MGE,
  AY322: MinorTarget.MGE,
  AY523: MinorTarget.MGE,
  AY315: MinorTarget.MGE,
  AY524: MinorTarget.MGE,
  AY299: MinorTarget.MGE,
  AY300: MinorTarget.MGE,
  AY301: MinorTarget.MGE,
  AY302: MinorTarget.MGE,
  AY303: MinorTarget.MGE,
  AY304: MinorTarget.MGE,
  AY305: MinorTarget.MGE,
  AY297: MinorTarget.MGE,
  AY526: MinorTarget.MGE,
  AY527: MinorTarget.MGE,
  AY306: MinorTarget.MGE,
};

export function filterMinorTarget<T extends { assay: string }>(
  abundances: T[],
  minorTarget: MinorTarget,
  includeAY1: boolean = false,
) {
  return filter(abundances, a => {
    const assayMinorTarget = get(minorTargetByAssay, a.assay, undefined);
    return assayMinorTarget === minorTarget || (includeAY1 && a.assay === 'AY1');
  });
}

export function isMinorTarget(str: string): str is MinorTarget {
  return values(MinorTarget).includes(str as MinorTarget);
}

// TODO move to assays
export const oneHealthAssays = new Set([
  'AY1',
  'AY8',
  'AY10',
  'AY331',
  'AY412',
  'AY413',
  'AY419',
  'AY427',
  'AY603',
  'AY24',
  'AY98',
  'AY117',
  'AY446',
  'AY339',
  'AY134',
  'AY125',
  'AY448',
  'AY130',
  'AY153',
  'AY152',
  'AY120',
  'AY110',
  'AY601',
  'AY435',
  'AY145',
  'AY126',
  'AY104',
  'AY439',
  'AY105',
  'AY129',
  'AY114',
  'AY111',
  'AY133',
  'AY293',
  'AY207',
  'AY199',
  'AY356',
  'AY485',
  'AY211',
  'AY489',
  'AY353',
  'AY318',
  'AY310',
  'AY512',
  'AY520',
  'AY309',
  'AY524',
  'AY299',
  'AY300',
  'AY301',
  'AY528',
  'AY533',
  'AY536',
  'AY538',
  'AY553',
  'AY94',
  'AY465',
  'AY198',
  'AY466',
  'AY191',
  'AY218',
  'AY558',
  'AY29',
  'AY32',
  'AY565',
  'AY456',
  'AY96',
  'AY460',
  'AY461',
  'AY246',
  'AY473',
  'AY634',
  'AY635',
  'AY636',
  'AY633',
  'AY629',
  'AY637',
  'AY475',
  'AY631',
  'AY476',
  'AY478',
  'AY632',
  'AY479',
  'AY628',
  'AY626',
  'AY625',
  'AY480',
  'AY627',
  'AY254',
  'AY262',
  'AY367',
  'AY574',
  'AY263',
  'AY267',
  'AY595',
  'AY159',
]);

type RiskClass = 'I' | 'II' | 'III' | 'IV';
type RiskClassByAssay = Record<string, RiskClass | undefined>;

// 19.9.2024 https://docs.google.com/spreadsheets/d/1mwrr1munOSpoNgq_nyIURG87bD0aMVmsyf7_fplclQw/edit?gid=0#gid=0
// Highest class captured by assay, with WIndi filled in blanks
export const riskClassByOneHealthAssay: RiskClassByAssay = {
  AY8: 'IV',
  AY10: 'I',
  AY331: 'I',
  AY412: 'IV',
  AY413: 'II',
  AY419: 'I',
  AY427: 'IV',
  AY603: 'IV',
  AY24: 'I',
  AY98: 'IV',
  AY117: 'III',
  AY446: 'I',
  AY339: 'I',
  AY134: 'I',
  AY125: 'I',
  AY448: 'IV',
  AY130: 'III',
  AY153: 'IV',
  AY152: 'I',
  AY120: 'II',
  AY110: 'I',
  AY601: 'IV',
  AY435: 'I',
  AY145: 'III',
  AY126: 'I',
  AY104: 'IV',
  AY439: 'IV',
  AY105: 'I',
  AY129: 'II',
  AY114: 'II',
  AY111: 'IV',
  AY133: 'IV',
  AY207: 'III',
  AY199: 'III',
  AY356: 'III',
  AY485: 'II',
  AY211: 'III',
  AY489: 'IV',
  AY353: 'III',
  AY528: 'I',
  AY533: 'I',
  AY536: 'I',
  AY538: 'IV',
  AY553: 'I',
  AY94: 'IV',
  AY465: 'I',
  AY198: 'IV',
  AY466: 'I',
  AY191: 'IV',
  AY218: 'IV',
  AY558: 'IV',
  AY29: 'I',
  AY32: 'I',
  AY565: 'IV',
  AY456: 'IV',
  AY96: 'II',
  AY460: 'III',
  AY461: 'I',
  AY246: 'I',
  AY254: 'IV',
  AY262: 'IV',
  AY367: 'I',
  AY574: 'I',
  AY263: 'II',
  AY267: 'II',
  AY595: 'IV',
  AY159: 'IV',
};

if (Object.keys(riskClassByOneHealthAssay).find(assay => !oneHealthAssays.has(assay))) {
  throw Error('Assay in riskClassByOneHealthAssay not found in oneHealthAssays');
}

export function areSamplesAnalysedWithOneHealthPanel(samplesByUID: FullSamplesByUID) {
  // TODO we could allow superset and limit ARGI to the onehealth assays
  const samples = chain(samplesByUID).values().flatten().value();
  const sample = samples.length ? samples[0] : undefined;
  const assays = sample
    ? chain(sample.abundances)
        .map(a => a.assay)
        .uniq()
        .value()
    : [];
  return assays.length === oneHealthAssays.size && every(assays, a => oneHealthAssays.has(a));
}

export function countMetricGenes(
  samplesByUID: FullSamplesByUID,
  metric: MetricMode,
  selectedAntibiotic: L2Target | undefined,
  getGroup: GetGroup,
) {
  const samples = chain(samplesByUID).values().flatten().value();
  const sample = samples.length ? samples[0] : undefined;
  const assays = sample
    ? chain(sample.abundances)
        .map(a => a.assay)
        .filter(a => !selectedAntibiotic || getGroup(a) === selectedAntibiotic)
        .uniq()
        .value()
    : [];

  switch (metric) {
    case MetricMode.RISK:
      return assays.filter(a => riskClassByOneHealthAssay[a]).length;
    case MetricMode.ARGI:
      return assays.filter(ay => getGroup(ay, 'l1Target') === L1Targets.ARG).length;
    case MetricMode.REDUCTION:
      return assays.length;
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw Error(`Unknown metric ${metric}`);
  }
}
