import { ProjectType, SampleStatus } from '@resistapp/common/types';
import { theme } from './theme';

export function getStatusStyles(statusOrType: ProjectType | SampleStatus | string) {
  return statusOrType === (SampleStatus.APPROVED as string)
    ? { bg: 'green', text: 'white', borderRadius: theme.borders.radius.full }
    : statusOrType === (SampleStatus.DRAFT as string)
      ? { bg: theme.colors.neutral500, text: 'white', borderRadius: theme.borders.radius.full }
      : statusOrType === (SampleStatus.APPROVED_WITH_ISSUES as string) ||
          statusOrType === (SampleStatus.APPROVED_SKIP_RESULT_STATISTICS as string)
        ? { bg: 'orange', text: theme.colors.neutral900, borderRadius: theme.borders.radius.full }
        : statusOrType === (SampleStatus.FAILED as string)
          ? { bg: 'red', text: theme.colors.neutral900, borderRadius: theme.borders.radius.full }
          : {
              bg: theme.colors.neutral100,
              text: theme.colors.neutral900,
              borderRadius: theme.borders.radius.full,
            };
}

export const dotStylesForSelect = (content: string, numberValue?: number | undefined) => ({
  position: 'relative',
  alignItems: 'center',
  display: 'flex',

  ':before':
    content === 'PO'
      ? {
          backgroundColor: theme.colors.neutral100,
          color: theme.colors.neutral900,
          borderRadius: theme.borders.radius.full,
          fontSize: theme.fontSize.small,
          content: `"${content}"`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: theme.spacing[2],
          height: 6,
          minHeight: 6,
          width: 7,
          minWidth: 7,
          border: `1px solid ${theme.colors.neutral900}`,
        }
      : undefined,
  ':after':
    numberValue && content !== 'PO'
      ? {
          backgroundColor: theme.colors.neutral100,
          color: theme.colors.neutral900,
          borderRadius: theme.borders.radius.full,
          fontSize: theme.fontSize.small,
          content: `"${numberValue}"`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          left: theme.spacing[12],
          top: '50%',
          transform: 'translateY(-50%)',
          height: 4,
          minHeight: 4,
          width: 4,
          minWidth: 4,
          border: `1px solid ${theme.colors.neutral900}`,
          marginLeft: theme.spacing[2],
        }
      : undefined,
});
