import { DEFAULT_START_INTERVAL, ensureLocalStartOfMonth } from '@resistapp/common/friendly';
import { chain } from 'lodash';
import { FilterInterval } from '../filter-data/filter';
import { OverviewDatum, OverviewLineData } from './build-overview-line-data';

export function buildMapData(trendData: OverviewLineData, interval: FilterInterval): OverviewDatum[] {
  // Drop abundances that are outside of the interval
  return trendData.map(envData => {
    const withinIntervalData = envData.filter(datum => {
      const datumDate = new Date(datum.date);
      return datumDate >= interval.start && datumDate < interval.end;
    });
    if (withinIntervalData.length === 1 || interval.start.getTime() === DEFAULT_START_INTERVAL.getTime()) {
      // One datum within interval, or showing latest datum for each env by default
      return { ...withinIntervalData[withinIntervalData.length - 1] };
    } else {
      // Zero (or more than one) samples within the interval, return a copy of thelatest datum without abundances
      const mutatingDatum = { ...envData[envData.length - 1] };
      mutatingDatum.beforeAbundances = undefined;
      mutatingDatum.afterAbundances = undefined;
      mutatingDatum.date = ensureLocalStartOfMonth(interval.start).toISOString();
      return mutatingDatum;
    }
  });
}

export function getUniqueMonths(data: OverviewLineData): string[] {
  return chain(data)
    .flatten()
    .map(datum => datum.date)
    .uniq()
    .sort()
    .reverse()
    .value();
}
