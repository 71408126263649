import { Props, Select, SelectInstance, type GroupBase } from 'chakra-react-select';
import { RefAttributes } from 'react';

// Styling for this happens with index.css and targeting with className
// because Chakra style customizations don't seem to work for the third party chakra react select component
export const resistomapSelectClassNamePrefix = 'resistomap-select';

type Option = { label: unknown; value: unknown };

export function ResistomapSelect<T extends Option, IsMulti extends boolean, Group extends GroupBase<T> = GroupBase<T>>(
  props: Props<T, IsMulti, Group> & RefAttributes<SelectInstance<T, IsMulti, Group>>,
) {
  return <Select {...props} classNamePrefix={resistomapSelectClassNamePrefix} />;
}
