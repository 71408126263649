import styled from '@emotion/styled';
import { MetricTitleWithDisclaimer } from '../../shared/overview-metric-title';
import { theme } from '../../shared/theme';

interface Props {
  timeLegend: React.ReactNode | string;
  indicators: React.ReactNode;
  indexRange: React.ReactNode;
}

export function BaseMapLegend({ timeLegend, indicators, indexRange }: Props) {
  return (
    <MapLegendContainer id="map-legend-container">
      <MapLegendTitle>
        <MetricTitleWithDisclaimer />, {timeLegend}
      </MapLegendTitle>
      <MapIndicatorsContainer>{indicators}</MapIndicatorsContainer>
      <MapIndexRange>{indexRange}</MapIndexRange>
    </MapLegendContainer>
  );
}

const mapLegendBottomMargin = 25;
const mapLegendHeight = 74;
const infoTooltipHeight = 19;
const infoTooltipTopMargin = 7;
export const infoTooltipRightMargin = 15;
export const analyseTooltipRightMargin = 380;
export const analyseTooltipTop = 20;
// This controls the position of the tooltip "inside" the map legend. The tooltip is located in totally different place
export const infoTooltipBottom = mapLegendBottomMargin + mapLegendHeight - infoTooltipHeight - infoTooltipTopMargin;
const MapLegendContainer = styled.div`
  position: absolute;
  bottom: ${mapLegendBottomMargin}px;
  right: 10px;
  width: 382px;
  max-width: 80dvw;
  height: ${mapLegendHeight}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${theme.zIndexes.overviewMapControls};
  padding: ${theme.spacing[2]};
  border-radius: ${theme.borders.radius.small};
  background-color: white;
  filter: ${theme.shadows.normal.filter};
  font-weight: 500;
`;

const MapLegendTitle = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 8px;
  ${/* this right padding fixes mobile view, where the info icon intersects the text */ ''}
  color: ${theme.colors.neutral700};
  font-size: 13px;
  line-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const MapIndicatorsContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing[0.5]}; // This was in resistance-index-map-legend.tsx, but not in risk-score-map-legend.tsx
`;
const MapIndexRange = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: ${theme.colors.neutral700};
  text-align: center;
  font-size: 13px;
  line-height: 100%;
  margin-top: 4px; // This was in risk-score-map-legend.tsx, but not in resistance-index-map-legend.tsx
`;
