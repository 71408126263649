import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSearchParamsContext } from '@resistapp/client/contexts/search-params-context';
import { AssayStr, GeneGrouping, L2Target, L2Targets, sixteenS } from '@resistapp/common/assays';
import {
  AllProjectEnvironmentTypesGroup,
  EnvironmentTypeGroup,
  allEnvGroupTypes,
  filterGroupEnvironments,
  getComparableEnvironmentGroups,
} from '@resistapp/common/comparable-env-groups';
import {
  DEFAULT_END_INTERVAL,
  DEFAULT_START_INTERVAL,
  ensureLocalStartOfMonth,
  ensureLocalStartOfNextMonth,
  ensureLocalValidMidnightOrDefault,
  type StandardDateFormat,
} from '@resistapp/common/friendly';
import { isConsideredAbsolute } from '@resistapp/common/normalisation-mode';
import { Environment, FullProject, FullSamplesByUID, NormalisationMode } from '@resistapp/common/types';
import { sortUniqEnvironmentsAndAddSampleStatus } from '@resistapp/common/utils';
import { format } from 'date-fns';
import { get, keyBy as groupBy, uniq } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import {
  AbunanceSelection,
  Filters,
  filterSamplesAndAbundances,
  getNextToggledIdentifiers,
} from '../../data-utils/filter-data/filter';
import {
  QueryParams,
  getEnvironmentTypeParam,
  getEnvironmentsParams,
  getGeneGroupingParams,
  getGeneGroupsParams,
  getNormalisationModeParam,
  mutateEnvironmentRelatedSearchParams,
  mutateSearchParamsWithSelection,
} from '../../utils/url-manipulation';

export interface UseQueryFilters {
  focusSamplesByUID: (samplesByUID: FullSamplesByUID) => FullSamplesByUID | undefined;
  queryFilters: QueryFilters;
}

type ToggleGeneGroup = (
  group: L2Target | L2Target[] | AssayStr,
  removeOldSelections: boolean,
  selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
) => void;

export interface QueryFilters {
  setGroupingStable: (grouping: GeneGrouping | null) => void;
  setEnvironmentTypeGroupStable: (type: EnvironmentTypeGroup, replaceAndClearEnvs: boolean) => void;
  toggleEnvironmentStable: (id: number | number[] | undefined, removeOldSelections: boolean) => void;
  setMatchingEnvironmentsAcrossTypes: (search: string) => void;
  hasFocus: boolean;
  toggleGeneGroupStable: ToggleGeneGroup;
  toggleSingleTarget: (target: string) => void;
  filters: Filters;
  setAbundanceModeStable: (mode: AbunanceSelection) => void;
  setNormalisationModeStable: (value: NormalisationMode) => void;
  resetFiltersStable: () => void;
  setIntervalStable: (start: Date | StandardDateFormat, end: Date | StandardDateFormat) => void;
  setMonthStable: (month: Date | null, endDate: Date | null) => void;
}

const defaultAbundanceMode = AbunanceSelection.ANALYSED;

function useFiltersInternal() {
  const { allGeneGroups } = useAssayContext();
  const [abundanceMode, setAbundanceModeStable] = useLocalStorageState(LocalStorageId.abundanceMode, {
    defaultValue: defaultAbundanceMode,
  });

  const { searchParams } = useSearchParamsContext();
  const start = ensureLocalValidMidnightOrDefault(
    searchParams.get(QueryParams.START) as StandardDateFormat,
    DEFAULT_START_INTERVAL,
  );
  const end = ensureLocalValidMidnightOrDefault(
    searchParams.get(QueryParams.END) as StandardDateFormat,
    DEFAULT_END_INTERVAL,
  );

  const [allProjectEnvironments, setAllProjectEnvironmentsStable] = useState<Environment[]>([]);
  const [allProjectEnvironmentNames, setAllProjectEnvironmentNamesStable] = useState<string[]>([]);
  const [allProjectEnvironmentIds, setAllProjectEnvironmentIdsStable] = useState<number[]>([]);

  const geneGroupsParam = searchParams.get(QueryParams.GENE_GROUPS);
  const queryGeneGroups = useMemo(() => getGeneGroupsParams(geneGroupsParam), [geneGroupsParam]);
  const queryGeneGrouping = getGeneGroupingParams(searchParams, allGeneGroups);

  const envs = searchParams.get(QueryParams.ENVIRONMENTS);
  const queryEnvironmentIds = useMemo(() => getEnvironmentsParams(envs, false), [envs]);
  const queryEnvironmentGroup =
    getEnvironmentTypeParam(searchParams) ?? AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;

  const queryNormalisationMode = getNormalisationModeParam(searchParams);
  useEffect(() => {
    // Convert legacy value
    if ((abundanceMode as unknown) === 'ANALYZED') {
      setAbundanceModeStable(AbunanceSelection.ANALYSED);
    }
  }, [abundanceMode]);

  const environmentsImplicitlySelectedWithEnvTypeGroupSelection = useMemo(
    () => filterGroupEnvironments(allProjectEnvironments, queryEnvironmentGroup),
    [allProjectEnvironments, queryEnvironmentGroup],
  );

  // Memoize selected environment names array
  // NOTE: this is project dependent and always runs after project is loaded
  // and this behaviour is exected by ResearchProvider for determining when to buildResearchPlotData
  const selectedEnvironmentIdsOrdered = useMemo(
    () =>
      queryEnvironmentIds.length
        ? queryEnvironmentIds
        : environmentsImplicitlySelectedWithEnvTypeGroupSelection.map(e => e.id),
    [queryEnvironmentIds, environmentsImplicitlySelectedWithEnvTypeGroupSelection],
  );

  // Memoize selected targets array
  const selectedTargets = useMemo(
    () => (queryGeneGroups.length ? queryGeneGroups : allGeneGroups[queryGeneGrouping]) as L2Targets[],
    [queryGeneGroups, allGeneGroups, queryGeneGrouping],
  );

  // Memoize interval object
  const interval = useMemo(
    () => ({
      start,
      end,
    }),
    // This warning is ok, but for some reason eslint does not allow surpresing it
    // eslint-disable-next-line exhaustive-deps-with-refs/exhaustive-deps
    [start.getTime(), end.getTime()],
  );

  // A convenience object containing the active query params
  // or all values when multi-select query params are not set
  // NOTE: each member is separately memoized for safe referencing in dependency arrays.
  // NOTE! NEVER use the full filters object in dependency arrays.
  const filters = useMemo<Filters>(
    () => ({
      selectedEnvironmentTypeGroup: queryEnvironmentGroup,
      selectedEnvironmentIdsOrdered,
      selectedTargetGrouping: queryGeneGrouping,
      selectedTargets,
      interval,
      normalisationMode: queryNormalisationMode,
      abundances: abundanceMode,
    }),
    [
      queryEnvironmentGroup,
      selectedEnvironmentIdsOrdered,
      queryGeneGrouping,
      selectedTargets,
      interval,
      queryNormalisationMode,
      abundanceMode,
    ],
  );

  return {
    filters,
    setAllProjectEnvironmentsStable,
    setAllProjectEnvironmentNamesStable,
    allProjectEnvironments,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    queryEnvironmentIds,
    queryGeneGroups,
    queryGeneGrouping,
    setAbundanceModeStable,
    allProjectEnvironmentNames,
    setAllProjectEnvironmentIdsStable,
    allProjectEnvironmentIds,
  };
}

export function useNormalisationMode(): NormalisationMode {
  const { searchParams } = useSearchParamsContext();
  return useMemo(() => getNormalisationModeParam(searchParams), [searchParams]);
}

export function useFilters(): Filters {
  const { filters } = useFiltersInternal();
  return filters;
}

export function useQueryFilters(project: FullProject | undefined): UseQueryFilters {
  const { setSearchParamsStable, searchParamsRef } = useSearchParamsContext();
  const { allGeneGroups, getGroup, assaysLoaded } = useAssayContext();
  const {
    filters,
    setAllProjectEnvironmentsStable,
    setAllProjectEnvironmentNamesStable,
    allProjectEnvironments,
    environmentsImplicitlySelectedWithEnvTypeGroupSelection,
    queryEnvironmentIds,
    queryGeneGroups,
    queryGeneGrouping,
    setAbundanceModeStable,
    allProjectEnvironmentNames,
    setAllProjectEnvironmentIdsStable,
    allProjectEnvironmentIds,
  } = useFiltersInternal();
  const location = useLocation();
  const [allProjectEnvironmentTypes, setAllProjectEnvironmentTypes] = useState<EnvironmentTypeGroup[]>([]);

  useEffect(() => {
    if (!project) {
      return;
    }

    const projectEnvironments = sortUniqEnvironmentsAndAddSampleStatus(project.samplesByUID, project.id);
    const allEnvironmentIds = projectEnvironments.map(env => env.id);
    const allEnvironmentNames = projectEnvironments.map(env => env.name);

    // Get both regular env types and comparable groups
    const comparableGroups = getComparableEnvironmentGroups(projectEnvironments, undefined);
    const allProjectEnvironmentTypesToBeSet = uniq([
      ...comparableGroups.map(g => g.type),
      ...projectEnvironments.map(env => env.type),
    ]);

    setAllProjectEnvironmentTypes(allProjectEnvironmentTypesToBeSet);
    setAllProjectEnvironmentsStable(projectEnvironments);
    setAllProjectEnvironmentNamesStable(allEnvironmentNames);
    setAllProjectEnvironmentIdsStable(allEnvironmentIds);
  }, [project]);

  useEffect(() => {
    // This should never be empty after data is loaded, and triggering this effect before
    // data is loaded would clear the environment names from query params
    if (!environmentsImplicitlySelectedWithEnvTypeGroupSelection.length) {
      return;
    }
    const allEnvTypeGroupEnvIds = allProjectEnvironments.map(e => e.id);
    const initialEnvironmentIds = queryEnvironmentIds.length
      ? getNextToggledIdentifiers(queryEnvironmentIds, allEnvTypeGroupEnvIds, allEnvTypeGroupEnvIds, true)
      : allProjectEnvironments.map(e => e.id);

    mutateSearchParamsWithSelection(
      QueryParams.ENVIRONMENTS,
      searchParamsRef.current,
      allEnvTypeGroupEnvIds,
      initialEnvironmentIds,
      true,
    );
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, [queryEnvironmentIds, environmentsImplicitlySelectedWithEnvTypeGroupSelection, allProjectEnvironments]);

  // (RE)SET GENE GROUPS ON PROJECT, ABS MODE OR GROUPING CHANGE
  const queryGeneGroupsRef = useRef(queryGeneGroups);
  queryGeneGroupsRef.current = queryGeneGroups;
  useEffect(() => {
    if (!assaysLoaded || queryGeneGrouping === 'assay') {
      return;
    }
    const groups = queryGeneGroupsRef.current.length
      ? getNextToggledIdentifiers(
          queryGeneGroupsRef.current,
          allGeneGroups[queryGeneGrouping],
          allGeneGroups[queryGeneGrouping],
          true,
        )
      : allGeneGroups[queryGeneGrouping];

    mutateSearchParamsWithSelection(
      QueryParams.GENE_GROUPS,
      searchParamsRef.current,
      allGeneGroups[queryGeneGrouping],
      groups,
      true,
    );
    setSearchParamsStable(searchParamsRef.current, { replace: true });

    // Should be mainly/only triggered on project, abs. mode or grouping change!?
  }, [
    location.pathname, // project change
    filters.abundances, // abs. mode change
    queryGeneGrouping, // gene grouping change (L2 / Lx / assay)
    assaysLoaded, // Loading
    allGeneGroups, // Loading
  ]);

  const setGroupingStable = useCallback((value: GeneGrouping | null) => {
    if (value) {
      searchParamsRef.current.set(QueryParams.GENE_GROUP_GROUPING, value);
      searchParamsRef.current.delete(QueryParams.GENE_GROUPS);
    } else {
      searchParamsRef.current.delete(QueryParams.GENE_GROUP_GROUPING);
    }
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, []);
  const absoluteModeOn = isConsideredAbsolute(filters.normalisationMode);
  useEffect(() => {
    if (!absoluteModeOn && queryGeneGrouping === sixteenS) {
      setGroupingStable('l2Target');
    }
  }, [absoluteModeOn, queryGeneGrouping, setGroupingStable]);

  const setEnvironmentTypeGroupStable = useCallback(
    (newGroup: EnvironmentTypeGroup, replaceAndClearEnvs: boolean) => {
      const validGroup = allEnvGroupTypes.includes(newGroup)
        ? newGroup
        : AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS;
      mutateEnvironmentRelatedSearchParams(searchParamsRef.current, validGroup, replaceAndClearEnvs);
      setSearchParamsStable(searchParamsRef.current, { replace: replaceAndClearEnvs });
    },
    [setSearchParamsStable, searchParamsRef],
  );

  const selectedEnvironmentIdsOrderedRef = useRef(filters.selectedEnvironmentIdsOrdered);
  selectedEnvironmentIdsOrderedRef.current = filters.selectedEnvironmentIdsOrdered;
  const allProjectEnvsRef = useRef(allProjectEnvironments);
  allProjectEnvsRef.current = allProjectEnvironments;
  const allProjectEnvIdsRef = useRef(allProjectEnvironmentIds);
  allProjectEnvIdsRef.current = allProjectEnvironmentIds;
  const toggleEnvironmentStable = useCallback((id: number | number[] | undefined, removeOldSelections: boolean) => {
    const allEnvIds = allProjectEnvIdsRef.current;
    const next = id
      ? getNextToggledIdentifiers(id, selectedEnvironmentIdsOrderedRef.current, allEnvIds, removeOldSelections)
      : allEnvIds;

    mutateSearchParamsWithSelection(
      QueryParams.ENVIRONMENTS,
      searchParamsRef.current,
      allEnvIds,
      next,
      removeOldSelections,
    );

    setSearchParamsStable(searchParamsRef.current, { replace: true });

    // Scroll to the map, when the selected environment changes
    const mapElement = document.querySelector('.mapboxgl-map');
    if (mapElement) {
      mapElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  const queryGeneGroupingRef = useRef(queryGeneGrouping);
  queryGeneGroupingRef.current = queryGeneGrouping;
  const selectedTargetsRef = useRef(filters.selectedTargets);
  selectedTargetsRef.current = filters.selectedTargets;
  const allGeneGroupsRef = useRef(allGeneGroups);
  allGeneGroupsRef.current = allGeneGroups;
  const toggleGeneGroupStable = useCallback(
    (
      group: L2Target | L2Target[] | AssayStr,
      removeOldSelections: boolean,
      selectedGroupingInCaseStateHasNotUpdated?: GeneGrouping,
    ) => {
      const realSelectedGrouping = selectedGroupingInCaseStateHasNotUpdated ?? queryGeneGroupingRef.current;
      const next = getNextToggledIdentifiers<L2Target | AssayStr>(
        group,
        selectedTargetsRef.current,
        allGeneGroupsRef.current[realSelectedGrouping] as L2Target[], // TODO Fix typing
        removeOldSelections,
      );
      mutateSearchParamsWithSelection(
        QueryParams.GENE_GROUPS,
        searchParamsRef.current,
        allGeneGroupsRef.current[realSelectedGrouping],
        next,
        removeOldSelections,
      );

      // TODO IS THIS NEEDED?

      // We are selecting OR unselecting an assay
      // If we have assay in the next selection, set grouping to 'assay'
      // If we don't have assay in the next selection, remove the grouping
      // If we are selecting non-assay, but had previously assay selected

      // const selectedAssay = typeof group === 'string' ? getAssayInfo(group) : undefined;
      // if (selectedAssay) {
      //   if (next.length === 1 && next[0] === selectedAssay.assay) {
      //     searchParamsRef.current.set(QueryParams.GENE_GROUP_GROUPING, 'assay');
      //   } else {
      //     searchParamsRef.current.delete(QueryParams.GENE_GROUP_GROUPING);
      //   }
      // } else if (searchParamsRef.current.get(QueryParams.GENE_GROUP_GROUPING) === 'assay') {
      //   searchParamsRef.current.delete(QueryParams.GENE_GROUP_GROUPING);
      // }

      setSearchParamsStable(searchParamsRef.current, { replace: true });
    },
    [],
  );

  const focusSamplesByUID = useCallback(
    (samplesByUID: FullSamplesByUID) => {
      if (!assaysLoaded) {
        return undefined;
      }
      return filterSamplesAndAbundances(samplesByUID, filters, getGroup);
    },
    [assaysLoaded, getGroup, filters],
  ); // Note: this is intentionally and unstable callback!

  const setNormalisationModeStable = useCallback((value: NormalisationMode) => {
    searchParamsRef.current.set(QueryParams.NORMALISATION_MODE, value);
    setSearchParamsStable(searchParamsRef.current, { replace: true });
  }, []);

  const resetFiltersStable = useCallback(() => {
    setSearchParamsStable({}, { replace: true });
    setAbundanceModeStable(defaultAbundanceMode);
  }, []);

  const setIntervalStable = useCallback(
    (requestedStart: Date | StandardDateFormat, requestedEnd: Date | StandardDateFormat) => {
      const snappedStart = ensureLocalValidMidnightOrDefault(requestedStart, DEFAULT_START_INTERVAL);
      const snappedEnd = ensureLocalValidMidnightOrDefault(requestedEnd, DEFAULT_END_INTERVAL);

      // NOTE: We do not want to use snappedStart and snappedEnd for comparison here, because it distorts the time of the dates
      if (new Date(requestedStart).getTime() === DEFAULT_START_INTERVAL.getTime()) {
        searchParamsRef.current.delete('start');
      } else {
        searchParamsRef.current.set('start', format(snappedStart, 'yyyy-MM-dd'));
      }
      if (new Date(requestedEnd).getTime() === DEFAULT_END_INTERVAL.getTime()) {
        searchParamsRef.current.delete('end');
      } else {
        searchParamsRef.current.set('end', format(snappedEnd, 'yyyy-MM-dd'));
      }
      setSearchParamsStable(searchParamsRef.current, { replace: true });
    },
    [],
  );

  const setMonthStable = useCallback((date: Date | null, endDate: Date | null) => {
    if (date === null) {
      setIntervalStable(DEFAULT_START_INTERVAL, DEFAULT_END_INTERVAL);
    } else {
      const startOfMonth = ensureLocalStartOfMonth(date);
      const startOfNextMonth = ensureLocalStartOfNextMonth(startOfMonth);
      setIntervalStable(startOfMonth, endDate ?? startOfNextMonth);
    }
  }, []);

  const toggleSingleTarget = useCallback(
    (target: string) => {
      const isCurrentlySelected = filters.selectedTargets.length === 1 && filters.selectedTargets[0] === target;
      const isAssay = target.startsWith('AY');
      const grouping = !isCurrentlySelected && isAssay ? 'assay' : 'l2Target';
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-confusing-void-expression
      if (!isAssay && !get(L2Targets, target, undefined)) {
        console.error(`Unexpected toggle target: ${target}`);
      }
      setGroupingStable(grouping);
      toggleGeneGroupStable(target as L2Target | AssayStr, true, grouping);
    },
    [filters.selectedTargets],
  );

  const allProjectEnvironmentNamesRef = useRef(allProjectEnvironmentNames);
  allProjectEnvironmentNamesRef.current = allProjectEnvironmentNames;
  const allProjectEnvironmentsRef = useRef(allProjectEnvironments);
  allProjectEnvironmentsRef.current = allProjectEnvironments;
  const { selectedEnvironmentIdsOrdered } = filters;
  const setMatchingEnvironmentsAcrossTypes = useCallback(
    (search: string) => {
      const newlyMatchedEnvNames = getMatchingStringsIgnoreCase(search, allProjectEnvironmentNamesRef.current);
      if (!search || !newlyMatchedEnvNames.length) {
        return;
      }

      const envsByName = groupBy(allProjectEnvironmentsRef.current, e => e.name);
      const newlyMatchedEnvs = newlyMatchedEnvNames.map(name => envsByName[name]).filter(Boolean);
      const newlyMatchedEnvIds = newlyMatchedEnvs.map(e => e.id);

      const allNewlyMatchedEnvTypes = uniq(newlyMatchedEnvs.map(e => e.type));
      setEnvironmentTypeGroupStable(
        allNewlyMatchedEnvTypes.length > 1
          ? allNewlyMatchedEnvTypes[0]
          : AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS,
        false,
      );

      const allEnvIdsOfTheSelectedTypes = allProjectEnvironmentsRef.current
        .filter(e => allNewlyMatchedEnvTypes.includes(e.type))
        .map(e => e.id);

      const next = getNextToggledIdentifiers(
        newlyMatchedEnvIds,
        selectedEnvironmentIdsOrdered,
        allEnvIdsOfTheSelectedTypes,
        true,
      );
      mutateSearchParamsWithSelection(
        QueryParams.ENVIRONMENTS,
        searchParamsRef.current,
        allEnvIdsOfTheSelectedTypes,
        next,
        true,
      );
      setSearchParamsStable(searchParamsRef.current, { replace: true });
    },
    [selectedEnvironmentIdsOrdered],
  );

  return {
    focusSamplesByUID,
    queryFilters: {
      toggleGeneGroupStable,
      setGroupingStable,
      filters,
      setAbundanceModeStable,
      setMatchingEnvironmentsAcrossTypes,
      toggleEnvironmentStable,
      setEnvironmentTypeGroupStable,
      setNormalisationModeStable,
      hasFocus:
        queryGeneGrouping !== 'l2Target' ||
        filters.selectedTargets.length < allGeneGroups[queryGeneGrouping].length ||
        // NOTE: this doesn't check whether all project envs are within a selected custom group, but just conservatively assumes there may be focus
        // TODO: fix if it turns out to be an issue
        (filters.selectedEnvironmentTypeGroup !== AllProjectEnvironmentTypesGroup.ALL_PROJECT_ENVIRONMENTS &&
          !(
            allProjectEnvironmentTypes.length === 1 &&
            filters.selectedEnvironmentTypeGroup === allProjectEnvironmentTypes[0]
          )) ||
        (filters.selectedEnvironmentIdsOrdered.length &&
          filters.selectedEnvironmentIdsOrdered.length < allProjectEnvironmentIds.length) ||
        filters.interval.start.getTime() !== DEFAULT_START_INTERVAL.getTime() ||
        filters.interval.end.getTime() !== DEFAULT_END_INTERVAL.getTime(),
      resetFiltersStable,
      setIntervalStable,
      setMonthStable,
      toggleSingleTarget,
    },
  };
}

export function getMatchingStringsIgnoreCase(searchString: string, stringsToMatchTo: string[]) {
  const lowerSearchString = searchString.toLowerCase();
  return stringsToMatchTo.filter(str => str.toLowerCase().includes(lowerSearchString));
}
