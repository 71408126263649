import { theme } from '@resistapp/client/components/shared/theme';
import {
  OverviewDatum,
  OverviewDatumWithQuartiles,
} from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { calcResistanceGeneIndex } from '@resistapp/common/statistics/resistance-index';
import { Group } from '@visx/group';
import { Threshold } from '@visx/threshold';
import { curveLinear } from '@vx/curve';
import { ScaleLinear, ScaleTime } from 'd3-scale';
import { isNil } from 'lodash';

interface Props {
  selectedEnvironmentData: OverviewDatumWithQuartiles[] | undefined;
  timeScale: ScaleTime<number | undefined, number | undefined>;
  resistanceIndexScale: ScaleLinear<number | undefined, number | undefined>;
  graphHeight: number;
  mouseMoveHandler: (data: OverviewDatum[], event: React.MouseEvent<SVGGElement>) => void;
  mouseClickHandler: (data: OverviewDatum[], event: React.MouseEvent<SVGGElement>) => void;
}

export function QuartileRange(props: Props) {
  const { selectedEnvironmentData, timeScale, resistanceIndexScale, graphHeight, mouseMoveHandler, mouseClickHandler } =
    props;
  if (!selectedEnvironmentData?.length) {
    return null;
  }

  return (
    <Group
      onMouseMove={event => {
        mouseMoveHandler(selectedEnvironmentData, event);
      }}
      onClick={event => {
        mouseClickHandler(selectedEnvironmentData, event);
      }}
      style={{ cursor: 'pointer' }}
    >
      <Threshold<OverviewDatumWithQuartiles>
        id={`${Math.random()}`}
        data={selectedEnvironmentData}
        x={(d: OverviewDatumWithQuartiles) => timeScale(new Date(d.date)) ?? 0}
        y0={(d: OverviewDatumWithQuartiles) => {
          // console.log('RANGE', d.firstQuartile, d.thirdQuartile);
          // const abundances = getBeforeOrAfterAbundances(d, effectiveSiteDetailsProcessMode);
          // const someData = getResistanceIndexData(abundances, l2Targets, getGroup);
          return resistanceIndexScale(isNil(d.firstQuartile) ? 0 : calcResistanceGeneIndex(d.firstQuartile)) ?? 0;
        }}
        y1={(d: OverviewDatumWithQuartiles) => {
          return resistanceIndexScale(isNil(d.thirdQuartile) ? 0 : calcResistanceGeneIndex(d.thirdQuartile)) ?? 0;
        }}
        clipAboveTo={0}
        clipBelowTo={graphHeight}
        curve={curveLinear}
        belowAreaProps={{
          fill: theme.colors.transparentBlue,
        }}
        aboveAreaProps={{
          fill: theme.colors.transparentBlue,
        }}
      />
    </Group>
  );
}
