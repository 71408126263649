import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { References } from '@resistapp/client/hooks/use-science-reference';
import { OverviewChartConfiguration } from '@resistapp/client/utils/overview-chart-configurations';
import { ChartUnit } from '@resistapp/common/types';
import { InfoTooltipIcon } from '../icons/info-tooltip-icon';
import { MetricTitle } from '../shared/overview-metric-title';
import { theme } from '../shared/theme';
import { InfoIconProps } from './info-icons-general';

export function ReductionInfoIcon(props: InfoIconProps & { activeOverviewConfiguration?: OverviewChartConfiguration }) {
  const style = props.style || {};
  const placement = props.placement || 'bottom';

  return (
    <InfoTooltipIcon
      header={props.header}
      placement={placement}
      style={{
        ...style,
        padding: 0,
        paddingRight: theme.oldSpacing.xs,
      }}
      isForceOpen={props.isForceOpen}
      highlight={props.isForceOpen}
      footer={props.tutorialFooter}
    >
      <ReductionTooltipContent tutorialActive={!!props.header} />
    </InfoTooltipIcon>
  );
}

interface ContentProps {
  tutorialActive?: boolean;
  activeChartUnit?: ChartUnit;
}

export function ReductionTooltipContent(props: ContentProps) {
  const boldOrNormal = props.tutorialActive ? 'normal' : 'bold';
  const { activeChartUnit } = useOverviewContext();
  const isVolumeMetric = activeChartUnit === ChartUnit.COPIES_PER_L;

  return (
    <div>
      <div>
        <span style={{ fontWeight: boldOrNormal }}>
          <MetricTitle noBold noColor />
        </span>{' '}
        {isVolumeMetric
          ? 'shows how many orders of magnitude the absolute abundance of effluent genes per liter changed compared to the influent samples.'
          : 'shows how many orders of magnitude the relative abundance of effluent genes changed compared to the influent samples.'}
      </div>
      <div style={{ marginTop: 12 }}>
        {isVolumeMetric ? (
          <>
            The metric is calculated by dividing the number of gene copies per liter of treated wastewater by the number
            of gene copies per liter of raw wastewater.
          </>
        ) : (
          <>
            First, a genewise fold change is calculated using the Δ��Ct method: First, the ΔCt is determined by
            subtracting the Ct value of 16S rRNA reference gene from the Ct value of the target gene in both the
            influent and effluent samples. Then, ΔΔCt is calculated by subtracting the ΔCt of the influent sample from
            the ΔCt of the effluent sample.
          </>
        )}
      </div>
      <div style={{ marginTop: 12 }}>
        {isVolumeMetric ? (
          <>
            To obtain an overall change across all genes and biological replicates, the geometric mean of the individual
            fold change ratios is calculated across genes and replicas.
          </>
        ) : (
          <>
            To obtain an overall change percentage across all genes and biological replicates, the geometric mean of the
            individual fold change ratios is calculated as 2<sup>−ΔΔCt</sup> (with ΔΔCt calculated across genes and
            replicas).
          </>
        )}
      </div>
      <div style={{ marginTop: 12 }}>
        Finally, this overall change ratio is presented as a logarithm of ten. A 10-fold change of 0 means no change in
        overall {isVolumeMetric ? 'absolute' : 'relative'} abundance of genes, a 10-fold change of -1 indicates a -90%
        change, a 10-fold change of 1 means a +900% change, and so on. The range has no upper or lower limit, but values
        are generally between -5 and 1.
      </div>
      <References style={{ marginTop: 25, marginLeft: -10, marginBottom: props.tutorialActive ? -5 : 15 }} />
    </div>
  );
}
