import { antibioticL2Targets, AssayInfo, GetGroup, L2TargetOrAssay } from '@resistapp/common/assays';
import { get10FoldChangeInVolume, getRelative10FoldChange } from '@resistapp/common/statistics/fold-change';
import {
  getResistanceGeneIndexAndLevel,
  getResistanceLevel,
  ResistanceLevel,
} from '@resistapp/common/statistics/resistance-index';
import { getRiskScore } from '@resistapp/common/statistics/risk-score';
import { ChartUnit, FullAbundance, MetricMode, ProcessMode } from '@resistapp/common/types';
import { interpolateRgb, piecewise } from 'd3-interpolate';
import { isNil } from 'lodash';
import { theme } from '../components/shared/theme';
import { getBeforeOrAfterAbundances } from '../data-utils/plot-data/build-overview-line-data';
import { resistanceLevelMetadata } from '../data-utils/resistance-level';
import { getOverviewConfiguration, overviewChartConfigurations } from './overview-chart-configurations';

export type MetricAndLevel = [number | null, ResistanceLevel | null];

export function getMetricAndLevel(
  _datum: { beforeAbundances: FullAbundance[] | undefined; afterAbundances: FullAbundance[] | undefined } | undefined,
  _targets: L2TargetOrAssay[],
  metricMode: MetricMode,
  processMode: ProcessMode,
  activeChartUnit: ChartUnit,
  getGroup: GetGroup,
  allAssays: AssayInfo[],
  useArgsInsteadOfSingleGene = false,
): MetricAndLevel {
  const datum = _datum || { beforeAbundances: undefined, afterAbundances: undefined };
  const targets =
    useArgsInsteadOfSingleGene &&
    _targets.length === 1 &&
    _targets[0].startsWith('AY') &&
    metricMode !== MetricMode.REDUCTION
      ? antibioticL2Targets
      : _targets;

  if (metricMode === MetricMode.ARGI) {
    const abundances = getBeforeOrAfterAbundances(datum, processMode);
    const { resistanceIndex, resistanceLevel } = getResistanceGeneIndexAndLevel(abundances, targets, getGroup);
    return [resistanceIndex, resistanceLevel];
  } else if (metricMode === MetricMode.RISK) {
    const abundances = getBeforeOrAfterAbundances(datum, processMode);
    const riskScore = getRiskScore(abundances as FullAbundance[], targets, getGroup);
    return [riskScore, null];
  } else {
    if (processMode !== ProcessMode.DURING || !datum.beforeAbundances || !datum.afterAbundances) {
      return [null, null];
    }
    if (activeChartUnit === ChartUnit.COPIES_PER_L) {
      const foldChangeInVol = get10FoldChangeInVolume(
        datum.beforeAbundances,
        datum.afterAbundances,
        targets.length === 1 ? targets[0] : undefined,
        allAssays,
      );
      return [foldChangeInVol, null];
    } else {
      const relativeFoldChange = getRelative10FoldChange(
        datum.beforeAbundances,
        datum.afterAbundances,
        targets.length === 1 ? targets[0] : undefined,
        allAssays,
      );
      return [relativeFoldChange, null];
    }
  }
}

const colorScales = {
  // we force the return type here, since in piecewise it's "any"
  [MetricMode.REDUCTION]: (t: number): string => {
    const colorScale = overviewChartConfigurations[MetricMode.REDUCTION][ChartUnit.COPYNUMBER]?.colorScale;
    const zeroPoint = colorScale ? colorScale[1] / 100 : 0.83;

    if (t === zeroPoint) {
      return '#FFFFFF';
    } else if (t > zeroPoint) {
      // Positive values (0 to 1) - red gradient
      const redNormalized = (t - zeroPoint) / (1 - zeroPoint);
      return piecewise(interpolateRgb, ['#FFFFFF', theme.colors.reductionMinus100])(redNormalized) as string;
    } else {
      // Negative values (-5 to 0) - blue gradient
      const blueNormalized = t / zeroPoint;
      return piecewise(interpolateRgb, [theme.colors.reduction100, '#FFFFFF'])(blueNormalized) as string;
    }
  },
  [MetricMode.RISK]: interpolateRgb(theme.colors.riskScore0, theme.colors.riskScore100),
};
export function getMetricColor(
  value: number | null,
  metricMode: MetricMode,
  chartUnit: ChartUnit,
  forMap = false,
): string {
  if (value === null) {
    return forMap ? theme.colors.neutral400 : theme.colors.neutral700;
  } else if (metricMode === MetricMode.ARGI) {
    const level = getResistanceLevel(value);
    return resistanceLevelMetadata[level].color;
  }

  const minValue = getOverviewConfiguration(metricMode, chartUnit).detailBarGraphMin;
  const maxValue = getOverviewConfiguration(metricMode, chartUnit).detailBarGraphMax();
  const normalizedValue = (value - minValue) / (maxValue - minValue);

  return colorScales[metricMode](normalizedValue);
}

export function friendlyMetricValue(metric: number | null, metricMode: MetricMode, activeChartUnit: ChartUnit): string {
  if (isNil(metric)) return '-';
  return getOverviewConfiguration(metricMode, activeChartUnit).getFriendlyMetricValue(metric) || '-';
}

export function getReductionMetricTextColor(value: number | null, _chartUnit: ChartUnit): string {
  if (value === null) {
    return 'black';
  }

  return value >= 0.5 || value <= -2.5 ? 'white' : 'black';
}
