import {
  Button,
  Flex,
  Kbd,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import { getCtrlKeyName } from '@resistapp/client/utils/general';
import { TestSelectors } from '@resistapp/common/testing/visual-test-selectors';
import { useEffect, useState } from 'react';

interface DownloadOptionsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onDownload: (includeChart: boolean, includeLegend: boolean) => Promise<void>;
  chartLabel?: string;
}

export function DownloadOptionsDialog({ isOpen, onClose, onDownload, chartLabel }: DownloadOptionsDialogProps) {
  const [downloadOption, setDownloadOption] = useState<'chart' | 'legend'>('chart');
  const [isDownloading, setIsDownloading] = useState(false);

  // Add effect to log state changes
  useEffect(() => {}, [isOpen]);

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      const includeChart = downloadOption === 'chart';
      const includeLegend = downloadOption === 'legend';
      await onDownload(includeChart, includeLegend);
      onClose();
    } catch (error) {
      console.error('Download failed:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={true}
      closeOnEsc={true}
      blockScrollOnMount={false}
      isCentered
    >
      <ModalOverlay
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
          backdropFilter: 'blur(1px)',
        }}
      />
      <Kbd>{getCtrlKeyName()}</Kbd> + <Kbd>click</Kbd>
      <ModalContent style={{ zIndex: 10001 }} boxShadow="0px 4px 20px rgba(0, 0, 0, 0.15)">
        <ModalHeader>Screenshot Download Options</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={4}>
            Sceenshots will reflect you current selection:
            <br />
            <br />
            <ul style={{ marginLeft: '20px' }}>
              <li style={{ marginBottom: '2px' }}>
                <b>Use the filter bar</b> to select gene categorization, sample type, time range, and relative /
                absolute abundance data for the screenshot.
              </li>
              <li>
                <b>Click the legend items</b> to select the desired gene classes and samples:
              </li>
              <ul style={{ marginLeft: '20px' }}>
                <li>Click a selected sample to deslect all others</li>
                <li>
                  <Kbd>{getCtrlKeyName()}</Kbd> + click a selected sample to deselect it
                </li>
                <li>
                  <Kbd>{getCtrlKeyName()}</Kbd> + click on unslected samples to select them in order. You choose the
                  order of samples in the screenshot by selecting them in order.
                </li>
              </ul>
            </ul>
            <br />
            Then download either the legend or the chart:
          </Text>
          <RadioGroup
            onChange={nextValue => {
              setDownloadOption(nextValue as 'chart' | 'legend');
            }}
            value={downloadOption}
          >
            <Stack direction="column" spacing={3}>
              <Radio value="chart" className={TestSelectors.TEST_DOWNLOAD_CHART_RADIO}>
                {chartLabel?.replace('_', ' ')} chart
              </Radio>
              <Radio value="legend" className={TestSelectors.TEST_DOWNLOAD_LEGEND_RADIO}>
                Legend
              </Radio>
            </Stack>
          </RadioGroup>
        </ModalBody>
        <ModalFooter>
          <Flex justify="flex-end" width="100%">
            <Button
              colorScheme="blue"
              onClick={() => {
                void handleDownload();
              }}
              isLoading={isDownloading}
              className={TestSelectors.TEST_DOWNLOAD_CONFIRM_BUTTON}
            >
              Download
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
