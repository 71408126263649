import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { RequiredFields } from '@resistapp/common/type-utils';
import { Environment, type AdminArea } from '@resistapp/common/types';
import { chain, isNil, max, min } from 'lodash';
import { LngLatBoundsLike, type MapboxMap } from 'react-map-gl';
import type { MapRef } from 'react-map-gl/dist/esm/mapbox/create-ref';
import { arrowOffset } from '../../arrows/arrow';
import { getMarkerSize, Positions } from './process-marker';

interface MarkerRectangle {
  width: number; // Without the arrow
  height: number; // Without the arrow
  x: number; // The original x co-ordinate projected by the map (top left marker corner)
  y: number; // The original y co-ordinate projected by the map (top left marker corner)
}

export interface MarkerRectangleWithPosition extends MarkerRectangle {
  position: Positions; // The position transformation to carry out upon rendering
}

export interface MarkerOverviewDatum extends OverviewDatum, MarkerRectangleWithPosition {
  environment: RequiredFields<Environment, 'inferredLat' | 'inferredLon'>;
  adminLevel?: AdminArea | undefined;
}

export function calculateMarkerLabelRectangle(marker: MarkerRectangle | MarkerOverviewDatum, position?: Positions) {
  const { width, height } = marker;
  // Label positions itself so that the arrow on the label points to the correct spot. So
  // if the label is on the top, the arrow is at the bottom center. If the label is on the left,
  // the arrow is on the right center.
  const desiredPosition = position || ('position' in marker ? marker.position : undefined);
  const offset = getMarkerOffset(desiredPosition, width, height, 'lat' in marker);

  // const addToWidth = desiredPosition === 'left' || desiredPosition === 'right';
  // const widthWithArrow = addToWidth ? width + arrowOffset : width;
  // const heightWithArrow = !addToWidth ? height + arrowOffset : height;

  return {
    x: marker.x + offset.x,
    y: marker.y + offset.y,
    width, // : widthWithArrow,
    height, // : heightWithArrow
  };
}

/*
 * By defualt, the markers are positioned so that their top right corner is on the x, y pixel co-ordinates (projected from lat lon).
 * This function accounts for the arrow and label size, and returnes a positioning offset so that the arrow head hits those x,y co-ordinates instead.
 */
export function getMarkerOffset(position: Positions | undefined, width: number, height: number, _skipArrow?: boolean) {
  switch (position) {
    case 'center':
      return { x: -width / 2, y: -height / 2 };
    case 'top':
      return { x: -width / 2, y: -height - arrowOffset };
    case 'bottom':
      return { x: -width / 2, y: arrowOffset };
    case 'left':
      return { x: arrowOffset, y: -height / 2 };
    case 'right':
      return { x: -width - arrowOffset, y: -height / 2 };
    case 'chart':
      return { y: 0, x: 0 };
    default:
      throw new Error('Unexpectedly missing position');
  }
}

export function doesRectangleCollide(
  rect1: { x: number; y: number; width: number; height: number },
  rect2: { x: number; y: number; width: number; height: number },
) {
  return (
    rect1.x < rect2.x + rect2.width &&
    rect1.x + rect1.width > rect2.x &&
    rect1.y < rect2.y + rect2.height &&
    rect1.y + rect1.height > rect2.y
  );
}

export function mutateLabelsWithOptimizedPosition<T extends MarkerOverviewDatum>(labels: T[]) {
  const arrowedPositionsInPrefOrder: Positions[] = ['top', 'bottom', 'right', 'left'];
  const onlyCenter: Positions[] = ['center', ...arrowedPositionsInPrefOrder];

  if (labels.length <= 1) {
    return;
  }
  labels.forEach(labelUnderPositioning => {
    const optimalPosition = chain(labels)
      .map(otherLabel => {
        if (labelUnderPositioning === otherLabel) {
          return undefined;
        }

        const localRectangle = calculateMarkerLabelRectangle(otherLabel);
        // we use lat to check if the customer gave originally exact coordinates and then we show different label
        const positionsToConsider = labelUnderPositioning.environment.lat ? arrowedPositionsInPrefOrder : onlyCenter;

        const overlaps = positionsToConsider.map(pos => {
          const outerRect = calculateMarkerLabelRectangle(labelUnderPositioning, pos);

          // Calculate border overlap using the same absolute position logic as rectangle overlap
          const borderRect = getRectangleAbsolutePositions({ ...outerRect, y: 0, height: 0 });
          borderRect.top = 0;
          const labelRect = getRectangleAbsolutePositions(outerRect);

          const xBorderOverlap = Math.max(
            0,
            Math.min(labelRect.right, borderRect.right) - Math.max(labelRect.left, borderRect.left),
          );
          const borderOverlap = labelRect.top < 0 ? xBorderOverlap * Math.abs(labelRect.top) : 0;

          return {
            position: pos,
            overlap: Math.max(borderOverlap, calculateHowMuchRectanglesOverlap(outerRect, localRectangle)),
          };
        });

        return overlaps;
      })
      .filter(Boolean)
      .reduce((acc, current) => {
        if (!current || !acc) return acc;
        current.forEach(pos => {
          const existing = acc.find(p => p.position === pos.position);
          if (!existing || pos.overlap > existing.overlap) {
            const index = acc.findIndex(p => p.position === pos.position);
            if (index !== -1) acc.splice(index, 1, pos);
            else acc.push(pos);
          }
        });
        return acc;
      })
      .minBy('overlap')
      .value();
    labelUnderPositioning.position = optimalPosition.position;
  });
}

export function calculateHowMuchRectanglesOverlap(rect1: MarkerRectangle, rect2: MarkerRectangle): number {
  // Convert all coordinates to absolute positions
  const r1 = getRectangleAbsolutePositions(rect1);
  const r2 = getRectangleAbsolutePositions(rect2);

  // Calculate overlap using absolute positions
  const xOverlap = Math.max(0, Math.min(r1.right, r2.right) - Math.max(r1.left, r2.left));
  const yOverlap = Math.max(0, Math.min(r1.bottom, r2.bottom) - Math.max(r1.top, r2.top));

  return xOverlap * yOverlap;
}

export function calculateMarkerBounds(mapData: OverviewDatum[]) {
  const minLat = min(mapData.map(c => c.environment.inferredLat)) as number;
  const minLon = min(mapData.map(c => c.environment.inferredLon)) as number;
  const maxLat = max(mapData.map(c => c.environment.inferredLat)) as number;
  const maxLon = max(mapData.map(c => c.environment.inferredLon)) as number;
  const latSpan = maxLat - minLat;
  const lonSpan = maxLon - minLon;
  const margin = 0.16;

  return [
    [
      minLon > 0 ? minLon - margin * lonSpan : minLon + margin * lonSpan,
      minLat > 0 ? minLat - margin * latSpan : minLat + margin * latSpan,
    ],
    [
      maxLon > 0 ? maxLon + margin * lonSpan : maxLon - margin * lonSpan,
      maxLat > 0 ? maxLat + margin * latSpan : maxLat - margin * latSpan,
    ],
  ] as LngLatBoundsLike;
}

export function getPositionedMarkers(
  mapData: OverviewDatum[],
  map: MapRef<MapboxMap>,
  getMarkerSizeLocal: typeof getMarkerSize,
  forcePosition?: Positions,
) {
  const labels: MarkerOverviewDatum[] = chain(mapData)
    .filter(marker => !isNil(marker.environment.inferredLat) && !isNil(marker.environment.inferredLon))
    .map(marker => {
      const position = 'top' as Positions;
      const environment = marker.environment as Environment & { inferredLat: number; inferredLon: number };
      const markerSize = getMarkerSizeLocal();
      const { x, y } = map.project([environment.inferredLon, environment.inferredLat]);
      const adminLevel =
        (marker.environment.adminLevels &&
          Object.values(marker.environment.adminLevels).find(level => marker.environment.name === level.name)) ||
        undefined;

      return {
        ...marker,
        environment,
        height: markerSize.height,
        width: markerSize.width,
        x,
        y,
        position: forcePosition || position,
        adminLevel,
      };
    })
    .sortBy(marker => marker.environment.inferredLat * -1000 + marker.environment.inferredLon)
    .value();

  // We don't move the labels to optimized positions
  if (forcePosition) {
    return labels;
  }

  mutateLabelsWithOptimizedPosition(labels);
  return labels;
}

export function getRectangleAbsolutePositions(rect: MarkerRectangle) {
  return {
    left: Math.min(rect.x, rect.x + rect.width),
    right: Math.max(rect.x, rect.x + rect.width),
    top: Math.min(rect.y, rect.y + rect.height),
    bottom: Math.max(rect.y, rect.y + rect.height),
  };
}
