import { ScaleLinear } from 'd3-scale';
import { useGeographicalReferenceLines } from './use-geographical-reference-lines';

interface Props {
  graphWidth: number;
  graphHeight: number;
  indexScale: ScaleLinear<number, number>;
}
export function GeographicalReferenceLines(props: Props) {
  const lines = useGeographicalReferenceLines();

  return (
    <>
      {lines.map((line, index) => (
        <line
          key={index}
          x1="0"
          x2={props.graphWidth}
          y1={props.indexScale(line.value)}
          y2={props.indexScale(line.value)}
          {...line.lineProps}
        />
      ))}
    </>
  );
}
