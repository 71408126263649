import { Accordion, Button, Flex } from '@chakra-ui/react';
import { TitleBar } from '@resistapp/client/components/bars/title-bar';
import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { theme } from '@resistapp/client/components/shared/theme';
import { LoadingView } from '@resistapp/client/components/states/loading';
import { useToastWithoutDebounce } from '@resistapp/client/hooks/use-resistomap-toast';
import { Project } from '@resistapp/common/types';
import { Dictionary, keyBy, sortBy } from 'lodash';
import { NavLink } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { useUser } from '../../contexts/use-user-context';
import { useOrganisations, useProjects } from '../../hooks/api';
import { OrganisationRow } from './organisation-row';
import { useUsersByOrganisationId } from './use-org-users';
import { AllUsersSection } from './users-section';

export function OrganisationsView() {
  const { isAdmin } = useUser();
  const { data: organisations, isLoading, error: organisationsError } = useOrganisations();
  const { data: projects, error: projectsError } = useProjects();

  const { usersByOrgId, refreshUsersByOrgId } = useUsersByOrganisationId();
  const showToast = useToastWithoutDebounce();
  const [orgToast, setOrganisationCreated] = useLocalStorageState(LocalStorageId.organisationCreated, {
    defaultValue: false,
  });

  const error = organisationsError || projectsError;
  if (error) {
    throw error;
  } else if (isLoading || !organisations) {
    return <LoadingView />;
  }

  if (orgToast) {
    setOrganisationCreated(false);
    showToast({
      title: 'Organisation created',
      description: 'The organisation has been created',
      status: 'success',
      duration: 9000,
    });
  }

  const projectNamesById: Dictionary<Project | null> = keyBy(projects, 'id');

  return (
    <div style={{ marginBottom: theme.spacing[16] }}>
      <TitleBar
        title="Organisations"
        $extraWide={true}
        controls={
          isAdmin ? (
            <Flex gap={theme.spacing[4]}>
              <Button as={NavLink} to="/organisation/create" alignSelf="center">
                New organisation
              </Button>
              <Button /* as={NavLink} to="/user" */ alignSelf="center" isDisabled>
                New user
              </Button>
              <Button as={NavLink} to="/project/create-empty" alignSelf="center" variant="green">
                New empty project
              </Button>
              <Button as={NavLink} to="/compose" alignSelf="center" variant="green">
                New pooled project
              </Button>
              <Button as={NavLink} to="/analyse" alignSelf="center" variant="green">
                Analyse chips
              </Button>
            </Flex>
          ) : null
        }
      />
      <Accordion allowToggle>
        {sortBy(organisations, org => org.name.toLowerCase()).map((org, key) => (
          <OrganisationRow
            key={key}
            org={org}
            projectNamesById={projectNamesById}
            usersByOrgId={usersByOrgId}
            refreshUsersByOrgId={refreshUsersByOrgId}
          />
        ))}
      </Accordion>
      <AllUsersSection />
    </div>
  );
}
