import { getFriendlyInterval } from '@resistapp/common/date-utils';
import { DEFAULT_START_INTERVAL, ensureLocalPreviousMonth, friendlyMonth } from '@resistapp/common/friendly';
import { useEffect, useMemo, useRef } from 'react';
import { useSampleDataContext } from '../contexts/sample-data-context';
import { useOverviewContext } from '../contexts/use-overview-context/use-overview-context';
import type { OverviewDatum } from '../data-utils/plot-data/build-overview-line-data';
import { useToastWithDebounce } from './use-resistomap-toast';

export interface TimeSpanOption {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface Props {
  isDisabled: (month: Date, months: Date[] | undefined) => boolean;
}

// Helper function to format a month with the correct year
function formatMonthWithCorrectYear(month: Date): string {
  // Always include the year to prevent the bug where January 2025 shows as January 2024
  return friendlyMonth(month, 'postfixAlways');
}

export function useMonthSelection({ isDisabled }: Props) {
  const { queryFilters } = useSampleDataContext();
  const { trendData, selectedSiteDatum, zoomedMapData } = useOverviewContext();
  const showToast = useToastWithDebounce();

  const interval = queryFilters.filters.interval;
  const intervalRef = useRef(interval);
  const latestMonthRef = useRef<Date | undefined>();

  const selectedOrZoomedDatum = selectedSiteDatum || zoomedMapData;
  intervalRef.current = interval;
  const months = useMemo(
    () => (selectedOrZoomedDatum ? getAvailableMonthsForSite(trendData, selectedOrZoomedDatum) : undefined),
    [trendData, selectedOrZoomedDatum],
  );

  const latestInterval = useMemo(() => {
    const latestDatumsForEachEnv = trendData?.map(dataLocal => dataLocal[dataLocal.length - 1]);
    return latestDatumsForEachEnv ? getFriendlyInterval(latestDatumsForEachEnv) : undefined;
  }, [trendData]);
  const allSelectableMonths = useMemo(
    () => months?.filter(month => formatMonthWithCorrectYear(month) !== latestInterval),
    [months, latestInterval],
  );
  latestMonthRef.current = useMemo(
    () => months?.find(month => formatMonthWithCorrectYear(month) === latestInterval),
    [months, latestInterval],
  );

  const options = useMemo(
    () =>
      [
        ...(allSelectableMonths || []).map(month => ({
          value: month.toISOString(),
          label: formatMonthWithCorrectYear(month),
          isDisabled: isDisabled(month, months),
        })),
        { value: 'latest', label: latestInterval ? `${latestInterval} (latest)` : 'Latest sampling' },
      ].reverse(),
    [latestInterval, allSelectableMonths, isDisabled, months],
  );

  const selectedValue = interval.start.toISOString() || 'latest';
  const selectedOption = options.find(option => option.value === selectedValue) || options[0];

  // this changes the date to something valid / supported. So if the URL gives you a date that this specific site or area
  // does not have, we use the default date. We only check the end month, so it works with month ranges too.
  const { setMonthStable } = queryFilters;
  useEffect(() => {
    if (!trendData || !allSelectableMonths) return;

    const queryIntervalStartDate = intervalRef.current.start;
    const monthStartBasedOnEndDate = ensureLocalPreviousMonth(intervalRef.current.end);

    const monthStartBasedOnEndDateIsValid = allSelectableMonths.some(
      month =>
        monthStartBasedOnEndDate.getTime() === month.getTime() ||
        monthStartBasedOnEndDate.getTime() === latestMonthRef.current?.getTime(),
    );
    const startAndEndDatesSuggestDifferentMonth =
      queryIntervalStartDate.getMonth() !== monthStartBasedOnEndDate.getMonth() ||
      queryIntervalStartDate.getFullYear() !== monthStartBasedOnEndDate.getFullYear();

    if (queryIntervalStartDate.getTime() === DEFAULT_START_INTERVAL.getTime()) {
      return;
    } else if (startAndEndDatesSuggestDifferentMonth && monthStartBasedOnEndDateIsValid) {
      setMonthStable(monthStartBasedOnEndDate, null);
      showToast({
        title: 'Month range not supported',
        description: `The page has range of months selected, but time range is not supported. Changing the selection to ${monthStartBasedOnEndDate.toLocaleDateString()}`,
        status: 'info',
      });
    } else if (!monthStartBasedOnEndDateIsValid) {
      setMonthStable(null, null);
      showToast({
        title: 'Data for month not available',
        description: `The selected month is not supported for this site. Changing to the newest month(s).`,
        status: 'info',
      });
    }
  }, [trendData, setMonthStable, allSelectableMonths, latestMonthRef, intervalRef, showToast]);

  return {
    options,
    selectedOption,
  };
}

function getAvailableMonthsForSite(
  data: Array<Array<{ date: string | Date; environment: { id: number } }>> | undefined,
  selectedSiteDatum: OverviewDatum,
) {
  const siteData = data?.find(dataLocal => dataLocal[0].environment.id === selectedSiteDatum.environment.id);
  return siteData?.map(dataLocal => new Date(dataLocal.date));
}
