import { friendlyGeneName } from '@resistapp/client/components/selected-map-environment/site-details/site-details-utils';
import { theme } from '@resistapp/client/components/shared/theme';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import {
  ChartDisplayMode,
  useChartDisplayMode,
} from '@resistapp/client/contexts/use-overview-context/chart-display-mode-context';
import { useTrendChartContext } from '@resistapp/client/contexts/use-overview-context/trendchart-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import {
  ALL_GENES,
  COPIES_PER_L,
  GLOBAL,
  ReferenceContinent,
  ReferenceEnvType,
  ReferenceMetric,
  ReferenceTarget,
  type ReferenceByMAEC,
} from '@resistapp/common/api-types';
import { L1Targets, L2Targets } from '@resistapp/common/assays';
import {
  EnvironmentSubType,
  EnvironmentType,
  friendlyEnvironmentType,
  friendlyEnvSubType,
} from '@resistapp/common/environment-types';
import { friendlyL2Target, sentanceCase } from '@resistapp/common/friendly';
import { MetricMode, ProcessMode } from '@resistapp/common/types';

export interface ReferenceLineProps {
  value: number;
  text: string;
  tooltipText: string;
  lineProps: {
    strokeWidth: number;
    strokeDasharray: string;
    strokeLinecap: 'round';
    stroke: string;
  };
}

export function useGeographicalReferenceLines(): ReferenceLineProps[] {
  const trendChartContext = useTrendChartContext();
  const { chartDisplayMode } = useChartDisplayMode();
  const { getAssayInfo } = useAssayContext();
  const {
    queryFilters: {
      filters: { selectedTargets },
    },
  } = useSampleDataContext();
  const { referenceLineData, metricMode, selectedSiteDatum, effectiveSiteDetailsProcessMode } = useOverviewContext();

  const referenceMetric = chartDisplayMode === ChartDisplayMode.METRIC ? metricMode : COPIES_PER_L;
  const referenceTarget =
    selectedTargets.length === 1 ? selectedTargets[0] : metricMode === MetricMode.REDUCTION ? ALL_GENES : L1Targets.ARG;
  const referenceEnvType =
    metricMode === MetricMode.REDUCTION
      ? selectedSiteDatum?.environment.type
      : effectiveSiteDetailsProcessMode === ProcessMode.BEFORE
        ? selectedSiteDatum?.environment.subtype
        : selectedSiteDatum?.environmentAfter?.subtype;
  const continent: ReferenceContinent | undefined = trendChartContext.continent;
  const continentMean =
    referenceLineData &&
    continent &&
    referenceEnvType &&
    getReference(referenceMetric, referenceTarget, referenceEnvType, continent, referenceLineData);

  // const globalMean =
  //   referenceLineData &&
  //   siteEnvironment &&
  //   getReference(metricMode, selectedTarget, siteEnvironment, GLOBAL, referenceLineData);
  // const globalMeanHeight = useMemo(() => globalMean && getScaledY(globalMean), [getScaledY, globalMean]);

  // console.log('DIMS', referenceMetric, referenceTarget, referenceEnvType, continent, referenceLineData);

  const selectedAssay = selectedTargets.length === 1 && selectedTargets[0].startsWith('AY') && selectedTargets[0];

  // const global = globalMeanHeight && {
  //   y: globalMeanHeight,
  //   lineProps: {
  //     stroke: globalMean && globalMean >= 2 ? theme.colors.levelMod : theme.colors.levelLow,
  //     strokeDasharray: '1,8',
  //     strokeWidth: 2.5,
  //     strokeLinecap: 'round' as const,
  //   },
  //   text: selectedAssay
  //     ? 'Global metric mean'
  //     : metricMode === MetricMode.ARGI
  //       ? 'Global mean ARGI'
  //       : metricMode === MetricMode.REDUCTION
  //         ? 'Global mean Reduction'
  //         : 'Global mean Risk score',
  //   tooltipText: `Mean ${
  //     selectedAssay
  //       ? 'Antibiotic Resistance Gene metric mean'
  //       : metricMode === MetricMode.ARGI
  //         ? 'Antibiotic Resistance Gene Indexes'
  //         : metricMode === MetricMode.REDUCTION
  //           ? 'Reduction'
  //           : 'Risk score'
  //   } from waste water samples in the Resistomap database, equally weighted between continents.`,
  // };

  const doColor = metricMode === MetricMode.ARGI && chartDisplayMode === ChartDisplayMode.METRIC;
  const antibioticName = selectedAssay
    ? ''
    : selectedTargets.length === 1
      ? friendlyL2Target(selectedTargets[0] as L2Targets)
      : '';

  const isGlobal = (continent as string) === GLOBAL;
  const continentPrefix = isGlobal ? 'Global ' : `${sentanceCase(continent || '')}an`;

  const typeString =
    metricMode === MetricMode.REDUCTION
      ? friendlyEnvironmentType(referenceEnvType as EnvironmentType)
      : selectedSiteDatum?.environment.type &&
        friendlyEnvSubType(referenceEnvType as EnvironmentSubType, selectedSiteDatum.environment.type);

  if (metricMode === MetricMode.REDUCTION && chartDisplayMode === ChartDisplayMode.GENES) {
    // Not supported for now (we'd need a double line per continent)
    return [];
  }

  const meanShort = chartDisplayMode === ChartDisplayMode.GENES ? `geom. mean` : 'mean';
  const meanLong = chartDisplayMode === ChartDisplayMode.GENES ? `geometric mean` : 'mean';
  const name = selectedAssay
    ? friendlyGeneName(selectedAssay, getAssayInfo(selectedAssay)?.gene || '')
    : antibioticName;
  const metric =
    chartDisplayMode === ChartDisplayMode.GENES
      ? 'gene copies per litre'
      : metricMode === MetricMode.ARGI
        ? 'Index'
        : metricMode === MetricMode.REDUCTION
          ? 'Reduction'
          : 'Risk score';
  const continentRefLine =
    continent && continentMean
      ? {
          value: continentMean,
          lineProps: {
            stroke: doColor
              ? continentMean >= 2
                ? theme.colors.levelMod
                : theme.colors.levelLow
              : theme.colors.neutral400,
            strokeDasharray: '8,8',
            strokeWidth: 2.5,
            strokeLinecap: 'round' as const,
          },
          text: `${continentPrefix} ${meanShort} ${name ? `for ${name} ` : metric.toLowerCase()}`,
          tooltipText: `${continentPrefix} ${meanLong} ${metric} for ${name} in ${typeString} samples in the Resistomap database.`,
        }
      : undefined;

  // if (metricMode === MetricMode.ARGI && global) {
  //   referenceLines.push(global);
  // }

  const referenceLines = [];
  if (continentRefLine) {
    referenceLines.push(continentRefLine);
  }

  return referenceLines.sort((a, b) => a.value - b.value);
}

function getReference(
  metricMode: ReferenceMetric,
  assayOrTarget: ReferenceTarget,
  etype: ReferenceEnvType,
  continent: ReferenceContinent,
  referenceData: ReferenceByMAEC,
) {
  return referenceData[metricMode]?.[assayOrTarget]?.[etype]?.[continent]?.mean;
}
