import { useToast } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

const DEFAULT_TOAST_DURATION_ERROR = 30000; // 30 seconds
const DEFAULT_TOAST_DURATION = 20000;
const DEFAULT_TOAST_DURATION_SUCCESS = 10000;
const DEFAULT_TOAST_DEBOUNCE = 1000; // 1 second

type ToastParams = {
  title: string;
  description: string;
  status: 'info' | 'warning' | 'success' | 'error';
  duration?: number | null; // null means no auto-dismiss
  isClosable?: boolean;
};

export function useToastWithDebounce(debounceMs = DEFAULT_TOAST_DEBOUNCE) {
  const toast = useToast();

  const showToastRef = useCallback(
    ({ title, description, status, duration, isClosable }: ToastParams) => {
      toast({
        title,
        description,
        status,
        duration:
          duration !== undefined
            ? duration
            : status === 'error'
              ? DEFAULT_TOAST_DURATION_ERROR
              : status === 'success'
                ? DEFAULT_TOAST_DURATION_SUCCESS
                : DEFAULT_TOAST_DURATION,
        isClosable: isClosable ?? true,
      });
    },
    [toast],
  );

  const showToastStable = useMemo(() => debounce(showToastRef, debounceMs), [showToastRef, debounceMs]);

  // Clean up the debounced function on unmount
  useEffect(() => {
    return () => {
      showToastStable.cancel();
    };
  }, [showToastStable]);

  return showToastStable;
}

export function useToastWithoutDebounce() {
  const toast = useToast();

  const showToastStable = useCallback(
    ({ title, description, status, duration, isClosable }: ToastParams) => {
      toast({
        title,
        description,
        status,
        duration:
          duration !== undefined
            ? duration
            : status === 'error'
              ? DEFAULT_TOAST_DURATION_ERROR
              : status === 'success'
                ? DEFAULT_TOAST_DURATION_SUCCESS
                : DEFAULT_TOAST_DURATION,
        isClosable: isClosable ?? true,
      });
    },
    [toast],
  );

  return showToastStable;
}
