import { OverviewDatum } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import {
  GeneAndCopyNumber,
  targetAbundancesByAssay,
} from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { AssayInfo, AssayStr, GetGroup, L1Target, L2Target } from '@resistapp/common/assays';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { get10FoldChangeInVolume, getRelative10FoldChange } from '@resistapp/common/statistics/fold-change';
import { chain, get } from 'lodash';
import { formatGeneMutationSuffixes } from './general-site-details';

export const detailGraphHeight = 280;

export function getFriendlyGenesAndReductionNumbers(
  genesAndCopyNumbers: GeneAndCopyNumber[],
  selectedSiteDatum: OverviewDatum,
  target: MinorTarget | L1Target | L2Target,
  allAssays: AssayInfo[],
  getGroup: GetGroup,
) {
  if (!selectedSiteDatum.beforeAbundances || !selectedSiteDatum.afterAbundances) {
    return [];
  }
  const influentValues = chain(targetAbundancesByAssay(selectedSiteDatum.beforeAbundances, target, getGroup, true))
    .values()
    .flatten()
    .value();
  const effluentValues = chain(targetAbundancesByAssay(selectedSiteDatum.afterAbundances, target, getGroup, true))
    .values()
    .flatten()
    .value();

  return genesAndCopyNumbers.map(geneAndNumber => {
    const relativeFoldChane = getRelative10FoldChange(
      influentValues,
      effluentValues,
      geneAndNumber.assay as AssayStr,
      allAssays,
    );
    const foldChangeInVol = get10FoldChangeInVolume(
      influentValues,
      effluentValues,
      geneAndNumber.assay as AssayStr,
      allAssays,
    );

    return {
      ...geneAndNumber,
      gene: friendlyGeneName(geneAndNumber.assay, geneAndNumber.gene),
      reduction: relativeFoldChane, // TODO RENAME THESE
      log10: foldChangeInVol,
    };
  });
}

export function getFriendlyGenesAndCopyNumbers(genesAndCopyNumbers: GeneAndCopyNumber[]) {
  return genesAndCopyNumbers.map(geneAndCopyNumber => ({
    ...geneAndCopyNumber,
    gene: friendlyGeneName(geneAndCopyNumber.assay, geneAndCopyNumber.gene),
  }));
}

export function friendlyGeneName(assay: string, geneName: string): string {
  const replaceables: Record<string, string> = {
    AY465: 'Bacitracin resistance',
    AY198: 'CrAssphage',
    AY191: 'Mercury resistance',
    AY466: 'Colistin resistance',
    AY218: 'Biocide resistance',
    AY631: 'E.coli',
  };
  return formatGeneMutationSuffixes(get(replaceables, assay, geneName));
}
