import { Flex } from '@chakra-ui/react';
import { useAssayContext } from '@resistapp/client/contexts/assay-context';
import { useOverviewContext } from '@resistapp/client/contexts/use-overview-context/use-overview-context';
import { getBeforeOrAfterAbundances } from '@resistapp/client/data-utils/plot-data/build-overview-line-data';
import { targetAbundancesByAssay } from '@resistapp/client/data-utils/plot-data/process-overview-line-datum';
import { get_inRawOrEffluentSamplesString } from '@resistapp/client/utils/string-manipulation';
import { MinorTarget } from '@resistapp/common/assays-temp-96-gene-minor-targets';
import { FullAbundance, MetricMode } from '@resistapp/common/types';
import { chain } from 'lodash';
import { MSTHorizontalBarGraph } from '../mst-horizontal-bar-graph';
import { SiteDetailTitle } from '../site-detail-title';
import { SiteDetailContainer, SiteDetailsBaseProps } from './general-site-details';

export function MSTDetails({ containerRef }: SiteDetailsBaseProps) {
  const { selectedSiteDatum, metricMode, effectiveSiteDetailsProcessMode } = useOverviewContext();
  const { getGroup } = useAssayContext();
  if (!selectedSiteDatum) {
    return null;
  }

  const mainAbundances = getBeforeOrAfterAbundances(selectedSiteDatum, effectiveSiteDetailsProcessMode) as
    | FullAbundance[]
    | undefined;
  if (!mainAbundances?.length) {
    return null;
  }

  const mainAbundancesByAssay = targetAbundancesByAssay(mainAbundances, MinorTarget.MST, getGroup);
  const detectedMstMainAbundances = chain(mainAbundancesByAssay)
    .values()
    .flatten()
    // .filter(target => target.absolute !== null) // This is not needed as we show all analysed categories
    .value();

  const afterAbundancesByAssay =
    metricMode === MetricMode.REDUCTION && selectedSiteDatum.afterAbundances
      ? targetAbundancesByAssay(selectedSiteDatum.afterAbundances, MinorTarget.MST, getGroup)
      : null;
  const detectedMstAfterAbundances = afterAbundancesByAssay
    ? chain(afterAbundancesByAssay)
        .values()
        .flatten()
        // .filter(target => target.absolute !== null) // This is not needed as we show all analysed categories
        .value()
    : [];

  const showAfterAbundances = metricMode === MetricMode.REDUCTION && detectedMstAfterAbundances.length > 0;

  const _inRawOrEffluentSamples = get_inRawOrEffluentSamplesString(selectedSiteDatum, effectiveSiteDetailsProcessMode);
  return (
    <SiteDetailContainer ref={containerRef} key="site-detail-box-pathogens">
      <SiteDetailTitle
        title="Microbial source tracking"
        explanation={`Relative distribution of host-specific genetic marker copy numbers${_inRawOrEffluentSamples}`}
      />
      {detectedMstMainAbundances.length > 0 ? (
        <>
          {showAfterAbundances ? (
            <>
              <MSTHorizontalBarGraph data={detectedMstMainAbundances} title="Raw" />
              <MSTHorizontalBarGraph data={detectedMstAfterAbundances} title="Treated" hideLegend={true} />
            </>
          ) : (
            <MSTHorizontalBarGraph data={detectedMstMainAbundances} />
          )}
        </>
      ) : (
        <Flex justifyContent="center" alignItems="center" height="100%">
          Data not available for this site or sample type
        </Flex>
      )}
    </SiteDetailContainer>
  );
}
