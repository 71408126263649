import { downloadDataUrl, getImageDataUrlFromHtml, getImageDataUrlFromSVG } from '@resistapp/client/utils/exporting';
import { NormalisedValueName } from '@resistapp/common/normalisation-mode';
import React, { createContext, useContext, useState } from 'react';
import { DownloadOptionsDialog } from '../components/dialogs/download-options-dialog';
import { createOverlay } from '../views/research-view/admin-section-utils';
import { useResearchContext } from './research-context';

interface ChartExportConfig {
  id: string | undefined;
  type: 'chart' | 'legend';
  plotType: 'heat' | 'box' | 'bar';
  label: string;
}

export interface ChartLegendPair {
  legendId: string | undefined;
  chartId: string;
  plotType: 'heat' | 'box' | 'bar';
  label: string;
  skipLegend?: boolean;
}

interface ChartDownloadContextType {
  downloadChart: (config: ChartExportConfig) => Promise<void>;
  downloadChartAndLegend: (config: ChartLegendPair) => void;
}

const ChartDownloadContext = createContext<ChartDownloadContextType | null>(null);

export function useChartDownload() {
  const context = useContext(ChartDownloadContext);
  if (!context) {
    throw new Error('useChartDownload must be used within a ChartDownloadProvider');
  }
  return context;
}

export function ChartDownloadProvider({ children }: { children: React.ReactNode }) {
  const { project, queryFilters } = useResearchContext();
  const normalisationMode = queryFilters.filters.normalisationMode;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentConfig, setCurrentConfig] = useState<ChartLegendPair | null>(null);

  console.log('ChartDownloadProvider render - isDialogOpen:', isDialogOpen, 'currentConfig:', currentConfig);

  async function downloadChart(config: ChartExportConfig) {
    if (!project || !config.id) {
      return;
    }

    const overlay = createOverlay('Exporting the image...');
    const fileName = `${project.id}_${NormalisedValueName[normalisationMode].replace(/\s+/g, '-')}_${config.label}`;

    try {
      if (config.type === 'chart') {
        const padding =
          config.plotType === 'heat' ? { left: 130, bottom: 60, right: 1 } : { left: 0, bottom: -10, right: 5 };
        const element = document.getElementById(config.id);
        if (!(element instanceof SVGElement)) {
          throw new Error(`Element with id ${config.id} is not an SVGElement`);
        }

        const dataUrl = await getImageDataUrlFromSVG(element, config.plotType, { padding, keepElement: false });
        if (dataUrl) {
          downloadDataUrl(dataUrl, `${fileName}.png`);
        }
      } else {
        const element = document.getElementById(config.id) as HTMLElement;
        const dataUrl = await getImageDataUrlFromHtml(element, true);
        if (dataUrl) {
          downloadDataUrl(dataUrl, `${fileName}.png`);
        }
      }
    } catch (error) {
      console.error('Error exporting image:', error);
    } finally {
      document.body.removeChild(overlay);
    }
  }

  async function handleDownloadOptions(includeChart: boolean, includeLegend: boolean) {
    if (!project || !currentConfig) {
      return;
    }

    const overlay = createOverlay(includeChart && includeLegend ? 'Exporting images...' : 'Exporting image...');

    try {
      // First download the legend if selected
      if (currentConfig.legendId && includeLegend) {
        const legendElement = document.getElementById(currentConfig.legendId);
        if (legendElement) {
          const legendFileName = `${project.id}_${NormalisedValueName[normalisationMode].replace(/\s+/g, '-')}_${currentConfig.label}_Legend`;
          const legendDataUrl = await getImageDataUrlFromHtml(legendElement, true);
          if (legendDataUrl) {
            downloadDataUrl(legendDataUrl, `${legendFileName}.png`);
          }
        }
      }

      // Then download the chart if selected (with slight delay if both are being downloaded)
      if (includeChart) {
        const processChartDownload = async () => {
          const chartElement = document.getElementById(currentConfig.chartId);
          if (chartElement instanceof SVGElement) {
            const chartFileName = `${project.id}_${NormalisedValueName[normalisationMode].replace(/\s+/g, '-')}_${currentConfig.label}_Chart`;
            const padding =
              currentConfig.plotType === 'heat'
                ? { left: 130, bottom: 60, right: 1 }
                : { left: 0, bottom: -10, right: 5 };

            const chartDataUrl = await getImageDataUrlFromSVG(chartElement, currentConfig.plotType, {
              padding,
              keepElement: false,
            });

            if (chartDataUrl) {
              downloadDataUrl(chartDataUrl, `${chartFileName}.png`);
            }
          }
        };

        if (includeLegend) {
          // Add slight delay if both are being downloaded to prevent browser issues
          setTimeout(() => {
            void processChartDownload();
          }, 500);
        } else {
          await processChartDownload();
        }
      }
    } catch (error) {
      console.error('Error exporting images:', error);
    } finally {
      document.body.removeChild(overlay);
    }
  }

  function downloadChartAndLegend(config: ChartLegendPair) {
    console.log('downloadChartAndLegend called with config:', config);

    if (!project) {
      console.log('Project is null, returning early');
      return;
    }

    // For backwards compatibility or automated testing
    if (config.skipLegend === true) {
      console.log('skipLegend is true, bypassing dialog');
      const chartConfig: ChartExportConfig = {
        id: config.chartId,
        type: 'chart',
        plotType: config.plotType,
        label: config.label,
      };
      void downloadChart(chartConfig);
      return;
    }

    // Store the config and open the dialog
    console.log('Setting current config and opening dialog');
    setCurrentConfig(config);
    setIsDialogOpen(true);
    console.log('Dialog state set to open');
  }

  const contextValue: ChartDownloadContextType = {
    downloadChart,
    downloadChartAndLegend,
  };

  return (
    <ChartDownloadContext.Provider value={contextValue}>
      {children}
      {isDialogOpen && currentConfig && (
        <DownloadOptionsDialog
          isOpen={isDialogOpen}
          onClose={() => {
            console.log('Dialog onClose called');
            setIsDialogOpen(false);
          }}
          onDownload={handleDownloadOptions}
          chartLabel={currentConfig.label}
        />
      )}
    </ChartDownloadContext.Provider>
  );
}
