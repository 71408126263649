import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { zodResolver } from '@hookform/resolvers/zod';
import { AgreePoliciesCheckbox } from '@resistapp/client/components/agree-policies-checkbox/agree-policies-checkbox';
import { SpacingContainer } from '@resistapp/client/components/containers/spacing-container';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { oldCss, theme } from '@resistapp/client/components/shared/theme';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { useSignup } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { PathParams, usePathParam } from '@resistapp/client/hooks/use-path-params';
import { SignupUserRequest, zSignupUserRequest } from '@resistapp/common/api-types';
import { useForm } from 'react-hook-form';
import useLocalStorageState from 'use-local-storage-state';
import { SignupLogo } from '../../components/icons/signup-logo';

const formFields = [
  {
    name: 'email' as const,
    label: 'Email',
    type: 'email',
  },
  {
    name: 'password' as const,
    label: 'Password',
    type: 'password',
  },
  {
    name: 'firstName' as const,
    label: 'First name',
    type: 'text',
  },
  {
    name: 'lastName' as const,
    label: 'Last name',
    type: 'text',
  },
  {
    name: 'organisation' as const,
    label: 'Company / organisation',
    type: 'text',
  },
  {
    name: 'position' as const,
    label: 'Role',
    type: 'text',
  },
] satisfies Array<{ name: keyof SignupUserRequest; label: string; type: string }>;
const defaultValues: Partial<SignupUserRequest> = {};

export function SignupView() {
  const signupCode = usePathParam(PathParams.signupCode);
  const { logout, user } = useUser();
  const { create } = useSignup();
  const navigate = useNavigateWithQuery();
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorageState(LocalStorageId.cookiesAccepted, {
    defaultValue: false,
  });
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<SignupUserRequest>({
    resolver: zodResolver(zSignupUserRequest),
    defaultValues,
  });

  // We logout the user, if the user is already logged in
  if (user) {
    logout();
    navigate(0, true);
  }

  if (create.isSuccess) {
    return (
      <Flex direction="column" alignItems="center">
        <SpacingSignupContainer>
          <SignupLogo />
          <Alert status="success" style={{ margin: '10px 0' }}>
            <AlertIcon />
            <AlertDescription>
              Account created.
              <br />
              Please check and verify your email!
            </AlertDescription>
          </Alert>
        </SpacingSignupContainer>
      </Flex>
    );
  }

  if (!signupCode) {
    return (
      <div>
        Please check your email for a signup link, or <a href="mailto:info@resistomap.com">contact us</a> for a demo
        invite.
      </div>
    );
  }

  const onSubmit = async (values: SignupUserRequest) => {
    try {
      await create.mutateAsync(values);
      reset(defaultValues);
    } catch (_error) {
      console.error(_error);
    }
  };

  const onValidationFailed = (_errors: any) => {
    console.warn('Form validation failed', _errors);
  };

  return (
    <Flex direction="column" alignItems="center">
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <SpacingSignupContainer as="form" onSubmit={handleSubmit(onSubmit, onValidationFailed)}>
        <SignupLogo />
        <RSecondTitle>Resistomap Platform Signup</RSecondTitle>

        <Stack spacing={6}>
          {formFields.map(field => (
            <FormControl isInvalid={!!errors[field.name]} key={field.name}>
              <FormLabel>{field.label}</FormLabel>
              <Input placeholder={field.label} type={field.type} {...register(field.name)} />
              <FormErrorMessage>{errors[field.name]?.message}</FormErrorMessage>
            </FormControl>
          ))}
          <AgreePoliciesCheckbox
            cookiesAccepted={cookiesAccepted}
            setCookiesAccepted={setCookiesAccepted}
            disabled={isSubmitting}
          />
          {<input type="hidden" {...register('signupCode', { value: signupCode })} />}
          {create.error && (
            <Alert status="error" style={{ margin: '10px 0' }}>
              <AlertIcon />
              <AlertDescription>{create.error.message}</AlertDescription>
            </Alert>
          )}
          <SubmitButton
            mt={4}
            isLoading={isSubmitting}
            isDisabled={!cookiesAccepted}
            type="submit"
            style={{ backgroundColor: theme.colors.blue900, color: 'white' }}
          >
            Signup
          </SubmitButton>
        </Stack>
      </SpacingSignupContainer>
    </Flex>
  );
}

const SpacingSignupContainer = styled(SpacingContainer)`
  max-width: 400px;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  text-transform: capitalize;
  ${oldCss.greyBorder200};
`;
