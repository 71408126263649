import styled from '@emotion/styled';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { useProjectImage } from '@resistapp/client/hooks/api';
import { useEffect } from 'react';
import { DownloadIcon } from '../icons/download-icon';

interface Props {
  filename: string;
  getFileContent?: () => Promise<string> | string; // Either getFileContent or projectId required
  projectId?: number;
}

export function DownloadLink({ projectId, filename, getFileContent }: Props) {
  const { data: image, mutate: download } = useProjectImage(projectId || 0);

  useEffect(() => {
    if (image) {
      clickDownloadElement(image, filename);
    }
  }, [image, filename]);

  return (
    <Root>
      <Link
        onClick={() => {
          void (async () => {
            const fileContent = getFileContent && (await getFileContent());
            if (fileContent) {
              clickDownloadElement(fileContent, filename);
            } else {
              download({ filename });
            }
          })();
        }}
      >
        <DownloadIcon size="md" style={{ paddingTop: '4px' }} />
        {filename}
      </Link>
    </Root>
  );
}

const clickDownloadElement = (content: string | Blob, filename: string) => {
  if (content) {
    const element = document.createElement('a');
    const file = typeof content === 'string' ? new Blob([content], { type: 'text/html' }) : content;
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};

const Root = styled.div`
  color: ${oldTheme.lightBlue};
  font-size: ${oldTheme.fontSize.s};
  margin-left: ${oldTheme.spacing.xxs};
`;

const Link = styled.a`
  display: flex;
  gap: 5px;
  min-width: 165px;
  cursor: pointer;
  color: gray;
  &:hover {
    color: ${oldTheme.black};
  }
`;
