import { MAPBOX_TOKEN } from '@resistapp/common/utils';

import { Box } from '@chakra-ui/react';
import { useMapContext } from '@resistapp/client/contexts/map-context';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useCallback, useEffect } from 'react';
import ReactMapGL, { NavigationControl, type MapRef } from 'react-map-gl';

// Add a declaration to extend the Window interface
declare global {
  interface Window {
    mapInstance: any; // Using 'any' to avoid type conflicts
  }
}

interface MapboxMapProps {
  children?: React.ReactNode;
  isFullsize: boolean;
}

/* MapBoxMap responsibilities is to handle the mapbox related map funcionalities. Meaning showing the map, zooming etc.
 * It does not handle the map data, or the markers.
 */
// NOTE: We need to wait for the map to be loaded before we can use it, otherwise we get an undefined error
export function MapboxMap({ children, isFullsize }: MapboxMapProps) {
  const { setMapInstance, setMapLoaded, mapReady, mapInstance } = useMapContext();

  useEffect(() => {
    if (mapReady && mapInstance) {
      setTimeout(() => {
        mapInstance.resize();
      });
    }
  }, [isFullsize, mapInstance, mapReady]);

  const onLoad = useCallback(() => {
    setMapLoaded(true);
    // Make the map instance available globally for debugging
    window.mapInstance = mapInstance;
  }, [setMapLoaded, mapInstance]);

  const setMapRef = useCallback(
    (mapRef: MapRef | null) => {
      if (!mapInstance && mapRef) {
        const map = mapRef.getMap();
        setMapInstance(map);
      }
    },
    [mapInstance, setMapInstance],
  );

  return (
    <Box position="relative" width="100%" height="100%">
      <ReactMapGL
        reuseMaps
        mapboxAccessToken={MAPBOX_TOKEN}
        ref={setMapRef}
        onLoad={onLoad}
        mapStyle="mapbox://styles/janneresistomap/clsvr6uah005w01qw17sh3sd7"
        style={{ width: '100%', height: '100%' }}
        trackResize
        // cooperativeGestures
        scrollZoom={false}
      >
        {mapReady && (
          <>
            <NavigationControl position="bottom-left" />
            {children}
          </>
        )}
      </ReactMapGL>
    </Box>
  );
}
