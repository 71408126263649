import { Alert, AlertDescription, AlertIcon, Button, Checkbox, Flex, Stack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { SpacingContainer } from '@resistapp/client/components/containers/spacing-container';
import { InputField } from '@resistapp/client/components/forms/form-inputs/input-field';
import { RSecondTitle } from '@resistapp/client/components/headers/r-second-title';
import { InfoTooltipIcon } from '@resistapp/client/components/icons/info-tooltip-icon';
import { LocalStorageId } from '@resistapp/client/components/shared/local-storage';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { PostOrganisationRequest, zPostOrganisationRequest } from '@resistapp/common/api-types';
import { Feature } from '@resistapp/common/features';
import { MetricMode } from '@resistapp/common/types';
import { useForm } from 'react-hook-form';
import useLocalStorageState from 'use-local-storage-state';
import { ControlledInput } from '../../components/forms/form-inputs/controlled-input';
import { ControlledSelect } from '../../components/forms/form-inputs/controlled-select';
import { usePatchOrganisation, useProjects } from '../../hooks/api';

export function CreateOrganisationView() {
  const defaultValues = {};
  const create = usePatchOrganisation();
  const { data: projects, error: projectsError } = useProjects();
  const [, setOrganisationCreated] = useLocalStorageState(LocalStorageId.organisationCreated, {
    defaultValue: false,
  });
  const navigate = useNavigateWithQuery();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm<PostOrganisationRequest>({
    resolver: zodResolver(zPostOrganisationRequest),
    defaultValues,
  });

  if (projectsError) {
    throw projectsError;
  }

  if (create.isSuccess) {
    setOrganisationCreated(true);
    navigate('/admin', false);
  }

  const onSubmit = async (values: PostOrganisationRequest) => {
    try {
      await create.mutateAsync(values);
      reset(defaultValues);
    } catch (_error) {
      console.error(_error);
    }
  };

  const onValidationFailed = (_errors: any) => {
    console.warn('Form validation failed', _errors);
  };

  return (
    <Flex direction="column" alignItems="center">
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <SpacingContainer as="form" onSubmit={handleSubmit(onSubmit, onValidationFailed)}>
        <RSecondTitle>Create an organisation</RSecondTitle>

        <Stack spacing={6}>
          <InputField
            key="name"
            field={{
              name: 'name',
              label: 'Organisation name*',
              type: 'text',
            }}
            errors={errors.name}
            register={register}
          />
          <ControlledSelect
            control={control}
            name="features"
            options={Object.values(Feature)}
            closeMenuOnSelect={true}
            placeholder="Select features"
            isMulti
          />
          <ControlledSelect
            control={control}
            name="accesses"
            options={projects || []}
            placeholder="Select projects"
            labeller={option => option.name}
            isMulti
          />
          <ControlledSelect
            control={control}
            name="defaultMetric"
            options={Object.values(MetricMode)}
            closeMenuOnSelect={true}
            placeholder="Select default metric"
          />

          <ControlledInput control={control} name="isDemo" label={null}>
            <Checkbox size="lg">
              Demo organisation{' '}
              <InfoTooltipIcon>
                Check if the organisation is created for demo purposes, and not for a real customer. Only demo
                organisations are allowed to have users that are not part of the organisation legal entity in the real
                world (eg. partners or collaborators).
              </InfoTooltipIcon>
            </Checkbox>
          </ControlledInput>
          {create.error && (
            <Alert status="error" style={{ margin: '10px 0' }}>
              <AlertIcon />
              <AlertDescription>{create.error.message}</AlertDescription>
            </Alert>
          )}
          <Button type="submit" isLoading={isSubmitting} isDisabled={Object.keys(errors).length > 0}>
            Create new
          </Button>
        </Stack>
      </SpacingContainer>
    </Flex>
  );
}
